import { Flex, Input, Radio, Typography } from "antd"
import React, { FC } from "react"
import { useNavigate } from "react-router-dom"
import { campaignData } from "src/components/pages/campaigns/campaignsData"
import { ConfirmButtons, Modal } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"

type CreateCampaignsModalProps = {
  open: boolean
  onCancel: VoidFunction
}

export const CreateFromSavedModal: FC<CreateCampaignsModalProps> = ({ open, onCancel }) => {
  const navigate = useNavigate()
  const closeModal = () => {
    onCancel()
  }

  return (
    <Modal
      open={open}
      title={"Select previous campaign, on the basis of which a new one will be created"}
      onCancel={closeModal}
      styles={{ header: { maxWidth: "400px" } }}
    >
      <Flex vertical gap={15}>
        <Flex vertical gap={10}>
          <Typography.Paragraph>Name new campaign</Typography.Paragraph>
          <Input placeholder={"Enter text"} />
        </Flex>
        <Flex vertical gap={10}>
          <Typography.Paragraph>Previous campaigns</Typography.Paragraph>
          <Input.Search
            className={"search-input"}
            placeholder={"Search Prospect data..."}
            allowClear
            prefix={<SvgIcon type={"search"} />}
            enterButton={false}
          />
          <Radio.Group>
            <Flex gap={5} vertical>
              {campaignData.map((item, index) => (
                <Radio key={`${index}-${item.id}`} value={item.campaign}>
                  {item.campaign}
                </Radio>
              ))}
            </Flex>
          </Radio.Group>
        </Flex>
        <ConfirmButtons
          handleSubmit={() => navigate("/campaign/from-saved")}
          onCancel={closeModal}
          rightButtonTitle={"Edit Prospects"}
          leftButtonTitle={"Back"}
        />
      </Flex>
    </Modal>
  )
}
