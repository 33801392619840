import React, { useState } from "react";
import { Select, SelectProps } from "antd";
import { SvgIcon } from "src/shared/icons";
import Fuse from "fuse.js";
import moment from "moment";
import "./index.less";

interface Option {
  value: string;
  label: string | React.ReactNode;
}

interface CustomSelectProps extends SelectProps<string> {
  customClassName?: string;
  isSearchSelect?: boolean;
  useOnSelectChange?: boolean;
  options: Option[];
}

const { Option: AntOption } = Select;

const highlightMatch = (text: string, query: string): string => {
  const fuse = new Fuse([text], { includeMatches: true, threshold: 0.4 });
  const result = fuse.search(query);
  if (result.length === 0 || result?.matches?.length) return text;

  const matches = result[0].matches[0].indices;
  let highlightedText = "";
  let lastIndex = 0;

  matches.forEach(([start, end]) => {
    highlightedText += text.slice(lastIndex, start);
    // highlightedText += `<strong style="background-color: rgb(222, 233, 255)">${text.slice(start, end + 1)}</strong>`;
    highlightedText += `<span style="background-color: #e6f7ff !important; color: #1890ff !important">${text.slice(start, end + 1)}</span>`;
    lastIndex = end + 1;
  });

  highlightedText += text.slice(lastIndex);
  return highlightedText;
};

export const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  isSearchSelect = true,
  useOnSelectChange = false,
  customClassName,
  value,
  onChange,
  ...props
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    if (isSearchSelect) {
      const fuse = new Fuse(options, { keys: ["label"], threshold: 0.4 });
      setFilteredOptions(value ? fuse.search(value).map((result) => result.item) : options);
    }
  };

  const handleChange = (value: string) => {
    setSearchQuery("");
    setFilteredOptions(options);
    if (onChange) onChange(value);
  };

  return (
    <Select
      showSearch={isSearchSelect}
      onSearch={isSearchSelect ? handleSearch : undefined}
      filterOption={false} // Disable default filtering to use Fuse.js
      suffixIcon={<SvgIcon type="selectArrow" />}
      className={`custom-select-root custom-select search-select ${customClassName}`}
      value={value}
      onChange={useOnSelectChange ? undefined : handleChange}
      onSelect={useOnSelectChange ? handleChange : undefined}
      aria-label="_noacinput" // randome letters in label stops autocomplete
      {...props}
    >
      {(searchQuery && isSearchSelect ? filteredOptions : options).map((option, i) => {
        const value = typeof option === "object" && option !== null && "value" in option ? option.value : option;
        const label = typeof option === "object" && option !== null && "label" in option ? option.label : option;

        return (
          <AntOption key={i} value={value} style={{ padding: "12px 24px", borderRadius: "unset", fontWeight: 400 }}>
            {typeof label === "string" ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: highlightMatch(i === 0 || i === 4 ? `${label}` : label, searchQuery) as string,
                }}
              />
            ) : (
              label
            )}
          </AntOption>
        );
      })}
    </Select>
  );
};

// import { Select, SelectProps } from "antd"
// import { SvgIcon } from "src/shared/icons"
// import "./index.less"
// import React from "react"
// import { Option } from "antd/es/mentions"

// interface Option {
//   value: string
//   label: string | React.ReactNode
// }

// interface CustomSelectProps extends SelectProps<string> {
//   customClassName?: string
//   isSearchSelect?: boolean
//   options: Option[]
// }

// export const CustomSelect: React.FC<CustomSelectProps> = ({
//   options,
//   isSearchSelect = true,
//   customClassName,
//   ...props
// }) => {
//    return (
//     <Select
//       showSearch
//       filterOption={(input, option) => {
//         const label = typeof option?.children === 'string' ? option.children : '';
//         return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
//       }}
//       suffixIcon={ <SvgIcon type="selectArrow" /> }
//       className={`custom-select-root custom-select search-select ${customClassName}`}
//       {...props}
//     >
//       {options.map((option,i) => (
//         <Option key={i} value={option.value}
//         style={{padding:'12px 24px',  borderRadius: 'unset',fontWeight: 400}}>
//           {option.label}
//         </Option>
//       ))}
//     </Select>
//   );
// return (
//   <Select
//     options={options}
//     suffixIcon={!isSearchSelect ? <SvgIcon type={"selectArrow"} /> : undefined}
//     className={`custom-select ${isSearchSelect && "search-select"} ${customClassName}`}
//     rootClassName={"custom-select-root"}
//     {...props}
//   />
// )
// }
