import { Button, message } from "antd";
import React, { FC, useEffect, useState } from "react";
import PageLayout from "src/components/layout/PageLayout";
import { ManageEmailDrawer } from "src/components/pages/emails-data";
import { statisticEmailsData } from "src/components/pages/emails-data/info";
import { EmailsTable } from "src/components/pages/emails-data/ui/EmailsTable";
import { StatisticsBar } from "src/components/ui/StatisticsBar";
import { useBreakpoints } from "../../helpers/useBreakpoints";
import { GoToDesktopModal } from "../../components/ui/GoToDesktopModal";
import { EmptyState } from "../../components/ui/EmptyState";
import { useDispatch, useSelector } from "react-redux";
import { outreachEmailsSelector, resetOutcome } from "src/store/outreach-emails";
import { addEmailBe } from "src/store/outreach-emails/asyncRequests";
import { useNavigate } from "react-router";

interface EmailsProps {
  isTourStep?: boolean;
}

const Emails: FC<EmailsProps> = ({ isTourStep }) => {
  const { isDesktop } = useBreakpoints();
  const [openDrawer, setOpenDrawer] = useState(false);

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onCancel = () => {
    setOpenDrawer(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => typeof prop === "string" && searchParams.get(prop),
    });
    const err = params?.error;
    const email = params?.em;
    const id = params?.id;
    const type = params?.type;
    const iframe = params?.iframe;
    console.log(params, "params");
    const savedPathSegment = localStorage.getItem("latestMeetzPg");
    console.log(savedPathSegment, "savedPathSegment");
    if (savedPathSegment && savedPathSegment !== "email-analytics") {
      // localStorage.removeItem('latestMeetzPg');

      const searchParams = new URLSearchParams(window.location.search);

      const newUrl = `${savedPathSegment}?${searchParams.toString()}`;

      // Construct the final URL, excluding the 'email-analytics' part if it's part of the current path
      const pathParts = location.pathname.split("/").filter((part) => part !== "email-analytics");
      const basePath = pathParts.join("/");

      // If basePath is empty, ensure the URL starts with a '/'
      const finalUrl = `${basePath}/${newUrl}`.replace(/\/\//g, "/");
      console.log(finalUrl, "basePath", savedPathSegment, searchParams.toString());
      // Use window.history.pushState to update the URL without reloading the page
      // window.history.pushState({}, '', finalUrl);
      console.log(finalUrl, "finalUrl");
      navigate(finalUrl);
      return;
    }
    if (err) {
      // push('/ai_email_campaign');
      // dispatch(emailErr({ email, type }));
      message.destroy();
      message.error(
        `Error integrating your ${
          type === "google" ? "Google" : "Outlook"
        } calendar. Make sure that you accepted the necessary permissions. Try to connect to it again soon.`
      );
    } else if (email && id) {
      dispatch(addEmailBe({ id, email }));
      message.destroy();
      message.info("Adding your email address now...");

      if (iframe === "true") {
        const jwt = localStorage.getItem("token");
        if (jwt) {
          window.location.href = `https://marketing.deal.ai/apps/app/meetz?appTokenName=token&appTokenValue=${jwt}`;
        } else {
          window.location.href = `https://marketing.deal.ai/apps/app/meetz`;
        }
      }
      // if(local){

      // }
      window.history.pushState({}, "", "/email-analytics");
      setOpenDrawer(true);
    }
  }, []);

  const rightHeaderContent = (
    <Button className={"primary"} onClick={showDrawer}>
      Setup Your Email Addresses
    </Button>
  );

  const [activeId, setActiveId] = useState<string | null>("email_sent");

  const { successfullUpdateMsg, failedUpdateMsg, infoUpdateMsg } = useSelector(outreachEmailsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (successfullUpdateMsg) {
      message.destroy();
      message.success(successfullUpdateMsg);
      dispatch(resetOutcome());
    }
  }, [successfullUpdateMsg]);

  useEffect(() => {
    if (failedUpdateMsg) {
      message.destroy();
      message.error(failedUpdateMsg);
      dispatch(resetOutcome());
    }
  }, [failedUpdateMsg]);

  useEffect(() => {
    if (infoUpdateMsg) {
      message.destroy();
      message.info(infoUpdateMsg);
      dispatch(resetOutcome());
    }
  }, [infoUpdateMsg]);

  return isDesktop ? (
    <PageLayout
      pageTitle={"Emails"}
      bottomHeaderContent={<StatisticsBar campaignId={"all"} dataType={"email"} activeId={activeId} setActiveId={setActiveId} />}
      rightHeaderContent={rightHeaderContent}
    >
      {/* {statisticEmailsData.length === 0 ? (
        <EmptyState text={"You have no emails sent. Start your campaign, and the email analytics will appear here."} />
      ) : ( */}
      <EmailsTable isTourStep={isTourStep} campaignId={"all"} activeId={activeId} />
      {/* )} */}
      {/* do not make manageemaildrawer not preeent in react othersise will ruin the logic for email. or just move that logci to this page */}
      <ManageEmailDrawer open={openDrawer} onCancel={onCancel} />
    </PageLayout>
  ) : (
    <GoToDesktopModal />
  );
};

export default Emails;
