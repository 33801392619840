/* eslint-disable react/jsx-fragments */
import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";
import { Button, Col, Flex, Form, Progress, Row, Segmented, Space, Typography, message } from "antd";
import PageLayout from "src/components/layout/PageLayout";
import { ExploreTable, ExportsTable, ProspectFilterCollapse } from "src/components/pages/prospect-data";
import { useBreakpoints } from "../../helpers/useBreakpoints";
import { GoToDesktopModal } from "../../components/ui/GoToDesktopModal";
import "./index.less";
import { prospectDataSelector, resetOutcome } from "src/store/prospect_data";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "src/store/user";
import { getProsFiles } from "src/store/prospect_data/asyncRequests";
import { AppDispatch } from "src/store";
import { SvgIcon } from "src/shared/icons";

interface ProspectDataProps {
  isTourStep?: boolean;
  ref?: ForwardedRef<HTMLDivElement>;
}

const LoadingContacts = () => {
  const { exportFiles } = useSelector(prospectDataSelector);
  const [exportedNow, setExportedNow] = useState(0);
  const [totalToExp, setTotalToExp] = useState(0);
  const [gotPendingFirst, setGotPendingFirst] = useState(false);
  const [pendingFile, setPendingFile] = useState({});

  useEffect(() => {
    const pendingFile = exportFiles?.find((fileCheck) => fileCheck.id === "pending");
    setPendingFile(pendingFile);
    setExportedNow(pendingFile?.goodProspAmnt || 0);
    if (!totalToExp) {
      setTotalToExp(pendingFile?.pendingTotal || 0);
    }
    if (pendingFile?.goodProspAmnt || pendingFile?.pendingTotal) {
      setGotPendingFirst(true);
    }
  }, [exportFiles]);
  // below hapens since pending isnot found anymore
  const finishedAll = !exportedNow && !pendingFile?.pendingTotal && gotPendingFirst;
  return (
    <Space direction={"vertical"}>
      <Row align={"middle"} style={{ gap: 8 }}>
        <SvgIcon type={"infoFilled"} />
        <Typography.Paragraph className={"bulk-message-title"}>
          {`Exporting ${finishedAll ? totalToExp : exportedNow} of ${finishedAll ? totalToExp : totalToExp} Contacts`}
        </Typography.Paragraph>
      </Row>
      <Progress
        strokeColor={"#01AEEF"}
        size={"small"}
        percent={!exportedNow && !totalToExp && gotPendingFirst ? 100 : Math.floor((exportedNow / totalToExp) * 100)}
        showInfo={false}
      />
    </Space>
  );
};

const ProspectData = forwardRef<HTMLDivElement, ProspectDataProps>(({ isTourStep }, ref) => {
  const { isDesktop } = useBreakpoints();
  const [activeTab, setActiveTab] = useState<string>("Explore");
  // const [activeTab, setActiveTab] = useState<string>("Explore");
  const [form] = Form.useForm();

  const isExplore = activeTab === "Explore";
  let { prospect_data_credits, new_payment_status, split_between_prosp_to_personalize, new_prospectdata_or_personalized_email_permonth, trial_credits } =
    useSelector(userSelector);
  const isTrial = new_payment_status === "trial";
  if (isTrial) {
    const credUsed = (1 - split_between_prosp_to_personalize / 100) * new_prospectdata_or_personalized_email_permonth - prospect_data_credits;
    prospect_data_credits = trial_credits - credUsed;
    if (prospect_data_credits < 0) {
      prospect_data_credits = 0;
    }
  }
  const bottomHeaderContent = (
    <>
      <Segmented options={["Explore", "My exports"]} value={activeTab} onChange={setActiveTab} />
      <Row align={"middle"} style={{ gap: 34 }}>
        <Typography.Paragraph className={"credits-left-title"}>
          {isTrial && `Trial `}Export Credits Left: {prospect_data_credits}
        </Typography.Paragraph>
        {/* <Button className={"primary"}>Test</Button> */}
      </Row>
    </>
  );

  const { successfullUpdateMsg, failedUpdateMsg, infoUpdateMsg, exportFiles, gotFirstHearbeatFiles } = useSelector(prospectDataSelector);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (successfullUpdateMsg) {
      message.destroy();
      message.success(successfullUpdateMsg);
      dispatch(resetOutcome());
    }
  }, [successfullUpdateMsg]);
  useEffect(() => {
    if (failedUpdateMsg) {
      message.destroy();
      message.error(failedUpdateMsg);
      dispatch(resetOutcome());
    }
  }, [failedUpdateMsg]);
  useEffect(() => {
    if (infoUpdateMsg) {
      message.destroy();
      message.info(infoUpdateMsg);
      dispatch(resetOutcome());
    }
  }, [infoUpdateMsg]);

  const [showedThePending, setShowedThePending] = useState(false);
  const [activatedHeartbeat, setActivatedHeartbeat] = useState(false);
  useEffect(() => {
    if (!activatedHeartbeat) {
      const pendingFiles = exportFiles?.some((file) => file.id === "pending");
      if (pendingFiles) {
        setActivatedHeartbeat(true);
        setInterval(() => {
          dispatch(getProsFiles());
        }, 2000);
      }
    }
  }, [exportFiles]);
  const [exportedNow, setExportedNow] = useState(0);
  const [totalToExp, setTotalToExp] = useState(0);
  const messageContent = (
    <Space direction={"vertical"}>
      <Row align={"middle"} style={{ gap: 8 }}>
        <SvgIcon type={"infoFilled"} />
        <Typography.Paragraph className={"bulk-message-title"}>{`Exporting ${exportedNow} of ${totalToExp} Contacts`}</Typography.Paragraph>
      </Row>
      <Progress strokeColor={"#01AEEF"} size={"small"} percent={Math.floor(exportedNow / totalToExp) * 100} showInfo={false} />
    </Space>
  );

  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    const pendingFile = exportFiles?.find((fileCheck) => fileCheck.id === "pending");
    // console.log(pendingFile, "asdfkln", pendingFile?.goodProspAmnt, pendingFile?.pendingTotal);
    setExportedNow(pendingFile?.goodProspAmnt || 0);
    setTotalToExp(pendingFile?.pendingTotal || 0);
    if (gotFirstHearbeatFiles && exportFiles?.some((file) => file.id === "pending" && !showedThePending)) {
      // if(gotFirstHearbeatFiles && exportFiles?.some((file)=>file.id==='pending' )){

      // if(!showedThePending){
      setShowedThePending(true);

      message.destroy();
      messageApi.destroy();
      messageApi.open({
        key: "updateMessage",
        content: <LoadingContacts />,
        className: "custom-message",
        duration: 0,
        style: {
          padding: "10px 16px",
        },
      });
      // }else{
      //        messageApi.update({
      //    key:'updateMessage',
      //       content: messageContent,
      //     });
      //   }
    }
    if (showedThePending && exportFiles?.every((file) => file.id !== "pending")) {
      setShowedThePending(false);
      message.destroy();
      messageApi.destroy();
      setActiveTab("My exports");
      message.success("Export finished", 4.5);
    }
  }, [exportFiles]);

  //  useEffect(() => {
  //    messageApi
  //       .open({
  //         content: messageContent(26),
  //         className: "custom-message",
  //         duration: 7.5,
  //         style: {
  //           padding: "10px 16px",
  //         },
  //       })
  //       .then(() => {
  //         message.success("Exporting finished", 2.5)
  //       })

  //  }, []);

  return isDesktop ? (
    <div>
      <PageLayout pageTitle={"Prospect Data"} bottomHeaderContent={bottomHeaderContent} isTableContent={false}>
        {contextHolder}
        <Row style={{ gap: 24 }} wrap={false}>
          {isExplore && (
            <Col flex={"344px"}>
              <ProspectFilterCollapse ref={ref} form={form} isTourStep={isTourStep} />
            </Col>
          )}
          <Flex className={"table-container"}>
            <Col flex={isExplore ? "auto" : "100%"}>{isExplore ? <ExploreTable isTourStep={isTourStep} /> : <ExportsTable />}</Col>
          </Flex>
        </Row>
      </PageLayout>
    </div>
  ) : (
    <GoToDesktopModal />
  );
});

export default ProspectData;
