/* eslint-disable no-shadow */
/* eslint-disable react/jsx-key */

// import countries from 'i18n-iso-countries';

import moment from "moment";
// import countriesImp from 'i18n-iso-countries/langs/en.json';
// countries.registerLocale(countriesImp);

export const CREDITS_PER_EMAIL_GEN = 1;
export const CREDITS_PER_HUMANLVL_EMAIL_GEN = 3;

export const MOBILE_CHECKOUT_BREAKPOINT = "(min-width:800px)";
export const ISOCOUNTRIES = [
  { value: "US", label: "United States" },
  { value: "GB", label: "United Kingdom" },
  { value: "CA", label: "Canada" },
  { value: "AF", label: "Afghanistan" },
  { value: "AX", label: "Åland Islands" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AS", label: "American Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AQ", label: "Antarctica" },
  { value: "AG", label: "Antigua and Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BA", label: "Bosnia and Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BV", label: "Bouvet Island" },
  { value: "BR", label: "Brazil" },
  { value: "IO", label: "British Indian Ocean Territory" },
  { value: "BN", label: "Brunei Darussalam" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CV", label: "Cape Verde" },
  { value: "KY", label: "Cayman Islands" },
  { value: "CF", label: "Central African Republic" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CX", label: "Christmas Island" },
  { value: "CC", label: "Cocos (Keeling) Islands" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CG", label: "Congo" },
  { value: "CD", label: "Congo, The Democratic Republic of the" },
  { value: "CK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica" },
  { value: "CI", label: "Cote D'Ivoire" },
  { value: "HR", label: "Croatia" },
  { value: "CU", label: "Cuba" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "ET", label: "Ethiopia" },
  { value: "FK", label: "Falkland Islands (Malvinas)" },
  { value: "FO", label: "Faroe Islands" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GF", label: "French Guiana" },
  { value: "PF", label: "French Polynesia" },
  { value: "TF", label: "French Southern Territories" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Greece" },
  { value: "GL", label: "Greenland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GG", label: "Guernsey" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HM", label: "Heard Island and Mcdonald Islands" },
  { value: "VA", label: "Holy See (Vatican City State)" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IR", label: "Iran, Islamic Republic of" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IM", label: "Isle of Man" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "KP", label: "Korea, Democratic People's Republic of" },
  { value: "KR", label: "Korea, Republic of" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Lao People's Democratic Republic" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libyan Arab Jamahiriya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MO", label: "Macao" },
  { value: "MK", label: "Macedonia, The Former Yugoslav Republic of" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia, Federated States of" },
  { value: "MD", label: "Moldova, Republic of" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "AN", label: "Netherlands Antilles" },
  { value: "NC", label: "New Caledonia" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolk Island" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PN", label: "Pitcairn" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Qatar" },
  { value: "RE", label: "Reunion" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russian Federation" },
  { value: "RW", label: "Rwanda" },
  { value: "SH", label: "Saint Helena" },
  { value: "KN", label: "Saint Kitts and Nevis" },
  { value: "LC", label: "Saint Lucia" },
  { value: "PM", label: "Saint Pierre and Miquelon" },
  { value: "VC", label: "Saint Vincent and the Grenadines" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "Sao Tome and Principe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },
  { value: "GS", label: "South Georgia and the South Sandwich Islands" },
  { value: "ES", label: "Spain" },
  { value: "EH", label: "Western Sahara" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard and Jan Mayen" },
  { value: "SZ", label: "Swaziland" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "SY", label: "Syrian Arab Republic" },
  { value: "TW", label: "Taiwan, Province of China" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania, United Republic of" },
  { value: "TH", label: "Thailand" },
  { value: "TL", label: "Timor-Leste" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad and Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks and Caicos Islands" },
  { value: "TV", label: "Tuvalu" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "UM", label: "United States Minor Outlying Islands" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VE", label: "Venezuela" },
  { value: "VN", label: "Viet Nam" },
  { value: "VG", label: "Virgin Islands, British" },
  { value: "VI", label: "Virgin Islands, U.S." },
  { value: "WF", label: "Wallis and Futuna" },
  { value: "EH", label: "Western Sahara" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" },
];

export const COUNTRIES_TWILIO = [
  { label: "United States", value: "US" },
  { label: "Canada", value: "CA" },
  { label: "United Kingdom", value: "GB" },
  // { label: 'Algeria', value: 'DZ' },
  { label: "Argentina", value: "AR" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Barbados", value: "BB" },
  { label: "Belgium", value: "BE" },
  { label: "Benin", value: "BJ" },
  { label: "Bosnia and Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Brazil", value: "BR" },
  { label: "Bulgaria", value: "BG" },
  { label: "Chile", value: "CL" },
  { label: "Colombia", value: "CO" },
  { label: "Croatia", value: "HR" },
  { label: "Czech Republic", value: "CZ" },
  { label: "Denmark", value: "DK" },
  { label: "Ecuador", value: "EC" },
  { label: "El Salvador", value: "SV" },
  { label: "Estonia", value: "EE" },
  { label: "Finland", value: "FI" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Greece", value: "GR" },
  { label: "Grenada", value: "GD" },
  { label: "Guinea", value: "GN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Indonesia", value: "ID" },
  { label: "Ireland", value: "IE" },
  { label: "Israel", value: "IL" },
  { label: "Japan", value: "JP" },
  { label: "Kenya", value: "KE" },
  { label: "Lithuania", value: "LT" },
  { label: "Macao", value: "MO" },
  { label: "Mali", value: "ML" },
  { label: "Mauritius", value: "MU" },
  { label: "Mexico", value: "MX" },
  { label: "Namibia", value: "NA" },
  { label: "Netherlands", value: "NL" },
  { label: "New Zealand", value: "NZ" },
  { label: "Panama", value: "PA" },
  { label: "Peru", value: "PE" },
  { label: "Philippines", value: "PH" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Romania", value: "RO" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "South Africa", value: "ZA" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Thailand", value: "TH" },
  { label: "Tunisia", value: "TN" },
  { label: "Uganda", value: "UG" },
  { label: "Virgin Islands, U.S.", value: "VI" },
];

export const SUB_INDUSTRY_VALUE_ARR = [
  // DO NOT CHANGE ORDER OF ARRAY WITHOUT UPDATING THE NAME ARRAY
  "Farming",
  "Dairy",
  "Tobacco",
  "Fishery",
  "Paper & Forest Products",
  "Mining & Metals",
  "Ranching",
  "Design",
  "Events Services",
  "Human Resources",
  "Accounting",
  "Environmental Services",
  "Market Research",
  "Business Supplies and Equipment",
  "Public Safety",
  "Think Tanks",
  "Law Enforcement",
  "Facilities Services",
  "Import and Export",
  "Graphic Design",
  "Executive Office",
  "Writing and Editing",
  "Program Development",
  "Animation",
  "Alternative Dispute Resolution",
  "Translation and Localization",
  "International Trade and Development",
  "Law Practice",
  "Legal Services",
  "Management Consulting",
  "Marketing and Advertising",
  "Printing",
  "Professional Training & Coaching",
  "Public Relations and Communications",
  "Security and Investigations",
  "Staffing and Recruiting",
  "Computer Hardware",
  "Computer Networking",
  "Semiconductors",
  "Consumer Electronics",
  "Consumer Goods",
  "Individual & Family Services",
  "Photography",
  "Restaurants",
  "E-Learning",
  "Education Management",
  "Higher Education",
  "Primary/Secondary Education",
  "Oil & Energy",
  "Renewables & Environment",
  "Utilities",
  "Accounting",
  "Banking",
  "Insurance",
  "Investment Banking",
  "Venture Capital & Private Equity",
  "Capital markets",
  "Fund-Raising",
  "Investment Management",
  "Government Administration",
  "Defense & Space",
  "Military",
  "Museums and Institutions",
  "Maritime",
  "Government Relations",
  "Judiciary",
  "Legislative Office",
  "Biotechnology",
  "Health, Wellness and Fitness",
  "Hospital & Health Care",
  "Medical Devices",
  "Mental Health Care",
  "Veterinary",
  "Alternative Medicine",
  "Medical Practice",
  "Pharmaceuticals",
  "Automotive",
  "Aviation & Aerospace",
  "Chemicals",
  "Food & Beverages",
  "Food Production",
  "Electrical/Electronic Manufacturing",
  "Packaging and Containers",
  "Nanotechnology",
  "Shipbuilding",
  "Railroad Manufacture",
  "Furniture",
  "Industrial Automation",
  "Machinery",
  "Mechanical or Industrial Engineering",
  "Plastics",
  "Textiles",
  "Broadcast Media",
  "Media Production",
  "Printing",
  "Motion Pictures and Film",
  "Music",
  "Newspapers",
  "Online Media",
  "Performing Arts",
  "Publishing",

  "Building Materials",
  "Civil Engineering",
  "Architecture & Planning",
  "Commercial Real Estate",
  "Glass, Ceramics & Concrete",
  "Construction",
  "Real Estate",
  "Apparel & Fashion",
  "Cosmetics",
  "E-Commerce",
  "Luxury Goods & Jewelry",
  "Sporting Goods",
  "Supermarkets",
  "Wine and Spirits",
  "Computer Software",
  "Information Technology and Services",
  "Internet",
  "Computer & Network Security",
  "Information Services",
  "Computer Games",
  "Outsourcing/Offshoring",
  "Wireless",
  "Logistics and Supply Chain",
  "Transportation/Trucking/Railroad",
  "Airlines/Aviation",
  "Warehousing",
  "Package/Freight Delivery",
  "Computer Games",
  "Entertainment",
  "Hospitality",
  "Performing Arts",
  "Music",
  "Gambling & Casinos",
  "Fine Art",
  "Arts and Crafts",
  "Leisure, Travel & Tourism",
  "Recreational Facilities and Services",
  "Sports",
  "Wholesale",
  "Civic & Social Organization",
  "Environmental Services",
  "Philanthropy",
  "Religious Institutions",
  "Local Business",
  "Research",
];
export const SUB_INDUSTRY_NAME_ARR = [
  "Agriculture & Mining:	Farming",
  "Agriculture & Mining:	Dairy",
  "Agriculture & Mining:	Tobacco",
  "Agriculture & Mining:	Fishery",
  "Agriculture & Mining:	Paper & Forest Products",
  "Agriculture & Mining:	Mining & Metals",
  "Agriculture & Mining:	Ranching",
  "Business Services:	Design",
  "Business Services:	Events Services",
  "Business Services:	Human Resources",
  "Business Services:	Accounting",
  "Business Services:	Environmental Services",
  "Business Services:	Market Research",
  "Business Services:	Business Supplies and Equipment",
  "Business Services:	Public Safety",
  "Business Services:	Think Tanks",
  "Business Services:	Law Enforcement",
  "Business Services:	Facilities Services",
  "Business Services:	Import and Export",
  "Business Services:	Graphic Design",
  "Business Services:	Executive Office",
  "Business Services:	Writing and Editing",
  "Business Services:	Program Development",
  "Business Services:	Animation",
  "Business Services:	Alternative Dispute Resolution",
  "Business Services:	Translation and Localization",
  "Business Services:	International Trade and Development",
  "Business Services:	Law Practice",
  "Business Services:	Legal Services",
  "Business Services:	Management Consulting",
  "Business Services:	Marketing and Advertising",
  "Business Services:	Printing",
  "Business Services:	Professional Training & Coaching",
  "Business Services:	Public Relations and Communications",
  "Business Services:	Security and Investigations",
  "Business Services:	Staffing and Recruiting",
  "Computers & Electronics:	Computer Hardware",
  "Computers & Electronics:	Computer Networking",
  "Computers & Electronics:	Semiconductors",
  "Computers & Electronics:	Consumer Electronics",
  "Consumer Services:	Consumer Goods",
  "Consumer Services:	Individual & Family Services",
  "Consumer Services:	Photography",
  "Consumer Services:	Restaurants",
  "Education:	E-Learning",
  "Education:	Education Management",
  "Education:	Higher Education",
  "Education:	Primary/Secondary Education",
  "Energy & Utilities:	Oil & Energy",
  "Energy & Utilities:	Renewables & Environment",
  "Energy & Utilities:	Utilities",
  "Financial Services:	Accounting",
  "Financial Services:	Banking",
  "Financial Services:	Insurance",
  "Financial Services:	Investment Banking",
  "Financial Services:	Venture Capital & Private Equity",
  "Financial Services:	Capital markets",
  "Financial Services:	Fund-Raising",
  "Financial Services:	Investment Management",
  "Government:	Government Administration",
  "Government:	Defense & Space",
  "Government:	Military",
  "Government:	Museums and Institutions",
  "Government:	Maritime",
  "Government:	Government Relations",
  "Government:	Judiciary",
  "Government:	Legislative Office",
  "Healthcare, Pharmaceuticals, & Biotech:	Biotechnology",
  "Healthcare, Pharmaceuticals, & Biotech:	Health, Wellness and Fitness",
  "Healthcare, Pharmaceuticals, & Biotech:	Hospital & Health Care",
  "Healthcare, Pharmaceuticals, & Biotech:	Medical Devices",
  "Healthcare, Pharmaceuticals, & Biotech:	Mental Health Care",
  "Healthcare, Pharmaceuticals, & Biotech:	Veterinary",
  "Healthcare, Pharmaceuticals, & Biotech:	Alternative Medicine",
  "Healthcare, Pharmaceuticals, & Biotech:	Medical Practice",
  "Healthcare, Pharmaceuticals, & Biotech:	Pharmaceuticals",
  "Manufacturing:	Automotive",
  "Manufacturing:	Aviation & Aerospace",
  "Manufacturing:	Chemicals",
  "Manufacturing:	Food & Beverages",
  "Manufacturing:	Food Production",
  "Manufacturing:	Electrical/Electronic Manufacturing",
  "Manufacturing:	Packaging and Containers",
  "Manufacturing:	Nanotechnology",
  "Manufacturing:	Shipbuilding",
  "Manufacturing:	Railroad Manufacture",
  "Manufacturing:	Furniture",
  "Manufacturing:	Industrial Automation",
  "Manufacturing:	Machinery",
  "Manufacturing:	Mechanical or Industrial Engineering",
  "Manufacturing:	Plastics",
  "Manufacturing:	Textiles",
  "Media & Entertainment:	Broadcast Media",
  "Media & Entertainment:	Media Production",
  "Media & Entertainment:	Printing",
  "Media & Entertainment:	Motion Pictures and Film",
  "Media & Entertainment:	Music",
  "Media & Entertainment:	Newspapers",
  "Media & Entertainment:	Online Media",
  "Media & Entertainment:	Performing Arts",
  "Media & Entertainment:	Publishing",

  "Real Estate & Construction:	Building Materials",
  "Real Estate & Construction:	Civil Engineering",
  "Real Estate & Construction:	Architecture & Planning",
  "Real Estate & Construction:	Commercial Real Estate",
  "Real Estate & Construction:	Glass, Ceramics & Concrete",
  "Real Estate & Construction:	Construction",
  "Real Estate & Construction:	Real Estate",
  "Retail:	Apparel & Fashion",
  "Retail:	Cosmetics",
  "Retail:	E-Commerce",
  "Retail:	Luxury Goods & Jewelry",
  "Retail:	Sporting Goods",
  "Retail:	Supermarkets",
  "Retail:	Wine and Spirits",
  "Software & Internet:	Computer Software",
  "Software & Internet:	Information Technology and Services",
  "Software & Internet:	Internet",
  "Software & Internet:	Computer & Network Security",
  "Software & Internet:	Information Services",
  "Software & Internet:	Computer Games",
  "Software & Internet:	Outsourcing/Offshoring",
  "Telecommunications:	Wireless",
  "Transportation & Storage:	Logistics and Supply Chain",
  "Transportation & Storage:	Transportation/Trucking/Railroad",
  "Transportation & Storage:	Airlines/Aviation",
  "Transportation & Storage:	Warehousing",
  "Transportation & Storage:	Package/Freight Delivery",
  "Travel, Recreation, and Leisure:	Computer Games",
  "Travel, Recreation, and Leisure:	Entertainment",
  "Travel, Recreation, and Leisure:	Hospitality",
  "Travel, Recreation, and Leisure:	Performing Arts",
  "Travel, Recreation, and Leisure:	Music",
  "Travel, Recreation, and Leisure:	Gambling & Casinos",
  "Travel, Recreation, and Leisure:	Fine Art",
  "Travel, Recreation, and Leisure:	Arts and Crafts",
  "Travel, Recreation, and Leisure:	Leisure, Travel & Tourism",
  "Travel, Recreation, and Leisure:	Recreational Facilities and Services",
  "Travel, Recreation, and Leisure:	Sports",
  "Wholesale & Distribution:	Wholesale",
  "Non-Profit:	Civic & Social Organization",
  "Non-Profit:	Environmental Services",
  "Non-Profit:	Philanthropy",
  "Non-Profit:	Religious Institutions",
  "Other:	Local Business",
  "Other:	Research",
];
export const COUNTRIES_AVAIL_IN_AMER_OR_EUR_ARR = [
  "United States",
  "United Kingdom",
  "Canada",
  "East Timor (Not Available)",
  "Saint Martin",
  "Guernsey",
  "Turkmenistan (Not Available)",
  "Saint Helena (Not Available)",
  "Ireland",
  "Cambodia (Not Available)",
  "Ethiopia (Not Available)",
  "Aruba",
  "Swaziland (Not Available)",
  "Belize",
  "Argentina (Not Available)",
  "Bolivia",
  "Cameroon (Not Available)",
  "Burkina Faso (Not Available)",
  "Bahrain (Not Available)",
  "Saudi Arabia (Not Available)",
  "Martinique",
  "Togo (Not Available)",
  "American Samoa (Not Available)",
  "Slovenia",
  "Guatemala",
  "Bosnia and Herzegovina",
  "Guinea (Not Available)",
  "Spain",
  "Australia (Not Available)",
  "Liberia (Not Available)",
  "Maldives (Not Available)",
  "Jamaica",
  "Oman (Not Available)",
  "Tanzania (Not Available)",
  "Bonaire",
  "São Tomé and Príncipe (Not Available)",
  "Costa Rica",
  "Cabo Verde (Not Available)",
  "Christmas Island (Not Available)",
  "Gabon (Not Available)",
  "Saint Pierre and Miquelon",
  "Monaco",
  "Bahamas",
  "Wallis and Futuna (Not Available)",
  "New Zealand (Not Available)",
  "Yemen (Not Available)",
  "Jersey",
  "Pakistan (Not Available)",
  "Albania",
  "Samoa (Not Available)",
  "Norfolk Island (Not Available)",
  "United Arab Emirates (Not Available)",
  "Guam (Not Available)",
  "Uruguay",
  "India (Not Available)",
  "Svalbard and Jan Mayen",
  "Lesotho (Not Available)",
  "Saint Vincent and the Grenadines",
  "Kenya (Not Available)",
  "Tajikistan (Not Available)",
  "Greenland",
  "Turkey (Not Available)",
  "Afghanistan (Not Available)",
  "Bangladesh (Not Available)",
  "Mauritania (Not Available)",
  "Solomon Islands (Not Available)",
  "Turks and Caicos Islands",
  "Saint Lucia",
  "San Marino",
  "Mongolia (Not Available)",
  "Azerbaijan (Not Available)",
  "Rwanda (Not Available)",
  "Slovakia",
  "Somalia (Not Available)",
  "Peru",
  "Laos (Not Available)",
  "Nauru (Not Available)",
  "Norway",
  "Malawi (Not Available)",
  "Cook Islands (Not Available)",
  "Benin (Not Available)",
  "Federated States of Micronesia (Not Available)",
  "Réunion (Not Available)",
  "Western Sahara (Not Available)",
  "Singapore (Not Available)",
  "Montenegro",
  "Republic of the Congo (Not Available)",
  "British Indian Ocean Territory (Not Available)",
  "China (Not Available)",
  "Ecuador",
  "Armenia (Not Available)",
  "Antigua and Barbuda",
  "Dominican Republic",
  "Ukraine",
  "Ghana (Not Available)",
  "Tonga (Not Available)",
  "Finland",
  "Libya (Not Available)",
  "Indonesia (Not Available)",
  "Central African Republic (Not Available)",
  "Sweden",
  "Vietnam (Not Available)",
  "British Virgin Islands",
  "Mali (Not Available)",
  "Vatican City",
  "Russia",
  "Bulgaria",
  "Mauritius (Not Available)",
  "Romania",
  "Angola (Not Available)",
  "French Southern Territories (Not Available)",
  "Chad (Not Available)",
  "South Africa (Not Available)",
  "Tokelau (Not Available)",
  "Macao (Not Available)",
  "South Georgia and the South Sandwich Islands (Not Available)",
  "Liechtenstein",
  "Malaysia (Not Available)",
  "Senegal (Not Available)",
  "Mozambique (Not Available)",
  "Uganda (Not Available)",
  "Hungary",
  "Niger (Not Available)",
  "Isle of Man",
  "Brazil (Not Available)",
  "Saint-Barthélemy",
  "Falkland Islands",
  "Faroe Islands",
  "Kuwait (Not Available)",
  "Panama",
  "Guyana",
  "Republic of Moldova",
  "Madagascar (Not Available)",
  "Luxembourg",
  "Andorra",
  "Gibraltar",
  "Ivory Coast (Not Available)",
  "Syria (Not Available)",
  "Italy",
  "Nigeria (Not Available)",
  "Guadeloupe",
  "Northern Mariana Islands (Not Available)",
  "Brunei (Not Available)",
  "Belarus",
  "Iran (Not Available)",
  "Algeria (Not Available)",
  "Republic of Lithuania",
  "El Salvador",
  "Tuvalu (Not Available)",
  "France",
  "Czechia",
  "Åland",
  "Pitcairn Islands (Not Available)",
  "Marshall Islands (Not Available)",
  "Chile",
  "Puerto Rico",
  "Belgium",
  "Thailand (Not Available)",
  "Haiti",
  "Iraq (Not Available)",
  "Hong Kong (Not Available)",
  "Sierra Leone (Not Available)",
  "Georgia (Not Available)",
  "Gambia (Not Available)",
  "Poland",
  "French Guiana",
  "Morocco (Not Available)",
  "Namibia (Not Available)",
  "French Polynesia (Not Available)",
  "Guinea-Bissau (Not Available)",
  "Kiribati (Not Available)",
  "Switzerland",
  "Grenada",
  "Myanmar [Burma] (Not Available)",
  "U.S. Virgin Islands",
  "Seychelles (Not Available)",
  "Portugal",
  "Estonia",
  "Kosovo",
  "Curaçao",
  "Mexico",
  "Lebanon (Not Available)",
  "U.S. Minor Outlying Islands (Not Available)",
  "Uzbekistan (Not Available)",
  "Tunisia (Not Available)",
  "Djibouti (Not Available)",
  "Heard Island and McDonald Islands (Not Available)",
  "Dominica",
  "Colombia",
  "Burundi (Not Available)",
  "Taiwan (Not Available)",
  "Cyprus (Not Available)",
  "Barbados",
  "Qatar (Not Available)",
  "Palau (Not Available)",
  "Bhutan (Not Available)",
  "Sudan (Not Available)",
  "Nepal (Not Available)",
  "Malta",
  "Netherlands",
  "Bermuda",
  "Suriname",
  "Cayman Islands",
  "Anguilla",
  "Venezuela",
  "Israel",
  "St Kitts and Nevis",
  "Iceland",
  "Zambia (Not Available)",
  "Austria",
  "Papua New Guinea (Not Available)",
  "Trinidad and Tobago",
  "Zimbabwe (Not Available)",
  "Germany",
  "Vanuatu (Not Available)",
  "Denmark",
  "Kazakhstan (Not Available)",
  "Philippines (Not Available)",
  "Eritrea (Not Available)",
  "Kyrgyzstan (Not Available)",
  "Mayotte (Not Available)",
  "Montserrat",
  "New Caledonia (Not Available)",
  "Macedonia",
  "Paraguay",
  "Latvia",
  "South Sudan (Not Available)",
  "Japan (Not Available)",
  "Croatia",
  "Hashemite Kingdom of Jordan (Not Available)",
  "Cuba",
  "Sint Maarten",
  "Honduras",
  "Equatorial Guinea (Not Available)",
  "Egypt (Not Available)",
  "Nicaragua",
  "Cocos [Keeling] Islands (Not Available)",
  "Serbia",
  "Comoros (Not Available)",
  "Antarctica (Not Available)",
  "Congo (Not Available)",
  "Greece",
  "Sri Lanka (Not Available)",
  "Fiji (Not Available)",
  "Botswana (Not Available)",
  "Niue (Not Available)",
  "North Korea (Not Available)",
  "Republic of Korea (Not Available)",
];

export const NAICS_CODES = [
  "3252:	Other Crop Farming",
  "3253:	Cattle Ranching and Farming",
  "3254:	Other Animal Production",
  "3255:	Logging",
  "3256:	Fishing",
  "9211:	Support Activities for Crop Production",
  "1121:	Coal Mining",
  "1129:	Metal Ore Mining",
  "1133:	Nonmetallic Mineral Mining and Quarrying",
  "3259:	Oil and Gas Extraction",
  "3261:	Oil and Gas Extraction",
  "3262:	Support Activities for Mining",
  "3271:	Electric Power Generation, Transmission and Distribution",
  "3272:	Electric Power Generation, Transmission and Distribution",
  "3273:	Electric Power Generation, Transmission and Distribution",
  "3274:	Electric Power Generation, Transmission and Distribution",
  "3279:	Electric Power Generation, Transmission and Distribution",
  "3315:	Electric Power Generation, Transmission and Distribution",
  "3321:	Electric Power Generation, Transmission and Distribution",
  "3322:	Electric Power Generation, Transmission and Distribution",
  "3323:	Electric Power Generation, Transmission and Distribution",
  "3324:	Electric Power Generation, Transmission and Distribution",
  "3325:	Water, Sewage and Other Systems",
  "3326:	Water, Sewage and Other Systems",
  "3327:	Water, Sewage and Other Systems",
  "8134:	Electric Power Generation, Transmission and Distribution",
  "3328:	Residential Building Construction",
  "3329:	Residential Building Construction",
  "3331:	Nonresidential Building Construction",
  "3332:	Nonresidential Building Construction",
  "3333:	Utility System Construction",
  "3334:	Land Subdivision",
  "3335:	Highway, Street, and Bridge Construction",
  "3336:	Other Heavy and Civil Engineering Construction",
  "3339:	Foundation, Structure, and Building Exterior Contractors",
  "3341:	Building Equipment Contractors",
  "3342:	Building Finishing Contractors",
  "3343:	Other Specialty Trade Contractors",
  "1141:	Cut and Sew Apparel Manufacturing",
  "1151:	Other Leather and Allied Product Manufacturing",
  "1153:	Other Leather and Allied Product Manufacturing",
  "3344:	Animal Food Manufacturing",
  "3345:	Dairy Product Manufacturing",
  "3346:	Animal Slaughtering and Processing",
  "3351:	Seafood Product Preparation and Packaging",
  "3352:	Bakeries and Tortilla Manufacturing",
  "3353:	Other Food Manufacturing",
  "3359:	Beverage Manufacturing",
  "3361:	Beverage Manufacturing",
  "3363:	Beverage Manufacturing",
  "3364:	Tobacco Manufacturing",
  "3365:	Apparel Accessories and Other Apparel Manufacturing",
  "3366:	Footwear Manufacturing",
  "3369:	Other Wood Product Manufacturing",
  "3371:	Converted Paper Product Manufacturing",
  "3372:	Printing and Related Support Activities",
  "3379:	Petroleum and Coal Products Manufacturing",
  "3391:	Basic Chemical Manufacturing",
  "3399:	Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments Manufacturing",
  "4231:	Pharmaceutical and Medicine Manufacturing",
  "4232:	Paint, Coating, and Adhesive Manufacturing",
  "4233:	Soap, Cleaning Compound, and Toilet Preparation Manufacturing",
  "4234:	Other Chemical Product and Preparation Manufacturing",
  "4235:	Plastics Product Manufacturing",
  "4236:	Plastics Product Manufacturing",
  "4237:	Rubber Product Manufacturing",
  "4238:	Clay Product and Refractory Manufacturing",
  "4239:	Cement and Concrete Product Manufacturing",
  "4241:	Other Nonmetallic Mineral Product Manufacturing",
  "2111:	Industrial Machinery Manufacturing",
  "2121:	Other General Purpose Machinery Manufacturing",
  "2122:	Manufacturing and Reproducing Magnetic and Optical Media",
  "2123:	Electric Lighting Equipment Manufacturing",
  "4242:	Forging and Stamping",
  "4243:	Cutlery and Handtool Manufacturing",
  "4244:	Boiler, Tank, and Shipping Container Manufacturing",
  "4245:	Boiler, Tank, and Shipping Container Manufacturing",
  "4246:	Hardware Manufacturing",
  "4247:	Spring and Wire Product Manufacturing",
  "4248:	Machine Shops; Turned Product; and Screw, Nut, and Bolt Manufacturing",
  "4249:	Coating, Engraving, Heat Treating, and Allied Activities",
  "4411:	Other Fabricated Metal Product Manufacturing",
  "4442:	Agriculture, Construction, and Mining Machinery Manufacturing",
  "4451:	Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing",
  "4453:	Metalworking Machinery Manufacturing",
  "4491:	Engine, Turbine, and Power Transmission Equipment Manufacturing",
  "4492:	Computer and Peripheral Equipment Manufacturing",
  "4552:	Communications Equipment Manufacturing",
  "4561:	Semiconductor and Other Electronic Component Manufacturing",
  "4571:	Semiconductor and Other Electronic Component Manufacturing",
  "4582:	Navigational, Measuring, Electromedical, and Control Instruments Manufacturing",
  "4583:	Household Appliance Manufacturing",
  "4591:	Electrical Equipment Manufacturing",
  "4592:	Other Electrical Equipment and Component Manufacturing",
  "4593:	Other Electrical Equipment and Component Manufacturing",
  "4594:	Motor Vehicle Manufacturing",
  "4595:	Aerospace Product and Parts Manufacturing",
  "4599:	Aerospace Product and Parts Manufacturing",
  "4812:	Railroad Rolling Stock Manufacturing",
  "4821:	Ship and Boat Building",
  "4832:	Other Transportation Equipment Manufacturing",
  "4842:	Household and Institutional Furniture and Kitchen Cabinet Manufacturing",
  "4851:	Office Furniture (including Fixtures) Manufacturing",
  "4852:	Other Furniture Related Product Manufacturing",
  "4853:	Medical Equipment and Supplies Manufacturing",
  "4854:	Other Miscellaneous Manufacturing",
  "4855:	Other Miscellaneous Manufacturing",
  "2131:	Apparel, Piece Goods, and Notions Merchant Wholesalers",
  "4859:	Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers",
  "4869:	Furniture and Home Furnishing Merchant Wholesalers",
  "4879:	Furniture and Home Furnishing Merchant Wholesalers",
  "4889:	Lumber and Other Construction Materials Merchant Wholesalers",
  "4911:	Professional and Commercial Equipment and Supplies Merchant Wholesalers",
  "4922:	Professional and Commercial Equipment and Supplies Merchant Wholesalers",
  "4931:	Metal and Mineral (except Petroleum) Merchant Wholesalers",
  "5121:	Household Appliances and Electrical and Electronic Goods Merchant Wholesalers",
  "5122:	Miscellaneous Durable Goods Merchant Wholesalers",
  "5131:	Paper and Paper Product Merchant Wholesalers",
  "5132:	Farm Product Raw Material Merchant Wholesalers",
  "5161:	Miscellaneous Nondurable Goods Merchant Wholesalers",
  "2211:	Furniture and Home Furnishings Retailers",
  "3169:	Grocery and Convenience Retailers",
  "1119:	Other Miscellaneous Retailers",
  "2212:	Florists",
  "2213:	Office Supplies, Stationery, and Gift Retailers",
  "2361:	Warehouse Clubs, Supercenters, and Other General Merchandise Retailers",
  "3152:	Sporting Goods, Hobby, and Musical Instrument Retailers",
  "5162:	Rail Transportation",
  "5171:	Inland Water Transportation",
  "5174:	Urban Transit Systems",
  "5178:	Interurban and Rural Bus Transportation",
  "5182:	School and Employee Bus Transportation",
  "5192:	Scenic and Sightseeing Transportation, Other",
  "2362:	Newspaper, Periodical, Book, and Directory Publishers",
  "2371:	Newspaper, Periodical, Book, and Directory Publishers",
  "2372:	Newspaper, Periodical, Book, and Directory Publishers",
  "2373:	Newspaper, Periodical, Book, and Directory Publishers",
  "2379:	Software Publishers",
  "2381:	Radio and Television Broadcasting Stations",
  "2382:	Radio and Television Broadcasting Stations",
  "2383:	Radio and Television Broadcasting Stations",
  "2389:	Wired and Wireless Telecommunications (except Satellite)",
  "3111:	Web Search Portals, Libraries, Archives, and Other Information Services",
  "3113:	Wired and Wireless Telecommunications (except Satellite)",
  "3114:	All Other Telecommunications",
  "3115:	Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers",
  "3116:	Web Search Portals, Libraries, Archives, and Other Information Services",
  "3117:	Web Search Portals, Libraries, Archives, and Other Information Services",
  "3118:	Web Search Portals, Libraries, Archives, and Other Information Services",
  "3119:	Web Search Portals, Libraries, Archives, and Other Information Services",
  "3133:	All Other Telecommunications",
  "3162:	Web Search Portals, Libraries, Archives, and Other Information Services",
  "3219:	Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services",
  "3222:	Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services",
  "3241:	Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services",
  "3251:	Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services",
  "5211:	Motion Picture and Video Industries",
  "5221:	Motion Picture and Video Industries",
  "5222:	Sound Recording Industries",
  "5223:	Satellite Telecommunications",
  "3121:	Depository Credit Intermediation",
  "3122:	Other Financial Investment Activities",
  "5231:	Depository Credit Intermediation",
  "5232:	Activities Related to Credit Intermediation",
  "5239:	Securities and Commodity Exchanges",
  "5241:	Agencies, Brokerages, and Other Insurance Related Activities",
  "5242:	Insurance and Employee Benefit Funds",
  "9221:	Other Investment Pools and Funds",
  "5251:	Lessors of Real Estate",
  "5259:	General Rental Centers",
  "5311:	Commercial and Industrial Machinery and Equipment Rental and Leasing",
  "9231:	Lessors of Real Estate",
  "5312:	Architectural, Engineering, and Related Services",
  "5322:	Architectural, Engineering, and Related Services",
  "5323:	Specialized Design Services",
  "5324:	Specialized Design Services",
  "5331:	Specialized Design Services",
  "5411:	Computer Systems Design and Related Services",
  "5412:	Computer Systems Design and Related Services",
  "5413:	Computer Systems Design and Related Services",
  "5414:	Computer Systems Design and Related Services",
  "5415:	Management, Scientific, and Technical Consulting Services",
  "5416:	Other Professional, Scientific, and Technical Services",
  "8139:	Computer Systems Design and Related Services",
  "8141:	Computer Systems Design and Related Services",
  "9241:	Management, Scientific, and Technical Consulting Services",
  "9251:	Computer Systems Design and Related Services",
  "5417:	Employment Services",
  "5418:	Business Support Services",
  "5419:	Investigation and Security Services",
  "5511:	Waste Collection",
  "5611:	Waste Treatment and Disposal",
  "5612:	Remediation and Other Waste Management Services",
  "5613:	Elementary and Secondary Schools",
  "5614:	Business Schools and Computer and Management Training",
  "5615:	Technical and Trade Schools",
  "5616:	Other Schools and Instruction",
  "5617:	Educational Support Services",
  "3231:	Child Care Services",
  "5619:	Offices of Dentists",
  "5621:	Offices of Other Health Practitioners",
  "5622:	Offices of Other Health Practitioners",
  "5629:	Offices of Other Health Practitioners",
  "6111:	Offices of Other Health Practitioners",
  "6113:	Offices of Other Health Practitioners",
  "6114:	Outpatient Care Centers",
  "6115:	Home Health Care Services",
  "6116:	Specialty (except Psychiatric and Substance Abuse) Hospitals",
  "6117:	Individual and Family Services",
  "6211:	Community Food and Housing, and Emergency and Other Relief Services",
  "6212:	Vocational Rehabilitation Services",
  "6213:	Performing Arts Companies",
  "6214:	Spectator Sports",
  "6215:	Museums, Historical Sites, and Similar Institutions",
  "6216:	Museums, Historical Sites, and Similar Institutions",
  "6219:	Museums, Historical Sites, and Similar Institutions",
  "6221:	Museums, Historical Sites, and Similar Institutions",
  "6223:	Amusement Parks and Arcades",
  "6239:	Other Amusement and Recreation Industries",
  "6241:	Other Amusement and Recreation Industries",
  "6242:	Other Amusement and Recreation Industries",
  "9261:	Gambling Industries",
  "6243:	Traveler Accommodation",
  "6244:	Traveler Accommodation",
  "7111:	Special Food Services",
  "3159:	Electronic and Precision Equipment Repair and Maintenance",
  "7112:	Personal and Household Goods Repair and Maintenance",
  "7115:	Personal Care Services",
  "7121:	Drycleaning and Laundry Services",
  "7131:	Grantmaking and Giving Services",
  "7132:	Social Advocacy Organizations",
  "7139:	Civic and Social Organizations",
  "9271:	Business, Professional, Labor, Political, and Similar Organizations",
  "7211:	Executive, Legislative, and Other General Government Support",
  "7223:	Justice, Public Order, and Safety Activities",
  "7224:	Justice, Public Order, and Safety Activities",
  "7225:	Justice, Public Order, and Safety Activities",
  "8111:	Administration of Human Resource Programs",
  "8112:	Administration of Human Resource Programs",
  "8113:	Administration of Human Resource Programs",
  "8114:	Administration of Environmental Quality Programs",
  "8121:	Administration of Housing Programs, Urban Planning, and Community Development",
  "8123:	Administration of Economic Programs",
  "8129:	Administration of Economic Programs",
  "8131:	National Security and International Affairs",
  "8132:	National Security and International Affairs",
  "8133:	National Security and International Affairs",
  "9281:	Justice, Public Order, and Safety Activities",
];

export const SIC_CODES = [
  "1099:	Metal ores",
  "1241:	Coal mining services",
  "1311:	Crude petroleum and natural gas",
  "1382:	Oil and gas exploration services",
  "1429:	Crushed and broken stone",
  "1522:	Residential construction",
  "1542:	Nonresidential construction",
  "1541:	Industrial buildings and warehouses",
  "1629:	Heavy construction",
  "1611:	Highway and street construction",
  "1711:	Plumbing, heating, air-conditioning",
  "1791:	Structural steel erection",
  "1761:	Roofing, siding, and sheetmetal work",
  "1799:	Special trade contractors, nec",
  "2048:	Prepared feeds",
  "2033:	Canned fruits and specialties",
  "2091:	Canned and cured fish and seafoods",
  "2051:	Bread, cake, and related products",
  "2032:	Canned specialties",
  "2084:	Wines, brandy, and brandy spirits",
  "2085:	Distilled and blended liquors",
  "2389:	Apparel and accessories",
  "2426:	Hardwood dimension and flooring mills",
  "2512:	Upholstered household furniture",
  "2621:	Paper mills",
  "2711:	Newspapers",
  "2721:	Periodicals",
  "2741:	Miscellaneous publishing",
  "2731:	Book publishing",
  "2789:	Bookbinding and related work",
  "2791:	Typesetting",
  "2819:	Industrial inorganic chemicals",
  "2879:	Agricultural chemicals",
  "2834:	Pharmaceutical preparations",
  "2851:	Paints and allied products",
  "2841:	Soap and other detergents",
  "2822:	Synthetic rubber",
  "2911:	Petroleum refining",
  "3061:	Mechanical rubber goods",
  "3069:	Fabricated rubber products, nec",
  "3089:	Plastics products, nec",
  "3171:	Women's handbags and purses",
  "3199:	Leather good",
  "3149:	Footwear, except rubber",
  "3253:	Ceramic wall and floor tile",
  "3231:	Products of purchased glass",
  "3274:	Lime",
  "3291:	Abrasive products",
  "3312:	Blast furnaces and steel mills",
  "3331:	Primary copper",
  "3497:	Metal foil and leaf",
  "3499:	Fabricated metal products",
  "3421:	Cutlery",
  "3443:	Fabricated plate work (boiler shop)",
  "3493:	Steel springs, except wire",
  "3442:	Metal doors, sash, and trim",
  "3599:	Industrial machinery",
  "3579:	Office machines",
  "3532:	Mining machinery",
  "3575:	Computer terminals",
  "3552:	Textile machinery",
  "3545:	Machine tool accessories",
  "3559:	Special industry machinery",
  "3531:	Construction machinery",
  "3549:	Metalworking machinery",
  "3568:	Power transmission equipment",
  "3571:	Electronic computers",
  "3572:	Computer storage devices",
  "3695:	Magnetic and optical recording media",
  "3634:	Electric housewares and fans",
  "3669:	Communications equipment",
  "3651:	Household audio and video equipment",
  "3674:	Semiconductors and related devices",
  "3631:	Household cooking equipment",
  "3679:	Electronic components",
  "3694:	Engine electrical equipment",
  "3633:	Household laundry equipment",
  "3761:	Guided missiles and space vehicles",
  "3743:	Railroad equipment",
  "3731:	Shipbuilding and repairing",
  "3799:	Transportation equipment",
  "3845:	Electromedical equipment",
  "3822:	Environmental controls",
  "3999:	Manufacturing industries",
  "3949:	Sporting and athletic goods",
  "4011:	Railroads, line-haul operating",
  "4111:	Local and suburban transit",
  "4131:	Intercity and rural bus transportation",
  "4121:	Taxicabs",
  "4151:	School buses",
  "4119:	Local passenger transportation",
  "4213:	Trucking, except local",
  "4212:	Local trucking, without storage",
  "4226:	Special warehousing and storage",
  "4311:	U.S. Postal Service",
  "4412:	Deep sea foreign transportation of freight",
  "4512:	Air transportation, scheduled",
  "4513:	Air courier services",
  "4619:	Pipelines",
  "4729:	Passenger transportation arrangement",
  "4731:	Freight transportation arrangement",
  "4833:	Television broadcasting stations",
  "4899:	Communication services",
  "4813:	Telephone communication, except radio",
  "4812:	Radiotelephone communication",
  "4931:	Electric and other services combined",
  "4911:	Electric services",
  "4932:	Gas and other services combined",
  "4971:	Irrigation systems",
  "4961:	Steam and air-conditioning supply",
  "4959:	Sanitary services",
  "4939:	Combination utilities",
  "5094:	Jewelry and precious stones",
  "5082:	Construction and mining machinery",
  "5013:	Motor vehicle supplies and new parts",
  "5047:	Medical and hospital equipment",
  "5012:	Automobiles and other motor vehicles",
  "5023:	Homefurnishings",
  "5021:	Furniture",
  "5074:	Plumbing and hydronic heating supplies",
  "5043:	Photographic equipment and supplies",
  "5045:	Computers, peripherals, and software",
  "5051:	Metals service centers and offices",
  "5064:	Electrical appliances, television and radio",
  "5075:	Warm air heating and air conditioning",
  "5088:	Transportation equipment and supplies",
  "5084:	Industrial machinery and equipment",
  "5093:	Scrap and waste materials",
  "5136:	Men's and boy's clothing",
  "5112:	Stationery and office supplies",
  "5145:	Confectionery",
  "5143:	Dairy products, except dried or canned",
  "5147:	Meats and meat products",
  "5181:	Beer and ale",
  "5169:	Chemicals and allied products",
  "5113:	Industrial and personal service paper",
  "5122:	Drugs, proprietaries, and sundries",
  "5139:	Footwear",
  "5149:	Groceries and related products",
  "5159:	Farm-product raw materials",
  "5172:	Petroleum products",
  "5199:	Nondurable goods",
  "5261:	Retail nurseries and garden stores",
  "5499:	Miscellaneous food stores",
  "5411:	Grocery stores",
  "5541:	Gasoline service stations",
  "5511:	New and used car dealers",
  "5651:	Family clothing stores",
  "5712:	Furniture stores",
  "5734:	Computer and software stores",
  "5813:	Drinking places",
  "5812:	Eating places",
  "5932:	Used merchandise stores",
  "5942:	Book stores",
  "5992:	Florists",
  "5999:	Miscellaneous retail stores",
  "5963:	Direct selling establishments",
  "6036:	Savings institutions, except federal",
  "6082:	Foreign trade and international banks",
  "6029:	Commercial banks",
  "6099:	Functions related to depository banking",
  "6141:	Personal credit institutions",
  "6282:	Investment advice",
  "6231:	Security and commodity exchanges",
  "6211:	Security brokers and dealers",
  "6221:	Commodity contracts brokers, dealers",
  "6371:	Pension, health, and welfare funds",
  "6311:	Life insurance",
  "6399:	Insurance carriers",
  "6411:	Insurance agents, brokers, and service",
  "6552:	Subdividers and developers",
  "6531:	Real estate agents and managers",
  "6519:	Real property lessors",
  "6719:	Holding companies",
  "6732:	Trusts: educational, religious, etc.",
  "6733:	Trusts",
  "7011:	Hotels and motels",
  "7221:	Photographic studios, portrait",
  "7251:	Shoe repair and shoeshine parlors",
  "7218:	Industrial launderers",
  "7216:	Drycleaning plants, except rugs",
  "7299:	Miscellaneous personal services",
  "7374:	Data processing and preparation",
  "7378:	Computer maintenance and repair",
  "7379:	Computer related services",
  "7375:	Information retrieval services",
  "7313:	Radio, television, publisher representatives",
  "7372:	Prepackaged software",
  "7373:	Computer integrated systems design",
  "7336:	Commercial art and graphic design",
  "7371:	Custom computer programming services",
  "7376:	Computer facilities management",
  "7363:	Help supply services",
  "7311:	Advertising agencies",
  "7389:	Business services",
  "7361:	Employment agencies",
  "7322:	Adjustment and collection services",
  "7382:	Security systems services",
  "7349:	Building maintenance services",
  "7519:	Utility trailer rental",
  "7539:	Automotive repair shops",
  "7538:	General automotive repair shops",
  "7622:	Radio and television repair",
  "7692:	Welding repair",
  "7641:	Reupholstery and furniture repair",
  "7812:	Motion picture and video production",
  "7819:	Services allied to motion pictures",
  "7929:	Entertainers and entertainment groups",
  "7911:	Dance studios, schools, and halls",
  "7941:	Sports clubs, managers, and promoters",
  "7922:	Theatrical producers and services",
  "7999:	Amusement and recreation",
  "7996:	Amusement parks",
  "7992:	Public golf courses",
  "7991:	Physical fitness facilities",
  "7993:	Coin-operated amusement devices",
  "8011:	Offices and clinics of medical doctors",
  "8021:	Offices and clinics of dentists",
  "8041:	Offices and clinics of chiropractors",
  "8042:	Offices and clinics of optometrists",
  "8063:	Psychiatric hospitals",
  "8049:	Offices of health practitioner",
  "8093:	Specialty outpatient clinics",
  "8082:	Home health care services",
  "8062:	General medical and surgical hospitals",
  "8059:	Nursing and personal care",
  "8111:	Legal services",
  "8231:	Libraries",
  "8211:	Elementary and secondary schools",
  "8221:	Colleges and universities",
  "8249:	Vocational schools",
  "8243:	Data processing schools",
  "8299:	Schools and educational services",
  "8351:	Child day care services",
  "8322:	Individual and family services",
  "8361:	Residential care",
  "8331:	Job training and related services",
  "8399:	Social services",
  "8412:	Museums and art galleries",
  "8641:	Civic and social associations",
  "8661:	Religious organizations",
  "8621:	Professional organizations",
  "8611:	Business associations",
  "8631:	Labor organizations",
  "8713:	Surveying services",
  "8712:	Architectural services",
  "8748:	Business consulting",
  "8734:	Testing laboratories",
  "8732:	Commercial nonphysical research",
  "8733:	Noncommercial research organizations",
  "8743:	Public relations services",
  "8731:	Commercial physical research",
  "8741:	Management services",
  "8744:	Facilities support services",
  "8721:	Accounting, auditing, and bookkeeping",
  "9111:	Executive offices",
  "9121:	Legislative bodies",
  "9199:	General government",
  "9222:	Legal counsel and prosecution",
  "9211:	Courts",
  "9221:	Police protection",
  "9223:	Correctional institutions",
  "9224:	Fire protection",
  "9229:	Public order and safety",
  "9311:	Finance, taxation, and monetary policy",
  "9411:	Administration of educational programs",
  "9431:	Administration of public health programs",
  "9511:	Air, water, and solid waste management",
  "9531:	Housing programs",
  "9661:	Space research and technology",
  "9611:	Administration of general economic programs",
  "9711:	National security",
  "9721:	International affairs",
];

// export const COUNTRIES_AVAIL_IN_AMER_OR_EUR = {
//   "East Timor": false,
//   "Saint Martin": true,
//   Guernsey: true,
//   Turkmenistan: false,
//   "Saint Helena": false,
//   Ireland: true,
//   Cambodia: false,
//   Ethiopia: false,
//   Aruba: true,
//   Swaziland: false,
//   Belize: true,
//   Argentina: false,
//   Bolivia: true,
//   Cameroon: false,
//   "Burkina Faso": false,
//   Bahrain: false,
//   "Saudi Arabia": false,
//   Martinique: true,
//   Togo: false,
//   "American Samoa": false,
//   Slovenia: true,
//   Guatemala: true,
//   "Bosnia and Herzegovina": true,
//   Guinea: false,
//   Spain: true,
//   Australia: false,
//   Liberia: false,
//   Maldives: false,
//   Jamaica: true,
//   Oman: false,
//   Tanzania: false,
//   Bonaire: true,
//   "São Tomé and Príncipe": false,
//   "Costa Rica": true,
//   "Cabo Verde": false,
//   "Christmas Island": false,
//   Gabon: false,
//   "Saint Pierre and Miquelon": true,
//   Monaco: true,
//   Bahamas: true,
//   "Wallis and Futuna": false,
//   "New Zealand": false,
//   Yemen: false,
//   Jersey: true,
//   Pakistan: false,
//   Albania: true,
//   Samoa: false,
//   "Norfolk Island": false,
//   "United Arab Emirates": false,
//   Guam: false,
//   Uruguay: true,
//   India: false,
//   "Svalbard and Jan Mayen": true,
//   Lesotho: false,
//   "Saint Vincent and the Grenadines": true,
//   Kenya: false,
//   Tajikistan: false,
//   Greenland: true,
//   Turkey: false,
//   Afghanistan: false,
//   Bangladesh: false,
//   Canada: true,
//   Mauritania: false,
//   "Solomon Islands": false,
//   "Turks and Caicos Islands": true,
//   "Saint Lucia": true,
//   "San Marino": true,
//   Mongolia: false,
//   Azerbaijan: false,
//   Rwanda: false,
//   Slovakia: true,
//   Somalia: false,
//   Peru: true,
//   Laos: false,
//   Nauru: false,
//   Norway: true,
//   Malawi: false,
//   "Cook Islands": false,
//   Benin: false,
//   "Federated States of Micronesia": false,
//   Réunion: false,
//   "Western Sahara": false,
//   Singapore: false,
//   Montenegro: true,
//   "Republic of the Congo": false,
//   "British Indian Ocean Territory": false,
//   China: false,
//   Ecuador: true,
//   Armenia: false,
//   "Antigua and Barbuda": true,
//   "Dominican Republic": true,
//   Ukraine: true,
//   Ghana: false,
//   Tonga: false,
//   Finland: true,
//   Libya: false,
//   Indonesia: false,
//   "Central African Republic": false,
//   "United States": true,
//   Sweden: true,
//   Vietnam: false,
//   "British Virgin Islands": true,
//   Mali: false,
//   "Vatican City": true,
//   Russia: true,
//   Bulgaria: true,
//   Mauritius: false,
//   Romania: true,
//   Angola: false,
//   "French Southern Territories": false,
//   Chad: false,
//   "South Africa": false,
//   Tokelau: false,
//   Macao: false,
//   "South Georgia and the South Sandwich Islands": false,
//   Liechtenstein: true,
//   Malaysia: false,
//   Senegal: false,
//   Mozambique: false,
//   Uganda: false,
//   Hungary: true,
//   Niger: false,
//   "Isle of Man": true,
//   Brazil: false,
//   "Saint-Barthélemy": true,
//   "Falkland Islands": true,
//   "Faroe Islands": true,
//   Kuwait: false,
//   Panama: true,
//   Guyana: true,
//   "Republic of Moldova": true,
//   Madagascar: false,
//   Luxembourg: true,
//   Andorra: true,
//   Gibraltar: true,
//   "Ivory Coast": false,
//   Syria: false,
//   Italy: true,
//   Nigeria: false,
//   Guadeloupe: true,
//   "Northern Mariana Islands": false,
//   Brunei: false,
//   Belarus: true,
//   Iran: false,
//   Algeria: false,
//   "Republic of Lithuania": true,
//   "El Salvador": true,
//   Tuvalu: false,
//   France: true,
//   Czechia: true,
//   Åland: true,
//   "Pitcairn Islands": false,
//   "Marshall Islands": false,
//   Chile: true,
//   "Puerto Rico": true,
//   Belgium: true,
//   Thailand: false,
//   Haiti: true,
//   Iraq: false,
//   "Hong Kong": false,
//   "Sierra Leone": false,
//   Georgia: false,
//   Gambia: false,
//   Poland: true,
//   "French Guiana": true,
//   Morocco: false,
//   Namibia: false,
//   "French Polynesia": false,
//   "Guinea-Bissau": false,
//   Kiribati: false,
//   Switzerland: true,
//   Grenada: true,
//   "Myanmar [Burma]": false,
//   "U.S. Virgin Islands": true,
//   Seychelles: false,
//   Portugal: true,
//   Estonia: true,
//   Kosovo: true,
//   Curaçao: true,
//   Mexico: true,
//   Lebanon: false,
//   "U.S. Minor Outlying Islands": false,
//   Uzbekistan: false,
//   Tunisia: false,
//   Djibouti: false,
//   "Heard Island and McDonald Islands": false,
//   Dominica: true,
//   Colombia: true,
//   Burundi: false,
//   Taiwan: false,
//   Cyprus: false,
//   Barbados: true,
//   Qatar: false,
//   Palau: false,
//   Bhutan: false,
//   Sudan: false,
//   Nepal: false,
//   Malta: true,
//   Netherlands: true,
//   Bermuda: true,
//   Suriname: true,
//   "Cayman Islands": true,
//   Anguilla: true,
//   Venezuela: true,
//   Israel: true,
//   "St Kitts and Nevis": true,
//   Iceland: true,
//   Zambia: false,
//   Austria: true,
//   "Papua New Guinea": false,
//   "Trinidad and Tobago": true,
//   Zimbabwe: false,
//   Germany: true,
//   Vanuatu: false,
//   Denmark: true,
//   Kazakhstan: false,
//   Philippines: false,
//   Eritrea: false,
//   Kyrgyzstan: false,
//   Mayotte: false,
//   Montserrat: true,
//   "New Caledonia": false,
//   Macedonia: true,
//   Paraguay: true,
//   Latvia: true,
//   "South Sudan": false,
//   Japan: false,
//   Croatia: true,
//   "Hashemite Kingdom of Jordan": false,
//   Cuba: true,
//   "Sint Maarten": true,
//   Honduras: true,
//   "Equatorial Guinea": false,
//   Egypt: false,
//   Nicaragua: true,
//   "Cocos [Keeling] Islands": false,
//   Serbia: true,
//   Comoros: false,
//   "United Kingdom": true,
//   Antarctica: false,
//   Congo: false,
//   Greece: true,
//   "Sri Lanka": false,
//   Fiji: false,
//   Botswana: false,
//   Niue: false,
//   "North Korea": false,
//   "Republic of Korea": false,
// };

export const TIMEZONE_ABBR = {
  "-12:00": "AOE",
  "-11:00": "NUT",
  "-10:00": "HST",
  "-09:00": "AKST",
  "-08:00": "PST",
  "-07:00": "MST",
  "-06:00": "MDT",
  "-05:00": "EST",
  "-04:00": "AT",
  "-03:00": "AMST",
  "-02:00": "FNT",
  "-01:00": "EGT",
  "+00:00": "GMT",
  "+01:00": "CET",
  "+02:00": "EET",
  "+03:00": "EEST",
  "+04:00": "ADT",
  "+05:00": "MVT",
  "+06:00": "IOT",
  "+07:00": "CXT",
  "+11:00": "LHDT",
  "+13:00": "PHOT",
  "+14:00": "TOST",
};

export const CHECKOUT_PAGE_ONE_DEFAULT_NAMES = ["city", "country", "line1", "postal_code"];

export const CHECKOUT_PAGE_TWO_DEFAULT_NAMES = ["cvc", "exp_month", "exp_year", "first4", "first_name", "last4", "last_name", "second4", "terms", "third4"];

export const CHECKOUT_PAGE_VALIDATION_RULES = {
  mode: "onSubmit",
  reValidateMode: "onSubmit",
  criteriaMode: "all",
  shouldFocusError: true,
};

export const USER_POPUP_VALIDATION_RULES = {
  mode: "onSubmit",
  reValidateMode: "onSubmit",
  criteriaMode: "all",
  shouldFocusError: true,
};

// export const country_list = Object.entries(countries.getNames('en', { select: 'official' }));

// eslint-disable-next-line import/prefer-default-export
// export const API_BASE = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : process.env.REACT_APP_SERVER_API;
export const API_BASE = import.meta.env.VITE_REACT_APP_SERVER_API;

export const LOGIN_AMINATION_URL = "https://assets8.lottiefiles.com/private_files/lf30_mmbjmjpa.json";

export const TUTORIAL = {
  step1: "step1",
  step2: "step2",
  step3: "step3",
};

export const SUBSCRIPTION_TYPES = {
  FREE: "FREE",
  // STANDARD: 'STANDARD',
  PREMIUM: "PREMIUM",
  SALES_CREDITS: "SALES_CREDITS",
  ENTERPRISE: "ENTERPRISE",
};

export const LANDING_PARAMS = {
  amount: "amount",
  sub: "sub",
  interval: "interval",
  from: "from",
  to: "to",
};

export const LANDING_TO_CHECKOUT = [LANDING_PARAMS.amount, LANDING_PARAMS.sub, LANDING_PARAMS.interval, LANDING_PARAMS.from];

export const PAYMENT_STATUS = {
  past_due: "past_due",
  unpaid: "unpaid",
  succeeded: "succeeded",
  paid: "paid",
  bounced: "bounced",
  pending: "pending",
};

export const CARD_TYPES = {
  visa: "visa",
  mastercard: "mastercard",
  amex: "amex",
  discover: "discover",
  diners: "diners",
  jcb: "jcb",
  unionpay: "unionpay",
};

// export const CARD_ICONS = {
//   [CARD_TYPES.visa]: <VisaIcon />,
//   [CARD_TYPES.mastercard]: <MasterIcon />,
//   [CARD_TYPES.amex]: <AmexIcon />,
//   [CARD_TYPES.discover]: <DiscoverIcon />,
//   [CARD_TYPES.diners]: <DinersIcon />,
//   [CARD_TYPES.jcb]: <JcbIcon />,
//   [CARD_TYPES.unionpay]: <UnionpayIcon />,
// };

export const SUBSCRIPTION_INTERVAL = {
  year: "year",
  month: "month",
};

export const SUBSCRIPTION_STATUS = {
  active: "active",
  canceled: "canceled",
  incomplete: "incomplete",
  incomplete_expired: "incomplete_expired",
  past_due: "past_due",
  trialing: "trialing",
  unpaid: "unpaid",
};

export const SUBSCRIPTION_ERROR_AND_SUCCESS_CODES = {
  payment_intent_authentication_failure: "payment_intent_authentication_failure",
  succeeded: "succeeded",
};

const freeSubscriptionData = {
  top_text: "Just getting started? Try Meetz and use Laura 100% free with our starter pack.",
  bottom_text: [
    <p key={1}>Meetz Ai Email Assistant</p>,
    <p key={2}>Over 100 Different Scenario Responses</p>,
    <p key={3}>Zoom, Google Meets, Teams Integration</p>,
    <p key={5}>
      <b>10</b> Monthly Follow Up Limits
    </p>,
  ],
};

const standardSubscriptionData = {
  top_text: "For individuals looking to get started with a more customized Laura.",
  bottom_text: [
    ...freeSubscriptionData.bottom_text.slice(0, -1),
    // <p key={5}>
    //   <b>40</b> Monthly Follow Up Limit
    // </p>,
    <p key={6}>Buffer Time Settings</p>,
    <p key={7}>Customizable Meeting Titles</p>,
    <p key={8}>Customize Laura’s Signature To Make It More Personal</p>,
  ],
};

const std_copy = [...standardSubscriptionData.bottom_text];
std_copy[4] = (
  <p key={5}>
    <b>Unlimited</b> Monthly Follow Up Limit
  </p>
);

// const premiumSubscriptionData = {
//   top_text:
//     'Unlock Meetz. AI Powered Lead Generation. Speed Dial at Scale, AI Email Personalize, and AI Email Assistant to Follow Up. The Complete All-In-One Package to Grow Your Sales With Ease.',
//   bottom_text: [
//     ...std_copy,
//     <p key={9}>Laura Checking Up In The Future</p>,
//     <p key={10}>Rename Laura’s Email To @YOURCompany.com </p>,
//     <p key={11}>Fully Customizable Meetings</p>,
//     <p key={12}>Change When Guests Can Meet</p>,
//   ],
// };
const premiumSubscriptionData = {
  top_text:
    "Unlock Meetz. AI Powered Lead Generation. Speed Dial at Scale, AI Email Personalize, and AI Email Assistant to Follow Up. The Complete All-In-One Package to Grow Your Sales With Ease.",
  explainer: [
    <p key={0}> Unleash The Power Of Ⓜ️eetz! </p>,
    <p key={1}> 📈 AI Power Dialer </p>,
    <p key={2}>Predictive Dialer To Call Up To 15 Prospects At Once</p>,
    <p key={3}>Once A Prospect Answer Automatically Meetz Stops Your Spotify, Get Prospects Info, And An AI Intro Line</p>,
    // <p key={4}>Automatically Leave Voicemails</p>,
    <p key={5}>🤖 AI Email Generator </p>,
    <p key={6}>Personalized Emails Based On Your Prospects LinkedIn</p>,
    <p key={7}>Email Prospects At Scale!</p>,
    <p key={8}>📅 AI Scheduler </p>,
    <p key={9}>Allow Your Prospect To Book Demos With Ease</p>,
    <p key={10}>Automatically Follows Up With All Your Prospects</p>,
  ],
  bottom_text: [
    <p key={1}> 📱 1. AI Power Dialer 📱</p>,
    <p key={2}>Predictive Dialer To Call Up To 15 Prospects At Once</p>,
    <p key={3}>Once A Prospect Answer Automatically Meetz Stops Your Spotify, Get Prospects Linkedin, Company Info And An AI Intro Line</p>,
    <p key={4}>Automatically Leave Voicemails</p>,
    <p key={5}>🤖 2. AI Email Generator 🤖</p>,
    <p key={6}>Create The Perfect Sales Pitch For Your Company&apos;s Value Proposition</p>,
    <p key={7}>Personalize Based On Your Prospects LinkedIn</p>,
    <p key={8}>📅 3. AI Scheduler 📅</p>,
    <p key={9}>Allow Your Prospect To Book Demos With Ease</p>,
    <p key={10}>Automatically Follows Up With All Your Prospects</p>,
  ],
};

const saleCredSubscriptionData = {
  top_text: "Tailor your email to your prospect accurately and fast. The most powerful AI that fills up your pipeline with ease.",
  bottom_text: [
    // ...std_copy,
    <p key={1}>Automated Sales Personalized Emails</p>,
    <p key={2}>Select Your Sales Tone </p>,
    <p key={3}>Adjust The Sales Methodology</p>,
    <p key={4}>Create The Perfect Sales Pitch For Your Company&apos;s Value Proposition</p>,
    <p key={5}>Our AI Measures Responses And The Messaging Get&apos;s more accurate with each iteration</p>,
    <p key={6}>Interconnected Scheduling Link To Speed Up Response To Meeting</p>,
    <p key={7}>Reactive Scheduling Links That Free Up Time For Your Prospect To Meet With You</p>,
    <p key={8}>LinkedIn Activity Personalized In The Email</p>,
    <p key={9}>Naturalize Variables So It Looks Handwritten</p>,
    <p key={10}>Smart Automated Follow Ups</p>,
    <p key={11}>Control The Outcome Of Your Pipeline</p>,
  ],
};
const entSubscriptionData = {
  top_text:
    "Team Plans to Grow Revenue Fast and Generate Leads At Scale. For Businesses That Want To Ensure The Top and Middle Of Funnel Pipeline Is Taken Care Of. Let Us Handle Your Top Of Funnel.",
  bottom_text: [
    // ...std_copy,
    <p key={1}>Team Plans</p>,
    <p key={2}>Customized Functionality</p>,
    <p key={3}>White Glove Advice and Insight from our Own Lead Gen Consultants</p>,
    <p key={4}>Admin Control and Analytics</p>,
    <p key={5}>Personalized Platform Settings</p>,
    <p key={6}>Salesforce, Salesloft, Outreach, Hubspot integrations</p>,
    <p key={8}>Our AI Measures Responses Across Your Team and The Messaging Get&apos;s More Accurate With Each Iteration</p>,
    <p key={9}>And More...</p>,
  ],
};

// export const SUBSCRIPTION_DATA = {
//   [SUBSCRIPTION_TYPES.FREE]: freeSubscriptionData,
//   [SUBSCRIPTION_TYPES.STANDARD]: standardSubscriptionData,
//   [SUBSCRIPTION_TYPES.PREMIUM]: premiumSubscriptionData,
//   [SUBSCRIPTION_TYPES.SALES_CREDITS]: saleCredSubscriptionData,
//   [SUBSCRIPTION_TYPES.ENTERPRISE]: entSubscriptionData,
// };

// export const SUBSCRIPTION_DATA_REMOVED = {
//   [SUBSCRIPTION_TYPES.FREE]: SUBSCRIPTION_DATA[SUBSCRIPTION_TYPES.FREE],
//   [SUBSCRIPTION_TYPES.STANDARD]: {
//     ...SUBSCRIPTION_DATA[SUBSCRIPTION_TYPES.STANDARD],
//     bottom_text: [
//       <p key={1}>Meetz Ai Email Assistant</p>,
//       <p key={2}>Buffer time settings</p>,
//       <p key={3}>Customizable meeting titles</p>,
//       <p key={4}>Customize Laura’s signature to make it more personal</p>,
//     ],
//   },
//   [SUBSCRIPTION_TYPES.PREMIUM]: {
//     ...SUBSCRIPTION_DATA[SUBSCRIPTION_TYPES.PREMIUM],
//     bottom_text: [
//       <p key={1}>Laura Checking Up In The Future With Guests</p>,
//       <p key={2}>Rename Laura’s email to @YOURCompany.com </p>,
//       <p key={3}>Fully Customizable Meetings</p>,
//       <p key={4}>Customize Laura’s signature to make it more personal</p>,
//       <p key={5}>Fully Unlock Meetz Scheduling Potential</p>,
//     ],
//   },
//   [SUBSCRIPTION_TYPES.SALES_CREDITS]: {
//     ...SUBSCRIPTION_DATA[SUBSCRIPTION_TYPES.SALES_CREDITS],
//     bottom_text: [
//       <p key={1}>Powerful Sales Email Generator</p>,
//       <p key={2}>Automated Mass Personalization Using Your Leads LinkedIn Activity - Hundreds Of Emails in 1 click</p>,
//       <p key={3}>Personalized Emails based on your own tone, sales methodolody, and elevator pitch</p>,
//       <p key={4}>Automated Follow Ups</p>,
//       <p key={5}>Triple Your Outbound Sales Effort With Ease!</p>,
//       <p key={6}>10 Credits Per Email Generated</p>,
//     ],
//   },
// };

export const MEETING_SERVICE = {
  google_meet: "google_meet",
  zoom: "zoom",
  teams: "teams",
  phone_call: "phone_call",
  other: "other",
  unset: "unset",
};

export const DURATIONS = {
  ONE_DAY_UNIX: 86400000,
  TWO_DAYS_UNIX: 172800000,
  THIRTY_MINUTES_UNIX: 1800000,
};

export const ACCOUNT_STATUS = {
  ACTIVE: "ACTIVE",
  SOFT_DELETED: "SOFT_DELETED",
  DELETED: "DELETED",
};

export const DEFAULT_WORKING_HOURS = {
  start: "9:00",
  end: "16:00",
};

export const SETTINGS_PAGE_TEXTAREA_MAX_LENGTH = "1000";

export const DEBOUNCE_TIME = 1500;

export const VALIDATION_PATTERNS = {
  cvc: /[0-9]{3,4}/,
  four_digit: /[0-9]{4}/,
  two_or_four_digit: /[0-9]{2,4}/,
  fourteen_or_sixteen_digit: /^\d{14,16}$/,
  name: /^[a-zA-Z]+(?:(-|'\s)[a-zA-Z]+)*$/,
  title: /[a-zA-Z?:(-|')\s]+/,
  province: /^[0-9a-zA-Z_'-,/\s]+$/,
  street: /^[0-9a-zA-Z_'-]+$/,
  phone: /[0-9]{10,15}/,
  get_digits: /[\D+]/gi,
  // eslint-disable-next-line
  non_english: /[^\x00-\x7F]+/gi,
  // email: /^\S+@\S+\.\S+$/gi,
  // notXSSInjection: /^[^<>{}()]*$/gi,
  email: /^\S+@\S+\.\S+$/i,
  notXSSInjection: /^[^<>{}()]+$/,
  // eslint-disable-next-line no-useless-escape
  // notEmpty: (.|\s)*\S(.|\s)*,
};

export const DEFAULT_MEETING_TITLE = {
  conferencing_platform: "conferencing_platform",
  guests_company: "guests_company",
};

export const TOOLTIP = {
  [DEFAULT_MEETING_TITLE.conferencing_platform]: "Meeting platform used in the meeting booked.",
  [DEFAULT_MEETING_TITLE.guests_company]: "Your guests' company name. *If not available will be left blank.",
};

export const IMAGES = {
  // just ignore low and high dont wast e time with it for now
  INSTRUCTIONS: {
    google: {
      low: "https://public-images-meetzai.s3.eu-west-1.amazonaws.com/goglemeet.jpg",
      high: "https://public-images-meetzai.s3.eu-west-1.amazonaws.com/goglemeet.jpg",
    },
    zoom: {
      low: "https://public-images-meetzai.s3.eu-west-1.amazonaws.com/zoom.png",
      high: "https://public-images-meetzai.s3.eu-west-1.amazonaws.com/zoom.png",
    },
    teams: {
      low: "https://public-images-meetzai.s3-eu-west-1.amazonaws.com/teams-instr.png",
      high: "https://public-images-meetzai.s3-eu-west-1.amazonaws.com/teams-instr.png",
    },
    profile: {
      low: "https://public-images-meetzai.s3-eu-west-1.amazonaws.com/meetingtools-profile-low.png",
      high: "https://public-images-meetzai.s3-eu-west-1.amazonaws.com/meetingtools-profile.png",
    },
  },
};

export const DAYLIGHT_DATES = {
  2021: {
    start: "2021-03-14",
    end: "2021-10-31",
  },
  2022: {
    start: "2022-03-13",
    end: "2022-10-06",
  },
  2023: {
    start: "2023-03-12",
    end: "2023-10-05",
  },
  2024: {
    start: "2024-03-10",
    end: "2024-10-03",
  },
  2025: {
    start: "2025-03-09",
    end: "2025-10-02",
  },
  2026: {
    start: "2026-03-08",
    end: "2026-10-01",
  },
  2027: {
    start: "2027-03-14",
    end: "2027-10-07",
  },
  2028: {
    start: "2028-03-12",
    end: "2028-10-05",
  },
  2029: {
    start: "2029-03-11",
    end: "2029-10-04",
  },
  2030: {
    start: "2030-03-10",
    end: "2030-10-03",
  },
};

export const integrations = ["Webex", "Zoom", "Google meets", "MS teams", "Bluejeans"];

const now = new Date().getUTCFullYear();
export const years = Array(now + 20 - now)
  .fill("")
  .map((v, idx) => now + idx);

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const reminders_per_flow_options = Array.from(Array(6).keys()).map((number) => ({ id: number, value: number }));

export const reminders_delay_options = Array.from(Array(10).keys()).map((number) =>
  number !== 0 ? { id: number, value: `${number + 1} days` } : { id: number, value: `${number + 1} day` }
);
export const week_days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

export const maxCallsAtSameTime = [
  { id: 1, value: 1 },
  { id: 2, value: 2 },
  { id: 3, value: 3 },
  { id: 4, value: 4 },
  { id: 5, value: 5 },
  { id: 6, value: 6 },
  { id: 7, value: 7 },
  { id: 8, value: 8 },
  { id: 9, value: 9 },
  { id: 10, value: 10 },
  { id: 11, value: 15 },
];
export const minTimeBetweenEmails = [
  { id: 1, value: 30, text: "30 Seconds" },
  { id: 2, value: 60, text: "1 Minute" },
  { id: 3, value: 90, text: "1.5 Minutes" },
  { id: 4, value: 120, text: "2 Minutes" },
  { id: 5, value: 300, text: "5 Minutes" },
  { id: 6, value: 600, text: "10 Minutes" },
  { id: 7, value: 1200, text: "20 Minutes" },
];
export const maxTimesToCall = [
  { id: 1, value: 1 },
  { id: 2, value: 2 },
  { id: 3, value: 3 },
  { id: 4, value: 4 },
  { id: 5, value: 5 },
  { id: 6, value: 6 },
  { id: 7, value: 7 },
  { id: 8, value: 8 },
  { id: 9, value: 9 },
  { id: 10, value: 10 },
  { id: 11, value: 15 },
  { id: 12, value: 20 },
];
export const timeBetweenCalls = [
  { id: 0, value: 1, text: "1 Hour" },
  { id: 1, value: 2, text: "2 Hours" },
  { id: 2, value: 3, text: "3 Hours" },
  { id: 3, value: 5, text: "5 Hours" },
  { id: 4, value: 24, text: "1 Day" },
  { id: 5, value: 48, text: "2 Days" },
  { id: 6, value: 72, text: "3 Days" },
  { id: 7, value: 96, text: "4 Days" },
];
export const roundHours = [
  { id: 0, value: 0, text: "12 AM" },
  { id: 1, value: 1, text: "1 AM" },
  { id: 2, value: 2, text: "2 AM" },
  { id: 3, value: 3, text: "3 AM" },
  { id: 4, value: 4, text: "4 AM" },
  { id: 5, value: 5, text: "5 AM" },
  { id: 6, value: 6, text: "6 AM" },
  { id: 7, value: 7, text: "7 AM" },
  { id: 8, value: 8, text: "8 AM" },
  { id: 9, value: 9, text: "9 AM" },
  { id: 10, value: 10, text: "10 AM" },
  { id: 11, value: 11, text: "11 AM" },
  { id: 12, value: 12, text: "12 Noon" },
  { id: 13, value: 13, text: "1 PM" },
  { id: 14, value: 14, text: "2 PM" },
  { id: 15, value: 15, text: "3 PM" },
  { id: 16, value: 16, text: "4 PM" },
  { id: 17, value: 17, text: "5 PM" },
  { id: 18, value: 18, text: "6 PM" },
  { id: 19, value: 19, text: "7 PM" },
  { id: 20, value: 20, text: "8 PM" },
  { id: 21, value: 21, text: "9 PM" },
  { id: 22, value: 22, text: "10 PM" },
  { id: 23, value: 23, text: "11 PM" },
];
export const hours = [
  { id: 0, value: "0:00", text: "12:00 am" },
  { id: 1, value: "00:15", text: "12:15 am" },
  { id: 2, value: "00:30", text: "12:30 am" },
  { id: 3, value: "00:45", text: "12:45 am" },
  { id: 4, value: "1:00", text: "1 am" },
  { id: 5, value: "1:15", text: "1:15 am" },
  { id: 6, value: "1:30", text: "1:30 am" },
  { id: 7, value: "1:45", text: "1:45 am" },
  { id: 8, value: "2:00", text: "2 am" },
  { id: 9, value: "2:15", text: "2:15 am" },
  { id: 10, value: "2:30", text: "2:30 am" },
  { id: 11, value: "2:45", text: "2:45 am" },
  { id: 12, value: "3:00", text: "3 am" },
  { id: 13, value: "3:15", text: "3:15 am" },
  { id: 14, value: "3:30", text: "3:30 am" },
  { id: 15, value: "3:45", text: "3:45 am" },
  { id: 16, value: "4:00", text: "4 am" },
  { id: 17, value: "4:15", text: "4:15 am" },
  { id: 18, value: "4:30", text: "4:30 am" },
  { id: 19, value: "4:45", text: "4:45 am" },
  { id: 20, value: "5:00", text: "5 am" },
  { id: 21, value: "5:15", text: "5:15 am" },
  { id: 22, value: "5:30", text: "5:30 am" },
  { id: 23, value: "5:45", text: "5:45 am" },
  { id: 24, value: "6:00", text: "6 am" },
  { id: 25, value: "6:15", text: "6:15 am" },
  { id: 26, value: "6:30", text: "6:30 am" },
  { id: 27, value: "6:45", text: "6:45 am" },
  { id: 28, value: "7:00", text: "7 am" },
  { id: 29, value: "7:15", text: "7:15 am" },
  { id: 30, value: "7:30", text: "7:30 am" },
  { id: 31, value: "7:45", text: "7:45 am" },
  { id: 32, value: "8:00", text: "8 am" },
  { id: 33, value: "8:15", text: "8:15 am" },
  { id: 34, value: "8:30", text: "8:30 am" },
  { id: 35, value: "8:45", text: "8:45 am" },
  { id: 36, value: "9:00", text: "9 am" },
  { id: 37, value: "9:15", text: "9:15 am" },
  { id: 38, value: "9:30", text: "9:30 am" },
  { id: 39, value: "9:45", text: "9:45 am" },
  { id: 40, value: "10:00", text: "10 am" },
  { id: 41, value: "10:15", text: "10:15 am" },
  { id: 42, value: "10:30", text: "10:30 am" },
  { id: 43, value: "10:45", text: "10:45 am" },
  { id: 44, value: "11:00", text: "11 am" },
  { id: 45, value: "11:15", text: "11:15 am" },
  { id: 46, value: "11:30", text: "11:30 am" },
  { id: 47, value: "11:45", text: "11:45 am" },
  { id: 48, value: "12:00", text: "12 pm" },
  { id: 49, value: "12:15", text: "12:15 pm" },
  { id: 50, value: "12:30", text: "12:30 pm" },
  { id: 51, value: "12:45", text: "12:45 pm" },
  { id: 52, value: "13:00", text: "1 pm" },
  { id: 53, value: "13:15", text: "1:15 pm" },
  { id: 54, value: "13:30", text: "1:30 pm" },
  { id: 55, value: "13:45", text: "1:45 pm" },
  { id: 56, value: "14:00", text: "2 pm" },
  { id: 57, value: "14:15", text: "2:15 pm" },
  { id: 58, value: "14:30", text: "2:30 pm" },
  { id: 59, value: "14:45", text: "2:45 pm" },
  { id: 60, value: "15:00", text: "3 pm" },
  { id: 61, value: "15:15", text: "3:15 pm" },
  { id: 62, value: "15:30", text: "3:30 pm" },
  { id: 63, value: "15:45", text: "3:45 pm" },
  { id: 64, value: "16:00", text: "4 pm" },
  { id: 65, value: "16:15", text: "4:15 pm" },
  { id: 66, value: "16:30", text: "4:30 pm" },
  { id: 67, value: "16:45", text: "4:45 pm" },
  { id: 68, value: "17:00", text: "5 pm" },
  { id: 69, value: "17:15", text: "5:15 pm" },
  { id: 70, value: "17:30", text: "5:30 pm" },
  { id: 71, value: "17:45", text: "5:45 pm" },
  { id: 72, value: "18:00", text: "6 pm" },
  { id: 73, value: "18:15", text: "6:15 pm" },
  { id: 74, value: "18:30", text: "6:30 pm" },
  { id: 75, value: "18:45", text: "6:45 pm" },
  { id: 76, value: "19:00", text: "7 pm" },
  { id: 77, value: "19:15", text: "7:15 pm" },
  { id: 78, value: "19:30", text: "7:30 pm" },
  { id: 79, value: "19:45", text: "7:45 pm" },
  { id: 80, value: "20:00", text: "8 pm" },
  { id: 81, value: "20:15", text: "8:15 pm" },
  { id: 82, value: "20:30", text: "8:30 pm" },
  { id: 83, value: "20:45", text: "8:45 pm" },
  { id: 84, value: "21:00", text: "9 pm" },
  { id: 85, value: "21:15", text: "9:15 pm" },
  { id: 86, value: "21:30", text: "9:30 pm" },
  { id: 87, value: "21:45", text: "9:45 pm" },
  { id: 88, value: "22:00", text: "10 pm" },
  { id: 89, value: "22:15", text: "10:15 pm" },
  { id: 90, value: "22:30", text: "10:30 pm" },
  { id: 91, value: "22:45", text: "10:45 pm" },
  { id: 92, value: "23:00", text: "11 pm" },
  { id: 93, value: "23:15", text: "11:15 pm" },
  { id: 94, value: "23:30", text: "11:30 pm" },
  { id: 95, value: "23:45", text: "11:45 pm" },
  { id: 96, value: "23:59", text: "11:59 pm" },
];

export const communication_methods = [
  { id: 1, value: "Zoom" },
  { id: 2, value: "Google Meet" },
  // { id: 3, value: 'Phone Call' },
];

// function addText(arr) {
//   return arr.map((item) => {
//     const minutes = moment.duration(item.value).minutes();
//     const hours = Math.trunc(moment.duration(item.value).asHours());

//     if (hours === 1) {
//       return { ...item, text: `${hours} hour` };
//     }

//     if (hours > 0) {
//       return { ...item, text: `${hours} hours` };
//     }
//     return { ...item, text: `${minutes} min` };
//   });
// }

// export const minutes = addText([
//   { id: 0, value: 0 },
//   { id: 1, value: DURATIONS.THIRTY_MINUTES_UNIX / 6 },
//   { id: 2, value: DURATIONS.THIRTY_MINUTES_UNIX / 3 },
//   { id: 3, value: DURATIONS.THIRTY_MINUTES_UNIX / 2 },
//   { id: 4, value: DURATIONS.THIRTY_MINUTES_UNIX },
//   { id: 5, value: (DURATIONS.THIRTY_MINUTES_UNIX * 3) / 2 },
//   { id: 7, value: DURATIONS.THIRTY_MINUTES_UNIX * 2 },
//   { id: 8, value: DURATIONS.THIRTY_MINUTES_UNIX * 4 },
//   { id: 9, value: DURATIONS.THIRTY_MINUTES_UNIX * 6 },
//   { id: 10, value: DURATIONS.THIRTY_MINUTES_UNIX * 8 },
//   { id: 11, value: DURATIONS.THIRTY_MINUTES_UNIX * 10 },
//   { id: 12, value: DURATIONS.THIRTY_MINUTES_UNIX * 12 },
//   { id: 13, value: DURATIONS.THIRTY_MINUTES_UNIX * 14 },
//   { id: 14, value: DURATIONS.THIRTY_MINUTES_UNIX * 16 },
//   { id: 15, value: DURATIONS.THIRTY_MINUTES_UNIX * 18 },
//   { id: 16, value: DURATIONS.THIRTY_MINUTES_UNIX * 20 },
//   { id: 17, value: DURATIONS.THIRTY_MINUTES_UNIX * 22 },
//   { id: 18, value: DURATIONS.THIRTY_MINUTES_UNIX * 24 },
//   { id: 19, value: DURATIONS.THIRTY_MINUTES_UNIX * 26 },
//   { id: 20, value: DURATIONS.THIRTY_MINUTES_UNIX * 28 },
//   { id: 21, value: DURATIONS.THIRTY_MINUTES_UNIX * 30 },
//   { id: 22, value: DURATIONS.THIRTY_MINUTES_UNIX * 32 },
//   { id: 23, value: DURATIONS.THIRTY_MINUTES_UNIX * 34 },
//   { id: 24, value: DURATIONS.THIRTY_MINUTES_UNIX * 36 },
//   { id: 25, value: DURATIONS.THIRTY_MINUTES_UNIX * 38 },
//   { id: 26, value: DURATIONS.THIRTY_MINUTES_UNIX * 40 },
//   { id: 27, value: DURATIONS.THIRTY_MINUTES_UNIX * 42 },
//   { id: 28, value: DURATIONS.THIRTY_MINUTES_UNIX * 44 },
//   { id: 29, value: DURATIONS.THIRTY_MINUTES_UNIX * 46 },
//   { id: 30, value: DURATIONS.THIRTY_MINUTES_UNIX * 48 },
// ]);
export const maxTimeslotsPerDay = [
  { id: 0, value: 0, text: "No max time" },
  { id: 1, value: 3, text: "3 Slots" },
  { id: 2, value: 4, text: "4 Slots" },
  { id: 3, value: 5, text: "5 Slots" },
  { id: 4, value: 6, text: "6 Slots" },
  { id: 5, value: 7, text: "7 Slots" },
  { id: 6, value: 8, text: "8 Slots" },
  { id: 7, value: 9, text: "9 Slots" },
  { id: 8, value: 10, text: "10 Slots" },
  { id: 9, value: 15, text: "15 Slots" },
];
export const days = [
  { id: 0, value: 0, text: "No max time" },
  { id: 1, value: 3, text: "3 days" },
  { id: 2, value: 4, text: "4 days" },
  { id: 3, value: 5, text: "5 days" },
  { id: 4, value: 6, text: "6 days" },
  { id: 5, value: 7, text: "7 days" },
  { id: 6, value: 8, text: "8 days" },
  { id: 7, value: 9, text: "9 days" },
  { id: 8, value: 10, text: "10 days" },
  { id: 9, value: 15, text: "15 days" },
  { id: 10, value: 30, text: "30 days" },
  { id: 11, value: 60, text: "60 days" },
  { id: 12, value: 120, text: "120 days" },
  { id: 13, value: 180, text: "180 days" },
  { id: 14, value: 365, text: "1 Year" },
];
export const daysFlwUp = [
  { id: 1, value: 1, text: "1 day" },
  { id: 2, value: 2, text: "2 days" },
  { id: 3, value: 3, text: "3 days" },
  { id: 4, value: 4, text: "4 days" },
  { id: 5, value: 5, text: "5 days" },
  { id: 6, value: 6, text: "6 days" },
  { id: 7, value: 7, text: "7 days" },
  { id: 8, value: 8, text: "8 days" },
  { id: 9, value: 9, text: "9 days" },
];

// export const minutes_for_meeting_length = addText([
//   // { id: 2, value: DURATIONS.THIRTY_MINUTES_UNIX / 3 }, DO NOT ADD A NON 15 minute interval it ruins the cal logic
//   { id: 3, value: DURATIONS.THIRTY_MINUTES_UNIX / 2 },
//   { id: 4, value: DURATIONS.THIRTY_MINUTES_UNIX },
//   { id: 5, value: (DURATIONS.THIRTY_MINUTES_UNIX * 3) / 2 },
//   { id: 7, value: DURATIONS.THIRTY_MINUTES_UNIX * 2 },
//   { id: 8, value: DURATIONS.THIRTY_MINUTES_UNIX * 4 },
//   { id: 9, value: DURATIONS.THIRTY_MINUTES_UNIX * 6 },
// ]);

// export const minutes_to_three_hours = addText([
//   { id: 0, value: 0 },
//   { id: 1, value: DURATIONS.THIRTY_MINUTES_UNIX / 6 },
//   { id: 2, value: DURATIONS.THIRTY_MINUTES_UNIX / 3 },
//   { id: 3, value: DURATIONS.THIRTY_MINUTES_UNIX / 2 },
//   { id: 4, value: DURATIONS.THIRTY_MINUTES_UNIX },
//   { id: 5, value: (DURATIONS.THIRTY_MINUTES_UNIX * 3) / 2 },
//   { id: 7, value: DURATIONS.THIRTY_MINUTES_UNIX * 2 },
//   { id: 8, value: DURATIONS.THIRTY_MINUTES_UNIX * 4 },
//   { id: 9, value: DURATIONS.THIRTY_MINUTES_UNIX * 6 },
// ]);
