import { Button, Flex, Row, RowProps, Tooltip } from "antd";
import React, { FC, ForwardedRef } from "react";
import { Loader } from "../Loader";

interface ConfirmButtonsProps extends RowProps {
  width?: number | string;
  leftButtonTitle?: string | null;
  rightButtonTitle: string;
  disabled?: boolean;
  leftButtonNoClick?: boolean;
  onCancel?: VoidFunction;
  handleSubmit?: VoidFunction | undefined;
  leftBtnRef?: ForwardedRef<HTMLDivElement | any>;
  rightBtnRef?: ForwardedRef<HTMLDivElement | any>;
  loading?: boolean;
  loadingText?: string;
  cancellation?: boolean;
  noEnterKeyboardSubmit?: boolean;
  disabledExplanation?: string;
}

export const ConfirmButtons: FC<ConfirmButtonsProps> = ({
  width,
  leftButtonTitle,
  rightButtonTitle,
  onCancel,
  handleSubmit,
  disabled = false,
  leftBtnRef,
  rightBtnRef,
  leftButtonNoClick = false,
  loading = false,
  noEnterKeyboardSubmit = false,
  disabledExplanation = "",
  loadingText = "",
  cancellation,
  ...props
}) => {
  return (
    <Row align={"middle"} style={{ width: width ? width : "100%" }} wrap={false} {...props}>
      {leftButtonTitle ? (
        leftButtonNoClick ? (
          <Button className={"link"} style={{ width: "100%", pointerEvents: "none" }}>
            {leftButtonTitle}
          </Button>
        ) : (
          <Button className={"link"} onClick={onCancel} style={{ width: "100%" }}>
            {leftButtonTitle}
          </Button>
        )
      ) : null}
      {disabled && disabledExplanation?.length ? (
        <Tooltip title={disabledExplanation} placement={"top"} rootClassName={"tooltip"} color={"#ECFBFC"}>
          {" "}
          <Button
            ref={rightBtnRef}
            className={"primary"}
            htmlType={noEnterKeyboardSubmit ? "button" : "submit"}
            style={{ width: "100%" }}
            onClick={handleSubmit}
            disabled={disabled || loading}
          >
            {loading ? (
              <Flex style={{ width: "100%", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <div style={{ width: "fit-content", marginTop: "5px", marginRight: "18px" }}>{loadingText || "Loading"}</div>
                <Loader size="small" className={"whiteSpin"} />
              </Flex>
            ) : (
              rightButtonTitle
            )}
          </Button>
        </Tooltip>
      ) : (
        <Button
          ref={rightBtnRef}
          className={"primary"}
          htmlType={noEnterKeyboardSubmit ? "button" : "submit"}
          style={{
            width: "100%",
            background: cancellation ? "white" : undefined,
            color: cancellation ? "red" : undefined,
            border: cancellation ? "red" : undefined,
            borderWidth: cancellation ? "1px" : undefined,
            borderStyle: cancellation ? "solid" : undefined,
            // fontWeight: cancellation ? 00 : undefined,
          }}
          onClick={handleSubmit}
          disabled={disabled || loading}
        >
          {loading ? (
            <Flex style={{ width: "100%", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <div style={{ width: "fit-content", marginTop: "5px", marginRight: "18px" }}>{loadingText || "Loading"}</div>
              <Loader size="small" className={"whiteSpin"} />
            </Flex>
          ) : (
            rightButtonTitle
          )}
        </Button>
      )}
    </Row>
  );
};
