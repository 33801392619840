/* eslint-disable react/jsx-fragments */
import { Col, Form, Input, Radio, Row, Space, Tooltip, Typography, message } from "antd";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { StepsSequence } from "src/components/pages/campaigns/ui/StepsSequence";
// import { sequenceData } from "src/components/pages/campaigns/campaignsData"
import { decode as atob, encode as btoa } from "base-64";
import { AiPersonalizedEmail, ManualEmails, PhoneCall, Sms, Task } from "./ui";
import { useDispatch, useSelector } from "react-redux";
import { outreachCampaignSelector, resetNewCmpId, warnRegenerateSequence } from "src/store/outreach-campaigns";
import { Collapse, ConfirmButtons, CustomLabel, Modal } from "src/components/ui";
import { addNewCampaignV2, createCampaignStepsWithAi } from "src/store/outreach-campaigns/asyncRequests";
import { DefaultContent } from "./ui/Default";
import { TestAiSettings } from "./ui/AiPersonalizedEmail/ui/TestAiSettings";
import { SvgIcon } from "src/shared/icons";
import { AppDispatch } from "src/store";
import { CreatingCampaignModal } from "./ui/CreatingCampaignModal";
import { useNavigate } from "react-router";

type Mode = {
  key: string;
  component: ReactNode;
};

export const StepThree: FC = ({ step, toggleHandleNextStep, setToggleHandleNextStep, from }) => {
  // from is either 'ai' or 'scratch';
  //   const [mode, setMode] = useState<string>('default')
  const [mode, setMode] = useState<string>("default");
  const [form] = Form.useForm();

  const {
    loadCreateCamp,
    warnRegenerateSequenceVal,
    aiGenProps,
    draftOutreachSeqAi,
    // draftOutreachSeqScratch,
    popularUploadName,
    generatedName,
    leadsIdSelected,
    backupEmail,
    loadingAddingCampaign,
    newCampId,
    aiSettings,
    draftProperlyFormatedOutreachSeqAi,
    totalCamps,
    draftProperlyFormatedOutreachSeqScratch,
    invalidDraftSteps,
  } = useSelector(outreachCampaignSelector);

  // console.log(popularUploadName, generatedName, "sadfjnasfnkl");
  const [subjectBackup, setSubjectBackup] = useState("");
  const [madeChanges, setMadeChanges] = useState(false);
  const [bodyBackup, setBodyBackup] = useState("");
  const [idOfEditingEmail, setIDOfEditingEmail] = useState(null);
  const [openSaveAndProceedModal, setOpenSaveAndProceedModal] = useState(false);

  const [controlActKeys, setControlActKeys] = useState([]);
  const [draftOutreachSequence, setDraftOutreachSequence] = useState([]);
  const [testAiSettingsOpen, setTestAiSettingsOpen] = useState(false);
  // this will show whats on the right side of the screen
  const modes: Mode[] = [
    { key: "default", component: <DefaultContent /> },
    {
      key: "templated",
      component: (
        <ManualEmails
          idOfEditingEmail={idOfEditingEmail}
          setDraftOutreachSequence={setDraftOutreachSequence}
          draftOutreachSequence={draftOutreachSequence}
          madeChanges={madeChanges}
          setMadeChanges={setMadeChanges}
        />
      ),
    },
    {
      key: "ai",
      component: (
        <AiPersonalizedEmail
          setTestAiSettingsOpen={setTestAiSettingsOpen}
          subject={subjectBackup}
          body={bodyBackup}
          setSubject={setSubjectBackup}
          setBody={setBodyBackup}
        />
      ),
    },
    { key: "task", component: <Task /> },
    { key: "sms", component: <Sms /> },
    { key: "phone", component: <PhoneCall /> },
  ];

  useEffect(() => {
    setTimeout(() => {
      setToggleHandleNextStep(false);
    }, 600); // wont turn to false if no settimeout
    if (toggleHandleNextStep && step === 2) {
      console.log("trying to create!");
      message.destroy();
      if (invalidDraftSteps?.length) {
        let aiStepWarned = false;
        let tempStepWarned = false;
        invalidDraftSteps?.forEach((badStep) => {
          if (!tempStepWarned && badStep?.stepIssue === "Templated Email") {
            tempStepWarned = false;
            message.error(
              <>
                "Templated Email" step needs to have at least 1 email template, <b>click Save after adding it</b>
              </>
            );
          } else if (!aiStepWarned && badStep?.stepIssue === "Ai Personalized Email") {
            aiStepWarned = false;
            message.error(`"Ai Personalized Email" step needs to have a backup email`);
          }
        });
        return;
      }

      if (loadCreateCamp) {
        message.error(`Wait for the campaign steps to load`);
        message.error(`🙂`);
        return;
      }
      if (!draftOutreachSequence?.length) {
        message.error("Must have at least one step to activate campaign");
        return;
      }
      let hasOneValStepAtLeast = false;
      draftOutreachSequence.map((step) => {
        // TODOF when sms is added so need to check sms like template email
        if (hasOneValStepAtLeast) {
          return;
        }
        if (step.type === "phonecall" || step.type === "aiemail") {
          hasOneValStepAtLeast = true;
          return;
        }
        if (step.type === "email") {
          step.emailVariantsArray?.forEach((variant) => {
            if (variant?.body?.trim()?.length) {
              hasOneValStepAtLeast = true;
            }
          });
          return;
        }
      });

      if (!hasOneValStepAtLeast) {
        message.error("Recheck that your steps are filled out and not left blank");
        return;
      }

      setOpenSaveAndProceedModal(true);
    }
  }, [toggleHandleNextStep, form]);

  const currentMode = modes.find((item) => item.key === mode);

  const dispatch = useDispatch<AppDispatch>();

  // const open = ()=>{
  //   dispatch(warnRegenerateSequence(true))
  // }
  const closeModal = () => {
    dispatch(warnRegenerateSequence(false));
  };
  const handleSubmit = () => {
    dispatch(createCampaignStepsWithAi({ leadsIdSelected, infoForCampaign: { ...(aiGenProps || {}) } }));
    dispatch(warnRegenerateSequence(false));
  };

  const [firstLoad, setFirstLoad] = useState(false);
  // console.log(from, "fromfirstLoad", firstLoad, draftProperlyFormatedOutreachSeqAi);

  useEffect(() => {
    // update ai
    if (from === "ai" && !firstLoad) {
      setDraftOutreachSequence(
        (draftProperlyFormatedOutreachSeqAi?.length ? draftProperlyFormatedOutreachSeqAi : draftOutreachSeqAi)?.map((step) => ({
          ...step,
          notUpdatedComp: true,
        }))
      );
      if (draftProperlyFormatedOutreachSeqAi?.length || draftOutreachSeqAi?.length) {
        setFirstLoad(true);
      }
    }
  }, [from, draftOutreachSeqAi, draftProperlyFormatedOutreachSeqAi]);

  useEffect(() => {
    console.log(from, "fromfrom", firstLoad, draftProperlyFormatedOutreachSeqScratch);
    // update scratch
    if (from === "scratch" && !firstLoad) {
      setDraftOutreachSequence(draftProperlyFormatedOutreachSeqScratch?.map((step) => ({ ...step, notUpdatedComp: true })));
      setFirstLoad(true);
    }
  }, [from, draftProperlyFormatedOutreachSeqScratch]);

  const [defCampName, setdefCampName] = useState(generatedName || popularUploadName || "custom");
  const [customName, setCustomName] = useState("");

  const handleRadioChange = (e) => {
    setControlActKeys([]);
    setdefCampName(e.target.value);
  };

  const handleInputChange = (e) => {
    setCustomName(e.target.value);
  };

  const handleAddCamp = async () => {
    setOpenSaveAndProceedModal(false);
    setMadeChanges && setMadeChanges(false);
    //  await setTimeout(() => {
    // wait for final save from the email templates bascially
    // }, 500);
    let campaignName;
    switch (defCampName) {
      case "custom":
        campaignName = customName;
        break;
      default:
        campaignName = defCampName;
        break;
    }
    // dispatch(newCampInfoToAdd({
    //   campaignName,
    //   leadsIdSelected,
    //   backupEmail,
    //   draftOutreachSequence,
    //   aiSettings}))

    dispatch(
      addNewCampaignV2({
        campaignName,
        leadsIdSelected,
        backupEmail,
        draftOutreachSequence,
        aiSettings,
      })
    );
    /* add this to the new campaign
    dispatch(addNewCampaignV2({
      campaignName,
      leadsIdSelected,
      backupEmail,
      draftOutreachSequence,
      // aiGenProps, aiSettings iis the submited and updated settings needed to use
      aiSettings}))
      */

    //  close
    // move to next page and show the load
  };

  useEffect(() => {
    setdefCampName(generatedName || popularUploadName || "custom");
  }, [generatedName, popularUploadName]);
  const navigate = useNavigate();
  useEffect(() => {
    if (newCampId) {
      // random number for campaign
      // window.location.href = `campaign/${btoa(`${newCampId}`)}`
      navigate(`/campaign/${btoa(`${newCampId + 12000}`)}`);
      dispatch(resetNewCmpId());
    }
  }, [newCampId]);

  const [creatingCampaignModel, setCreatingCampaignModel] = useState(false);
  const isFirstCamp = !totalCamps;
  useEffect(() => {
    if (loadingAddingCampaign) {
      setCreatingCampaignModel(true);
    } else {
      setCreatingCampaignModel(false);
    }
  }, [loadingAddingCampaign]);

  if (step !== 2) {
    return;
  }

  // useEffect(() => {
  //   if(warnRegenerateSequence){

  //   }

  // }, [warnRegenerateSequence]);

  // if(testAiSettingsOpen){
  //   return <TestAiSettings />
  // }
  //     const drawShape = (context) => {
  //   context.font = '16px Arial';
  //   context.fillStyle = '#4CAF50';
  //   context.fillText('Meetz AI', -30, 0);
  //   context.fillText('Cold Outreach', -60, 20);
  //   context.fillText('Leads', -20, 40);
  // };
  return (
    <React.Fragment>
      <CreatingCampaignModal isFirstCamp={isFirstCamp} open={creatingCampaignModel} />
      {warnRegenerateSequenceVal && (
        <Modal width={"600px"} open title={"Regenerate the outreach sequence?"} onCancel={closeModal}>
          <Typography.Paragraph
            // className={"tag-counter"}
            style={{ marginBottom: 28 }}
          >
            {warnRegenerateSequenceVal === "err"
              ? `Try regenerating again after checking your internet is connected and you filled out all the fields.`
              : `Warning: This will reset the entire outreach sequence that was previously generated and any changes you might of made.`}
          </Typography.Paragraph>
          <ConfirmButtons leftButtonTitle={"Cancel"} rightButtonTitle={"Regenerate Anyways"} onCancel={closeModal} handleSubmit={handleSubmit} />
        </Modal>
      )}
      <Modal
        open={openSaveAndProceedModal}
        width={"450px"}
        // styles={{paddingTop:0}}
        title={"Campaign Name"}
        onCancel={() => setOpenSaveAndProceedModal(false)}
      >
        {/* popularUploadName
generatedName */}
        <CustomLabel
          title={"Campaign Name to use"}

          // subtitle={
          //   "Add A Quote Your Customers Gave With Or Add The ROI Your Customer Got From Your Product Or Services, include Your Customers Company Name."
          // }
          // style={{height:'fit-content',padding:0}}
        />

        <Radio.Group onChange={handleRadioChange} style={{ width: "100%" }} value={defCampName}>
          <Space direction={"vertical"}>
            <Row align={"middle"} wrap={false}>
              {!!generatedName?.trim()?.length && (
                <Radio value={generatedName}>
                  {generatedName?.length > 75 ? (
                    <Tooltip placement={"right"} title={generatedName}>
                      {generatedName?.substring(0, 75)}...
                    </Tooltip>
                  ) : (
                    generatedName
                  )}
                </Radio>
              )}
            </Row>
            <Row align={"middle"} wrap={false}>
              {!!popularUploadName?.trim()?.length && (
                <Radio value={popularUploadName}>
                  {popularUploadName?.length > 75 ? (
                    <Tooltip placement={"right"} title={popularUploadName}>
                      {popularUploadName?.substring(0, 75)}...
                    </Tooltip>
                  ) : (
                    popularUploadName
                  )}
                </Radio>
              )}
            </Row>
            <Row align={"middle"} wrap={false}>
              {!generatedName && !popularUploadName ? (
                <Input value={customName} onChange={handleInputChange} placeholder="Enter campaign name" />
              ) : (
                <Radio value="custom">
                  <Input value={customName} onChange={handleInputChange} placeholder="Enter campaign name" />
                </Radio>
              )}
            </Row>
          </Space>
        </Radio.Group>

        <br />
        <br />
        <br />
        <CustomLabel
          // title={"Testimonials To Include"}
          subtitle={"FAQ"}
          // subtitle={
          //   "Add A Quote Your Customers Gave With Or Add The ROI Your Customer Got From Your Product Or Services, include Your Customers Company Name."
          // }
        />

        <Collapse
          controlActKeys={controlActKeys}
          setControlActKeys={setControlActKeys}
          items={[
            {
              key: "1",
              label: "Important: Best practise in the campaign and how to name it accordingly",
              children: (
                <>
                  <p>
                    As you can see from the steps, Meetz will <strong>maximize all the prospects</strong> in your outreach sequence with{" "}
                    <em>optimized and personalized steps</em>, anyone interested or relevant will answer.
                  </p>

                  <p>
                    So all you need to is A/B test is the prospects you reach out to, <em>just like a Facebook PPC campaign</em>.
                  </p>

                  <p>
                    Best practice: Name your outreach with a clear explanation of the sequences audience. Optionally, include the value proposition you used.
                    This way, you can easily compare campaigns side by side and see which audience is resonating best with your offer, and{" "}
                    <strong>where you're getting the most clients from</strong>.{" "}
                  </p>
                </>
              ),
            },
            {
              key: "2",
              label: "How many clients can I expect to get? (More or less)",
              children: (
                <>
                  <p>
                    When building Meetz, our main goal and shared interest is to generate more business for our clients and help them succeed in generating
                    leads. Our platform is based on what we found to work best.
                    {/* We are here to help you with any questions you might have and guide you to succeeding with our platform. will increase support ticketrs */}
                  </p>

                  <p>
                    <strong>Q: How many clients can I expect?</strong>
                  </p>
                  <p>
                    We consistently see email open rates of around <strong>30-50%</strong> throughout personalized campaigns. This means if you reach out to{" "}
                    <strong>1,000 prospects</strong>, you can expect to pitch to <strong>300-500 people</strong>. So every email counts which is why we A/B test
                    everything.
                  </p>
                  <p>
                    Even with a niche B2B offer, once optimized, you should see at least <strong>5 interested prospects</strong> out of <strong>1,000</strong>.
                    For lower-priced or lower-commitment offers, this number can increase to <strong>20-30 interested prospects</strong> with a well-rounded and
                    effectively messaged sequence.*
                    <br />
                    <br />
                    *Of course we can't promise results but these are the numbers you should see more or less.
                  </p>
                </>
              ),
            },
            {
              key: "3",
              label: "What if I don't get a lot of responses?",
              children: (
                <>
                  <p>
                    1. Responses and clients depend on your offer but there are way's to increase the amount of clients you get see the above question of "Best
                    practise the campaign and naming it".
                    <br />
                    2. We track if your prospects "forwarded" sequences emails internally, this allows you to see if anyone might be interested but forgot to
                    respond to you. This greatly increases your ability to follow up and get extra leads.
                    <br />
                    3. Reach out to support! We are here to ensure your success and help optimize your account.
                  </p>
                </>
              ),
            },
          ]}
        />

        <ConfirmButtons
          leftButtonTitle={"Back"}
          rightButtonTitle={"Save & Create"}
          onCancel={() => setOpenSaveAndProceedModal(false)}
          disabled={!defCampName || (defCampName === "custom" && !customName?.trim()?.length)}
          handleSubmit={handleAddCamp}
        />
      </Modal>

      <TestAiSettings newSeq testAiSettingsOpen={testAiSettingsOpen} setTestAiSettingsOpen={setTestAiSettingsOpen} />
      <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <StepsSequence
              setIDOfEditingEmail={setIDOfEditingEmail}
              isEdit
              data={draftOutreachSequence}
              setData={setDraftOutreachSequence}
              setMode={setMode}
              loading={from === "scratch" ? false : loadCreateCamp}
              isDraft
              madeChanges={madeChanges}
              setMadeChanges={setMadeChanges}
              from={from}
            />
          </Col>
          {!loadCreateCamp ? <Col span={12}>{currentMode?.component}</Col> : null}
        </Row>
      </Form>
    </React.Fragment>
  );
};

// const seq=[
//     {
//         "type": "phonecall",
//         "delayFromLatestStep": "immediate",
//         "id": "0"
//     },
//     {
//         "type": "aiemail",
//         "delayFromLatestStep": 60,
//         "id": "1"
//     },
//     {
//         "type": "email",
//         "delayFromLatestStep": 2880,
//         "emailVariantsArray": [
//             {
//                 "subject": "Transform Your Outreach with AI, {{var:first_name;alt:there;nat:0;}}",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>I'm Tom from <b>meetz</b>, and I wanted to introduce you to a game-changing solution for your outreach efforts. <b>Meetz</b> leverages AI to generate the best emails and cold call sequences in just 2 minutes. Imagine automating your A/B tests and focusing on what truly matters—growing your business.</p><p></p><p>Our clients have seen remarkable results, like <i>15 demos in 15 minutes</i>. Interested in seeing how we can transform your outreach?</p><p></p><p>Best,<p></p>Tom</p>"
//             },
//             {
//                 "subject": "Boost Your Leads with AI, {{var:first_name;alt:there;nat:0;}}",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>I'm reaching out from <b>meetz</b> to introduce our AI-driven solution that can revolutionize your lead generation. Our platform automates the creation of highly effective email and cold call sequences, saving you time and effort.</p><p></p><p>With <b>meetz</b>, you can focus on your business while we handle the outreach. <i>Our clients have reported amazing results</i>, and I believe you could benefit too.</p><p></p><p>Best regards,<p></p>Tom</p>"
//             },
//             {
//                 "subject": "Revolutionize Your Outreach, {{var:first_name;alt:there;nat:0;}}",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>Tom here from <b>meetz</b>. We're the first in the world to offer AI-generated emails and cold call sequences that are ready in just 2 minutes. Our solution is designed to help you focus on your core business while we handle the outreach.</p><p></p><p>Don't miss out on the chance to see how <b>meetz</b> can benefit your company. <i>Our clients have seen significant improvements</i>, and I'm confident you will too.</p><p></p><p>Best,<p></p>Tom</p>"
//             },
//             {
//                 "subject": "AI-Powered Outreach for {{var:company_name;alt:your company;nat:0;}}",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>I'm Tom from <b>meetz</b>, and I wanted to share how our AI-powered solution can transform your outreach efforts. With <b>meetz</b>, you can create the best emails and cold call sequences in just 2 minutes, allowing you to focus on what matters most—growing your business.</p><p></p><p>Our clients have seen impressive results, and I believe you could too. Interested in learning more?</p><p></p><p>Best regards,<p></p>Tom</p>"
//             }
//         ],
//         "id": "2"
//     },
//     {
//         "type": "phonecall",
//         "delayFromLatestStep": 2880,
//         "id": "3"
//     },
//     {
//         "type": "email",
//         "delayFromLatestStep": 15,
//         "emailVariantsArray": [
//             {
//                 "subject": "ReplyTo",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>I tried calling you earlier today but couldn't reach you. I wanted to follow up on my previous email about <b>meetz</b> and how our AI-driven solution can revolutionize your outreach.</p><p></p><p>Would you be interested in a quick chat to discuss further?</p><p></p><p>Best,<p></p>Tom</p>"
//             },
//             {
//                 "subject": "ReplyTo",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>Following up on my call earlier, I wanted to see if you had any questions about <b>meetz</b> and how our AI can help boost your lead generation efforts.</p><p></p><p>Looking forward to your response.</p><p></p><p>Best regards,<p></p>Tom</p>"
//             },
//             {
//                 "subject": "ReplyTo",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>I tried reaching you by phone earlier today. Just wanted to follow up on my email about <b>meetz</b> and our AI-driven outreach solution.</p><p></p><p>Could we schedule a quick call to discuss?</p><p></p><p>Best,<p></p>Tom</p>"
//             },
//             {
//                 "subject": "ReplyTo",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>I attempted to call you earlier today and wanted to follow up on my previous email about <b>meetz</b>. Our AI solution can significantly improve your outreach efforts.</p><p></p><p>Would you be open to a brief conversation?</p><p></p><p>Best regards,<p></p>Tom</p>"
//             }
//         ],
//         "id": "4"
//     },
//     {
//         "type": "email",
//         "delayFromLatestStep": 4320,
//         "emailVariantsArray": [
//             {
//                 "subject": "Unlock AI-Powered Outreach, {{var:first_name;alt:there;nat:0;}}",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>Just wanted to circle back and highlight how <b>meetz</b> can transform your outreach strategy. Our AI-driven platform generates the best emails and cold call sequences in just 2 minutes, allowing you to focus on growing your business.</p><p></p><p>Imagine the time you'll save and the results you'll achieve. <i>Our clients have seen amazing improvements</i>, and I'm confident you will too.</p><p></p><p>Best,<p></p>Tom</p>"
//             },
//             {
//                 "subject": "Elevate Your Outreach with AI, {{var:first_name;alt:there;nat:0;}}",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>Hope you're doing well. I'm reaching out to remind you about <b>meetz</b> and our AI-driven solution that can elevate your outreach efforts. With our platform, you can automate A/B tests and focus on what matters most—your business.</p><p></p><p>Interested in learning more? <i>Our clients have reported impressive results</i>, and I'd love to show you how we can help.</p><p></p><p>Best regards,<p></p>Tom</p>"
//             },
//             {
//                 "subject": "AI Outreach for {{var:company_name;alt:your company;nat:0;}}, {{var:first_name;alt:there;nat:0;}}",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>Tom here from <b>meetz</b>. Just wanted to follow up and see if you had any questions about our AI-powered outreach solution. Our platform generates the best emails and cold call sequences in just 2 minutes, making your life easier.</p><p></p><p>Would you like to schedule a demo to see how it works?</p><p></p><p>Best,<p></p>Tom</p>"
//             },
//             {
//                 "subject": "Transform Your Outreach Strategy, {{var:first_name;alt:there;nat:0;}}",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>Following up to see if you had a chance to review my previous emails about <b>meetz</b>. Our AI-driven solution can revolutionize your outreach efforts, allowing you to focus on growing your business.</p><p></p><p>Would you be interested in a quick chat to discuss further?</p><p></p><p>Best regards,<p></p>Tom</p>"
//             }
//         ],
//         "id": "5"
//     },
//     {
//         "type": "phonecall",
//         "delayFromLatestStep": 2880,
//         "id": "6"
//     },
//     {
//         "type": "email",
//         "delayFromLatestStep": 15,
//         "emailVariantsArray": [
//             {
//                 "subject": "ReplyTo",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>I tried calling you earlier today but couldn't get through. Just wanted to follow up on my email about <b>meetz</b> and our AI-driven outreach solution.</p><p></p><p>Would you be open to a brief conversation to discuss further?</p><p></p><p>Best,<p></p>Tom</p>"
//             },
//             {
//                 "subject": "ReplyTo",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>Following up on my call earlier, I wanted to see if you had any questions about <b>meetz</b> and how our AI can help elevate your outreach efforts.</p><p></p><p>Looking forward to your response.</p><p></p><p>Best regards,<p></p>Tom</p>"
//             },
//             {
//                 "subject": "ReplyTo",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>I tried reaching you by phone earlier today. Just wanted to follow up on my email about <b>meetz</b> and our AI-driven outreach solution.</p><p></p><p>Could we schedule a quick call to discuss?</p><p></p><p>Best,<p></p>Tom</p>"
//             },
//             {
//                 "subject": "ReplyTo",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>I attempted to call you earlier today and wanted to follow up on my previous email about <b>meetz</b>. Our AI solution can significantly improve your outreach efforts.</p><p></p><p>Would you be open to a brief conversation?</p><p></p><p>Best regards,<p></p>Tom</p>"
//             }
//         ],
//         "id": "7"
//     },
//     {
//         "type": "email",
//         "delayFromLatestStep": 4320,
//         "emailVariantsArray": [
//             {
//                 "subject": "Last Chance to Transform Your Outreach, {{var:first_name;alt:there;nat:0;}}",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>Hope you're doing well. I wanted to reach out one last time regarding <b>meetz</b> and our AI-driven solution that can transform your outreach efforts.</p><p></p><p>If the timing isn't right, feel free to reach out when it is. Otherwise, I'd love to schedule a quick chat to discuss how we can help.</p><p></p><p>Best,<p></p>Tom</p>"
//             },
//             {
//                 "subject": "Final Follow-Up: AI-Powered Outreach, {{var:first_name;alt:there;nat:0;}}",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>This is my final follow-up about <b>meetz</b> and our AI-powered outreach solution. I believe it can significantly benefit your company.</p><p></p><p>If you're interested, let's schedule a quick call. If not, feel free to reach out when the timing is better.</p><p></p><p>Best regards,<p></p>Tom</p>"
//             },
//             {
//                 "subject": "Last Call: AI Outreach for {{var:company_name;alt:your company;nat:0;}}, {{var:first_name;alt:there;nat:0;}}",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>Reaching out one last time about <b>meetz</b> and our AI-driven outreach solution. I think it could be a great fit for your company.</p><p></p><p>Would you be interested in a brief conversation to discuss further?</p><p></p><p>Best,<p></p>Tom</p>"
//             },
//             {
//                 "subject": "Final Reminder: Transform Your Outreach, {{var:first_name;alt:there;nat:0;}}",
//                 "body": "<p>Hi {{var:first_name;alt:there;nat:0;}},</p><p></p><p>This is my final reminder about <b>meetz</b> and our AI-powered outreach solution. I believe it can significantly benefit your company.</p><p></p><p>If you're interested, let's schedule a quick call. If not, feel free to reach out when the timing is better.</p><p></p><p>Best regards,<p></p>Tom</p>"
//             }
//         ],
//         "id": "8"
//     }
// ]
