/* eslint-disable no-unused-vars */
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { apiHandler } from "../helpers/apiHandler";

// new stripe

export const skipTrialNow = createAsyncThunk("stripe/skipTrialNow", async (_, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/stripe/skipTrial",
    method: "post",
    body: {},
  });
  console.log(res, "skipTrialNowres");

  return res;
});

// export const getLatestSubIssue = createAsyncThunk('stripe/getLatestSubIssue', async (body, { getState }) => {
//   const { iframeJwt } = getState().helpers;
//   const res = await apiHandler({
//     iframeJwt,
//     url: '/stripe/getLatestSubIssue',
//     method: 'post',
//     body,
//   });
//   // console.log(res, 'clientSecret');
//   return res;
// });

// only for new clients since it marks a new trial. if churn send to same backend but from dif async request
export const newActivateTrialClient = createAsyncThunk("stripe/newActivateTrialClient", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/stripe/newActivateTrialClient",
    method: "post",
    body,
  });
  // console.log(res, 'res');
  // if (res.message || res.error) {
  //   throw res.message || res.error;
  // }

  // BRING THIS BACK SO I NEED TO UPDATE THIS!!!! SINCE CLI IS SOMEWHERE ELSE just add res ==='success'
  console.log(res?.cliForFirstPrmoter, "cliForFirstPrmoter");
  // if (res?.cliForFirstPrmoter) {
  //   // (window as any).fpr("referral", { uid: res?.cliForFirstPrmoter });
  //   console.log("cliForFirstPrmoter1");
  //   (window as any).rdt("track", "Purchase", {
  //     itemCount: 1,
  //   });
  //   console.log("cliForFirstPrmoter2");
  //   (window as any).fbq("track", "StartTrial", { value: "50", currency: "USD" });
  //   console.log("cliForFirstPrmoter3");
  //   (window as any).gtag("event", "conversion", { send_to: "AW-630038230/qyzOCN6ApsgZENa9tqwC" });
  //   console.log("cliForFirstPrmoter4");
  //   // (window as any)?.hubalzscript?.action('StartTrial');
  //   (window as any).qp("track", "Purchase");
  // }

  return res?.updClient;
});

export const newActivatePaidClient = createAsyncThunk("stripe/newActivatePaidClient", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/stripe/newActivatePaidClient",
    method: "post",
    body,
  });
  // console.log(res, 'res');
  // if (res.message || res.error) {
  //   throw res.message || res.error;
  // }

  // if (res?.cliForFirstPrmoter) {
  //   // (window as any).fpr("referral", { uid: res?.cliForFirstPrmoter });
  //   (window as any).rdt("track", "Purchase", {
  //     itemCount: 1,
  //   });
  //   (window as any).fbq("track", "StartTrial", { value: "50", currency: "USD" });
  //   (window as any).gtag("event", "conversion", { send_to: "AW-630038230/qyzOCN6ApsgZENa9tqwC" });
  //   // (window as any)?.hubalzscript?.action('StartTrial');
  //   (window as any).qp("track", "Purchase");
  // }

  return res?.updClient;
});

export const createSubscriptionV3 = createAsyncThunk(
  "stripe/createSubscriptionV3",
  async ({ currentPlanName, isDiscount }: any, { getState }: { getState: any }) => {
    const { iframeJwt } = getState().helpers;
    // console.log('createPayIntentresbefore');
    const res = await apiHandler({
      iframeJwt,
      url: "/stripe/createSubscriptionV3",
      method: "post",
      body: {
        currentPlanName,
        isDiscount,
      },
    });

    // (window as any).rdt("track", "Purchase", {
    //   itemCount: 1,
    // });
    // (window as any).fbq("track", "StartTrial", { value: "50", currency: "USD" });
    // (window as any).gtag("event", "conversion", { send_to: "AW-630038230/qyzOCN6ApsgZENa9tqwC" });

    // // (window as any)?.hubalzscript?.action('StartTrial');
    // (window as any).qp("track", "Purchase");
    // console.log(res, "createPayIntentres")
    // if (res.message || res.error) {
    //   throw res.message || res.error;
    // }

    return res?.updClient;
  }
);

export const createSubIntentNewClientsOnly = createAsyncThunk(
  "stripe/createSubIntentNewClientsOnly",
  async ({ plan, isChange }: any, { getState }: { getState: any }) => {
    const { iframeJwt } = getState().helpers;
    // console.log('createPayIntentresbefore');
    const res = await apiHandler({
      iframeJwt,

      url: "/stripe/createSubIntentNewClientsOnly",
      method: "post",
      body: {
        plan,
        isChange,
      },
    });
    // console.log(res, "createPayIntentres")
    if (res.message || res.error) {
      throw res.message || res.error;
    }

    return res;
  }
);
export const cancelSubscriptionV2 = createAsyncThunk("stripe/cancelSubscription", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;

  const res = await apiHandler({
    iframeJwt,

    url: "/stripe/cancelSubscription",
    method: "post",
    body,
  });
  // console.log(res, "createPayIntentres")
  // if (res.message || res.error) {
  //   throw res.message || res.error;
  // }

  return res;
});

export const createPayIntent = createAsyncThunk("stripe/createPayIntent", async ({ plan, isChange }: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  // console.log('createPayIntentresbefore');
  const res = await apiHandler({
    iframeJwt,

    url: "/stripe/createPayIntent",
    method: "post",
    body: {
      plan,
      isChange,
    },
  });
  // console.log(res, "createPayIntentres")
  if (res.message || res.error) {
    throw res.message || res.error;
  }

  return res;
});

// // end of new stripe

// export const cancelStripeSubscription = createAsyncThunk('stripe/cancelStripeSubscription', async (_, { getState }) => {
//   const { iframeJwt } = getState().helpers;
//   const res = await apiHandler({
//     iframeJwt,
//     url: '/stripe/subscription',
//     method: 'delete',
//   });

//   if (res.message || res.error) {
//     throw res.message || res.error;
//   }

//   return res;
// });

// class CustomError extends Error {
//   constructor(message, code) {
//     super(message);
//     this.code = code;
//     // Set the prototype explicitly.
//     Object.setPrototypeOf(this, CustomError.prototype);
//   }
// }

// export const addFreeNum = createAsyncThunk('stripe/addFreeNum', async (body, { getState }) => {
//   const { iframeJwt } = getState().helpers;
//   const res = await apiHandler({
//     iframeJwt,
//     url: '/stripe/addFreeNum',
//     method: 'post',
//     body,
//   });
//   console.log(res, 'phone res 1');
//   if (res.code || res.error) {
//     // const errorPayload = new CustomError('123', res.code || null);
//     throw res;
//   }

//   return res;
// });

// export const createPhoneSubscription = createAsyncThunk(
//   'stripe/createPhoneSubscription',
//   async (
//     { city, country, coupon, cvc, exp_month, exp_year, ccNum, first_name, last_name, line1, postal_code, phoneNum, phoneCountry },
//     { getState }
//   ) => {
//     const { iframeJwt } = getState().helpers;
//     const { coupon_name } = getState().stripe;
//     const res = await apiHandler({
//       iframeJwt,

//       url: '/stripe/create-phone-subscription',
//       method: 'post',
//       body: {
//         card: {
//           number: `${ccNum}`,
//           exp_month: moment().month(exp_month).format('M'),
//           exp_year,
//           cvc,
//         },
//         billing_details: {
//           address: {
//             country,
//             city,
//             line1,
//             postal_code,
//           },
//           name: `${first_name} ${last_name}`,
//         },
//         coupon_name: coupon || coupon_name || undefined,
//         phoneNum,
//         phoneCountry,
//       },
//     });

//     if (
//       res?.subscription &&
//       res?.settings &&
//       res?.subscription?.customer &&
//       (res?.settings?.sales_sub_amount !== 0 || res?.settings?.subscription_type !== 'FREE')
//     ) {
//       // success and sending to referral program the stripe ID
//       // window.fpr('referral', { uid: res?.subscription?.customer });
//       // window.rdt('track', 'Purchase', {
//       //   itemCount: 1,
//       // });
//       // window.qp('track', 'Purchase');
//     }

//     if (res?.message || res?.error || !Object.keys(res)?.length) {
//       throw res?.message || res?.error || 'An error occured. Please try again later';
//     }
//     return res;
//   }
// );
// export const createSubscription = createAsyncThunk(
//   'stripe/createSubscription',
//   async (
//     {
//       city,
//       country,
//       coupon,
//       cvc,
//       exp_month,
//       exp_year,
//       ccNum,
//       first_name,
//       last_name,
//       line1,
//       postal_code,
//       price_id,
//       includeSched,
//       isMobile,
//       sales_cred_plan,
//       isMonthly,
//       prospect_credit_amount,
//     },
//     { getState }
//   ) => {
//     const { iframeJwt } = getState().helpers;
//     const { coupon_name } = getState().stripe;
//     const res = await apiHandler({
//       iframeJwt,

//       url: '/stripe/create-subscription-v2',
//       method: 'post',
//       body: {
//         card: {
//           number: `${ccNum}`,
//           exp_month: moment().month(exp_month).format('M'),
//           exp_year,
//           cvc,
//         },
//         billing_details: {
//           address: {
//             country,
//             city,
//             line1,
//             postal_code,
//           },
//           name: `${first_name} ${last_name}`,
//         },
//         coupon_name: coupon || coupon_name || undefined,
//         price_id,
//         includeSched,
//         isMobile,
//         sales_cred_plan,
//         isMonthly,
//         prospect_credit_amount,
//       },
//     });

//     if (
//       res?.subscription &&
//       res?.settings &&
//       res?.subscription?.customer &&
//       (res?.settings?.sales_sub_amount !== 0 || res?.settings?.subscription_type !== 'FREE')
//     ) {
//       // success and sending to referral program the stripe ID
//       window.fpr('referral', { uid: res?.subscription?.customer });
//       window.rdt('track', 'Purchase', {
//         itemCount: 1,
//       });
//       window.qp('track', 'Purchase');
//     }

//     if (res?.message || res?.error || !Object.keys(res)?.length) {
//       throw res?.message || res?.error || 'An error occured. Please try again later';
//     }

//     return res;
//   }
// );

// export const fetchBillingHistory = createAsyncThunk('stripe/fetchBillingHistory', async (data, { getState }) => {
//   const { pageData, directions, sort_param } = getState().stripe;
//   const { email } = getState().user.user;
//   const { filter } = getState().search;

//   let params;
//   let direction = 'ASC';
//   if (data && data.sort_param) {
//     direction = directions[data.sort_param] ? 'DESC' : 'ASC';
//     params = { sort_param: data.sort_param, direction, ...pageData };
//   } else if (sort_param) {
//     direction = !directions[sort_param] ? 'DESC' : 'ASC';
//     params = { sort_param, direction, ...pageData };
//   }

//   if (data && data.take) {
//     params = { ...params, take: data.take, page: data.page };
//   }

//   if (filter.start && filter.end) {
//     params = { ...params, start: filter.start, end: filter.end };
//   }

//   if (filter.search_query) {
//     params = { ...params, search_query: filter.search_query };
//   }

//   params = { ...params, email };

//   const { iframeJwt } = getState().helpers;
//   const res = await apiHandler({
//     iframeJwt,
//     url: '/stripe/billing-history',
//     method: 'get',
//     params,
//   });

//   if (res.message || res.error) {
//     throw res.message || res.error;
//   }

//   params = { ...params, email };
//   let response = res;

//   if (data && data.sort_param) {
//     const newDirections = Object.keys(directions).reduce((acc, d) => {
//       if (d === data.sort_param) {
//         acc = { ...acc, [d]: !directions[d] };
//         return acc;
//       }
//       acc = { ...acc, [d]: true };
//       return acc;
//     }, {});

//     response = { ...res, directions: newDirections };
//   }

//   return response;
// });

// export const fetchBillingAddress = createAsyncThunk('stripe/fetchBillingAddress', async (data, { getState }) => {
//   const { iframeJwt } = getState().helpers;
//   const { email } = getState().user.user;

//   const res = await apiHandler({
//     iframeJwt,
//     url: '/stripe/billing-address',
//     method: 'get',
//     params: { email },
//   });
//   if (res.message || res.error) {
//     throw res.message || res.error;
//   }

//   return res;
// });

// export const fetchPlans = createAsyncThunk('stripe/fetchPlans', async (_, { getState }) => {
//   const { iframeJwt } = getState().helpers;
//   const res = await apiHandler({
//     iframeJwt,
//     url: '/stripe/plans',
//     method: 'get',
//   });

//   if (res.message || res.error) {
//     throw res.message || res.error;
//   }

//   return res;
// });

// export const fetchPaymentMethod = createAsyncThunk('stripe/fetchPaymentMethod', async (data, { getState }) => {
//   const { iframeJwt } = getState().helpers;
//   const { email } = getState().user.user;

//   const res = await apiHandler({
//     iframeJwt,
//     url: '/stripe/payment-method',
//     method: 'get',
//     params: { email },
//   });
//   if (res.message || res.error) {
//     throw res.message || res.error;
//   }

//   return res;
// });

// export const fetchNextInvoice = createAsyncThunk('stripe/fetchNextInvoice', async (data, { getState }) => {
//   const { iframeJwt } = getState().helpers;
//   const { email } = getState().user.user;

//   const res = await apiHandler({
//     iframeJwt,
//     url: '/stripe/next-invoice',
//     method: 'get',
//     params: { email },
//   });

//   if (res.message || res.error) {
//     throw res.message || res.error;
//   }

//   return res;
// });

// export const getCurrentSubscription = createAsyncThunk('stripe/getCurrentSubscription', async (_, { getState }) => {
//   const { iframeJwt } = getState().helpers;
//   const res = await apiHandler({
//     iframeJwt,
//     url: '/stripe/get-current-subscription',
//     method: 'get',
//   });

//   if (!res || !res?.status) {
//     throw new Error('User does not have a subscription');
//   }

//   if (res?.message || res?.error) {
//     throw res.message || res.error;
//   }

//   return res;
// });

// export const fetchStripeCustomer = createAsyncThunk('stripe/fetchStripeCustomer', async (data, { dispatch, getState }) => {
//   const { iframeJwt } = getState().helpers;
//   const res = await apiHandler({
//     iframeJwt,
//     url: '/stripe/get-customer',
//     body: data,
//   });

//   if (res.message || res.error) {
//     throw res.message || res.error;
//   }

//   dispatch(getCurrentSubscription());
//   return res.customer;
// });

// export const getPreviewOnSubChange = createAsyncThunk('stripe/getPreviewOnSubChange', async (body, { getState }) => {
//   const { iframeJwt } = getState().helpers;
//   const res = await apiHandler({
//     iframeJwt,
//     url: '/stripe/retrieve-upcoming-invoice',
//     method: 'post',
//     body,
//   });

//   if (res.message || res.error) {
//     throw res.message || res.error;
//   }

//   return res;
// });

// export const handleCouponValidation = createAsyncThunk('stripe/checkAvailableCoupons', async (data, { getState }) => {
//   const { iframeJwt } = getState().helpers;
//   const price_id = getState().stripe.selected_price_id;
//   const res = await apiHandler({
//     iframeJwt,
//     url: '/stripe/check-available-coupons',
//     body: { ...data, price_id },
//   });

//   if (res.message || res.error) {
//     throw res.message || res.error;
//   }

//   return { ...res, ...data };
// });

// export const postBillingAddress = createAsyncThunk('stripe/postBillingAddress', async (data, { getState }) => {
//   const { iframeJwt } = getState().helpers;
//   const { email } = getState().user.user;

//   data = {
//     ...data,
//   };

//   const res = await apiHandler({
//     iframeJwt,

//     url: '/stripe/add-billing-address',
//     method: 'post',
//     params: { email },
//     body: { address: data },
//   });

//   if (res.message || res.error) {
//     throw res.message || res.error;
//   }

//   return res;
// });

// export const postPaymentMethod = createAsyncThunk(
//   'stripe/postPaymentMethod',
//   async ({ exp_month, exp_year, first4, second4, third4, last4, first_name, last_name, cvc }, { getState }) => {
//     const { iframeJwt } = getState().helpers;
//     const { email } = getState().user.user;

//     const res = await apiHandler({
//       iframeJwt,

//       url: '/stripe/add-payment-method',
//       method: 'post',
//       params: { email },
//       body: {
//         cvc,
//         exp_month,
//         exp_year,
//         number: `${first4}${second4}${third4}${last4}`,
//         name: `${first_name} ${last_name}`,
//       },
//     });

//     if (res.message || res.error) {
//       throw res.message || res.error;
//     }

//     return res;
//   }
// );

// export const turnToFree = createAsyncThunk('stripe/updateSubscription', async (_, { getState }) => {
//   const { iframeJwt } = getState().helpers;
//   const res = await apiHandler({
//     iframeJwt,
//     url: '/stripe/turnToFree',
//     method: 'post',
//   });

//   if (res.message || res.error) {
//     throw res.message || res.error;
//   }

//   return res;
// });

// // export const updateSubscription = createAsyncThunk('stripe/updateSubscription', async ({ price_id }) => {
// //   const res = await apiHandler({
// //  iframeJwt,
// //     url: '/stripe/subscription',

// //     method: 'put',
// //     body: {
// //       price_id,
// //     },
// //   });

// export const updateSubscription = createAsyncThunk('stripe/updateSubscription', async ({ credits, isMonthlyPlan }, { getState }) => {
//   const { iframeJwt } = getState().helpers;
//   const res = await apiHandler({
//     iframeJwt,

//     url: '/stripe/subscriptionNew',
//     method: 'put',
//     body: {
//       credits,
//       isMonthlyPlan,
//     },
//   });

//   if (res.message || res.error) {
//     throw res.message || res.error;
//   }

//   return res;
// });
