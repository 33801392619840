/* eslint-disable react/jsx-fragments */
import { CheckOutlined, MinusOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Empty, Flex, Image, MenuProps, Row, Space, Tooltip, Typography, message } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { FC, useEffect, useState } from "react";
import NoData from "src/shared/images/noData.png";
import { Modal, StatusTag, Table, TableHeader } from "src/components/ui";
import "./index.less";
import { App } from "src/types";
import { forStepTwoData } from "../../CreateCampaign/ui/AIGenerated/ui/StepTwo/stepTwoData";
import { useDispatch, useSelector } from "react-redux";
import {
  outreachCampaignSelector,
  resetNewCmpId,
  resetSelProsIds,
  resetUplName,
  resetUploadedInfo,
  selectAllCurrent,
  setSelectedRowKeys,
} from "src/store/outreach-campaigns";
import { SvgIcon } from "src/shared/icons";
import { ProspectsFilter } from "./ProspectsFilter";
import { PhonesCell } from "src/components/pages/prospect-data";
import { addProsToCampaign, getFilteredLeads } from "../../../../../store/outreach-campaigns/asyncRequests";
import { useNavigate, useParams } from "react-router";
import { prospectDataSelector, resetSelectedRows } from "src/store/prospect_data";
import { userSelector } from "src/store/user";
import { ExtractionFileModal } from "../../CreateCampaign/ui/AIGenerated/ui/StepTwo/ui/ExtractionFileModal";
import { Loader } from "src/components/layout";
import { UploadProspectsModal } from "../../CreateCampaign/ui/AIGenerated/ui/StepTwo/ui/UploadProspectsModal";

const items: MenuProps["items"] = [
  {
    key: "1",
    label: "Add To Sequence",
  },
  {
    key: "2",
    label: "Take Off Of Sequence",
  },
];

export const ProspectsTable: FC<{
  handleDetailsModalOpen?: (id: App.ForStepTwoProspectEntity["id"]) => void;
  addProsToCamp?: boolean;
}> = ({ handleDetailsModalOpen, addProsToCamp }) => {
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const { Text } = Typography;
  const UploadingLeadsFulfill = ({ leadsLeftOut, leadsUploaded }) => {
    //  if you show how many were not uploaded so too many questions (even the column title is counted as not uploaded)
    return (
      <Space direction={"vertical"}>
        <Row align={"middle"} style={{ gap: 8, margin: "10px" }}>
          {leadsUploaded ? <SvgIcon type={"success"} /> : <SvgIcon type={"alarm"} />}
          <div>
            {!!leadsLeftOut && !leadsUploaded && (
              <Typography.Paragraph className={"bulk-message-title-warn"} style={{ color: "#ff772ed9" }}>
                {`${
                  leadsLeftOut === 1 ? `1 Prospect Was` : `${leadsLeftOut} Prospects Were `
                } Not Uploaded, Deselect the "Filter" Checkboxes In The Upload Section If Necessary.`}
              </Typography.Paragraph>
            )}
            {!!leadsUploaded && (
              <Typography.Paragraph className={"bulk-message-title-warn"} style={{ color: "#4ec352d9" }}>
                {`${leadsUploaded === 1 ? `1 Prospect Was` : `${leadsUploaded} Prospects Were`} Successfully Uploaded!`}
              </Typography.Paragraph>
            )}
          </div>
        </Row>
      </Space>
    );
  };
  const UploadingLeads = ({ prospectAmountExtracting }) => {
    return (
      <Space direction={"vertical"}>
        <Row align={"middle"} style={{ gap: 8, margin: "10px" }}>
          <Loader size="large" style={{ marginRight: "25px" }} />
          <Typography.Paragraph className={"bulk-message-title"}>{`Uploading ${prospectAmountExtracting} Potential Future Clients`}</Typography.Paragraph>
          <SvgIcon type={"ultimate"} />
        </Row>
      </Space>
    );
  };
  const columns: ColumnsType<App.ForStepTwoProspectEntity> = [
    // {
    //   title: "Contacts",
    //   className: "clickable-table-title",
    //   key: "first_name",
    //   // align: "right",
    //   render: record =>
    //     record.first_name ? (
    //       <Typography.Paragraph onClick={() => handleDetailsModalOpen && handleDetailsModalOpen(record.id)}>
    //         {record.contacts}
    //       </Typography.Paragraph>
    //     ) : (
    //       <MinusOutlined />
    //     ),
    // }, TODOF add this later to the name so they can open and get the contact details
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "130px",
      // align: "right",
      render: (_, val) => {
        return _?.length ? `${val?.first_name} ${val?.last_name || ""}` : <MinusOutlined />;
      },
    },
    {
      title: "Job Title",
      dataIndex: "title",
      key: "title",
      render: (record) => (record ? record : <MinusOutlined />),
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      width: "140px",
      // align: "center",
      render: (record) => (record?.length ? record : <MinusOutlined />),
    },
    {
      title: "Phone Numbers",
      key: "status",
      align: "center",
      width: "100px",
      render: (text, record) => <PhonesCell phonesData={[record?.business_number, record?.direct_phone, record?.mobile_phone]} />,
    },

    {
      title: "LinkedIn Url",
      dataIndex: "linkedin_profile_url",
      key: "linkedin_profile_url",
      // align: "left",
      width: "90px",
      align: "center",
      render: (record) => (record?.length ? <CheckOutlined /> : <MinusOutlined />),
    },
    {
      title: "Upload Name",
      dataIndex: "upload_name",
      key: "upload_name",
      render: (record) => {
        if (record?.length) {
          if (record?.length > 30) {
            return (
              <>
                <Tooltip placement={"right"} title={record}>
                  {" "}
                  {record?.substring(0, 30) + "..."}
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        } else {
          return <MinusOutlined />;
        }
      },
    },
    {
      title: "# Of Campaigns In",
      dataIndex: "campaigns",
      key: "campaigns",
      align: "center",
      width: "160px",
      render: (record) =>
        record?.length ? (
          <Tooltip
            placement={"bottom"}
            title={
              <>
                <b>Campaigns Prospect Is Connected To Already:</b>
                {record?.map((campName, i) => {
                  return (
                    <>
                      <br />
                      <br />
                      <b>{i + 1}.</b> {campName}
                    </>
                  );
                })}
              </>
            }
          >
            <CheckOutlined />
          </Tooltip>
        ) : (
          "None"
        ),
    },

    //   {
    //     title: "Actions",
    //     key: "action",
    //     render: (_, record) => (
    //       <Dropdown placement={"bottomRight"} menu={{ items: items }} trigger={["click"]}>
    //         <Space style={{ cursor: "pointer" }} onClick={e => e.preventDefault()}>
    //           <MoreOutlined />
    //         </Space>
    //       </Dropdown>
    //     ),
    //   },TODOF add option to  delete the prospect if needed
  ];

  // https://lordicon.com/icons/wired/outline TODOC add to rocket in the pop up also replace the icons if needed
  const dispatch = useDispatch();

  const {
    leads,
    leadsIdSelected,
    uplNameOptions,
    filteredAfterProsUploadName,
    leadsTotalCnt,
    loadingLeads,
    leadsIds,
    newCampId,
    loadingUploadPros,
    leadsLeftOut,
    leadsUploaded,
  } = useSelector(outreachCampaignSelector);

  console.log(leads, "leads");

  const [uploadNameAvail, setUploadNameAvail] = useState([]);
  const [filters, setFilters] = useState({
    uploadName: "All",
    recentlyUpdated: false,
    includeInCampaign: false,
    includeContactedAlready: false,
  });
  const [page, setPage] = useState(1);

  const handlePageChanger = (pageChangeTo) => {
    setPage(pageChangeTo);
    dispatch(
      getFilteredLeads({
        ...filters,
        page,
        pageChange: true,
      })
    );
  };

  const { id } = useParams();
  const selectedCampaignId = id ? (atob(`${id}`) ? +atob(`${id}`) : -1) - 12000 : null;

  useEffect(() => {
    setUploadNameAvail([{ label: "All", value: "All" }, ...uplNameOptions?.map((upl) => upl && { label: upl, value: upl })?.filter(Boolean)]);
  }, [uplNameOptions]);

  const [filtersSubmited, setFiltersSubmited] = useState(0);

  const submitFilters = () => {
    const filtereduploadName = filters?.uploadName !== "All" ? 1 : 0;
    const filteredrecentlyUpdated = filters?.recentlyUpdated ? 1 : 0;
    const filteredincludeInCampaign = filters?.includeInCampaign ? 1 : 0;
    const filteredincludeContactedAlready = filters?.includeContactedAlready ? 1 : 0;

    setFiltersSubmited(filtereduploadName + filteredrecentlyUpdated + filteredincludeInCampaign + filteredincludeContactedAlready);

    setOpenFilterDrawer(false);
    message.info("Applying Filters");
    // remove
    console.log(filters, "filters");
    setPage(1);
    dispatch(
      getFilteredLeads({
        ...filters,
        page: 1,
        pageChange: false,
        selectedCampaignId: selectedCampaignId || null,
      })
    );
  };

  useEffect(() => {
    if (filteredAfterProsUploadName?.length) {
      setFilters({
        uploadName: filteredAfterProsUploadName,
        recentlyUpdated: true,
        includeInCampaign: true,
        includeContactedAlready: true,
      });
      setFiltersSubmited(4);
      setPage(1);
      dispatch(resetUplName());
    }
  }, [filteredAfterProsUploadName]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    // pagination selection logic check whats selected on page then rem/add based on the logic
    const leadsOnPage = leads
      ?.map((lead) => lead?.id)
      ?.filter(Boolean)
      ?.filter((id) => leadsIdSelected.includes(id));

    const added = newSelectedRowKeys.filter((key) => !leadsOnPage.includes(key));
    const removed = leadsOnPage.filter((key) => !newSelectedRowKeys.includes(key));
    console.log(added, removed, "removedremoved");

    dispatch(setSelectedRowKeys({ added, removed }));
  };

  const rowSelection = {
    selectedRowKeys: leadsIdSelected,
    onChange: onSelectChange,
  };

  const setUploadNameToSearch = (uploadName) => {
    setFilters({ ...filters, uploadName });
  };

  const setRecentlyUpdatedToSearch = (event) => {
    const recentlyUpdated = event.target.checked;
    setFilters({ ...filters, recentlyUpdated });
  };

  const setIncludeInCampaignToSearch = (event) => {
    const includeInCampaign = event.target.checked;
    setFilters({ ...filters, includeInCampaign });
  };

  const setIncludeContactedAlreadyToSearch = (event) => {
    const includeContactedAlready = event.target.checked;
    setFilters({ ...filters, includeContactedAlready });
  };

  const resetFilters = () => {
    setFilters({
      uploadName: "All",
      recentlyUpdated: false,
      includeInCampaign: false,
      includeContactedAlready: false,
    });

    setPage(1);
    dispatch(
      getFilteredLeads({
        uploadName: "All",
        recentlyUpdated: false,
        includeInCampaign: false,
        includeContactedAlready: false,
        page: 1,
        pageChange: false,
      })
    );
    setFiltersSubmited(0);
  };
  const resetSelPros = () => {
    // remove
    dispatch(resetSelProsIds());
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (newCampId) {
      // random number for campaign
      // window.location.href = `campaign/${btoa(`${newCampId}`)}`
      navigate(`/campaign/${btoa(`${newCampId + 12000}`)}`);
      dispatch(resetNewCmpId());
    }
  }, [newCampId]);
  const selectedLength = leadsIdSelected?.length || "0";
  const handleAddProspects = () => {
    if (+selectedLength) {
      message.info("Adding prospects");
      dispatch(
        addProsToCampaign({
          leadsIdSelected,
          campId: selectedCampaignId,
        })
      );
    } else {
      message.warning("Select prospects to add first");
    }
  };
  const [messageApi, contextHolder] = message.useMessage();

  const [prospectAmountExtracting, setProspectAmountExtracting] = useState(0);
  const [openExtractionFileModal, setOpenExtractionFileModal] = useState(false);
  const [openUpModal, setOpenUpModal] = useState(false);
  const [openUploadProspectsModal, setOpenUploadProspectsModal] = useState(false);
  const allIdsPresent = leadsIds?.every((id) => leadsIdSelected.includes(id));
  useEffect(() => {
    if (prospectAmountExtracting && loadingUploadPros) {
      messageApi.destroy();
      messageApi.open({
        key: "updateMessage",
        content: <UploadingLeads prospectAmountExtracting={prospectAmountExtracting} />,
        className: "custom-message",
        duration: 0,
        style: {
          padding: "10px 16px",
        },
      });
    }
  }, [prospectAmountExtracting, loadingUploadPros]);
  useEffect(() => {
    console.log(leadsLeftOut, leadsUploaded, "leadsLeftOutleadsLeftOut");
    if (leadsLeftOut || leadsUploaded) {
      messageApi.destroy();
      messageApi.open({
        key: "updateMessage",
        content: <UploadingLeadsFulfill leadsLeftOut={leadsLeftOut} leadsUploaded={leadsUploaded} />,
        className: "custom-message",
        duration: 7,
        // duration: 0,
        style: {
          padding: "10px 16px",
        },
      });
      dispatch(resetUploadedInfo());
      dispatch(resetSelectedRows());
    }
    // }, []);
  }, [leadsLeftOut, leadsUploaded]);
  const { exportFiles } = useSelector(prospectDataSelector);
  const client = useSelector(userSelector);
  const showExtractionFileModal = () => {
    setOpenUpModal(false);
    setOpenExtractionFileModal(true);
  };
  const showUploadProspectsModal = () => {
    setOpenUpModal(false);
    setOpenUploadProspectsModal(true);
  };
  const closeExtractionFileModal = () => {
    // setOpenUpModal(true);
    setOpenExtractionFileModal(false);
  };
  const closeUploadProspectsModal = () => {
    // setOpenUpModal(true);
    setOpenUploadProspectsModal(false);
  };

  return (
    <>
      {!!addProsToCamp && openUpModal && (
        <Modal
          open
          title={"Upload prospects to Meetz"}
          onCancel={() => {
            console.log("closingsgnifb");
            setOpenUpModal(false);
          }}
        >
          <br />
          <Flex vertical align="flex-start" gap={10}>
            <Text>Add prospects from your Meetz exports</Text>
            <Button
              onClick={() => exportFiles?.length && showExtractionFileModal()}
              className={"link"}
              style={{ marginTop: "-7px", pointerEvents: !exportFiles?.length ? "none" : "auto", color: !exportFiles?.length ? "orange" : "default" }}
            >
              {!exportFiles?.length
                ? //  clients with no files should be directed to prospect data page  to get prospects
                  'No Exported Prospects From Meetz - Go to the "Prospect Data" tab'
                : `Select extracted file`}
            </Button>
            <Text style={{ margin: "20px 0" }}>or</Text>
            <Flex vertical>
              <Text>Upload prospects</Text>
              <small>Add prospects from a file you have</small>
            </Flex>
            <Button onClick={showUploadProspectsModal} className={"link"} style={{ marginTop: "-5px" }}>
              Upload prospects file
            </Button>
          </Flex>
        </Modal>
      )}
      {contextHolder}
      <Flex vertical className={"explore-wrapper step-two-table-container"} style={{ padding: addProsToCamp ? 0 : 24 }}>
        <div>
          {" "}
          <Flex justify="space-between" align="center">
            {" "}
            <Typography.Title level={3}>Select Prospects To Add To Campaign</Typography.Title>
            {!!addProsToCamp && (
              <Flex justify="space-between" align="center">
                <Button onClick={() => setOpenUpModal(true)} className={"link"} style={{ cursor: "pointer" }}>
                  Upload prospects
                </Button>
                <Button onClick={handleAddProspects} className={"primary"} style={{ cursor: "pointer", marginLeft: "15px" }}>
                  Add prospects to campaign
                </Button>
              </Flex>
            )}
          </Flex>
          {/* {!!addProsToCamp && <hr style={{ color: "lightgrey !important", height: "2px", marginTop: "25px", marginBottom: "-5px" }} />} */}
          <br />
          <ul />
          <TableHeader
            leftHeaderContent={
              <Flex
                justify="space-between"
                style={{ borderTop: !!addProsToCamp ? "1px solid #eaeaea" : "none", width: "100%", height: "100%", padding: 0, paddingTop: "25px", margin: 0 }}
              >
                <Row style={{ gap: 16 }} wrap={false}>
                  <StatusTag value={"Total filtered prospects"} color={"default"} count={leadsTotalCnt ?? "-"} />
                  <StatusTag
                    value={"Filters Applied"}
                    color={"processing"}
                    count={filtersSubmited || "0"}
                    removeAction={resetFilters}
                    showRemActionIcon={!!+filtersSubmited}
                  />
                  <StatusTag
                    value={"Prospects Selected To Add To Campaign"}
                    color={+selectedLength ? "success" : "red"}
                    count={selectedLength?.toLocaleString()}
                    removeAction={resetSelPros}
                    showRemActionIcon={!!+selectedLength}
                  />
                </Row>
                <Flex justify="space-between" align="center">
                  <Button
                    className={"link"}
                    style={{ width: "100%" }}
                    onClick={() => (allIdsPresent ? dispatch(selectAllCurrent("rem")) : dispatch(selectAllCurrent("add")))}
                  >
                    {leadsTotalCnt && allIdsPresent ? "Deselect" : "Select"} All Filtered Prospects
                  </Button>
                  <Button
                    onClick={() => setOpenFilterDrawer(true)}
                    className={addProsToCamp ? "link" : "primary"}
                    style={{ marginLeft: addProsToCamp ? "25px" : "10px", cursor: "pointer" }}
                  >
                    <div>Filter</div>
                    <SvgIcon type={"whiteFilter"} style={{ width: "17px", color: "white", height: "17px" }} />
                  </Button>
                </Flex>
              </Flex>
            }
            marginBottom={5}
          />
          {/* <TableHeader searchPlaceholder={"Search Prospect data..."} /> TODOF add in later */}
          <Table
            quickSkip
            quickDropdownSkip
            loading={loadingLeads}
            columns={columns}
            dataSource={leads}
            currentPage={page}
            setCurrentPage={handlePageChanger}
            totalAmnt={leadsTotalCnt}
            totalPages={leadsTotalCnt ? Math.ceil(leadsTotalCnt / 10) : 1}
            rowSelection={{ ...rowSelection }}
            rowKey={"id"}
            locale={{
              emptyText: (
                <Empty
                  image={<Image preview={false} src={NoData} alt={"No data"} width={173} height={150} />}
                  imageStyle={{
                    height: 180, // Ensure the image height is consistent with its actual height
                    marginTop: "30px",
                  }}
                  description={<span style={{ marginTop: "10px" }}>No Prospects Found</span>} // Add margin to separate text from the image
                />
              ),
            }}
            divHeight="fit-content"
            style={{ height: "fit-content" }}
          />
          {openFilterDrawer && (
            <ProspectsFilter
              onClose={() => setOpenFilterDrawer(false)}
              uplNameOptions={uploadNameAvail}
              uploadNameToSearch={filters?.uploadName}
              setUploadNameToSearch={setUploadNameToSearch}
              recentlyUpdatedToSearch={filters?.recentlyUpdated}
              setRecentlyUpdatedToSearch={setRecentlyUpdatedToSearch}
              includeInCampaignToSearch={filters?.includeInCampaign}
              setIncludeInCampaignToSearch={setIncludeInCampaignToSearch}
              includeContactedAlreadyToSearch={filters?.includeContactedAlready}
              setIncludeContactedAlreadyToSearch={setIncludeContactedAlreadyToSearch}
              submitFilters={submitFilters}
              addProsToCamp={addProsToCamp}
            />
          )}
        </div>
      </Flex>{" "}
      <ExtractionFileModal open={openExtractionFileModal} onCancel={closeExtractionFileModal} setProspectAmountExtracting={setProspectAmountExtracting} />
      <UploadProspectsModal onCancel={closeUploadProspectsModal} open={openUploadProspectsModal} setProspectAmountExtracting={setProspectAmountExtracting} />
    </>
  );
};
