/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

import { createSelector, createSlice } from "@reduxjs/toolkit";
// import colors from 'config/assets';
// import { fetchUsersInvitations, updateTeammemInLinkAndPerc } from '../store/invitations/asyncRequests';
// import { patchLinks, getLinks, addAnothLink, removeLink, postLinkQuestions, patchLinkQuestions } from './asyncRequests';
// import { getUser, initiateAndGetAllUserInfo } from "../user/asyncRequests"
import { addCalBe, addMeetingLink, removeCal, removeLink, updateMeeting, zoomIntegrate } from "./asyncRequests";
import { initiateAndGetAllUserInfo, patchSettings } from "../user/asyncRequests";
// import { updateIsIntegrated } from '../integrations';

const reactiveLinksSlice = createSlice({
  name: "reactiveLinks",
  initialState: {
    schedulingFormated: [] as any[],
    additionalAvailabilityFormated: [] as any[],
    integrations: null as any,
    data: null,
    teamData: null,
    loading: false,
    patchLoading: false,
    successfullUpdateMsg: false as boolean | string,
    failedUpdateMsg: false as boolean | string,
    loadingUpdTeamMmem: false,
    patchOutcome: "",
    error: null,
  },
  reducers: {
    resetOutcome: (state) => {
      state.successfullUpdateMsg = false;
      state.failedUpdateMsg = false;
    },
    // changeDesciption: (state, action) => {
    //   state.data.meeting_description = action.payload;
    // },
    // changeEmail: (state, action) => {
    //   state.data.additional_email = action.payload;
    // },
    // clearError: (state) => {
    //   state.error = null;
    // },
    // clearLinkPatchOutcome: (state) => {
    //   state.patchOutcome = '';
    // },
    // updateDragMeetingTitle: (state, action) => {
    //   state.data.default_meeting_title.variables = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateAndGetAllUserInfo.fulfilled, (state, action) => {
        state.schedulingFormated = action.payload?.schedulingFormated;
        state.additionalAvailabilityFormated = action.payload?.additionalAvailabilityFormated;
        state.integrations = action.payload?.integrations;
      })
      .addCase(initiateAndGetAllUserInfo.rejected, (state, action) => {
        console.log("rejected initiate", action);
      })
      .addCase(removeLink.fulfilled, (state, action) => {
        //      settings
        //  client
        state.schedulingFormated = action.payload?.schedulingFormated;
        // state.successfullUpdateMsg='Removed'
      })

      .addCase(removeLink.rejected, (state, action) => {
        console.log(action, "Error in add meeting");
        state.failedUpdateMsg = "Error removing the meeting link. Please check your internet.";
      })
      .addCase(addMeetingLink.fulfilled, (state, action) => {
        //      settings
        //  client
        state.schedulingFormated = action.payload?.schedulingFormated;
        state.successfullUpdateMsg = "Added another meeting link";
      })

      .addCase(addMeetingLink.rejected, (state, action) => {
        console.log(action, "Error in add meeting");
        state.failedUpdateMsg = "Error adding the meeting link. Please check your internet.";
      })
      .addCase(updateMeeting.fulfilled, (state, action) => {
        //      settings
        //  client
        state.schedulingFormated = action.payload?.schedulingFormated;
        state.successfullUpdateMsg = "Updated your meeting";
      })
      .addCase(updateMeeting.rejected, (state, action) => {
        //      settings
        //  client
        console.log(action, "Error in upd meeting");
        state.failedUpdateMsg = "Error updating your meeting link. Please check your internet.";
      })
      .addCase(zoomIntegrate.fulfilled, (state, action) => {
        console.log(action.payload, "action.payload?.integrations");
        state.integrations = action.payload?.integrations;
        state.successfullUpdateMsg = "Successfully connected to your Zoom account";
      })
      .addCase(zoomIntegrate.rejected, (state, action) => {
        state.failedUpdateMsg = "Error in connecting to your Zoom. Please check that you accepted all permissions.";
        // state.schedulingFormated = action.payload;
      })
      .addCase(zoomIntegrate.pending, (state) => {
        //
      })
      .addCase(patchSettings.fulfilled, (state, action) => {
        console.log(action.payload, "action.payload?.integrations22");
        state.integrations = action.payload.integrations;
        state.schedulingFormated = action.payload.schedulingFormated;
        state.successfullUpdateMsg = "Updated";
      })
      .addCase(patchSettings.rejected, (state, action) => {
        console.log(action, "actionaction");
        state.failedUpdateMsg = "Error in updating. Please check your internet or try later.";
        // state.schedulingFormated = action.payload;
      })
      .addCase(patchSettings.pending, (state) => {
        //
      })
      .addCase(addCalBe.fulfilled, (state, action) => {
        state.additionalAvailabilityFormated = action.payload?.additionalAvailabilityFormated;
        state.successfullUpdateMsg = "Connected your calendar successfully.";
      })
      .addCase(addCalBe.rejected, (state, action) => {
        state.failedUpdateMsg = "Error in connecting your calendar. Try again soon and check your internet.";
        // state.schedulingFormated = action.payload;
      })
      .addCase(addCalBe.pending, (state) => {
        //
      })
      .addCase(removeCal.fulfilled, (state, action) => {
        console.log("remv1");
        state.additionalAvailabilityFormated = action.payload?.additionalAvailabilityFormated;
        state.successfullUpdateMsg = "Removed calendar";
      })
      .addCase(removeCal.rejected, (state, action) => {
        console.log("remv1ERR");
        //  state.failedUpdateMsg = "Error in connecting your calendar. Try again soon and check your internet."
        // state.schedulingFormated = action.payload;
      })
      .addCase(removeCal.pending, (state) => {
        //
      });
    //   [updateIsIntegrated]: (state, action) => {
    //     state.data.is_integrated = action.payload;
    //   },
    //   [getUser.fulfilled]: (state, action) => {
    //     const { reactiveLinks } = action.payload;
    //     state.data = reactiveLinks;
    //   },
    //   [updateTeammemInLinkAndPerc.fulfilled]: (state, action) => {
    //     const { teamLinks } = action.payload;
    //     state.teamData = teamLinks;
    //     state.loadingUpdTeamMmem = false;
    //   },
    //   [updateTeammemInLinkAndPerc.rejected]: (state, action) => {
    //     state.loadingUpdTeamMmem = false;
    //     // state.error = action.payload;
    //   },
    //   [updateTeammemInLinkAndPerc.pending]: (state) => {
    //     if (!state.loadingUpdTeamMmem) {
    //       state.loadingUpdTeamMmem = true;
    //     }
    //   },
    //   [fetchUsersInvitations.fulfilled]: (state, action) => {
    //     const { teamLinks } = action.payload;
    //     state.teamData = teamLinks;
    //   },

    //   [removeLink.pending]: (state) => {
    //     if (!state.loading) {
    //       state.loading = true;
    //     }
    //   },
    //   [removeLink.fulfilled]: (state, action) => {
    //     state.loading = false;

    //     const { teamLinks, sortLinks } = action.payload;
    //     state.data = sortLinks;
    //     state.teamData = teamLinks;
    //   },
    //   [removeLink.rejected]: (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload;
    //   },
    //   [postLinkQuestions.pending]: (state) => {
    //     if (!state.loading) {
    //       // state.loading = true;
    //     }
    //   },
    //   [postLinkQuestions.fulfilled]: (state, action) => {
    //     // state.loading = false;
    //     const { teamLinks, sortLinks } = action.payload;
    //     state.data = sortLinks;
    //     state.teamData = teamLinks;
    //   },
    //   [postLinkQuestions.rejected]: (state, action) => {
    //     // state.loading = false;

    //     state.error = action.payload;
    //   },
    //   [patchLinkQuestions.pending]: (state) => {
    //     if (!state.loading) {
    //       // state.loading = true;
    //     }
    //   },
    //   [patchLinkQuestions.fulfilled]: (state, action) => {
    //     // state.loading = false;
    //     const { teamLinks, sortLinks } = action.payload;
    //     state.data = sortLinks;
    //     state.teamData = teamLinks;
    //   },
    //   [patchLinkQuestions.rejected]: (state, action) => {
    //     // state.loading = false;

    //     state.error = action.payload;
    //   },
    //   [getLinks.pending]: (state) => {
    //     if (!state.loading) {
    //       console.log('patch links here pending');
    //       state.loading = true;
    //     }
    //   },
    //   [getLinks.fulfilled]: (state, action) => {
    //     console.log(action.payload, 'patch links here');
    //     state.loading = false;
    //     const { teamLinks, sortLinks } = action.payload;
    //     state.data = sortLinks;
    //     state.teamData = teamLinks;
    //   },
    //   [getLinks.rejected]: (state, action) => {
    //     console.log(action, 'patch links here patchlinkerror');
    //     state.loading = false;

    //     state.error = action.payload;
    //   },

    //   [addAnothLink.pending]: (state) => {
    //     if (!state.loading) {
    //       state.loading = true;
    //     }
    //   },
    //   [addAnothLink.fulfilled]: (state, action) => {
    //     state.loading = false;
    //     const { teamLinks, sortLinks } = action.payload;
    //     state.data = sortLinks;
    //     state.teamData = teamLinks;
    //   },
    //   [addAnothLink.rejected]: (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload;
    //   },
    //   [patchLinks.pending]: (state) => {
    //     if (!state.patchLoading) {
    //       // console.log('patch links here pending');
    //       state.patchLoading = true;
    //     }
    //   },
    //   [patchLinks.fulfilled]: (state, action) => {
    //     state.patchLoading = false;
    //     const { teamLinks, sortLinks } = action.payload;
    //     // console.log(action.payload, 'patch links here');
    //     state.data = sortLinks;
    //     state.teamData = teamLinks;
    //     state.patchOutcome = action.payload.outcome;
    //   },
    //   [patchLinks.rejected]: (state, action) => {
    //     // console.log(action, 'patch links here patchlinkerror');
    //     state.patchLoading = false;
    //   },
  },
});

const { actions, reducer } = reactiveLinksSlice;

export const { resetOutcome } = actions;

export default reducer;

export const reactiveLinksSelector = createSelector(
  (state) => state.reactiveLinks,
  (reactiveLinks) => reactiveLinks
);
