import { Flex, Row, Typography, message } from "antd";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "src/components/layout";
import { AppDispatch } from "src/store";
import { stripeSelector } from "src/store/stripe";
import { getUser } from "src/store/user/asyncRequests";
import { Modal } from "../../../../ui";
import "./index.less";
import { createSubscriptionV3 } from "src/store/stripe/asyncRequests";
import { useNavigate } from "react-router";
import { userSelector } from "src/store/user";

interface SuccessfulActivationModalProps {
  currentPlanName: string;
  buildingPlan?: boolean;
  isDiscount?: any;
  onClose: () => void;
  closeAll: () => void;
  location: {
    country: string;
    postal: string;
  };
}

// DO NOT USE THIS WINDOW FOR ANYTHING OTHER THAN ACTIVATING TRIAL!! THERE ARE CONVERSION PIXELS HERE
export const SuccessfulActivationModal: FC<SuccessfulActivationModalProps> = ({ currentPlanName, onClose, buildingPlan, isDiscount, closeAll, location }) => {
  const { loading_upd_client_package } = useSelector(stripeSelector);
  const [loadedBuild, setLoadedBuild] = useState(false);
  const [twoSecPassed, setTwoSecPassed] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  let { first_name, last_name, email } = useSelector(userSelector);

  const navigate = useNavigate();
  useEffect(() => {
    if (buildingPlan && !loadedBuild) {
      setLoadedBuild(true);
      // sending pixels of "Started trial"
      // console.log("checkingpixel1");

      // console.log("checkingpixel2");

      window.fbq("track", "StartTrial", { value: "20", currency: "USD" });
      // console.log("checkingpixel3");
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "starttrial",
        email,
        address: {
          first_name,
          last_name,
          country: location?.country,
          postal_code: location?.postal,
        },
      });
      // window.gtag("event", "conversion", { send_to: "AW-630038230/qyzOCN6ApsgZENa9tqwC" });
      // console.log("checkingpixel4");
      window.qp("track", "Purchase");
      // console.log("checkingpixel5");
      window.rdt("track", "Purchase", {
        itemCount: 1,
      });
      messageApi.destroy(); // this is to not show the notification twice
      messageApi.success("Success", 5.5);
      // dispatch(createSubscriptionV3({ currentPlanName, isDiscount }));
      setTimeout(async () => {
        navigate("/dashboard");
      }, 1500);
      setTimeout(async () => {
        dispatch(getUser());
        setTwoSecPassed(true);
      }, 3000);
    }
  }, []);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (twoSecPassed && !loading_upd_client_package) {
      closeAll();
    }
  }, [twoSecPassed, loading_upd_client_package]);

  return (
    <Modal rootClassName={"successful-activation-modal"} open={true} closeIcon={false} onCancel={onClose}>
      {contextHolder}
      <Row className={"banner"} />
      <Flex className={"info-wrapper"} vertical={true} gap={16}>
        <Typography.Title level={2} className={"title"}>
          {buildingPlan ? "Preparing your account..." : `Your Current Plan: ${currentPlanName}!`}
        </Typography.Title>
        <Typography.Paragraph className={"description"}>
          {buildingPlan
            ? "Less than 5 seconds and your account is ready!"
            : "Update to a team plan by reaching out to support. If you are on a team plan now, contact your customer success manager for help and optimization."}
          <br />
          <br />
          <br />
          {buildingPlan ? <Loader size="large" /> : ""}
        </Typography.Paragraph>
      </Flex>
    </Modal>
  );
};
