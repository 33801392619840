import { Col, Flex, Form, Grid, Image, Input, Space, Tooltip, Typography, message, notification } from "antd";
// import React, { FC,Suspense,useEffect,useRef,useState } from "react"
import { Drawer } from "../../ui";
import "./index.less";
import { SvgIcon } from "src/shared/icons";
import { useDispatch, useSelector } from "react-redux";
import { reactiveLinksSelector } from "src/store/reactive-links-settings";
import EmailEditor from "react-email-editor";
import { AppDispatch } from "src/store";
import { patchSettings } from "src/store/user/asyncRequests";
import { Loader } from "../Loader";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
// import { userSelector } from "src/store/user"
import React, { FC, useEffect, useState, useRef } from "react";
// import { patchSettings } from "src/store/reactive-links-settings/asyncRequests"

interface AssistantSettingsDrawerProps {
  onClose: () => void;
  editorKey: number;
}

const AssistantToolTip = (
  <React.Fragment>
    {" "}
    <Typography variant="body1">
      <b> Important - First create the new email address in your domain and only after enter the new email here and click save.</b> (If you didn&apos;t do this
      by accident reach out to Support to help)
    </Typography>
    <br />
    <Typography variant="body1">
      1st step: Click on the link in the email that was sent to you. With the subject line &quot;TAKE ACTION: Step 1&quot;
    </Typography>
    <Typography variant="body1">
      2nd Step: Forward all emails to Laura@meetz.ai{" "}
      <a href="https://support.google.com/mail/answer/10957?hl=en" target="_blank">
        (Google Instructions
      </a>{" "}
      or{" "}
      <a
        href={
          "https://support.microsoft.com/en-us/office/turn-on-automatic-forwarding-in-outlook-7f2670a1-7fff-4475-8a3c-5822d63b0c8e#:~:text=In%20the%20Options%20menu%2C%20select,to%20remain%20in%20your%20mailbox."
        }
        target="_blank"
        rel="noreferrer"
      >
        Outlook Instructions)
      </a>{" "}
      IMPORTANT: In both options select to SAVE a copy of emails forwarded.
    </Typography>
    <br />
    <Typography variant="body1">
      <b> Next 2 steps are only for Google Emails (Outlook forwarding is completed by here already):</b>
      <br />
      3rd step: Click on the link in the last email sent to you with the subject line &quot;TAKE ACTION: Step 3&quot;.
    </Typography>
    <Typography variant="body1">4th step: Make sure Laura@Meetz.Ai is saved in the forwarding settings of this new email.</Typography>
  </React.Fragment>
);

export const AssistantSettingsDrawer: FC<AssistantSettingsDrawerProps> = ({ onClose, editorKey }) => {
  const [form] = Form.useForm();

  const { schedulingFormated } = useSelector(reactiveLinksSelector);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    // defaultMeetingTitle: "Meeting | Quick Intro",
    signature: "",
  });

  const [editor_loaded, setEditorLoaded] = useState(false);

  useEffect(() => {
    if (schedulingFormated?.length) {
      const { schedulerName, schedulerEmail, emailSignature } = schedulingFormated[0];

      setUserData({
        name: schedulerName,
        email: schedulerEmail,
        // defaultMeetingTitle: "Meeting | Quick Intro",
        signature: emailSignature,
      });
      form.setFieldsValue({
        name: schedulerName,
        email: schedulerEmail,
        // defaultMeetingTitle: "Meeting | Quick Intro",
        signature: emailSignature,
      });
    }
  }, [schedulingFormated]);

  const handleResetClick = () => {
    // setUserData({
    //   name: "",
    //   email: "",
    //   // defaultMeetingTitle: "",
    //   // signature: {
    //   //   img: "",
    //   // },
    // })
    form.setFieldsValue({
      name: "Laura",
      email: "Laura@meetz.ai",
      // defaultMeetingTitle: "Meeting | Quick Intro",
      signature: {
        img: "",
      },
    });
  };
  const emailEditorRef: any = useRef(null);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const exportHtmlAndUpdSettings = (userData) => {
    emailEditorRef.current.editor.exportHtml((emailSignature, _) => {
      const { design, html } = emailSignature;
      const { name, email } = userData;

      dispatch(patchSettings({ schedulers_name: name?.trim(), additional_email: email?.toLowerCase()?.trim(), email_signature: { design, html } }));
      onClose();
    });
  };
  const handleFormSubmit = async () => {
    setIsFormSubmitting(true);
    form
      .validateFields()
      .then((values) => {
        const updatedValues = { ...userData, ...values };
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
        return;
      })
      .finally(() => {
        setIsFormSubmitting(false);
      });

    exportHtmlAndUpdSettings(userData);

    message.destroy();
    message.info("Updating your assistant now...");
    //
    //   dispatch(
    //     patchSettings({
    //       email_signature: { design, html },
    //     })
    //   );

    // notification.success({
    //   message: "Updated",
    //   placement: "bottomRight",
    //   icon: <SvgIcon type={"success"} />,
    //   description: false,
    //   closeIcon: false,
    //   duration: 5,
    // })
  };

  const [hasErrors, setHasErrors] = useState(false);
  const handleValuesChange = (value) => {
    let hasErrorsNow = false;
    form
      .validateFields()
      .then((values) => {
        const updatedValues = { ...userData, ...values };
        // console.log(updatedValues, "updatedValues");
      })
      .catch((errorInfo) => {
        if (errorInfo?.errorFields?.length) {
          setHasErrors(true);
          hasErrorsNow = true;
        }
      })
      .finally(() => {
        if (!hasErrorsNow) {
          setHasErrors(false);
        }
      });
    setIsFormDirty(true);
    setUserData({ ...userData, ...value });

    // setUserData
  };

  // const isFormValid = isFormDirty

  useEffect(() => {
    if (emailEditorRef?.current?.editor && schedulingFormated?.length && schedulingFormated[0]?.emailSignature?.design) {
      // emailEditorRef?.current?.editor?.init({className:'editor',
      // });
      // console.log(schedulingFormated[0]?.emailSignature?.design, "schedulingFormated[0]?.design");
      emailEditorRef?.current?.editor?.loadDesign(schedulingFormated[0]?.emailSignature?.design);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulingFormated, editor_loaded]);

  const dispatch = useDispatch<AppDispatch>();

  return (
    <Drawer
      open={true}
      title={"Assistant Customizations"}
      leftButtonTitle={"Close"}
      rightButtonTitle={"Save"}
      onBtnPrimaryClick={handleFormSubmit}
      // onBtnSecondaryClick={handleResetClick}
      onBtnSecondaryClick={onClose}
      rightButtonDis={hasErrors || !editor_loaded}
      onCancel={onClose}
      rootClassName={"assistant-settings-drawer"}
      width="900px"
    >
      <Form form={form} initialValues={userData} layout={"vertical"} className={"form"} onValuesChange={handleValuesChange}>
        <Flex vertical={true} gap={22}>
          <Form.Item
            name={"name"}
            label={"Assistants First Name"}
            rules={[{ required: true, message: "Please enter a name for your assistant" }]}
            style={{ width: "350px" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"email"}
            label={
              <React.Fragment>
                {" "}
                Assistants Email
                <Tooltip placement={"right"} title={AssistantToolTip}>
                  <SvgIcon type={"tooltip"} style={{ marginLeft: 11, cursor: "pointer" }} />
                </Tooltip>
              </React.Fragment>
            }
            rules={[
              { required: true, message: "Please enter a valid email address" },
              { type: "email", message: "Please enter a valid email address" },
            ]}
            style={{ width: "350px" }}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
            name={"defaultMeetingTitle"}
            label={"Default Meeting Title"}
            rules={[{ required: true, message: "Please enter a default meeting title" }]}
          >
            <Input />
          </Form.Item> */}
          <Col className={"signature-wrapper"}>
            <Typography.Paragraph className={"label"}>Assistants Email Signature</Typography.Paragraph>
            <ErrorBoundary>
              {!editor_loaded && (
                <Flex vertical={true} align={"center"} gap={24} className={"loader-wrapper"} style={{ margin: "35px", marginTop: "55px" }}>
                  <Loader size="large" />{" "}
                </Flex>
              )}
              {/* <Space className={"signature-img-wrapper"}> */}
              <div className={"signature-img-wrapper"}>
                {/* <div > */}
                <EmailEditor
                  // TODOF fix the above asap
                  key={editorKey}
                  style={{ opacity: editor_loaded ? 1 : 0 }}
                  ref={emailEditorRef}
                  projectId={import.meta.env.VITE_REACT_APP_EMAIL_EDITOR_ID}
                  // editorId={`${editorKey}`}
                  onReady={() => {
                    setEditorLoaded(true);
                  }}
                  tools={{
                    html: { enabled: false },
                    menu: { enabled: false },
                    image: {
                      properties: {
                        src: {
                          value: {
                            url: "https://s3.amazonaws.com/unroll-images-production/projects%2F44317%2F1635425059840-brandmark-design+%2822%29.png",
                            width: 500,
                            height: 100,
                          },
                        },
                      },
                    },
                  }}
                  options={{
                    editor: {
                      minRows: 1,
                      maxRows: 1,
                    },
                    features: {
                      stockImages: false,
                    },
                  }}
                  minHeight="420px"
                  setBodyValues={{
                    backgroundColor: "#e7e7e7",
                    contentWidth: "50%",
                    // fontFamily: {
                    //   label: 'Helvetica',
                    //   value: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                    // },
                  }}
                />
              </div>{" "}
            </ErrorBoundary>
            {/* </div> */}
            {/* </Space> */}
          </Col>
        </Flex>
      </Form>
    </Drawer>
  );
};
