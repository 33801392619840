import { FC, SVGProps } from "react";
import { ReactComponent as Action } from "src/shared/icons/action.svg";
import { ReactComponent as ActionFrom } from "src/shared/icons/actionFrom.svg";
import { ReactComponent as ActionTo } from "src/shared/icons/actionTo.svg";
import { ReactComponent as Alarm } from "src/shared/icons/alarm.svg";
import { ReactComponent as ArrowBack } from "src/shared/icons/arrowBack.svg";
import { ReactComponent as ArrowCollapse } from "src/shared/icons/arrowCollapse.svg";
import { ReactComponent as ArrowDown } from "src/shared/icons/arrowDown.svg";
import { ReactComponent as ArrowHeader } from "src/shared/icons/arrowHeader.svg";
import { ReactComponent as Warning } from "src/shared/icons/warning.svg";
import { ReactComponent as ArrowLeft } from "src/shared/icons/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "src/shared/icons/arrowRight.svg";
import { ReactComponent as ArrowSuffix } from "src/shared/icons/arrowSuffix.svg";
import { ReactComponent as Battery } from "src/shared/icons/battery.svg";
import { ReactComponent as Bell } from "src/shared/icons/bell.svg";
import { ReactComponent as Calendar } from "src/shared/icons/calendar.svg";
import { ReactComponent as Calendar2 } from "src/shared/icons/calendar2.svg";
import { ReactComponent as AllActions } from "src/shared/icons/campaignIcons/allActions.svg";
import { ReactComponent as FromSaved } from "src/shared/icons/campaignIcons/bookmark_icon.svg";
import { ReactComponent as Fire } from "src/shared/icons/campaignIcons/fire.svg";
import { ReactComponent as Letter } from "src/shared/icons/campaignIcons/letter.svg";
import { ReactComponent as ProgressDot } from "src/shared/icons/campaignIcons/progressDot.svg";
import { ReactComponent as FromScratch } from "src/shared/icons/campaignIcons/ruler_icon.svg";
import { ReactComponent as EmailSelect } from "src/shared/icons/campaignIcons/selectStepIcons/emailSelect.svg";
import { ReactComponent as PersonalizedEmailSelect } from "src/shared/icons/campaignIcons/selectStepIcons/personalizedEmailSelect.svg";
import { ReactComponent as PhoneCallSelect } from "src/shared/icons/campaignIcons/selectStepIcons/phoneCallSelect.svg";
import { ReactComponent as SmsSelect } from "src/shared/icons/campaignIcons/selectStepIcons/smsSelect.svg";
import { ReactComponent as TaskSelect } from "src/shared/icons/campaignIcons/selectStepIcons/taskSelect.svg";
import { ReactComponent as DividerDot } from "src/shared/icons/campaignIcons/sequenceSteps/dividerDot.svg";
import { ReactComponent as Email } from "src/shared/icons/campaignIcons/sequenceSteps/email.svg";
import { ReactComponent as LargeEmail } from "src/shared/icons/campaignIcons/largerEmail.svg";
import { ReactComponent as Microphone } from "src/shared/icons/campaignIcons/sequenceSteps/microphone.svg";
import { ReactComponent as Pause } from "src/shared/icons/campaignIcons/sequenceSteps/pause.svg";
import { ReactComponent as Person } from "src/shared/icons/campaignIcons/sequenceSteps/person.svg";
import { ReactComponent as PersonalizedEmail } from "src/shared/icons/campaignIcons/sequenceSteps/personalizedEmail.svg";
import { ReactComponent as PhoneCall } from "src/shared/icons/campaignIcons/sequenceSteps/phoneCall.svg";
import { ReactComponent as Sms } from "src/shared/icons/campaignIcons/sequenceSteps/sms.svg";
import { ReactComponent as StepPrefix } from "src/shared/icons/campaignIcons/sequenceSteps/stepPrefix.svg";
import { ReactComponent as Task } from "src/shared/icons/campaignIcons/sequenceSteps/task.svg";
import { ReactComponent as VoiceMail } from "src/shared/icons/campaignIcons/sequenceSteps/voiceMail.svg";
import { ReactComponent as VoicemailPlay } from "src/shared/icons/campaignIcons/sequenceSteps/voicemailPlay.svg";
import { ReactComponent as Watch } from "src/shared/icons/campaignIcons/sequenceSteps/watch.svg";
import { ReactComponent as StarShine } from "src/shared/icons/campaignIcons/starShine.svg";
import { ReactComponent as Statistic } from "src/shared/icons/campaignIcons/statistic.svg";
import { ReactComponent as AiGeneratedSequence } from "src/shared/icons/campaignIcons/stick_icon.svg";
import { ReactComponent as SendEmail } from "src/shared/icons/campaignIcons/sendEmail.svg";
import { ReactComponent as Flash } from "src/shared/icons/campaignIcons/flash.svg";
import { ReactComponent as Cancel } from "src/shared/icons/cancel.svg";
import { ReactComponent as Checked } from "src/shared/icons/checked.svg";
import { ReactComponent as Checked2 } from "src/shared/icons/checked2.svg";
import { ReactComponent as Clock } from "src/shared/icons/clock.svg";
import { ReactComponent as Close } from "src/shared/icons/close.svg";
import { ReactComponent as CloseModal } from "src/shared/icons/closeModal.svg";
import { ReactComponent as CloseTag } from "src/shared/icons/closeTag.svg";
import { ReactComponent as CompanyInfo } from "src/shared/icons/companyInfo.svg";
import { ReactComponent as ContactInfo } from "src/shared/icons/contactInfo.svg";
import { ReactComponent as Copy } from "src/shared/icons/copy.svg";
import { ReactComponent as CursorBlack } from "src/shared/icons/cursorBlack.svg";
import { ReactComponent as Download } from "src/shared/icons/download.svg";
import { ReactComponent as Export } from "src/shared/icons/export.svg";
import { ReactComponent as WhiteFilter } from "src/shared/icons/whitefilter.svg";
import { ReactComponent as Filter } from "src/shared/icons/filter.svg";
import { ReactComponent as Google } from "src/shared/icons/google.svg";
import { ReactComponent as Growth } from "src/shared/icons/growth.svg";
import { ReactComponent as Human } from "src/shared/icons/human.svg";
import { ReactComponent as InfoFilled } from "src/shared/icons/infoFilled.svg";
import { ReactComponent as LikeLinkedIn } from "src/shared/icons/likeLinkedIn.svg";
import { ReactComponent as Logo } from "src/shared/icons/logo.svg";
import { ReactComponent as LogoLetter } from "src/shared/icons/logoLetter.svg";
import { ReactComponent as Microsoft } from "src/shared/icons/microsoft.svg";
import { ReactComponent as Minus } from "src/shared/icons/minus.svg";
import { ReactComponent as PaginationArrowLeft } from "src/shared/icons/paginationArrowLeft.svg";
import { ReactComponent as PaginationArrowRight } from "src/shared/icons/paginationArrowRight.svg";
import { ReactComponent as PaginationSuperArrowLeft } from "src/shared/icons/paginationSuperArrowLeft.svg";
import { ReactComponent as PaginationSuperArrowRight } from "src/shared/icons/paginationSuperArrowRight.svg";
import { ReactComponent as PauseOutline } from "src/shared/icons/pauseOutline.svg";
import { ReactComponent as People } from "src/shared/icons/people.svg";
import { ReactComponent as Phone } from "src/shared/icons/phone.svg";
import { ReactComponent as PhoneDialer } from "src/shared/icons/phoneDialer.svg";
import { ReactComponent as Play } from "src/shared/icons/play.svg";
import { ReactComponent as PlayHeader } from "src/shared/icons/playHeader.svg";
import { ReactComponent as Plus } from "src/shared/icons/plus.svg";
import { ReactComponent as RewindBack } from "src/shared/icons/rewindBack.svg";
import { ReactComponent as RewindForward } from "src/shared/icons/rewindForward.svg";
import { ReactComponent as Search } from "src/shared/icons/search.svg";
import { ReactComponent as SearchSuffixIcon } from "src/shared/icons/searchSuffixIcon.svg";
import { ReactComponent as SearchSuffixIconK } from "src/shared/icons/searchSuffixIconK.svg";
import { ReactComponent as SelectArrow } from "src/shared/icons/selectArrow.svg";
import { ReactComponent as Settings } from "src/shared/icons/settings.svg";
import { ReactComponent as Shuffle } from "src/shared/icons/shuffle.svg";
import { ReactComponent as ActiveBar } from "src/shared/icons/sidebarIcons/activeBar.svg";
import { ReactComponent as Billing } from "src/shared/icons/sidebarIcons/billing.svg";
import { ReactComponent as Campaigns } from "src/shared/icons/sidebarIcons/compaigns.svg";
import { ReactComponent as CampaignsActive } from "src/shared/icons/sidebarIcons/compaignsActive.svg";
import { ReactComponent as Customizations } from "src/shared/icons/sidebarIcons/customizations.svg";
import { ReactComponent as Dashboard } from "src/shared/icons/sidebarIcons/dashboard.svg";
import { ReactComponent as DashboardActive } from "src/shared/icons/sidebarIcons/dashboardActive.svg";
import { ReactComponent as Deals } from "src/shared/icons/sidebarIcons/deals.svg";
import { ReactComponent as DealsActive } from "src/shared/icons/sidebarIcons/dealsActive.svg";
import { ReactComponent as Emails } from "src/shared/icons/sidebarIcons/emails.svg";
import { ReactComponent as EmailsActive } from "src/shared/icons/sidebarIcons/emailsActive.svg";
import { ReactComponent as MeetingLinks } from "src/shared/icons/sidebarIcons/meetingsLinks.svg";
import { ReactComponent as MeetingLinksActive } from "src/shared/icons/sidebarIcons/meetingsLinksActive.svg";
import { ReactComponent as PhoneCalls } from "src/shared/icons/sidebarIcons/phoneCalls.svg";
import { ReactComponent as PhoneCallsActive } from "src/shared/icons/sidebarIcons/phoneCallsActive.svg";
import { ReactComponent as ProspectData } from "src/shared/icons/sidebarIcons/prospectData.svg";
import { ReactComponent as ProspectDataActive } from "src/shared/icons/sidebarIcons/prospectDataActive.svg";
import { ReactComponent as Question } from "src/shared/icons/sidebarIcons/question.svg";
import { ReactComponent as TeamMembers } from "src/shared/icons/sidebarIcons/teamMembers.svg";
import { ReactComponent as TeamMembersActive } from "src/shared/icons/sidebarIcons/teamMembersActive.svg";
import { ReactComponent as SpotifyLong } from "src/shared/icons/spotifyLong.svg";
import { ReactComponent as GreyCancel } from "src/shared/icons/greyCancel.svg";
import { ReactComponent as Starter } from "src/shared/icons/starter.svg";
import { ReactComponent as Success } from "src/shared/icons/success.svg";
import { ReactComponent as AddFile } from "src/shared/icons/textEditorIcons/addFile.svg";
import { ReactComponent as AlignCenter } from "src/shared/icons/textEditorIcons/alignCenter.svg";
import { ReactComponent as AlignLeft } from "src/shared/icons/textEditorIcons/alignLeft.svg";
import { ReactComponent as AlignRight } from "src/shared/icons/textEditorIcons/alignRight.svg";
import { ReactComponent as Bold } from "src/shared/icons/textEditorIcons/bold.svg";
import { ReactComponent as Italic } from "src/shared/icons/textEditorIcons/italic.svg";
import { ReactComponent as Link } from "src/shared/icons/textEditorIcons/link.svg";
import { ReactComponent as OrderedList } from "src/shared/icons/textEditorIcons/orderedList.svg";
import { ReactComponent as Underline } from "src/shared/icons/textEditorIcons/underline.svg";
import { ReactComponent as UnorderedList } from "src/shared/icons/textEditorIcons/unorderedList.svg";
import { ReactComponent as TimeAvailable } from "src/shared/icons/timeAvailable.svg";
import { ReactComponent as TimeNotAvailable } from "src/shared/icons/timeNotAvailable.svg";
import { ReactComponent as Tooltip } from "src/shared/icons/tooltip.svg";
import { ReactComponent as Ultimate } from "src/shared/icons/ultimate.svg";
import { ReactComponent as Undo } from "src/shared/icons/undo.svg";
import { ReactComponent as Video } from "src/shared/icons/video.svg";
import { ReactComponent as WaitingDot } from "src/shared/icons/waitingDot.svg";
import { ReactComponent as Wallet } from "src/shared/icons/wallet.svg";
import { ReactComponent as Play2 } from "src/shared/icons/play2.svg";
import { ReactComponent as Phone2 } from "src/shared/icons/phone2.svg";
import { ReactComponent as View } from "src/shared/icons/view.svg";
import { ReactComponent as ManAvatar } from "src/shared/icons/manAvatar.svg";
import { ReactComponent as WomanAvatar } from "src/shared/icons/womanAvatar.svg";
import { ReactComponent as ArrowBackAccent } from "src/shared/icons/arrowBackAccent.svg";
import { ReactComponent as ArrowDownTriangle } from "src/shared/icons/arrowDownTriangle.svg";
import { ReactComponent as StarterIcon } from "src/shared/icons/starteicon.svg";
import { ReactComponent as GrowthIcon } from "src/shared/icons/growthicon.svg";
import { ReactComponent as UltimateIcon } from "src/shared/icons/ultimate.svg";
import { ReactComponent as CheckWhite } from "src/shared/icons/CheckWhite.svg";
import { ReactComponent as Refresh } from "src/shared/icons/refresh.svg";

const icons: { [key: string]: FC<SVGProps<SVGSVGElement>> } = {
  refresh: Refresh,
  checkWhite: CheckWhite,
  starterIcon: StarterIcon,
  growthIcon: GrowthIcon,
  ultimateIcon: UltimateIcon,
  action: Action,
  activeBar: ActiveBar,
  actionFrom: ActionFrom,
  actionTo: ActionTo,
  addFile: AddFile,
  aiGeneratedSequence: AiGeneratedSequence,
  alarm: Alarm,
  alignCenter: AlignCenter,
  alignLeft: AlignLeft,
  alignRight: AlignRight,
  allActions: AllActions,
  arrowBack: ArrowBack,
  arrowBackAccent: ArrowBackAccent,
  warning: Warning,
  arrowCollapse: ArrowCollapse,
  arrowDown: ArrowDown,
  arrowDownTriangle: ArrowDownTriangle,
  arrowHeader: ArrowHeader,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowSuffix: ArrowSuffix,
  bold: Bold,
  battery: Battery,
  bell: Bell,
  billing: Billing,
  calendar: Calendar,
  calendar2: Calendar2,
  campaigns: Campaigns,
  campaignsActive: CampaignsActive,
  cancel: Cancel,
  checked: Checked,
  checked2: Checked2,
  close: Close,
  closeModal: CloseModal,
  closeTag: CloseTag,
  clock: Clock,
  companyInfo: CompanyInfo,
  contactInfo: ContactInfo,
  copy: Copy,
  cursorBlack: CursorBlack,
  customizations: Customizations,
  dashboard: Dashboard,
  dashboardActive: DashboardActive,
  deals: Deals,
  dealsActive: DealsActive,
  dividerDot: DividerDot,
  download: Download,
  email: Email,
  largeEmail: LargeEmail,
  emails: Emails,
  emailsActive: EmailsActive,
  emailSelect: EmailSelect,
  export: Export,
  fire: Fire,
  filter: Filter,
  whiteFilter: WhiteFilter,
  flash: Flash,
  fromScratch: FromScratch,
  fromSaved: FromSaved,
  google: Google,
  greyCancel: GreyCancel,
  growth: Growth,
  human: Human,
  infoFilled: InfoFilled,
  italic: Italic,
  letter: Letter,
  likeLinkedIn: LikeLinkedIn,
  link: Link,
  logo: Logo,
  logoLetter: LogoLetter,
  manAvatar: ManAvatar,
  meetingLinks: MeetingLinks,
  meetingLinksActive: MeetingLinksActive,
  microsoft: Microsoft,
  microphone: Microphone,
  minus: Minus,
  orderedList: OrderedList,
  paginationArrowLeft: PaginationArrowLeft,
  paginationArrowRight: PaginationArrowRight,
  paginationSuperArrowLeft: PaginationSuperArrowLeft,
  paginationSuperArrowRight: PaginationSuperArrowRight,
  pause: Pause,
  pauseOutline: PauseOutline,
  people: People,
  person: Person,
  personalizedEmail: PersonalizedEmail,
  personalizedEmailSelect: PersonalizedEmailSelect,
  phoneCall: PhoneCall,
  phoneCallSelect: PhoneCallSelect,
  prospectData: ProspectData,
  prospectDataActive: ProspectDataActive,
  phone: Phone,
  phone2: Phone2,
  phoneCalls: PhoneCalls,
  phoneCallsActive: PhoneCallsActive,
  phoneDialer: PhoneDialer,
  playHeader: PlayHeader,
  progressDot: ProgressDot,
  play: Play,
  play2: Play2,
  plus: Plus,
  rewindBack: RewindBack,
  rewindForward: RewindForward,
  search: Search,
  searchSuffixIcon: SearchSuffixIcon,
  searchSuffixIconK: SearchSuffixIconK,
  selectArrow: SelectArrow,
  sendEmail: SendEmail,
  settings: Settings,
  shuffle: Shuffle,
  sms: Sms,
  smsSelect: SmsSelect,
  starter: Starter,
  starShine: StarShine,
  statistic: Statistic,
  stepPrefix: StepPrefix,
  spotifyLong: SpotifyLong,
  success: Success,
  task: Task,
  taskSelect: TaskSelect,
  teamMembers: TeamMembers,
  teamMembersActive: TeamMembersActive,
  timeAvailable: TimeAvailable,
  timeNotAvailable: TimeNotAvailable,
  tooltip: Tooltip,
  question: Question,
  ultimate: Ultimate,
  undo: Undo,
  underline: Underline,
  unorderedList: UnorderedList,
  video: Video,
  view: View,
  voiceMail: VoiceMail,
  voicemailPlay: VoicemailPlay,
  waitingDot: WaitingDot,
  wallet: Wallet,
  watch: Watch,
  womanAvatar: WomanAvatar,
} as const;

type SvgIconProps = SVGProps<SVGSVGElement> & { type: string };

export const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type] ?? null;
  return Icon && <Icon {...svgProps} />;
};
