import { FC, lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { Loader } from "../components/layout";
import { RegisteredLayout } from "../components/layout/RegisteredLayout";
const FallbackComponent = () => <div> </div>;
const Dashboard = lazy<FC>(() =>
  import("./dashboard").catch(() => {
    window.location.reload();
    return {
      default: FallbackComponent,
    };
  })
);

const VerifyEmail = lazy<FC>(() =>
  import("src/components/pages/verify-email").catch(() => {
    window.location.reload();
    return {
      default: FallbackComponent,
    };
  })
);
const Unsubscribe = lazy<FC>(() =>
  import("src/components/pages/unsubscribe").catch(() => {
    window.location.reload();
    return {
      default: FallbackComponent,
    };
  })
);
const DoNotSellMyInfo = lazy<FC>(() =>
  import("src/components/pages/do-not-sell-info").catch(() => {
    window.location.reload();
    return {
      default: FallbackComponent,
    };
  })
);

const ProspectData = lazy<FC>(() =>
  import("./prospect-data").catch(() => {
    window.location.reload();
    return {
      default: FallbackComponent,
    };
  })
);
const Campaigns = lazy<FC>(() =>
  import("./campaigns").catch(() => {
    window.location.reload();
    return {
      default: FallbackComponent,
    };
  })
);
const Emails = lazy<FC>(() =>
  import("./emails-data").catch(() => {
    window.location.reload();
    return {
      default: FallbackComponent,
    };
  })
);
const PhoneCalls = lazy<FC>(() =>
  import("./phone-calls").catch(() => {
    window.location.reload();
    return {
      default: FallbackComponent,
    };
  })
);
const MeetingLinks = lazy<FC>(() =>
  import("./meeting-links").catch(() => {
    window.location.reload();
    return {
      default: FallbackComponent,
    };
  })
);
const TeamMembers = lazy<FC>(() =>
  import("./team-members").catch(() => {
    window.location.reload();
    return {
      default: FallbackComponent,
    };
  })
);
const BookMeeting = lazy<FC>(() =>
  import("./book-meeting").catch(() => {
    window.location.reload();
    return {
      default: FallbackComponent,
    };
  })
);
const BookMeetingFromEmail = lazy<FC>(() =>
  import("./book-meeting-from-email").catch(() => {
    window.location.reload();
    return {
      default: FallbackComponent,
    };
  })
);
const Settings = lazy<FC>(() =>
  import("./settings").catch(() => {
    window.location.reload();
    return {
      default: FallbackComponent,
    };
  })
);
const LogIn = lazy<FC>(() =>
  import("./login").catch(() => {
    window.location.reload();
    return {
      default: FallbackComponent,
    };
  })
);
const SignUp = lazy<FC>(() =>
  import("./signup").catch(() => {
    window.location.reload();
    return {
      default: FallbackComponent,
    };
  })
);

const Deals = lazy<FC>(() =>
  import("./deals").catch(() => {
    window.location.reload();
    return {
      default: FallbackComponent,
    };
  })
);

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Navigate to="/login" replace />,
  },
  {
    path: "*",
    element: <Navigate to="/login" replace />,
  },
  {
    Component: RegisteredLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: ProspectData,
        path: "/prospect-data",
      },
      {
        Component: Dashboard,
        path: "/dashboard",
      },
      {
        Component: Deals,
        path: "/deals",
      },
      {
        Component: Campaigns,
        path: "/campaigns",
      },
      {
        Component: Campaigns,
        path: "/campaign",
        children: [
          {
            Component: Campaigns,
            path: "ai-generated",
          },
          {
            Component: Campaigns,
            path: "from-scratch",
          },
          {
            Component: Campaigns,
            path: "from-saved",
          },
          {
            Component: Campaigns,
            path: ":id",
            children: [
              {
                Component: Campaigns,
                path: "prospects",
              },
              {
                Component: Campaigns,
                path: "edit-sequence",
              },
            ],
          },
        ],
      },
      {
        Component: Emails,
        path: "/email-analytics",
      },
      {
        Component: PhoneCalls,
        path: "/phone-calls",
      },
      {
        Component: MeetingLinks,
        path: "/meeting-links",
      },
      {
        Component: TeamMembers,
        path: "/team-members",
        children: [
          {
            Component: TeamMembers,
            path: ":id",
          },
        ],
      },
      {
        Component: Settings,
        path: "/settings",
      },
      {
        element: <Navigate to="/login" replace />,
        path: "*",
      },
    ],
  },
  {
    Component: VerifyEmail,
    path: "/verifyemail",
  },
  {
    Component: Unsubscribe,
    path: "/unsubscribe/:unsub_str?",
  },
  {
    Component: DoNotSellMyInfo,
    path: "/do_not_sell_my_info",
  },
  {
    Component: BookMeeting,
    path: "/cal/:custom_url?/:email_thread_id?",
  },
  {
    Component: BookMeetingFromEmail,
    path: "/EmailConfirmed/:code?/:name?",
  },
  {
    Component: LogIn,
    path: "/login",
  },
  {
    Component: SignUp,
    path: "/signup",
  },
];

export default routes;
