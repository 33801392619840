import { Collapse as AntCollapse, CollapseProps } from "antd"
import React, { FC, PropsWithChildren, useEffect, useState } from "react"
import { SvgIcon } from "src/shared/icons"
import "./index.less"

interface CustomCollapseProps extends CollapseProps {
  customClassName?: string
  multiOpen: any
  items: any
  initialOpenKey?: any
  controlActKeys?: any
  setControlActKeys?: any
}
const { Panel } = AntCollapse;

export const Collapse: FC<PropsWithChildren<CustomCollapseProps>> = ({ customClassName, items,multiOpen=false,initialOpenKey=undefined,controlActKeys,setControlActKeys, ...props }) => {


  const [activeKey, setActiveKey] = useState(initialOpenKey);

  useEffect(() => {
    if(controlActKeys && setControlActKeys){
setControlActKeys(initialOpenKey)
    }else{
setActiveKey(initialOpenKey)}
  }, [initialOpenKey]);

       const handleCollapseChange = (key) => {
    if (key.length) {
      const keysOpen = multiOpen ? key :[key[key.length - 1]];
      console.log(key,'ketyke',keysOpen);

      if(controlActKeys && setControlActKeys){
setControlActKeys(keysOpen)
    }else{
setActiveKey(keysOpen)
}
    } else {
        if(controlActKeys && setControlActKeys){
setControlActKeys(undefined)
    }else{
setActiveKey(undefined)
}
    }
  };

  return (
    <AntCollapse
      className={`collapse-wrapper ${customClassName}`}
      items={items}
      expandIcon={({ isActive }) => (
        <SvgIcon type={"arrowCollapse"} style={{ transform: isActive ? "rotate(0deg)" : "rotate(180deg)" }} />
      )}
      collapsible={"header"}
      ghost
      activeKey={controlActKeys||activeKey}
      onChange={handleCollapseChange}
      {...props}
    > {items.map((item, index) => (
        // Rendering each panel with a header and content
        <Panel header={item.header} key={index}>
          {item.content}
        </Panel>
      ))}
      </AntCollapse>
  )
}
