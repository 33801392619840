import { Button, Checkbox, CheckboxProps, Flex, Form, Input } from "antd"
import React, { FC, useState } from "react"
import { ContentCard } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import BillingHistoryEmptyImg from "src/shared/images/dashboards.webp"

export const DefaultContent: FC = () => {


  return (
    <ContentCard
      headerIcon={<SvgIcon type={"checked2"} />}
      cardTitle={"Click On Any Step To View Or Edit"}
     
    >
     <div> Click "Save & Create Campaign" to start getting leads!</div>
      <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',marginTop:'30px'}}>
    <img
            src={BillingHistoryEmptyImg}
            alt={"Empty table"}
            // className={"table-empty-img"}
            width={333}
            // style={{margin:'auto'}}
            // height={238}
          /></div>
    </ContentCard>
  )
}
