/* eslint-disable react/jsx-fragments */
import { Col, MenuProps, message, notification, Row } from "antd"
import React, { forwardRef, useContext, useEffect, useMemo, useState } from "react"
import { saveToClipboard } from "../../../helpers"
import { SvgIcon } from "../../../shared/icons"
import { MeetingLinksContext } from "../../app/context/MeetingLinksContext"
import { CustomCard } from "../../ui/CustomCard"
import { EmptyState } from "../../ui/EmptyState"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch } from "src/store"
import { addCalBe } from "src/store/reactive-links-settings/asyncRequests"
import { reactiveLinksSelector, resetOutcome } from "src/store/reactive-links-settings"
import { Loader } from "src/components/ui"

interface MeetingLinksProps {
  openEditMeetingLinkDrawer: (boolean) => void
  isTourStep?: boolean
  openDeleteMeetingLinkDrawer: () => void
  openAiAssistantSettings: () => void
  meetingLinksCardRef?: any
}

export const MeetingLinks = forwardRef<HTMLDivElement, MeetingLinksProps>(
  (
    { isTourStep, openEditMeetingLinkDrawer, openDeleteMeetingLinkDrawer, meetingLinksCardRef,openAiAssistantSettings },
    ref
  ) => {
  const { meetingLinks, setSelectedMeetingLinkId } = useContext(MeetingLinksContext)
    const [tourStepData, setTourStepData] = useState(meetingLinks)

  

  // const { emailToAddN, setEmailToAddNow } = useContext(MeetingLinksContext)

   
  const sortedMeetingLinks = [...meetingLinks].sort((a, b) =>
    a.isAiGenerated === b.isAiGenerated ? 0 : a.isAiGenerated ? -1 : 1
  )
   
       

  const dropdownMenuItems: MenuProps["items"] = [
    {
      key: "1",
      label: "Edit Meeting Link",
      onClick: () => openEditMeetingLinkDrawer(false),
    },
   meetingLinks?.length>2 && {
      key: "2",
      label: "Delete",
      onClick: () => openDeleteMeetingLinkDrawer(), 
    },
  ]?.filter((val)=>val)

  // DO NOT ALLOW DELETE OF AI!!
  const aiDropDownMenuItems: MenuProps["items"] = [
    {
      key: "1",
      label: "Edit Default Meeting",
      onClick: () => openEditMeetingLinkDrawer(true),
    },
    {
      key: "2",
      label: "Customize Ai Assistant",
      onClick: () => openAiAssistantSettings(),
    },
  ]

console.log(
sortedMeetingLinks,'sortedMeetingLinks'
);
 
  // isTourStep ? (
    //   <Row gutter={[24, 24]}> 
    //   {sortedMeetingLinks.map((card, index) => (
    //       <Col span={8} key={card.id}>
    //         <CustomCard
    //           ref={index === 0 ? meetingLinksCardRef : null}
    //           id={card.id}
    //           title={card.name}
    //           description={card.description}
    //           tags={card.tags}
    //           links={card.links}
    //           isOutlined={card.isAiGenerated}
    //           onDropdownClick={() => setSelectedMeetingLinkId(card.id)}
    //           onLinkClick={(href: string) => {
    //             saveToClipboard(href)
    //             notification.info({
    //               message: "Copied",
    //               description: false,
    //               duration: 5,
    //               icon: <SvgIcon type={"infoFilled"} />,
    //               closeIcon: false,
    //               placement: "bottomRight",
    //             })
    //           }}
    //           dropdownMenuItems={dropdownMenuItems}
    //         />
    //       </Col>
    //     ))}
    //   </Row>
    // ) :
    
     
    //  sortedMeetingLinks.length === 0 ? (
    //   <EmptyState text={"Loading..."} /> should always have at least 2 or there is an error.
    // ) : 
  return  (
    
    <>
      <Row gutter={[24, 24]}>
        {sortedMeetingLinks?.length ? sortedMeetingLinks.map((card,i) => 
      { 
        const tags = [  { text:`${card.meetingLength/60000} min`, color: "Green", type: "Meeting Length" },!i &&   { text: "AI", color: "Purple", type: "AI Generated" }]?.filter(Boolean)
        const title = card.name?.value || card.name || ''
        return  (
          <Col span={8} key={card.id}>
            <CustomCard
              ref={i === 0 ? meetingLinksCardRef : null}
              id={card.id}
              title={title?.length >150 ?title?.substring(0, 150) +'...' :title}
              description={card.description?.length >200 ?card.description?.substring(0, 200) +'...' :card.description || ''}
              tags={tags}
              links={!i? card.links: [{href: `https://app.meetz.ai/cal/${card?.custom_url}`,text:'Copy link'}] }
              isOutlined={card.isAiGenerated}
              onDropdownClick={() => setSelectedMeetingLinkId(card.id)}
              onLinkClick={(href: string) => {
                saveToClipboard(href)
                notification.info({
                  message: !i ? "Copied Email" : "Copied Link",
                  description: false,
                  duration: 5,
                  icon: <SvgIcon type={"infoFilled"} />,
                  closeIcon: false,
                  placement: "bottomRight",
                })
              }}
              dropdownMenuItems={!i ? aiDropDownMenuItems : dropdownMenuItems}
            />
          </Col>
        )}) :''}
      </Row>
    </>
  )
})
