/* eslint-disable import/no-unresolved */
import { Layout } from "antd";
import { FC, lazy, Suspense, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { SideBarMenu } from "src/components/layout/SideBarMenu";
import { ParallelDialerModal } from "src/components/pages/campaigns/CampaignDetails/ui";
import { SetColapsedSdBar, fullUserSelector, userSelector } from "src/store/user";
import { CampaignsContext } from "../../app/context/CampaignsContext";
import { Loader } from "../../ui";
import { DefaultHeader } from "../DefaultHeader";
import { HowToUseCard } from "../SideBarMenu/HowToUseCard";
import { WelcomeTour } from "../SideBarMenu/Tour";
import { CommonContext } from "../../app/context/CommonContext";
import { ViewingProcess } from "../../ui/ViewingProcess";
import { useBreakpoints } from "../../../helpers/useBreakpoints";
import { PlansModal } from "src/components/pages/billing/ui/PlansModal";
import { UnlockAccessModal } from "src/components/pages/dashboard";
import { meetzinappanalytics } from "src/store/user/asyncRequests";
// const Loader = lazy<FC>(() => import("../../ui/Loader"));

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const { Sider, Header } = Layout;

const UnlockTrial: FC = ({ mobile }) => {
  const [openUnlockAccessModal, setOpenUnlockAccessModal] = useState(true);
  const [openPlansAndPricing, setOpenPlansAndPricing] = useState(false);
  const handleUnlockAccessModalClose = () => {
    setOpenUnlockAccessModal(false);
  };
  const handleSubmit = () => {
    handleUnlockAccessModalClose();
    setOpenPlansAndPricing(true);
    // setIsDashboardLocked(true)
  };

  return (
    <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
      {!mobile && <div>Activate trial to start getting clients!</div>}
      {openUnlockAccessModal && <UnlockAccessModal open={openUnlockAccessModal} handleSubmit={handleSubmit} />}
      {openPlansAndPricing && (
        <PlansModal
          onClose={() => {
            setOpenPlansAndPricing(false);
            setOpenUnlockAccessModal(true);
          }}
        />
      )}{" "}
    </div>
  );
};
const RegisteredLayout: FC = () => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const { openParallelDialerModal, setOpenParallelDialerModal } = useContext(CampaignsContext);
  const { sdBarCollapsed } = useSelector(fullUserSelector);
  const { isTourOpened, isViewingProcess } = useContext(CommonContext);
  const { isDesktop } = useBreakpoints();
  // console.log(sdBarCollapsed,'sdBarCollapsed');
  const { user, loading, error, timezones } = useSelector(fullUserSelector);
  const client = useSelector(userSelector);
  // try{
  // if(user?.email){
  //   // ignore-ts
  //   (window as any).Intercom("boot", {
  //     app_id: "eodlisfw",
  //     email: user.email,
  //   })
  // }}catch(e){console.log(e,'Error in initialization');}
  const [firstTimeLoadedUser, setFirstTimeLoadedUser] = useState(false);
  console.log(client?.new_payment_status, "client?.new_payment_status");
  useEffect(() => {
    if (user?.id) {
      setFirstTimeLoadedUser(true);
    }
  }, [user]);
  // console.log(isDesktop, user, "Log are these working ????");

  const [initialized, setInitialized] = useState(false);
  const asyncActionIpFetch = async () => {
    try {
      const fetchedPromise = await fetch("https://api.ipify.org/?format=json");
      const fetchedPromiseJson = await fetchedPromise?.json();
      if (!initialized) {
        setInitialized(true);
        dispatch(meetzinappanalytics({ ip_address: fetchedPromiseJson.ip, mobile_mode: !isDesktop }));
        // window.rdt("track", "SignUp");
        // window.qp("track", "CompleteRegistration");
      }
    } catch (e) {
      console.log("Error fetching", e);
    }
  };
  useEffect(() => {
    asyncActionIpFetch();
  }, []);

  return isDesktop ? (
    isTourOpened ? (
      <WelcomeTour />
    ) : (
      <Layout hasSider style={{ position: "relative", minHeight: "100vh" }}>
        <Sider
          collapsible
          className={"authenticated-slider"}
          collapsed={sdBarCollapsed}
          onCollapse={(value) => dispatch(SetColapsedSdBar(value))}
          width={280}
          style={{ padding: sdBarCollapsed ? "28px 14px" : "37px 28px", height: "auto", borderRight: "1px solid #EDEFF3" }}
        >
          <SideBarMenu isLogoLetter={sdBarCollapsed} />
          <HowToUseCard isCollapsed={sdBarCollapsed} />
        </Sider>
        <Layout.Content style={{ width: "100%", padding: "0 auto" }}>
          <Header style={{ height: 80 }}>
            <DefaultHeader />
          </Header>
          {/* <Suspense fallback={<Loader size={"large"} style={{ padding: "50vh" }} />}> */}
          <Suspense fallback={<Loader size={"large"} style={{ padding: "50vh" }} />}>
            {firstTimeLoadedUser ? client?.new_payment_status === "none" ? <UnlockTrial /> : <Outlet /> : <Loader size={"large"} style={{ padding: "50vh" }} />}
            {/* {user?.id ? <Outlet />: <Loader size={"large"} style={{ padding: "50vh"}} /> }*/}
          </Suspense>
        </Layout.Content>
        {openParallelDialerModal && <ParallelDialerModal onCancel={() => setOpenParallelDialerModal(false)} />}
        {isViewingProcess && <ViewingProcess />}
      </Layout>
    )
  ) : (
    <>
      <Layout>
        <Suspense fallback={<Loader size={"large"} style={{ padding: "50vh" }} />}>
          {firstTimeLoadedUser ? (
            client?.new_payment_status === "none" ? (
              <UnlockTrial mobile />
            ) : (
              <Outlet />
            )
          ) : (
            <Loader size={"large"} style={{ padding: "50vh" }} />
          )}
        </Suspense>
      </Layout>
    </>
  );
};
// <Spin className={className ? `spinner ${className}` : "spinner"} {...props}>
//   <div className={"content"} />
// </Spin>

export { RegisteredLayout };
