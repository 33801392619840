import { MenuProps, Tooltip, message } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { FC, useEffect, useState } from "react";
import { TableHeader } from "src/components/ui/TableHeader";
import { SvgIcon } from "src/shared/icons";
import { App } from "src/types";
import { exportData } from "../../info";
import { DotsMenuDropdown, Table } from "../../../../ui";
import { useDispatch, useSelector } from "react-redux";
import tz from "moment-timezone";
import { addToRenameArr, prospectDataSelector, setDeletedFileIds } from "src/store/prospect_data";
import { downloadProspects, eraseProsDataFile, renameFileName } from "src/store/prospect_data/asyncRequests";
import { AppDispatch } from "src/store";
import { DeleteWarningModal } from "src/components/ui/DeleteWarningModal";
import { EditTextModal } from "src/components/ui/TextEditModal";

export const ExportsTable: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const formatUnixTimestamp = (unixTimestamp) => {
    const date = tz.unix(unixTimestamp).tz(tz.tz.guess());
    return date.format("MMMM Do 'YY [at] h:mm A");
  };
  const { exportFiles, deleteFileIds, editedFileNames } = useSelector(prospectDataSelector);

  const [exportFilesClean, setExportFilesClean] = useState([]);

  const handleSubmit = (value, id) => {
    console.log(value, "valuevalue");
    dispatch(addToRenameArr({ name: value?.editedtext, id }));
    dispatch(renameFileName({ name: value?.editedtext, id }));
    message.destroy();
    message.info("Updating file name");
    setOpenRenameItem({});
  };

  const deleteMeetingLink = (id) => {
    dispatch(setDeletedFileIds([...deleteFileIds, id]));
    dispatch(eraseProsDataFile({ id }));
    message.destroy();
    message.info("Deleting file");
    setOpenWarningItem({});
  };

  useEffect(() => {
    setExportFilesClean(
      exportFiles
        ?.filter((exp) => exp?.id !== "pending" && !deleteFileIds?.some((id) => exp?.id === id))
        ?.sort((a, b) => b.pendingUnix - a.pendingUnix)
        ?.map((exp) => {
          const editedFile = editedFileNames.find((edit) => edit.id === exp.id);
          if (editedFile) {
            return { ...exp, name: editedFile.name };
          }
          return exp;
        })
    );
  }, [exportFiles, deleteFileIds, editedFileNames]);

  const [openRenameItem, setOpenRenameItem] = useState({});
  const [openWarningItem, setOpenWarningItem] = useState({});
  const columns: ColumnsType<App.ExportEntity> = [
    {
      title: "Export Name",
      dataIndex: "name",
      key: "name",
      render: (text) =>
        text ? (
          text?.trim()?.length > 30 ? (
            <Tooltip placement={"left"} title={text}>
              {text?.slice(0, 30)}...
            </Tooltip>
          ) : (
            text?.trim()
          )
        ) : (
          <SvgIcon type={"minus"} />
        ),
    },
    {
      title: "Amount of Verified Prospects",
      dataIndex: "goodProspAmnt",
      key: "goodProspAmnt",
      align: "center",
    },
    {
      title: "Date Extracted",
      dataIndex: "pendingUnix",
      key: "pendingUnix",
      align: "center",
      render: (text) => formatUnixTimestamp(text),
    },
    {
      title: (
        <>
          Download{" "}
          <Tooltip
            placement={"top"}
            title={
              "You don't need to download your prospects to reach out to them in Meetz. Simply go to Campaigns and you'll be able to easily select which file from there."
            }
          >
            <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
          </Tooltip>
        </>
      ),
      render: (text, values) => (
        <SvgIcon
          type={"download"}
          onClick={() => {
            message.destroy();
            message.info("Downloading your prospects");
            dispatch(downloadProspects({ extract: values }));
          }}
          style={{ cursor: "pointer" }}
        />
      ),
      className: "download-btn",
      key: "download",
      align: "center",
    },
    {
      title: "Actions",
      key: "action",
      align: "center",
      render: (_, record) => <DotsMenuDropdown menuItems={items(record)} />,
    },
  ];

  const items = (record) =>
    [
      {
        key: "1",
        label: "Rename",
        onClick: () => setOpenRenameItem(record),
      },
      {
        key: "2",
        // danger: true,
        label: "Delete",
        onClick: () => setOpenWarningItem(record),
      },
    ] as MenuProps["items"];

  return (
    <React.Fragment>
      <TableHeader />
      {/* <TableHeader searchPlaceholder={"Search Prospect data..."} TODOF return prospect data search /> */}
      <Table rowClassName={"explore-row"} rowKey={"id"} columns={columns} dataSource={exportFilesClean} divHeight={""} />
      {openRenameItem?.id && (
        <EditTextModal
          handleSubmit={(values) => handleSubmit(values, openRenameItem?.id)}
          onClose={() => {
            setOpenRenameItem({});
          }}
          id={openRenameItem?.id}
          label={"File Name"}
          title={"Rename Prospect Export"}
          initialValue={openRenameItem?.name}
          placeholder={openRenameItem?.name}
        />
      )}
      {openWarningItem?.id && (
        <DeleteWarningModal
          handleDeleteBtnClick={deleteMeetingLink}
          onClose={() => {
            setOpenWarningItem({});
          }}
          id={openWarningItem?.id}
          title={"Are you sure you want to delete this file export?"}
          paragraph={"This action cannot be undone"}
        />
      )}
    </React.Fragment>
  );
};
