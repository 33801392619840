/* eslint-disable no-unused-vars */
 
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiHandler } from '../helpers/apiHandler';
import { stringifyResults } from '../util';

const updateResults = (result: any) => {
  let { schedule, meeting_description, prospect_schedule, default_meeting_title, is_integrated } = result;
  [schedule, meeting_description, prospect_schedule, default_meeting_title, is_integrated] = stringifyResults([
    schedule,
    meeting_description,
    prospect_schedule,
    default_meeting_title,
    is_integrated,
  ]);

  return { ...result, schedule, meeting_description, prospect_schedule, default_meeting_title, is_integrated };
}




export const addMeetingLink = createAsyncThunk('reactive-links/addMeetingLink', async (body, { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/reactive-links/addMeetingLink',
    method: 'post',
    body:{isTeam:false}
  });
  return res;
});


export const addCalBe = createAsyncThunk('meeting/addCalBe', async (body:any, { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/calendar/addMultipleCal',
    method: 'post',
    body,
  });
  return res;
});

export const removeCal = createAsyncThunk('meeting/removeCal', async (body:any, { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/calendar/removeCal',
    method: 'delete',
    body,
  });
  return res;
});


export const updateMeeting = createAsyncThunk('reactive-links/updateMeeting', async (body:any, { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/reactive-links/updateMeeting',
    method: 'post',
    body,
  });


  console.log(res,'123321123321');

  const settings  = updateResults(res?.settings)


  return {...res,settings};
});

export const zoomIntegrate = createAsyncThunk('integrations/zoomIntegrate', async (body:any, { getState }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/zoom/integrate',
    method: 'post',
    body,
  });

  return res;
});

// export const patchSettings = createAsyncThunk(
//   "meeting/patchSettings",
//   async (body: NonNullable<unknown>, { getState }: { getState: any }) => {
//     const { iframeJwt } = getState().helpers
//     const result = await apiHandler({
//       iframeJwt,
//       url: "/settings",
//       body,
//       method: "put",
//     })
//     if (result.error) {
//       throw new Error(result.error.message)
//     }
//     // console.log(result,'restul');

//     return {integrations : result?.integrations, schedulingFormated : result?.schedulingFormated}
//   }
// )

// export const addAnothLink = createAsyncThunk('reactive-links/addAnothLink', async (body,  { getState }:{getState:any}) => {
//   const { iframeJwt } = getState().helpers;
//   const result = await apiHandler({
//     iframeJwt,
//     url: '/reactive-links/addLink',
//     method: 'post',
//     body,
//   });

//   if (result.error) {
//     throw new Error(result.error.message);
//   }

//   result.teamLinks = updateResults(result?.teamLinks);
//   result.sortLinks = updateResults(result?.sortLinks);
//   return result;
// });

// export const getLinks = createAsyncThunk('reactive-links/getLinks', async (_,  { getState }:{getState:any}) => {
//   const { iframeJwt } = getState().helpers;
//   const result = await apiHandler({
//     iframeJwt,
//     url: '/reactive-links',
//     method: 'get',
//   });

//   if (result.error) {
//     console.log(result.error.message, 'result.error.message');
//     throw new Error(result.error.message);
//   }

//   result.teamLinks = updateResults(result?.teamLinks);
//   result.sortLinks = updateResults(result?.sortLinks);
//   console.log(result, 'result.error.message2w');
//   return result;
// });
export const removeLink = createAsyncThunk('reactive-links/remLink', async (body,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const result = await apiHandler({
    iframeJwt,
    url: '/reactive-links/remLink',
    method: 'post',
    body,
  });
  return result;
});

// export const patchLinks = createAsyncThunk('reactive-links/patchLinks', async (data,  { getState }:{getState:any}) => {
//   const { iframeJwt } = getState().helpers;
//   const result = await apiHandler({
//     iframeJwt,
//     url: '/reactive-links',
//     body: data,
//     method: 'put',
//   });

//   if (result.error) {
//     throw new Error(result.error.message);
//   }
//   console.log(result, 'result 2');
//   result.teamLinks = updateResults(result?.teamLinks);
//   result.sortLinks = updateResults(result?.sortLinks);
//   return result;
// });
// export const patchLinkQuestions = createAsyncThunk('reactive-links/patchLinkQuestions', async (data,  { getState }:{getState:any}) => {
//   const { iframeJwt } = getState().helpers;
//   const result = await apiHandler({
//     iframeJwt,
//     url: '/reactive-links/questions',
//     body: data,
//     method: 'put',
//   });

//   result.teamLinks = updateResults(result?.teamLinks);
//   result.sortLinks = updateResults(result?.sortLinks);
//   return result;
// });

// export const postLinkQuestions = createAsyncThunk('reactive-links/questions', async (data,  { getState }:{getState:any}) => {
//   const { iframeJwt } = getState().helpers;
//   const result = await apiHandler({
//     iframeJwt,
//     url: '/reactive-links/question',
//     body: data,
//     method: 'post',
//   });

//   result.teamLinks = updateResults(result?.teamLinks);
//   result.sortLinks = updateResults(result?.sortLinks);
//   return result;
// });











