/* eslint-disable react/jsx-fragments */
import { Button, Checkbox, CollapseProps, Divider, Flex, Form, Input, Radio, Row, Select, Slider, Switch, Tooltip, Typography, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageLayout from "src/components/layout/PageLayout";
import { Collapse, ConfirmButtons, ContentCard, CustomLabel, CustomSelect, Modal, TextEditor } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import { SaveValueModal, SelectFromSavedModal } from "src/components/pages/campaigns/CreateCampaign/ui/AIGenerated/ui/StepThree/ui";
import "./index.less";
import {
  callToActionsOptions,
  companyNameOptions,
  discussingPainOptions,
  helpingHandOptions,
  languageOptions,
  providingValueOptions,
  reactiveMeetingLinkOptions,
  salesMethodologyOptions,
  shortenEmailOptions,
} from "./infoData";
import { useDispatch, useSelector } from "react-redux";
import { outreachCampaignSelector } from "src/store/outreach-campaigns";
import { reactiveLinksSelector } from "src/store/reactive-links-settings";
import { validateNoSpecialChars } from "../../../../../StepOne";
import { updateTestEmailSettings, updateTestEmailSettingsWithEmail } from "src/store/outreach-campaigns/asyncRequests";

const { Text } = Typography;

export const TestAiSettings: FC = ({ testAiSettingsOpen, setTestAiSettingsOpen, newSeq }) => {
  const topLanguages = [
    // update on backedn if needed
    "English (US)",
    "Spanish",
    "French",
    "German",
    "Dutch",
    "Hebrew",
    "Mandarin Chinese",
    "Italian",
    "Hindi",
    "Arabic",
    "Ukrainian",
    "Bengali",
    "Greek",
    "English (UK)",
    "English (AU)",
    "English (CA)",
    "Czech",
    "Hungarian",
    "Danish",
    "Norwegian",
    "Finnish",
    "Bengali",
    "Bulgarian",
    "Russian",
    "Portuguese",
    "Swedish",
    "Japanese",
    "Punjabi",
    "Turkish",
    "Korean",
    "Vietnamese",
    "Polish",
  ];

  const [openSaveValueModal, setOpenSaveValueModal] = useState(false);
  const [openSelectFromSavedModal, setOpenSelectFromSavedModal] = useState(false);
  // const [shortenEmailCurrent, setShortenEmailCurrent] = useState(25)
  // const [helpingHandCurrent, setHelpingHandCurrent] = useState(50)
  // const [providingValueCurrent, setProvidingValueCurrent] = useState(75)
  // const [discussingPainCurrent, setDiscussingPainCurrent] = useState(100)

  const [loadedOnce, setLoadedOnce] = useState(false);
  const [selectedValProp, setSelectedValProp] = useState("");
  const [useLinks, setUseLinks] = useState(false);
  const [calToUse, setCalToUse] = useState();
  const [cta, setCta] = useState<string[]>([""]);
  const [savedValueProposition, setSavedValueProposition] = useState([]);
  const addCta = () => {
    setCta([...cta, ""]);
  };
  const remCta = (index) => {
    const newCta = [...cta];
    newCta.splice(index, 1);
    const bookMetWithLin = form.getFieldValue("callToAction");
    // console.log(newCta,index,cta,'ctaaaaaa',bookMetWithLin);
    setCta(newCta);
  };

  const { schedulingFormated } = useSelector(reactiveLinksSelector);
  const sortedMeetingLinks = [...schedulingFormated].sort((a, b) => (a.isAiGenerated === b.isAiGenerated ? 0 : a.isAiGenerated ? -1 : 1));
  const handeUseLinkChng = (e) => {
    form.validateFields(["callToAction"]);
    setUseLinks(e.target.checked);
  };

  const handleCalChange = (met) => {
    // console.log(met.target.value,'meetet');
    setCalToUse(met.target.value);
    form.setFieldsValue({ bookMetWithLink: met.target.value });
  };
  const [form] = Form.useForm();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let { aiSettings, testAiEmail, loadingGenTest, campaignDisplaying } = useSelector(outreachCampaignSelector);

  aiSettings = newSeq ? aiSettings : campaignDisplaying?.campaign?.ai_campaign_sales_settings || aiSettings;

  useEffect(() => {
    if (loadedOnce && selectedValProp?.length) {
      form.setFieldsValue({
        valueProposition: selectedValProp || "",
      });
    }
  }, [selectedValProp]);
  useEffect(() => {
    setSavedValueProposition(aiSettings?.value_props || []);
    if (aiSettings?.id && !loadedOnce) {
      console.log(aiSettings, "aiSettings");
      setLoadedOnce(true);
      setUseLinks(!!aiSettings?.link_in_email?.id);
      setCalToUse(aiSettings?.link_in_email?.id || "");
      setCta(aiSettings?.call_to_action_custom_list || [""]);

      // when i will separate the bare with phone and emails so this logic has to be implemented here

      form.setFieldsValue({
        special_instructions_subject: aiSettings?.special_instructions_subject || "",
        special_instructions_body: aiSettings?.special_instructions_body || "",
        emoji_subject: aiSettings?.emoji_subject || false,
        emoji_body: aiSettings?.emoji_body || false,
        sales_methodology: aiSettings?.sales_methodology || "consultative",
        email_length_ai_custom: aiSettings?.email_length_ai_custom || 50,
        helping_hand_ai_custom: aiSettings?.helping_hand_ai_custom || 75,
        providing_value_ai_custom: aiSettings?.providing_value_ai_custom || 25,
        discussing_pain_ai_custom: aiSettings?.discussing_pain_ai_custom || 50,
        valueProposition: aiSettings?.current_value_prop || [],
        companyName: aiSettings?.seller_company_name || "",
        jobTitle: aiSettings?.job_title || "",
        testimonials: aiSettings?.testimonial_quote || "",
        testimonialsWhoSaidIt: aiSettings?.testimonial_quote_whosaidit || "",
        language: aiSettings?.language_in_email || "English (US)",
        bookMetWithLink: aiSettings?.link_in_email?.id || sortedMeetingLinks?.length > 1 ? sortedMeetingLinks[1]?.id : "",
        callToAction: aiSettings?.call_to_action_custom_list || [],
      });
    }
  }, [aiSettings]);
  const handleCtaChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    form.validateFields(["callToAction"]);
    const newCta = [...cta];
    newCta[index] = event.target.value;
    setCta(newCta);
  };
  const navigate = useNavigate();

  const showSaveValueModal = () => {
    setOpenSaveValueModal(true);
  };

  const showSelectFromSavedModal = () => {
    setOpenSelectFromSavedModal(true);
  };

  const closeModal = () => {
    setOpenSaveValueModal(false);
    setOpenSelectFromSavedModal(false);
  };
  // const onShortenEmailCurrent = (value: number) => {
  //   setShortenEmailCurrent(value)
  // }
  // const onHelpingHandCurrent = (value: number) => {
  //   setHelpingHandCurrent(value)
  // }
  // const onProvidingValueCurrent = (value: number) => {
  //   setProvidingValueCurrent(value)
  // }
  // const onDiscussingPainCurrent = (value: number) => {
  //   setDiscussingPainCurrent(value)
  // }

  const [checkFailed, setCheckFailed] = useState(false);

  const validateSecondInput = async (_, value) => {
    const firstInputValue = form.getFieldValue("testimonials");
    if (firstInputValue && !value) {
      return Promise.reject(new Error("This field is required if the testimonial is provided"));
    }
    return Promise.resolve();
  };
  const handleTestimonialsChange = () => {
    const firstInputValue = form.getFieldValue("testimonials");
    if (!checkFailed || !firstInputValue) {
      form.validateFields(["testimonialsWhoSaidIt"]);
    }
  };
  const validateNoSpecialCharsArray = (_, value) => {
    console.log(value, "valeiuafd");
    if (!cta || cta.every((str) => /^[^\{\}\[\]]*$/.test(str))) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("No special characters allowed"));
  };
  const validateAtLeastOneCTA = (_, value) => {
    const oneCustomCta = cta?.some((callToAction) => callToAction?.trim()?.length);
    return oneCustomCta || useLinks ? Promise.resolve() : Promise.reject(new Error('Add at least one "Call To Action"'));
  };

  const dispatch = useDispatch();

  const allSettings = () => {
    form.submit();
  };

  const onFinish = (vals) => {
    console.log("Success:", vals);

    message.info("Saving Ai Email Settings");
    dispatch(updateTestEmailSettings({ ...(vals || {}), callToAction: cta }));
    // dispatch(setAiGenProp({...(vals||{}),callToAction:cta}))
    // onNext();
  };

  const handleGenerate = async () => {
    try {
      await form.validateFields();
    } catch (e) {
      const finFail = e?.errorFields?.length ? e?.errorFields : [];
      finFail?.forEach((err) => {
        err?.errors?.forEach((error) => {
          error?.length && message.error(error);
        });
      });
      return;
    }

    message.info("Generating email");
    const vals = form.getFieldsValue();
    console.log(vals, "valsvals");
    dispatch(updateTestEmailSettingsWithEmail({ ...(vals || {}), callToAction: cta }));
  };

  const onFinishFailed = (errorInfo) => {
    setCheckFailed(true);
    message.destroy();
    const finFail = errorInfo?.errorFields?.length ? errorInfo?.errorFields : [];
    finFail?.forEach((err) => {
      err?.errors?.forEach((error) => {
        error?.length && message.error(error);
      });
    });
  };

  const rightHeaderAiTest = (
    <ConfirmButtons width={"131px"} leftButtonTitle={null} rightButtonTitle={"Save AI Settings"} handleSubmit={() => console.log("Save")} />
  );

  const aiTestPageTitle = (
    <Row align={"middle"} style={{ gap: 16 }}>
      <SvgIcon type={"arrowBack"} onClick={() => navigate("/campaign/ai-generated")} style={{ cursor: "pointer" }} />
      <Typography.Title level={2} className={"page-title"}>
        {"Test AI Settings"}
      </Typography.Title>
    </Row>
  );
  // messaging + methodology + subjectline + emailbody + emoji + language
  const items: CollapseProps["items"] = [
    // TODO BUG ANY ADDITIONAL PROP HAS TO BE ADDED TO THE STUPID stupidFormHelper variable
    {
      key: "1",
      label: "Messaging Style",
      children: (
        <>
          <Flex className={"options-bar-container"} vertical>
            <Text className={"options-bar-title"}>
              Email Length
              <Tooltip
                placement={"right"}
                title={
                  <>
                    Short cold emails are better unless they are personalized. When the emails are personalized we found the opens and responses are higher even
                    if it's a longer email, it will allow room for the Ai to personalize and further explain your value proposition.
                    <br />
                    <br />
                    Recommended to keep at "Moderate" or "Long" length.
                  </>
                }
              >
                <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
              </Tooltip>
            </Text>
            <Form.Item name={"email_length_ai_custom"}>
              <Slider
                tooltip={{ formatter: null }}
                className={"options-bar"}
                // onChange={onShortenEmailCurrent}
                marks={shortenEmailOptions}
                step={null}
                // defaultValue={shortenEmailCurrent}
              />
            </Form.Item>
          </Flex>
          <Flex className={"options-bar-container"} vertical>
            <Text className={"options-bar-title"}>
              Helping Hand{" "}
              <Tooltip
                placement={"right"}
                title={<>This puts the email in the type of tone that is coming to help the prospect with their current processes.</>}
              >
                <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
              </Tooltip>
            </Text>
            <Form.Item name={"helping_hand_ai_custom"}>
              <Slider
                tooltip={{ formatter: null }}
                className={"options-bar"}
                // onChange={onHelpingHandCurrent}
                marks={helpingHandOptions}
                step={null}
                // defaultValue={helpingHandCurrent}
              />
            </Form.Item>
          </Flex>
          <Flex className={"options-bar-container"} vertical>
            <Text className={"options-bar-title"}>
              Providing Value
              <Tooltip placement={"right"} title={<>Will put more emphasis on your testimonials and use cases your value proposition have.</>}>
                <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
              </Tooltip>
            </Text>
            <Form.Item name={"providing_value_ai_custom"}>
              <Slider
                tooltip={{ formatter: null }}
                className={"options-bar"}
                // onChange={onProvidingValueCurrent}
                marks={providingValueOptions}
                step={null}
                // defaultValue={providingValueCurrent}
              />
            </Form.Item>
          </Flex>
          <Flex className={"options-bar-container"} vertical>
            <Text className={"options-bar-title"}>
              Discussing Pain
              <Tooltip placement={"right"} title={<>Puts the focus on the pains associated with your prospects current workflow without your solution.</>}>
                <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
              </Tooltip>
            </Text>
            <Form.Item name={"discussing_pain_ai_custom"}>
              <Slider
                tooltip={{ formatter: null }}
                className={"options-bar"}
                // onChange={onDiscussingPainCurrent}
                marks={discussingPainOptions}
                step={null}
                // defaultValue={discussingPainCurrent}
              />
            </Form.Item>
          </Flex>
        </>
      ),
    },
    {
      key: "2",
      label: "Call To Action",
      children: (
        <Flex gap={10} className={"card-content"} vertical>
          <Form.Item name={"callToAction"} rules={[{ validator: validateAtLeastOneCTA }, { validator: validateNoSpecialCharsArray }]}>
            {" "}
            <div>
              <CustomLabel
                title={
                  <>
                    Call to action{" "}
                    <Tooltip
                      placement={"bottom"}
                      title={
                        <>
                          <b>Best practices for call to actions:</b>
                          <br />
                          <br />
                          <b>1.</b> <u>Add several CTA to let Meetz test them all:</u> Every line NEEDS to be a separate CTA.
                          <br />
                          <br />
                          <b>2.</b> <u>Give an easy yes or no question + add value if they say yes:</u> The yes or no question will trigger auto responses for
                          them in Gmail/Outlook making it easy for your prospects to click "Yes, I'm interested" and they can get value if they respond.
                          <br />
                          <br />
                          Example CTA: Is this of interest? I'm happy to send out a quick video showing how we can help your business.
                        </>
                      }
                    >
                      <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                    </Tooltip>
                  </>
                }
              />
              <Flex className={"collapse-card-content"} gap={10} vertical>
                {cta?.map((callToA, index) => {
                  return (
                    <>
                      {" "}
                      <Flex justify="flex-start">
                        {" "}
                        <Input
                          onChange={(event) => handleCtaChange(index, event)}
                          className={"content-input"}
                          placeholder={"Would this be of interest?"}
                          value={callToA}
                        />
                        {cta?.length < 6 && index === cta?.length - 1 && (
                          <SvgIcon
                            type={"plus"}
                            style={{ margin: "auto", cursor: "pointer" }}
                            onClick={() => {
                              addCta();
                            }}
                          />
                        )}
                        {cta?.length > 1 && (
                          <SvgIcon
                            type={"closeTag"}
                            style={{ margin: "auto", cursor: "pointer" }}
                            onClick={() => {
                              remCta(index);
                            }}
                          />
                        )}
                      </Flex>
                    </>
                  );
                })}
                <Text> And </Text>
                <Checkbox checked={useLinks} onChange={handeUseLinkChng}>
                  Book meeting call to action
                </Checkbox>
                {useLinks && (
                  <Form.Item name={"bookMetWithLink"} style={{ marginLeft: "25px" }}>
                    <div>
                      <div style={{ fontWeight: 600 }}>
                        Meetz scheduling links:
                        <Tooltip placement={"topLeft"} title={<>You can edit these links or add more in the "Scheduling Links" tab.</>}>
                          <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                        </Tooltip>
                      </div>
                      <Radio.Group style={{ display: "flex", flexDirection: "column" }} onChange={handleCalChange} value={calToUse}>
                        {sortedMeetingLinks?.length > 1
                          ? sortedMeetingLinks?.map((mt, i) => {
                              // TODON if no selected use the first one as a default.
                              if (!i) {
                                return;
                              }
                              return (
                                <Radio value={mt.id} key={mt.id} style={{ margin: "3px" }}>
                                  {mt?.name?.length > 200 ? mt?.name?.substring(0, 200) + "..." : mt?.name} - {`${mt.meetingLength / 60000} min`}
                                </Radio>
                              );
                            })
                          : ""}
                        {/* <Checkbox.Group className={"content-checkbox-group"} options={callToActionsOptions} /> */}
                      </Radio.Group>
                    </div>
                  </Form.Item>
                )}{" "}
              </Flex>
            </div>
          </Form.Item>
        </Flex>
      ),
    },

    {
      key: "3",
      label: "Sales Methodology",
      children: (
        <Flex className={"collapse-card-content"} gap={10} vertical>
          <Text>Style of email template</Text>
          <Form.Item name={"sales_methodology"}>
            <Checkbox.Group className={"content-checkbox-group"} options={salesMethodologyOptions} />
          </Form.Item>
        </Flex>
      ),
    },
    // { TODOF later
    //   key: "5",
    //   label: "Naturalize Company Name",
    //   children:
    //     <Flex className={"collapse-card-content"} gap={10} vertical>
    //       <Text>Select how to naturalize the company name to make it more personalized:</Text>
    //       <Checkbox.Group className={"content-checkbox-group"} options={companyNameOptions} />
    //     </Flex>
    // },

    {
      key: "4",
      label: "Custom Subject Line Instructions",
      children: (
        <Flex className={"collapse-card-content"} gap={10} vertical>
          <Form.Item name={"special_instructions_subject"}>
            <Input className={"content-input"} placeholder={"Ex: Make the subject line less than 4 words long"} />
          </Form.Item>
        </Flex>
      ),
    },
    {
      key: "5",
      label: "Custom Email Body Instructions",
      children: (
        <Flex className={"collapse-card-content"} gap={10} vertical>
          <Form.Item name={"special_instructions_body"}>
            <Input className={"content-input"} placeholder={"Ex: Talk about how my consultation can help improve their business finances"} />
          </Form.Item>
        </Flex>
      ),
    },
    {
      key: "6",
      label: "Emoji's 😊",
      children: (
        <Flex className={"collapse-card-content"} gap={10} vertical>
          <Flex gap={15}>
            <Form.Item name={"emoji_subject"}>
              <div>
                {" "}
                <Switch />
                <Text style={{ marginLeft: "10px" }}> Include emojis in the email subject line</Text>
              </div>
            </Form.Item>
          </Flex>
          <Flex gap={15}>
            <Form.Item name={"emoji_body"}>
              <div>
                {" "}
                <Switch />
                <Text style={{ marginLeft: "10px" }}> Include emojis in the email body</Text>
              </div>
            </Form.Item>
          </Flex>
        </Flex>
      ),
    },
    {
      key: "7",
      label: "Language",
      children: (
        <Flex className={"collapse-card-content"} gap={10} vertical>
          <Form.Item name={"language"}>
            <CustomSelect options={topLanguages} defaultValue={"English (US)"} />
          </Form.Item>
        </Flex>
      ),
    },
  ];

  // cant read form if not open initially. THIS is an antd bug this is how to bypass it
  const [stupidFormHelper, setstupidFormHelper] = useState(["1", "2", "3", "4", "5", "6", "7"]);

  useEffect(() => {
    setstupidFormHelper(["1", "2", "3", "4", "5", "6", "7"]);

    setTimeout(() => {
      setstupidFormHelper(undefined);
    }, 500);
  }, [testAiSettingsOpen]);

  const [testBody, setTestBody] = useState(null);
  const [testSubject, setTestSubject] = useState(null);

  useEffect(() => {
    setTestBody(testAiEmail?.body);
    setTestSubject(testAiEmail?.subject);
  }, [testAiEmail]);

  // TODOFF in a/b test lock out of platform after 2/4 days to force them to pay if they want to use Meetz as part of the testing + 30 day guarantee money back?
  return (
    // <PageLayout pageTitle={aiTestPageTitle} rightHeaderContent={rightHeaderAiTest} isTableContent={false}>
    <Modal
      title={"Test Ai Personalized Emails"}
      open={testAiSettingsOpen}
      onCancel={() => setTestAiSettingsOpen(false)}
      width={"fit-content"}
      // less than 95% hides the ai personalized email. no good
      height={"80%"}
    >
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Flex className={"test-ai-container"} wrap={"wrap"} gap={20}>
          <ContentCard
            headerIcon={<SvgIcon type={"settings"} />}
            cardTitle={"Ai Personalized Email Settings"}
            headerRightContent={
              <Button className={"primary"} onClick={allSettings}>
                Save All Settings
              </Button>
            }
          >
            <Flex gap={10} className={"card-content"} vertical>
              <Row justify={"space-between"}>
                <Text className={"content-subtitle"}>
                  <div>
                    Value Proposition
                    <Tooltip
                      placement={"right"}
                      title={
                        <>
                          <b>What if I have more than 1 value proposition?</b>
                          <br />
                          <br />
                          There are 2 ways to go about it depending on your service and your prospects.
                          <br />
                          <br />
                          <b>1.</b>
                          <u> If all your value propositions fit the same audience:</u> Then add all of them here and our personalization will automatically use
                          what is relevant to that specific client.
                          <br />
                          <br />
                          <b>2.</b> <u>If your value propositions are relevant to different types of prospects:</u> Then use only the value propostion per
                          campaign and 1 type of prospects per campaign.
                          <br />
                          <br />
                          Tip: List as much details as possible about your service and how it helps your clients.
                        </>
                      }
                    >
                      <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                    </Tooltip>
                  </div>
                </Text>
                <Row align={"middle"}>
                  <Button className={"link"} onClick={showSaveValueModal}>
                    Save Value Prop
                  </Button>
                  <Divider type={"vertical"} />
                  <Button className={"link"} onClick={showSelectFromSavedModal}>
                    Select From Saved
                  </Button>
                </Row>
              </Row>
              <Text className={"content-description"}>
                Add The Main Key Benefit Clients Would Get By Using Your Company's Service/Product And The Specific Feature That Delivers It.
                <br />
                <br />
                *Include a complete explanation about your value proposition even if it's long
              </Text>
              <Form.Item
                name={"valueProposition"}
                rules={[{ required: true, message: `Please enter a value proposition` }, { validator: validateNoSpecialChars }]}
              >
                <Input.TextArea placeholder={"Meetz easily optimizes outreach with automated A/B tests and using Ai personalized emails."} />
              </Form.Item>
            </Flex>
            <Flex gap={10} className={"card-content"} vertical>
              <Text className={"content-subtitle"}>Keep It Brief</Text>
              <Text className={"content-description"}>
                Add A Quote Your Customers Gave With "" Or Add The ROI Your Customer Got From Your Product Or Services, include Your Customers Company Name.
              </Text>
              <Form.Item name={"testimonials"} rules={[{ validator: validateNoSpecialChars }]}>
                <Input.TextArea
                  placeholder={`We felt how powerful Meetz is within 5 minutes of signing up! In the first campaign we already got a few great demos.`}
                  onChange={handleTestimonialsChange}
                />
              </Form.Item>
              <Form.Item name={"testimonialsWhoSaidIt"} rules={[{ validator: validateSecondInput }, { validator: validateNoSpecialChars }]}>
                <Input placeholder={`Rob, VP of Sales at Acmef`} />
              </Form.Item>
            </Flex>

            <Flex gap={10} className={"card-content"} vertical>
              <Text className={"content-subtitle"}>Your Job Title</Text>{" "}
              <Form.Item name={"jobTitle"} rules={[{ validator: validateNoSpecialChars }]}>
                <Input className={"content-input"} placeholder={"SDR/ Account Executive/ VP Of Sales/ Founder"} />
              </Form.Item>
            </Flex>
            <Flex gap={10} className={"card-content"} vertical>
              <Text className={"content-subtitle"}>Your Company's Name</Text>
              <Form.Item name={"companyName"} rules={[{ required: true, message: `Please enter a company name` }, { validator: validateNoSpecialChars }]}>
                <Input className={"content-input"} placeholder={"Meetz"} />
              </Form.Item>
            </Flex>
          </ContentCard>
          <ContentCard
            headerIcon={<SvgIcon type={"sendEmail"} />}
            cardTitle={
              <>
                Personalized Email Example
                <Tooltip
                  placement={"top"}
                  title={
                    <>
                      Click "Generate Test Email" to understand how the Ai personalized emails will look like. Adjust on the left side to change the Ai output
                      and get the feel you are looking for.
                      <br />
                      <br />
                      You can't directly edit the below email since every email is unique and personalized to your prospects based on their LinkedIn/ Company
                      information/ online activity. Rather adjust the instruction on the left side of this page. <br />
                      If you want full control over the email that will be sent, use the "Templated Email" step instead of the "Ai personalized email".
                    </>
                  }
                >
                  <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                </Tooltip>
              </>
            }
            headerRightContent={
              <Button className={"link"} onClick={!loadingGenTest && handleGenerate} disabled={loadingGenTest}>
                Generate Test Email
              </Button>
            }
          >
            <Flex className={"card-content"} gap={10} vertical>
              <Text className={"content-subtitle"}>Subject Line</Text>
              {/* Generate Test Emial (Link) */}
              <Input className={"content-input-disabled"} placeholder={'Subject line -   Click "Generate Test Email" '} value={testSubject || ""} readOnly />
            </Flex>
            <Flex className={"card-content"} gap={10} vertical>
              <Text className={"content-subtitle"}>Email Body</Text>
              <TextEditor
                readOnly
                content={testBody || 'Email Body - Click "Generate Test Email" to see an example Ai email according to your settings'}
                placeholderText={'Email Body - Click "Generate Test Email" to see an example Ai email according to your settings'}
                isMaxHeight
              />
              {/* <TextArea
              className={"content-input"}
              // autoSize 
              readOnly
              placeholder={
                "Email Body - Click \"Generate Test Email\" to see an example Ai email according to your settings"
              } 
              value={testAiEmail?.body||''} 
              rows={10}
            /> */}
            </Flex>
          </ContentCard>
          <ContentCard
            headerIcon={<SvgIcon type={"flash"} />}
            cardTitle={"Messaging Tone and Advanced Options"}
            //  style={{marginRight:'auto'}}
          >
            <Collapse customClassName={"collapse-card-content"} initialOpenKey={stupidFormHelper} items={items} />
          </ContentCard>
          {/* form doesnt render items without an initialopenkey */}
        </Flex>
        <SaveValueModal defaultVal={form.getFieldValue("valueProposition")} open={openSaveValueModal} onCancel={closeModal} />
        <SelectFromSavedModal open={openSelectFromSavedModal} onCancel={closeModal} data={savedValueProposition} setSelectedValProp={setSelectedValProp} />
      </Form>
    </Modal>
    // </PageLayout>
  );
};
