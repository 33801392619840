/* eslint-disable react/jsx-fragments */
import { Button, Row, Typography, message } from "antd";
import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { CampaignsContext } from "src/components/app/context/CampaignsContext"
import PageLayout from "src/components/layout/PageLayout";
import { StepThree, StepTwo } from "../AIGenerated/ui";
import { SequenceCreatedModal, StepsInCampaign, AddStepPage, NewCampaign } from "./ui";
import { ConfirmButtons, PageTitle, TabsSwitch } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import { useSelector } from "react-redux";
import { outreachCampaignSelector } from "src/store/outreach-campaigns";

type Page = {
  key: string;
  page: number;
  title: ReactNode;
  component: ReactNode;
  rightHeaderContent?: ReactNode;
  bottomHeaderContent?: ReactNode;
};

export const FromScratch: FC = () => {
  const [page, setPage] = useState<number>(1);
  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setActiveTab] = useState("Overview");
  // const { campaignName, isStepsInCampaignPage, setIsStepsInCampaignPage, selectedStep, setSelectedStep } = useContext(CampaignsContext)
  const navigate = useNavigate();

  // useEffect(() => {
  //   isStepsInCampaignPage && onNextPage()
  //   setIsStepsInCampaignPage(false)
  // }, [isStepsInCampaignPage])

  const handleBack = () => {
    navigate("/campaigns");
    // setSelectedStep("")
  };

  const showModal = () => {
    setOpenModal(true);
  };

  // const onNextPage = () => {
  //   if (page !== pages?.length) {
  //     setPage(prevState => prevState + 1)
  //   }
  // }
  const onPreviousPage = () => {
    setPage((prevStep) => prevStep - 1);
  };

  // const pages: Page[] = [
  //   {
  //     key: "addStepPage",
  //     page: 0,
  //     title: <PageTitle title={campaignName} onClick={handleBack}/>,
  //     component: <AddStepPage />
  //   },
  //   { key: "stepsInCampaign",
  //     page: 1,
  //     title: <PageTitle title={`Steps in ${campaignName}`} onClick={onPreviousPage}/>,
  //     rightHeaderContent: isStepsInCampaignPage || selectedStep ? (
  //         <Button className={"primary"} onClick={showModal}>
  //           Create Sequence
  //         </Button>
  //       ) : null,
  //     component: <StepsInCampaign selectedStep={selectedStep} />
  //   },
  //   { key: "addProspectsPage",
  //     page: 2,
  //     title: <PageTitle title={"Add prospects"} onClick={onPreviousPage}/>,
  //     rightHeaderContent:
  //       <ConfirmButtons
  //         width={250}
  //         rightButtonTitle={"Save & Create Campaign"}
  //         leftButtonTitle={"Back"}
  //         onCancel={onPreviousPage}
  //         handleSubmit={()=>{{
  //           onNextPage()
  //           message.success("New campaign created")
  //         }
  //         }}
  //           />,
  //     component: <StepTwo/>
  //   },
  //   { key: "newCampaign",
  //     page: 3,
  //     title: <PageTitle title={campaignName} onClick={onPreviousPage} hasAction/>,
  //     bottomHeaderContent:
  //     <>
  //       <TabsSwitch firstTab={"Overview"} secondTab={"Emails"} thirdTab={"Phone"} activeTab={activeTab} setActiveTab={setActiveTab}/>
  //       <Button className={"primary"} onClick={()=> navigate("/prospects-data")}>
  //         Prospects
  //       </Button>
  //     </>
  //    ,
  //     component: <NewCampaign />
  //   },
  // ]

  const pageTitle = (
    <Row align={"middle"} style={{ gap: 16 }}>
      <SvgIcon type={"greyCancel"} onClick={() => navigate("/campaigns")} style={{ cursor: "pointer", marginTop: "3px" }} />
      <Typography.Title level={2} className={"page-title"}>
        {"Creating Campaign From Scratch"}
      </Typography.Title>
    </Row>
  );

  const onCancel = () => {
    setOpenModal(false);
  };
  const nextStep = () => {
    // setOpenModal(false)
  };
  // const onCancel = () => {
  //   setOpenModal(false)
  // }

  const [toggleHandleNextStep, setToggleHandleNextStep] = useState(false);

  // const [invalidSteps, setInvalidSteps] = useState([]);
  const handleNextStep = async () => {
    if (!toggleHandleNextStep) {
      setToggleHandleNextStep(false);
      await setTimeout(() => {
        setToggleHandleNextStep(true);
      }, 300);
      return;
    }

    setToggleHandleNextStep(true);
    // if (formSubmitRef.current) {
    //   formSubmitRef.current();
    // } else {
    //   // nextStep();
    // }
  };

  useEffect(() => {
    if (toggleHandleNextStep) {
      setToggleHandleNextStep(false); // making sure it will always reset sometimes it doesnt
    }
  }, [toggleHandleNextStep]);

  const rightHeaderCreateContent = (
    <ConfirmButtons
      width={"260px"}
      leftButtonTitle={null}
      rightButtonTitle={"Save & Create Campaign"}
      // onCancel={onPreviousStep}
      handleSubmit={handleNextStep}
      // handleSubmit={step!== steps.length - 1 ? onNextStep : onFinish}
    />
  );

  return (
    <>
      <PageLayout
        pageTitle={pageTitle}
        // bottomHeaderContent={<CreateCampaignsStepsProgress currentStep={step} />}
        rightHeaderContent={rightHeaderCreateContent}
        isTableContent={false}
      >
        <StepThree
          step={2}
          toggleHandleNextStep={toggleHandleNextStep}
          setToggleHandleNextStep={setToggleHandleNextStep}
          // setInvalidSteps={setInvalidSteps}
          from={"scratch"}
        />
      </PageLayout>
    </>
  );
  // return (
  // <PageLayout
  //   pageTitle={pages[page].title}
  //   rightHeaderContent={pages[page].rightHeaderContent}
  //   bottomHeaderContent={pages[page].bottomHeaderContent}
  //   isTableContent={!selectedStep}
  // >
  //   {pages[page].component}
  //   <SequenceCreatedModal open={openModal} onCancel={onCancel} onNextPage={onNextPage} />
  // </PageLayout>
  // )
};
