/* eslint-disable react/jsx-fragments */
import { Button, Flex, Form, Input, TimePicker, Tooltip, Typography, message, notification } from "antd"
import TextArea from "antd/es/input/TextArea"
import _ from 'lodash';
import React, { FC, useContext, useEffect, useState } from "react"
import { Collapse, Drawer } from "src/components/ui"
import { generateRandomDigits, saveToClipboard } from "../../../../../helpers"
import { SvgIcon } from "../../../../../shared/icons"
import { MeetingLinksContext } from "../../../../app/context/MeetingLinksContext"
import { CustomSelect } from "../../../../ui"
import { WeekTimePlannerWithTabs } from "../../../../ui/WeekTimePlannerWithTabs"
import { AddVariableModal } from "../../../campaigns/CreateCampaign/ui/AIGenerated/ui/StepThree/ui"
import "./index.less"
import dayjs from "dayjs"
import { fullUserSelector, settingsSelector, userSelector } from "src/store/user";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import { isSummerTimeChecker } from "src/store/util";
import { SelectFilterItem } from "src/components/pages/prospect-data/ui/ProspectFilterCollapse/ui";
import { updateMeeting } from "src/store/reactive-links-settings/asyncRequests";

interface MeetingLinkDrawerProps {
  isEditAiMeeting: boolean
  isNewMeeting?: boolean
  onClose: () => void
}

const reminderTimeOptions = [
  // {
  //   label: "None",
  //   value: "None",
  // },
  {
    label: "15 Min",
    value: 900000,
  },
  {
    label: "30 Min",
    value: 1800000,
  },
  {
    label: "1 Hour",
    value: 3600000,
  },
  {
    label: "2 Hours",
    value: 7200000,
  },
  {
    label: "3 Hours",
    value: 10800000,
  },
  {
    label: "8 Hours",
    value: 28800000,
  },
  // {
  //   label: "1 Day",
  //   value: "1 Day",
  // },
  // {
  //   label: "2 Days",
  //   value: "2 Days",
  // },
]

// const timezoneOptions = [
//   {
//     label: "American/New_York EST",
//     value: "American/New_York EST",
//   },
//   {
//     label: "Option 2",
//     value: "Option 2",
//   },
//   {
//     label: "Option 3",
//     value: "Option 3",
//   },
// ]

const maximumDaysToScheduleForwardOptions = [
  {
    label: "No Max Time",
    value: 0,
  },
  {
    label: "3 Days",
    value: "3 Days",
  },
  {
    label: "4 Days",
    value: "4 Days",
  },
  {
    label: "5 Days",
    value: "5 Days",
  },
  {
    label: "6 Days",
    value: "6 Days",
  },
  {
    label: "7 Days",
    value: "7 Days",
  },
  {
    label: "8 Days",
    value: "8 Days",
  },
  {
    label: "9 Days",
    value: "9 Days",
  },
  {
    label: "10 Days",
    value: "10 Days",
  },
  {
    label: "15 Days",
    value: "15 Days",
  },
  {
    label: "30 Days",
    value: "30 Days",
  },
  {
    label: "60 Days",
    value: "60 Days",
  },
  {
    label: "120 Days",
    value: "120 Days",
  },
  {
    label: "180 Days",
    value: "180 Days",
  },
  {
    label: "1 Year",
    value: "1 Year",
  },
]

const minimumTimeToBookInAdvanceOptions = [
  {
    label: "0 Min",
    value: 0,
  },
  {
    label: "5 Min",
    value: 300,
  },
  {
    label: "10 Min",
    value: 600,
  },
  {
    label: "15 Min",
    value: 900,
  },
  {
    label: "30 Min",
    value: 1800,
  },
  {
    label: "45 Min",
    value: 2700,
  },
  {
    label: "1 Hour",
    value: 3600,
  },
  {
    label: "2 Hours",
    value: 7200,
  },
  {
    label: "3 Hours",
    value: 10800,
  },
  {
    label: "4 Hours",
    value: 14400,
  },
  {
    label: "5 Hours",
    value: 18000,
  },
  {
    label: "6 Hours",
    value: 21600,
  },
  {
    label: "7 Hours",
    value: 25200,
  },
  {
    label: "8 Hours",
    value: 28800,
  },
  {
    label: "9 Hours",
    value: 32400,
  },
  {
    label: "10 Hours",
    value: 36000,
  },
  {
    label: "11 Hours",
    value: 39600,
  },
  {
    label: "12 Hours",
    value: 43200,
  },
  {
    label: "16 Hours",
    value:57600,
  },
 
  {
    label: "20 Hours",
    value:72000,
  },
  {
    label: "1 Day",
    value:86400,
  },
  {
    label: "2 Days",
    value:172800,
  },
  {
    label: "3 Days",
    value:259200,
  },
  {
    label: "1 Week",
    value:604800,
  },
  {
    label: "2 Weeks",
    value:1209600,
  },
]

const bufferTimeBetweenMeetings = [
  {
    label: "0 Min",
    value: 0,
  },

  {
    label: "5 Min",
    value: 300000,
  },
  {
    label: "10 Min",
    value: 600000 ,
  },
  {
    label: "15 Min",
    value: 900000 ,
  },
  {
    label: "30 Min",
    value: 1800000 ,
  },
  {
    label: "45 Min",
    value: 2700000 ,
  },
  {
    label: "1 Hour",
    value: 3600000 ,
  },
  {
    label: "2 Hours",
    value: 7200000 ,
  },
  {
    label: "3 Hours",
    value:10800000 ,
  },
]

const questionRequiredOptions = [
  {
    label: "Required",
    value: "Required",
  },
  {
    label: "Not Required",
    value: "Not Required",
  },
]

const questionTypeOptions = [
  {
    label: "Opened Question",
    value: "Opened Question",
  },
  {
    label: "Multiple Choice Question",
    value: "Multiple Choice Question",
  },
]

export const MeetingLinkDrawer: FC<MeetingLinkDrawerProps> = ({ isEditAiMeeting,isNewMeeting, onClose }) => {
  const { meetingLinks, selectedMeetingLinkId, addMeetingLink, editMeetingLink } = useContext(MeetingLinksContext)
  // const [meetingLinkData, setMeetingLinkData] = useState<any>(
  //   isNewMeeting
  //     ? {
  //         id: generateRandomDigits(10),
  //         name: "Intro Meeting",
  //         description: "Meeting powered by Meetz.Ai",
  //         tags: [],
  //         links: [
  //           {
  //             text: "",
  //             href: `App.Meetz.Ai/Cal/${generateRandomDigits(10)}`,
  //           },
  //         ],
  //         reminders: {},
  //         meetingLength: "15 Min",
  //         timezone: "American/New_York EST",
  //         maximumDaysToScheduleForward: "15 Days",
  //         minimumTimeToBookInAdvance: "0 Min",
  //         bufferTimeBetweenMeetings: "0 Min",
  //         isOutlined: false,
  //       }
  //     : meetingLinks.find(el => el.id === selectedMeetingLinkId)
  // )

  const is_dst = isSummerTimeChecker();
  const { user, settings, timezones }= useSelector(fullUserSelector);
  const displayValueGetter = (option: { name: any; abbr_summer: any; abbr_winter: any; }) => {
      const res = is_dst ? `${option.name} ${option.abbr_summer}` : `${option.name} ${option.abbr_winter}`;

      return res;
    };
  const { timezone,timezone_abbr } =settings
  const { first_name } = user
const dispatch = useDispatch<AppDispatch>()

   const [timezoneSelected, settimezoneSelected] = useState<any>('')
   const [timezoneOptions, settimezoneOptions] = useState<any[]>([])
   
  const handleChangeTimeZone = e => {
    settimezoneSelected(e.split(" ")[0])
  }
    useEffect(() => {
   
    if (timezones.length) {
     console.log(timezone,'timezonetimezonetimezone');
      
      
    }

  }, [timezones]);

  useEffect(() => {
    if(timezone?.length){
    form.setFieldsValue({timezone})
    settimezoneSelected(timezone)}
  }, [timezone]);

  useEffect(() => {console.log(timezones,'timezones');
   if(timezones?.length){settimezoneOptions([...timezones.map((tmz)=>({label:displayValueGetter(tmz),value:displayValueGetter(tmz)}))])}
  }, [timezones]);


 const [meetingLinkData, setMeetingLinkData] = useState<any>(()=>{
const meting=meetingLinks.find(el => el.id === selectedMeetingLinkId)


if(meting){
console.log(meetingLinks,'meting.minimumTimeToBookInAdvance');
return {  
  first_name:first_name||'',
  links:meting?.links||'',
  name:meting?.name?.value||meting?.name||'' ,
  description:meting?.description||'',
  meetingLength:meting.meetingLength||0,
  // schedule:meting.schedule,
  schedule: Object.fromEntries(Object.entries(meting?.schedule).map(([key, value]) => [key, value.map(timerange => ([  dayjs(timerange.start, "HH:mm"), dayjs(timerange.end, "HH:mm")] ))])),
  prospectSchedule: [dayjs(meting.prospectSchedule?.start, "H:mm"), dayjs(meting.prospectSchedule?.end, "H:mm")],
  amountOfFollowUps:meting.amountOfFollowUps||0,
  timezone,
  followDelays:meting.followDelays||0,
  maximumTimeslotsToOfferPerDay:meting.maxTimeslotsPerDay,
  maximumDaysToScheduleForward:meting.maximumDaysToScheduleForward||0,
  minimumTimeToBookInAdvance:meting.minimumTimeToBookInAdvance||0, 
  bufferTimeBetweenMeetings:meting.bufferTimeBetweenMeetings||0,
  questionsForGuests:meting.questions || []
}}else{ return {}}
 })

 const handleUpdateSched= (newSched)=>{
  const deepCopiedData = _.cloneDeep(meetingLinkData);

  deepCopiedData.schedule = newSched
setMeetingLinkData(deepCopiedData)
 }

//  useEffect(() => { BUG tried making this update in real time but doesnt work might be too complex, might need to optimistically update it in the redux object
// const meting=meetingLinks.find(el => el.id === selectedMeetingLinkId)
// if(meting){
//   const newSched = {schedule:Object.fromEntries(Object.entries(meting?.schedule).map(([key, value]) => [key, value.map(timerange => ([  dayjs(timerange.start, "HH:mm"), dayjs(timerange.end, "HH:mm")] ))]))}
//   form.setFieldsValue(newSched)

//     const deepCopiedData = _.cloneDeep(meetingLinkData);

//   deepCopiedData.schedule = newSched.schedule
// setMeetingLinkData(deepCopiedData)
// }
//  }, [meetingLinks]);
 useEffect(() => {
  
  console.log(meetingLinkData?.schedule,'testings123 data updated on main screen');
 }, [meetingLinkData]);


//  useEffect(() => {
  
// const meting=meetingLinks.find(el => el.id === selectedMeetingLinkId)
// if(meting){

// setMeetingLinkData({  
//   links:meting?.links,
//   name:meting?.name?.value||meting?.name ,
//   description:meting?.description,
//   meetingLength:meting.meetingLength,
//   schedule:meting.schedule,
//   prosSchedule:meting.prospectSchedule,
//   amountOfFollowUps:meting.amountOfFollowUps,
//   followDelays:meting.followDelays,
//   maximumTimeslotsToOfferPerDay:meting.maxTimeslotsPerDay,
//   maximumDaysToScheduleForward:meting.maximumDaysToScheduleForward,
//   minimumTimeToBookInAdvance:meting.minimumTimeToBookInAdvance, 
//   bufferTimeBetweenMeetings:meting.bufferTimeBetweenMeetings,
//   questionsForGuests:meting.questions || []
// })}
//  }, [meetingLinks]);


  const [questionsForGuests, setQuestionsForGuests] = useState(
    meetingLinkData.questionsForGuests || [
      {
        id: generateRandomDigits(10),
        text: "",
        type: "Opened Question",
        choices: [
          {
            id: generateRandomDigits(10),
            text: "",
          },
        ],
        isRequired: false,
      },
    ]
  )
  const [form] = Form.useForm()
  const [isAddVariableModalOpened, setIsAddVariableModalOpened] = useState(false)

  useEffect(() => {
    if (meetingLinks && !isNewMeeting) {
      const selectedMeetingLink = meetingLinks.find(el => el.id === selectedMeetingLinkId)
      if (selectedMeetingLink) {
        setMeetingLinkData(selectedMeetingLink)
      }
    }
  }, [meetingLinks, selectedMeetingLinkId])

  const openAddVariableModal = () => {
    setIsAddVariableModalOpened(true)
  }

  const closeAddVariableModal = () => {
    setIsAddVariableModalOpened(false)
  }

  const reminderCollapseItemMarkup = (index: number) => (
    <Flex vertical gap={11} key={generateRandomDigits(10)}>
      <Form.Item name={["reminders", `reminder${index}`, "time"]} initialValue={"15 Min"}>
        <CustomSelect options={reminderTimeOptions} />
      </Form.Item>
      <Flex vertical gap={8}>
        <Flex justify={"space-between"} align={"center"}>
          <Typography.Paragraph className={"label"}>Text of Reminders</Typography.Paragraph>
          <Typography.Paragraph className={"variable"} onClick={openAddVariableModal}>
            Variable
          </Typography.Paragraph>
        </Flex>
        <Form.Item name={["reminders", `reminder${index}`, "text"]} initialValue={""}>
          <TextArea placeholder={"Enter the text of reminder"} />
        </Form.Item>
      </Flex>
      <Flex vertical gap={8}>
        <Typography.Paragraph className={"label"}>Example</Typography.Paragraph>
        <TextArea
          value={
            "Hi Iron, our meeting is coming up in 2 hours here is the meeting link: App.Meetz.Ai/Cal/Tom-reactivelink-2282."
          }
          disabled
        />
      </Flex>
    </Flex>
  )

  const defaultReminders = [
    {
      key: "1",
      label: "Reminder 1",
      children: reminderCollapseItemMarkup(1),
    },
    {
      key: "2",
      label: "Reminder 2",
      children: reminderCollapseItemMarkup(2),
    },
  ]

  const [reminders, setReminders] = useState(defaultReminders)
  const [remindersActiveKeys, setRemindersActiveKeys] = useState<string[]>(defaultReminders.map(item => item.key))

  const handleFormSubmit = () => {


    form
      .validateFields()
      .then(values => {
      message.destroy()
      message.info('Updating meeting settings')
        dispatch(updateMeeting({...values,timezone:timezoneSelected,
          id: meetingLinkData?.id,
          prospectSchedule: values?.prospectSchedule?.length>1 ? {start: values?.prospectSchedule[0]?.format('H:mm') ,end: values?.prospectSchedule[1]?.format('H:mm')}:{},
        schedule:meetingLinkData?.schedule,isEditAiMeeting}))


        // if (!isNewMeeting) {
        //   editMeetingLink({
        //     ...meetingLinkData,
        //     ...values,
        //     questionsForGuests,
        //     tags: meetingLinkData.tags.map((tag: any) =>
        //       tag.type === "Meeting Length"
        //         ? { text: values.meetingLength, color: "Green", type: "Meeting Length" }
        //         : tag
        //     ),
        //   })
        // } else {
        //   addMeetingLink({
        //     ...meetingLinkData,
        //     ...values,
        //     questionsForGuests,
        //     tags: [{ text: values.meetingLength, color: "Green", type: "Meeting Length" }],
        //     links: [{ text: "Copy link", href: `App.Meetz.Ai/Cal/${generateRandomDigits(10)}` }],
        //   })
        // }
        
    onClose()
      })
      .catch(errorInfo => {
        
  message.destroy() 
        message.error(`Fill out the missing fields.`)
        console.error("Validation failed:", errorInfo)
      })
  }

  const handleResetBtnClick = () => {
    form.resetFields()
  }

  const handleAddVariableSubmit = (values: any) => {
    console.log(values)
  }

  const handleCopyMeetingLinkBtnClick = () => {
    if (meetingLinkData) {
      saveToClipboard(`https://app.meetz.ai/cal/${form.getFieldValue("link")}`)
      notification.info({
        message: "Copied",
        description: false,
        duration: 5,
        icon: <SvgIcon type={"infoFilled"} />,
        closeIcon: false,
        placement: "bottomLeft",
      })
    }
  }

  const handleAddReminder = () => {
    const newReminderIndex = reminders.length + 1
    const newReminder = {
      key: newReminderIndex.toString(),
      label: `Reminder ${newReminderIndex}`,
      children: reminderCollapseItemMarkup(newReminderIndex),
    }
    setReminders(prevReminders => [...prevReminders, newReminder])
    setRemindersActiveKeys(prevActiveKeys => [...prevActiveKeys, newReminder.key])
  }

  const handleQuestionInputChange = (questionId: string, e: any) => {
    const updatedQuestions = questionsForGuests.map((question: any) =>
      question.id === questionId
        ? {
            ...question,
            text: e.target.value,
          }
        : question
    )
    setQuestionsForGuests(updatedQuestions)
  }

  const handleAddQuestionBtnClick = () => {
    setQuestionsForGuests((prevState: any) => [
      ...prevState,
      {
        id: generateRandomDigits(10),
        text: "",
        type: "Opened Question",
        choices: [{ id: generateRandomDigits(10), text: "" }],
        isRequired: false,
      },
    ])
  }

  const handleIsRequiredQuestionChange = (questionId: string, value: any) => {
    const updatedQuestions = questionsForGuests.map((question: any) =>
      question.id === questionId ? { ...question, isRequired: value === "Required" } : question
    )
    setQuestionsForGuests(updatedQuestions)
  }

  const handleQuestionTypeChange = (questionId: string, value: any) => {
    const updatedQuestions = questionsForGuests.map((question: any) =>
      question.id === questionId ? { ...question, type: value } : question
    )
    setQuestionsForGuests(updatedQuestions)
  }

  const handleAddChoiceBtnClick = (questionId: string) => {
    const updatedQuestions = questionsForGuests.map((question: any) =>
      question.id === questionId
        ? { ...question, choices: [...question.choices, { id: generateRandomDigits(10), text: "" }] }
        : question
    )
    setQuestionsForGuests(updatedQuestions)
  }

  const handleDeleteChoiceBtnClick = (questionId: string, choiceId: string) => {
    const updatedQuestions = questionsForGuests.map((question: any) =>
      question.id === questionId
        ? { ...question, choices: question.choices.filter((choice: any) => choice.id !== choiceId) }
        : question
    )
    setQuestionsForGuests(updatedQuestions)
  }

  const handleChoiceInputChange = (questionId: string, choiceId: string, e: any) => {
    const updatedQuestions = questionsForGuests.map((question: any) =>
      question.id === questionId
        ? {
            ...question,
            choices: question.choices.map((choice: any) =>
              choice.id === choiceId ? { ...choice, text: e.target.value } : choice
            ),
          }
        : question
    )
    setQuestionsForGuests(updatedQuestions)
  }


//   const updateSchedule = (schedule )=>{
// console.log(schedule,'schedule');
// const newSchedule = []

// setMeetingLinkData({...meetingLinkData,newSchedule})
//   }

// useEffect(() => {
  
// console.log(timezoneSelected,timezone,'timezoneSelectedtimezoneSelected');
// }, [timezoneSelected,timezone]);

  const [hasErrors, setHasErrors] = useState(false)
  const handleValuesChange = value => {
    let hasErrorsNow = false
    form
      .validateFields()
      .catch(errorInfo => {
      if(errorInfo?.errorFields?.length){
        setHasErrors(true)
        hasErrorsNow = true}
      })
      .finally(() => {
        if (!hasErrorsNow) {
          setHasErrors(false)
        }
      })

    // setUserData
  }

  return (
    <>
      <Drawer
        open
        title={isNewMeeting ? "Add Meeting Link" :isEditAiMeeting ? "Default Ai Booked Meeting": "Edit Meeting Link"}
        leftButtonTitle={"Close"}
        rightButtonTitle={"Save"}
        rightButtonDis={hasErrors}
        onBtnPrimaryClick={handleFormSubmit}
        onBtnSecondaryClick={handleResetBtnClick}
        onCancel={onClose} // TODOF add are you sure you want to discard changes if form is dirty
        rootClassName={"MeetingLinkDrawer"}
        // width={'550px'}
      >
        <Form form={form} layout={"vertical"} className={"edit-meeting-link-form"}
      //  value={meetingLinkData}
         initialValues={meetingLinkData}
        onValuesChange={handleValuesChange}
         >
          <Flex vertical gap={24}>
    <Flex vertical gap={8} style={{marginTop:'20px'}}>
              <Typography.Paragraph className={"label"}>Your First Name</Typography.Paragraph>
              <Form.Item name={"first_name"}  rules={[{ required: true, message: "Please enter your first name" }]}>
                <Input placeholder={"Enter your name"}   />
              </Form.Item>
            </Flex>
        {      !isEditAiMeeting &&   (    
         <Flex vertical gap={8}>
    <Flex justify={"space-between"} align={"center"}>
                <Typography.Paragraph className={"label"}>Meeting Link</Typography.Paragraph>
                <Button
                  className={"copy-btn"}
                  icon={<SvgIcon type={"copy"} />}
                  onClick={handleCopyMeetingLinkBtnClick}
                />
              </Flex>
              <Flex align={"center"} gap={8}>
                <Typography.Paragraph className={"label"} style={{ flexShrink: 0 }}>App.Meetz.Ai/Cal/</Typography.Paragraph>
                <Form.Item
                  name={"link"}
                  initialValue={meetingLinkData.links[0].href.replace("App.Meetz.Ai/Cal/", "")}
                  style={{ width: "100%" }}
                >
                  <Input placeholder={"Enter the path"} />
                </Form.Item>
              </Flex>
            </Flex>
            )}
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"} >Meeting Title <Tooltip placement={"bottom"} title={<>All of the below properties are dynamic, what you set here will be the default values.<br /><br />Example: If you tell your assistant to book a meeting titled &quot;Onboarding call with John&quot;. So in this case your assistant will book the meeting with your title, many times you won&apos;t have to specifically ask for customizations, simply based on the conversations context your assistant will do this automatically!  </>}>
            <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
          </Tooltip></Typography.Paragraph>
              <Form.Item name={"name"}  rules={[{ required: true, message: "Please enter a default meeting title" }]}>
                <Input placeholder={"Enter the meeting name"} />
              </Form.Item>
            </Flex>
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Meeting Description</Typography.Paragraph>
              <Form.Item name={"description"}>
                <TextArea rows={4} placeholder={"Enter the meeting description"} />
              </Form.Item>
            </Flex>
            {/* <Flex vertical> TODOF also add if they confirmed meeting or not.
              <Form.Item name={"reminders"}>
                <Collapse
                  items={reminders}
                  activeKey={remindersActiveKeys}
                  onChange={keys => setRemindersActiveKeys(keys as string[])}
                />
              </Form.Item>
              <Button className={"link"} onClick={handleAddReminder}>
                Add Remind
              </Button>
            </Flex> */}
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Meeting Length</Typography.Paragraph>
              <Form.Item name={"meetingLength"}>
                <CustomSelect options={reminderTimeOptions} />
              </Form.Item>
            </Flex>
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Timezone</Typography.Paragraph>
              <Form.Item name={"timezone"}>
                 <CustomSelect options={timezoneOptions}  onChange={handleChangeTimeZone}  value={timezoneSelected?.length ?  `${timezoneSelected[0]} ${timezoneSelected[1]}` : ''}  /> 
                {/* <SelectFilterItem data={timezoneOptions} form={form} fieldName={"lastName"} placeholder={"Smith"}/> TODOF later switch tz to this so they can search it quickly just change the field name which is in there */}
                {/* // TODOF add foolwup and delay between followups */}
              </Form.Item>
            </Flex>
            <Flex vertical gap={8}>
              <WeekTimePlannerWithTabs scheduling initialAvail={meetingLinkData.schedule}
           
          setInitialAvail={handleUpdateSched}
              formItemName={["availability"]} className={"meeting-link-week-planner"}
              //  onChange={updateSchedule}
                />
            </Flex>
          {isEditAiMeeting ? <Flex vertical gap={8}>
           <Typography.Paragraph className={"label"} >Guests working hours      <Tooltip placement={"bottom"} title={<>If your Ai Email Scheduling Assistant finds out that your guest is based in a different timezone than you mid-conversation we will offer times between these times in your guests working hours.<br /><br />*Recommended to keep this range as wide as possible so that the assistant will have enough cross times to offer availability and easily book a meeting.</>}>
            <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
          </Tooltip></Typography.Paragraph>
              <Form.Item name={"prospectSchedule"} rules={[{ required: true, message: "Add times that we can book in your guests timezone" }]}>
                <TimePicker.RangePicker
                needConfirm={false} 
                allowClear={false}
                minuteStep={15} 
                  className={"time-picker"}
                  format={"h:mm A"}
                  placeholder={["No min time", "No max time"]}
                />
              </Form.Item>
            </Flex> : <>
            {/* TODOF <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Maximum Timeslots To Offer Per Day</Typography.Paragraph>
              <Form.Item name={"maximumTimeslotsToOfferPerDay"}>
                <TimePicker.RangePicker
                  className={"time-picker"}
                  format={"h"}
                  placeholder={["No Max time", "No Max time"]}
                />
              </Form.Item>
            </Flex> */}
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Maximum Days Guest Can Schedule In The Future</Typography.Paragraph>
              <Form.Item name={"maximumDaysToScheduleForward"}>
                <CustomSelect options={maximumDaysToScheduleForwardOptions} />
              </Form.Item>
            </Flex> </>}
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Minimum Time To Book In Advance</Typography.Paragraph>
              <Form.Item name={"minimumTimeToBookInAdvance"}>
                <CustomSelect options={minimumTimeToBookInAdvanceOptions} />
              </Form.Item>
            </Flex>
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Buffer Time Between Meetings</Typography.Paragraph>
              <Form.Item name={"bufferTimeBetweenMeetings"}>
                <CustomSelect options={bufferTimeBetweenMeetings} />
              </Form.Item>
            </Flex>
           {/* TODOF and in schedule add this too {!isEditAiMeeting && <>   <Flex vertical gap={8}>
            <Typography.Paragraph className={"label"}>Questions For Guests</Typography.Paragraph>
              <Flex vertical={true} gap={16}>
                { questionsForGuests.map((question: any, index: number) => (
                  <Flex vertical={true} gap={8} key={index}>
                    <Flex align={"center"}>
                      <Typography.Paragraph className={"question-count"}>{index + 1} Question</Typography.Paragraph>
                      <Flex gap={8} style={{ marginLeft: "auto" }}>
                        <CustomSelect
                          options={questionRequiredOptions}
                          defaultValue={question.isRequired ? "Required" : "Not Required"}
                          className={"question-select"}
                          onChange={value => handleIsRequiredQuestionChange(question.id, value)}
                        />
                        <CustomSelect
                          options={questionTypeOptions}
                          defaultValue={question.type || "Opened Question"}
                          className={"question-select"}
                          onChange={value => handleQuestionTypeChange(question.id, value)}
                        />
                      </Flex>
                    </Flex>
                    {question.type === "Opened Question" && (
                      <Input defaultValue={question.text || ""} placeholder={"Enter the question"} />
                    )}
                    {question.type === "Multiple Choice Question" && (
                      <>
                        <Flex vertical={true} gap={4}>
                          <Typography.Paragraph className={"small-label"}>Title</Typography.Paragraph>
                          <Input
                            defaultValue={question.text || ""}
                            placeholder={"Title"}
                            onChange={e => handleQuestionInputChange(question.id, e)}
                          />
                        </Flex>
                        <Flex vertical={true} gap={4} style={{ width: "100%" }}>
                          <Flex justify={"space-between"} align={"center"}>
                            <Typography.Paragraph className={"small-label"}>Choices</Typography.Paragraph>
                            <Button className={"question-btn"} onClick={() => handleAddChoiceBtnClick(question.id)}>
                              Add Choice
                            </Button>
                          </Flex>
                          {question.choices.map((choice: any,i) => (
                            <Flex align={"center"} gap={20} key={i}>
                              <Input
                                defaultValue={choice.text || ""}
                                placeholder={"Choice"}
                                onChange={e => handleChoiceInputChange(question.id, choice.id, e)}
                              />
                              <SvgIcon
                                type={"close"}
                                className={"delete-choice-close-btn"}
                                onClick={() => handleDeleteChoiceBtnClick(question.id, choice.id)}
                              />
                            </Flex>
                          ))}
                        </Flex>
                      </>
                    )}
                  </Flex>
                ))}
              </Flex>
              <Button className={"link"} style={{ marginTop: 8 }} onClick={handleAddQuestionBtnClick}>
                Add
              </Button>
            </Flex></>} */}
          </Flex>
        </Form>
      </Drawer>
      {isAddVariableModalOpened && (
        <AddVariableModal open={true} onSubmit={handleAddVariableSubmit} onCancel={closeAddVariableModal} />
      )}
    </>
  )
}
