import { App } from "../../../types";

export const prospectData: App.ProspectEntity[] = [
  {
    ruid: "7f3a8d59-4f8b-4b4f-a6e1-11a5678b8d92",
    contactFullName: "Mario Rossi",
    contactMiddleName: null,
    contactLastName: "Rossi",
    contactFirstName: "Mario",
    contactEmail: "m****i@a****e.com",
    contactTitle: "CMO",
    contactTitleLevel: "C-Level",
    contactLocation: "New York City",
    contactPhone: "***-***-****",
    personalEmail: "m****i@g****l.ai",
    directDialPhone: "***-***-****",
    hasContactPhone: true,
    hasPersonalEmail: true,
    hasContactEmail: true,
    hasDirectDial: false,
    hasLocalAddress: false,
    companyId: 12938475,
    companyName: "AlfaRomeo",
    companyPhone: "***-***-****",
    hasCompanyPhone: true,
  },
  {
    ruid: "3e2a8d71-5b2a-4a5f-b3e4-22b6789b9f23",
    contactFullName: "Giulia Bianchi",
    contactMiddleName: null,
    contactLastName: "Bianchi",
    contactFirstName: "Giulia",
    contactEmail: "g****i@f****y.ai",
    contactTitle: "VP of Sales",
    contactTitleLevel: "VP",
    contactLocation: "Toronto",
    contactPhone: "***-***-****",
    personalEmail: "g****i@g****l.com",
    directDialPhone: "",
    hasContactPhone: true,
    hasPersonalEmail: true,
    hasContactEmail: true,
    hasDirectDial: true,
    hasLocalAddress: true,
    companyId: 23847591,
    companyName: "Fiatly",
    companyPhone: "***-***-****",
    hasCompanyPhone: true,
  },
  {
    ruid: "4f3b9d62-7d3a-4c6f-a2d5-33a7898c7d34",
    contactFullName: "Luca Verdi",
    contactMiddleName: null,
    contactLastName: "Verdi",
    contactFirstName: "Luca",
    contactEmail: "l****i@c****y.com",
    contactTitle: "R&D",
    contactTitleLevel: "Junior",
    contactLocation: "Paris",
    contactPhone: "***-***-****",
    personalEmail: "l****i@o****k.com",
    directDialPhone: "",
    hasContactPhone: false,
    hasPersonalEmail: true,
    hasContactEmail: true,
    hasDirectDial: true,
    hasLocalAddress: false,
    companyId: 34758602,
    companyName: "Crayer",
    companyPhone: "***-***-****",
    hasCompanyPhone: true,
  },
  {
    ruid: "5d3c8d73-8f4b-4d7f-a3e6-44b7899d8e45",
    contactFullName: "Sara Neri",
    contactMiddleName: null,
    contactLastName: "Neri",
    contactFirstName: "Sara",
    contactEmail: "s****i@f****z.io",
    contactTitle: "Backend Developer",
    contactTitleLevel: "Senior",
    contactLocation: "Miami",
    contactPhone: "",
    personalEmail: "s****i@g****l.com",
    directDialPhone: "",
    hasContactPhone: true,
    hasPersonalEmail: true,
    hasContactEmail: true,
    hasDirectDial: false,
    hasLocalAddress: false,
    companyId: 45678913,
    companyName: "Ferz",
    companyPhone: "***-***-****",
    hasCompanyPhone: true,
  },
  {
    ruid: "6e4d9d74-9g5c-4e8f-b4f7-55c8900e9f56",
    contactFullName: "Alessandro Russo",
    contactMiddleName: null,
    contactLastName: "Russo",
    contactFirstName: "Alessandro",
    contactEmail: "a****o@m****r.com",
    contactTitle: "SDR",
    contactTitleLevel: "Entry Level",
    contactLocation: "New York City",
    contactPhone: "",
    personalEmail: "a****o@g****l.com",
    directDialPhone: "",
    hasContactPhone: true,
    hasPersonalEmail: true,
    hasContactEmail: true,
    hasDirectDial: true,
    hasLocalAddress: false,
    companyId: 56789024,
    companyName: "Maser",
    companyPhone: "***-***-****",
    hasCompanyPhone: true,
  },
  {
    ruid: "7f5e0d85-af6d-4f9f-c5g8-66d9011f0g67",
    contactFullName: "Martina Rizzo",
    contactMiddleName: null,
    contactLastName: "Rizzo",
    contactFirstName: "Martina",
    contactEmail: "m****o@p****i.com",
    contactTitle: "QA",
    contactTitleLevel: "Junior",
    contactLocation: "Los Angeles",
    contactPhone: "",
    personalEmail: "m****o@g****l.com",
    directDialPhone: "",
    hasContactPhone: false,
    hasPersonalEmail: true,
    hasContactEmail: true,
    hasDirectDial: true,
    hasLocalAddress: false,
    companyId: 67890135,
    companyName: "Pirelli",
    companyPhone: "***-***-****",
    hasCompanyPhone: true,
  },
  {
    ruid: "8g6f1d96-bg7e-5g0f-d6h9-77e0122h1h78",
    contactFullName: "Francesca Grassi",
    contactMiddleName: null,
    contactLastName: "Grassi",
    contactFirstName: "Francesca",
    contactEmail: "f****i@d****c.com",
    contactTitle: "Marketing EMEA",
    contactTitleLevel: "Manager",
    contactLocation: "Boston",
    contactPhone: "",
    personalEmail: "f****i@g****l.com",
    directDialPhone: "",
    hasContactPhone: true,
    hasPersonalEmail: true,
    hasContactEmail: true,
    hasDirectDial: false,
    hasLocalAddress: false,
    companyId: 78901246,
    companyName: "Duc",
    companyPhone: "***-***-****",
    hasCompanyPhone: true,
  },
  {
    ruid: "9h7g2d07-ch8f-6h1f-e7i0-88f1233i2i89",
    contactFullName: "Andrea Fer",
    contactMiddleName: null,
    contactLastName: "Fer",
    contactFirstName: "Andrea",
    contactEmail: "a****i@l****a.com",
    contactTitle: "CEO",
    contactTitleLevel: "C-Level",
    contactLocation: "London",
    contactPhone: "",
    personalEmail: "a****i@g****l.com",
    directDialPhone: "",
    hasContactPhone: false,
    hasPersonalEmail: true,
    hasContactEmail: true,
    hasDirectDial: true,
    hasLocalAddress: false,
    companyId: 89012357,
    companyName: "Lancia",
    companyPhone: "***-***-****",
    hasCompanyPhone: true,
  },
  {
    ruid: "0i8h3d18-dh9g-7i2f-f8j1-99g2344j3j90",
    contactFullName: "Giorgio Conti",
    contactMiddleName: null,
    contactLastName: "Conti",
    contactFirstName: "Giorgio",
    contactEmail: "g****i@a****h.io",
    contactTitle: null,
    contactTitleLevel: "",
    contactLocation: "Denver",
    contactPhone: "",
    personalEmail: "g****i@g****l.com",
    directDialPhone: "",
    hasContactPhone: true,
    hasPersonalEmail: true,
    hasContactEmail: true,
    hasDirectDial: true,
    hasLocalAddress: false,
    companyId: 90123468,
    companyName: "Abarth",
    companyPhone: "***-***-****",
    hasCompanyPhone: true,
  },
  {
    ruid: "1j9i4d29-eh0h-8j3f-g9k2-00h3455k4k01",
    contactFullName: "Valentina Sala",
    contactMiddleName: null,
    contactLastName: "Sala",
    contactFirstName: "Valentina",
    contactEmail: "v****a@a****a.io",
    contactTitle: null,
    contactTitleLevel: "",
    contactLocation: "Bari,Apulia",
    contactPhone: "",
    personalEmail: "v****a@g****l.com",
    directDialPhone: "",
    hasContactPhone: true,
    hasPersonalEmail: true,
    hasContactEmail: true,
    hasDirectDial: false,
    hasLocalAddress: false,
    companyId: 12345679,
    companyName: "Aprilia",
    companyPhone: "***-***-****",
    hasCompanyPhone: true,
  },
];

/* 
 export const prospectData: App.ProspectEntity[] = [

  {
    id: "1",
    firstName: "Aron",
    lastName: "Adams",
    company: "INRIX",
    jobTitle: "Chief Marketing Officer",
    email: "string",
    phoneNumbers: ["+1233453434", "", ""],
    country: "United States",
    state: "New York",
    linkedin: "",
    location: "United States",
    industry: "Software & Internet",
    value: "string",
    twitter: "string",
    headcount: 150,
    revenue: 1500000,
    companyUrl: "www.twitter.com",
    technologies: ["Biometrics", "Blockchain"],
  },
  {
    id: "2",
    firstName: "Nick",
    lastName: "Powills",
    company: "No Limit Agency",
    state: "Arizona",
    jobTitle: "Chief Brand Strategist",
    email: "string",
    phoneNumbers: ["+1233453434", "", "+1233453434"],
    country: "United States",
    linkedin: "string",
    location: "United States",
    industry: "Business Services",
    value: "string",
    twitter: "string",
    headcount: 10,
    revenue: 100000,
    companyUrl: "www.facebook.com",
    technologies: ["Machine vision", "Magnonics"],
  },
  {
    id: "3",
    firstName: "Catherine",
    lastName: "Lacour",
    company: "Blackbaud",
    jobTitle: "Chief Marketing Officer",
    email: "",
    phoneNumbers: ["+1233453434", "+1233453434", ""],
    country: "France",
    state: "Utah",
    linkedin: "",
    location: "France",
    industry: "Software & Internet",
    value: "string",
    twitter: "",
    headcount: 50000,
    revenue: 550000000,
    companyUrl: "www.meetz.ai",
    technologies: ["Biometrics", "Blockchain", "Solid-state transformer"],
  },
  {
    id: "4",
    firstName: "Christa",
    lastName: "Crane",
    company: "Loeb & Loeb",
    jobTitle: "Chief Client Development and Marketing Officer",
    email: "",
    phoneNumbers: ["", "+1233453434", ""],
    country: "United States",
    state: "Idaho",
    linkedin: "string",
    location: "United States",
    industry: "Retail",
    value: "string",
    twitter: "string",
    headcount: 1010,
    revenue: 120000000,
    companyUrl: "www.google.com",
    technologies: ["Fusion power", "Commercialization", "Solid-state transformer"],
  },
  {
    id: "5",
    firstName: "Jennifer",
    lastName: "Lopez",
    company: "Blackbaud",
    jobTitle: "Chief Marketing Officer",
    email: "",
    phoneNumbers: ["", "+1233453434", "1233453434"],
    country: "Moldova",
    state: "Florida",
    linkedin: "",
    location: "Moldova",
    industry: "Software & Internet",
    value: "string",
    twitter: "",
    headcount: 600,
    revenue: 200000,
    companyUrl: "www.blackbaud.com",
    technologies: ["Fusion power", "Solid-state transformer"],
  },
  {
    id: "6",
    firstName: "Jenny",
    lastName: "Young",
    company: "Blackbaud",
    jobTitle: "Chief Marketing Officer",
    email: "",
    phoneNumbers: ["", "+1233453434", "1233453434"],
    country: "Moldova",
    state: "Florida",
    linkedin: "",
    location: "Moldova",
    industry: "Software & Internet",
    value: "string",
    twitter: "",
    headcount: 600,
    revenue: 200000,
    companyUrl: "www.blackbaud.com",
    technologies: ["Fusion power", "Solid-state transformer"],
  },
  {
    id: "7",
    firstName: "Catherine",
    lastName: "Lacour",
    company: "Blackbaud",
    jobTitle: "Chief Marketing Officer",
    email: "",
    phoneNumbers: ["+1233453434", "+1233453434", ""],
    country: "France",
    state: "Utah",
    linkedin: "",
    location: "France",
    industry: "Software & Internet",
    value: "string",
    twitter: "",
    headcount: 50000,
    revenue: 550000000,
    companyUrl: "www.meetz.ai",
    technologies: ["Biometrics", "Blockchain", "Solid-state transformer"],
  },
  {
    id: "8",
    firstName: "Catrine",
    lastName: "Black",
    company: "Blackbaud",
    jobTitle: "Chief Marketing Officer",
    email: "",
    phoneNumbers: ["+1233453434", "+1233453434", ""],
    country: "France",
    state: "Utah",
    linkedin: "",
    location: "France",
    industry: "Software & Internet",
    value: "string",
    twitter: "",
    headcount: 50000,
    revenue: 550000000,
    companyUrl: "www.meetz.ai",
    technologies: ["Biometrics", "Blockchain", "Solid-state transformer"],
  },
  {
    id: "9",
    firstName: "Aron",
    lastName: "Adams",
    company: "INRIX",
    jobTitle: "Chief Marketing Officer",
    email: "string",
    phoneNumbers: ["+1233453434", "", ""],
    country: "United States",
    state: "New York",
    linkedin: "",
    location: "United States",
    industry: "Software & Internet",
    value: "string",
    twitter: "string",
    headcount: 150,
    revenue: 1500000,
    companyUrl: "www.twitter.com",
    technologies: ["Biometrics", "Blockchain"],
  },
  {
    id: "10",
    firstName: "Christa",
    lastName: "Crane",
    company: "Loeb & Loeb",
    jobTitle: "Chief Client Development and Marketing Officer",
    email: "",
    phoneNumbers: ["", "+1233453434", ""],
    country: "United States",
    state: "Idaho",
    linkedin: "string",
    location: "United States",
    industry: "Retail",
    value: "string",
    twitter: "string",
    headcount: 1010,
    revenue: 120000000,
    companyUrl: "www.google.com",
    technologies: ["Fusion power", "Commercialization", "Solid-state transformer"],
  },
  {
    id: "11",
    firstName: "Jennifer",
    lastName: "Lopez",
    company: "Blackbaud",
    jobTitle: "Chief Marketing Officer",
    email: "",
    phoneNumbers: ["", "+1233453434", "1233453434"],
    country: "Moldova",
    state: "Florida",
    linkedin: "",
    location: "Moldova",
    industry: "Software & Internet",
    value: "string",
    twitter: "",
    headcount: 600,
    revenue: 200000,
    companyUrl: "www.blackbaud.com",
    technologies: ["Fusion power", "Solid-state transformer"],
  },
]
*/
export const exportData: App.ExportEntity[] = [
  {
    id: "1",
    name: "Winter Wonderland Discounts",
    prospectsAmount: 100,
  },
  {
    id: "2",
    name: "Skylink Technologies",
    prospectsAmount: 2344,
  },
  {
    id: "3",
    name: "CrystalClear Communications",
    prospectsAmount: 45,
  },
  {
    id: "4",
    name: "SilverPeak Innovations 2.0",
    prospectsAmount: 140,
  },
  {
    id: "5",
    name: "BrightHorizon Solutions",
    prospectsAmount: 110,
  },
  {
    id: "6",
    name: "Starlight Consulting Group",
    prospectsAmount: 1220,
  },
  {
    id: "7",
    name: "Evergreen Enterprises",
    prospectsAmount: 50,
  },
  {
    id: "8",
    name: "Skylink Technologies",
    prospectsAmount: 10430,
  },
  {
    id: "9",
    name: "CrystalClear Communications",
    prospectsAmount: 45,
  },
  {
    id: "10",
    name: "Oceanic Dynamics Ltd.",
    prospectsAmount: 140,
  },
];
export const departmentOptions = [
  {
    key: "1",
    label: "Marketing",
    value: "Marketing",
  },
  {
    key: "2",
    label: "Operations",
    value: "Operations",
  },
  {
    key: "3",
    label: "Sales",
    value: "Sales",
  },
  {
    key: "4",
    label: "Public Relations",
    value: "Public Relations",
  },
  {
    key: "5",
    label: "Finance & Administration",
    value: "Finance & Administration",
  },
  {
    key: "6",
    label: "Education",
    value: "Education",
  },
  {
    key: "7",
    label: "Customer Service",
    value: "Customer Service",
  },
  {
    key: "8",
    label: "Real Estate",
    value: "Real Estate",
  },
  {
    key: "9",
    label: "Health",
    value: "Health",
  },
  {
    key: "10",
    label: "Media",
    value: "Media",
  },
  {
    key: "11",
    label: "Human Resources",
    value: "Human Resources",
  },
  {
    key: "12",
    label: "IT & IS",
    value: "IT & IS",
  },
  {
    key: "13",
    label: "Design",
    value: "Design",
  },
  {
    key: "14",
    label: "Legal",
    value: "Legal",
  },
  {
    key: "15",
    label: "Skilled Trades",
    value: "Skilled Trades",
  },
];
/*
export const departmentOptions = [
    {
    key: "1",
    label: "Marketing",
    value: "marketing",
  },
  {
    key: "2",
    label: "Operations",
    value: "operations",
  },
  {
    key: "3",
    label: "Sales",
    value: "sales",
  },
  {
    key: "4",
    label: "Public Relations",
    value: "public relations",
  },
  {
    key: "5",
    label: "Finance & Administration",
    value: "finance",
  },
  {
    key: "6",
    label: "Education",
    value: "education",
  },
  {
    key: "7",
    label: "Customer Service",
    value: "customer service",
  },
  {
    key: "8",
    label: "Real Estate",
    value: "real estate",
  },
  {
    key: "9",
    label: "Health",
    value: "health",
  },
  {
    key: "10",
    label: "Media",
    value: "media",
  },
  {
    key: "11",
    label: "Human Resources",
    value: "human resources",
  },
  {
    key: "12",
    label: "IT & IS",
    value: "IT",
  },
  {
    key: "13",
    label: "Design",
    value: "design",
  },
  {
    key: "14",
    label: "Legal",
    value: "legal",
  },
  {
    key: "15",
    label: "Skilled Trades",
    value: "Trades",
  },
]
*/

interface Option {
  value: string;
  label: string;
  children?: Option[];
}

export const informationOptions: Option[] = [
  {
    label: "All Information Available",
    value: "All Information Available",
    children: [
      {
        label: "Business Email Address",
        value: "Business Email Address",
      },
      {
        label: "Personal Email Address",
        value: "Personal Email Address",
      },
      {
        label: "Any Phone",
        value: "Any Phone",
      },
      {
        label: "Direct Phone",
        value: "Direct Phone",
      },
    ],
  },
  {
    label: "Data Checking Level",
    value: "Data Checking Level",
    children: [
      {
        label: "Validated Emails",
        value: "Validated Emails",
      },
      {
        label: "Updated In The Last 3 Months",
        value: "Updated In The Last 3 Months",
      },
    ],
  },
];

export const countriesList = [
  "United States",
  "United Kingdom",
  "Canada",
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua And Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia And Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Caribbean Netherlands",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  // 'China', not good according to lawyer
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curacao",
  "Cyprus",
  "Czechia",
  "Democratic Republic Of The Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island And Mcdonald Islands",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle Of Man",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "North Korea",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic Of The Congo",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Barthelemy",
  "Saint Helena",
  "Saint Kitts And Nevis",
  "Saint Lucia",
  "Saint Martin",
  "Saint Pierre And Miquelon",
  "Saint Vincent And The Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia And The South Sandwich Islands",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard And Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad And Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks And Caicos Islands",
  "Tuvalu",
  "U.S. Virgin Islands",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Wallis And Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const stateList = [
  "Alabama",
  "Alaska",
  "Alberta",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "British Columbia",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District Of Columbia",
  "Federated States Of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Manitoba",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Brunswick",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "Newfoundland And Labrador",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ohio",
  "Oklahoma",
  "Ontario",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Prince Edward Island",
  "Puerto Rico",
  "Quebec",
  "Rhode Island",
  "Saskatchewan",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Islands",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "Yukon",
];

export const industryOptions: Option[] = [
  {
    label: "Hospitality and Food Services",
    value: "Hospitality and Food Services",
    children: [
      {
        label: "Bed-and-Breakfasts, Hostels, Homestays",
        value: "Bed-and-Breakfasts, Hostels, Homestays",
      },
      {
        label: "Hospitality",
        value: "Hospitality",
      },
      {
        label: "Hotels and Motels",
        value: "Hotels and Motels",
      },
      {
        label: "Accommodation Services",
        value: "Accommodation Services",
      },
      {
        label: "Caterers",
        value: "Caterers",
      },
      {
        label: "Food and Beverage Services",
        value: "Food and Beverage Services",
      },
      {
        label: "Mobile Food Services",
        value: "Mobile Food Services",
      },
      {
        label: "Bars, Taverns, and Nightclubs",
        value: "Bars, Taverns, and Nightclubs",
      },
      {
        label: "Restaurants",
        value: "Restaurants",
      },
    ],
  },
  {
    label: "Administrative Services",
    value: "Administrative Services",
    children: [
      {
        label: "Translation and Localization",
        value: "Translation and Localization",
      },
      {
        label: "Administrative and Support Services",
        value: "Administrative and Support Services",
      },
      {
        label: "Office Administration",
        value: "Office Administration",
      },
      {
        label: "Facilities Services",
        value: "Facilities Services",
      },
      {
        label: "Executive Search Services",
        value: "Executive Search Services",
      },
      {
        label: "Staffing and Recruiting",
        value: "Staffing and Recruiting",
      },
      {
        label: "Temporary Help Services",
        value: "Temporary Help Services",
      },
      {
        label: "Collection Agencies",
        value: "Collection Agencies",
      },
      {
        label: "Telephone Call Centers",
        value: "Telephone Call Centers",
      },
      {
        label: "Writing and Editing",
        value: "Writing and Editing",
      },
      {
        label: "Travel Arrangements",
        value: "Travel Arrangements",
      },
      {
        label: "Security and Investigations",
        value: "Security and Investigations",
      },
      {
        label: "Security Guards and Patrol Services",
        value: "Security Guards and Patrol Services",
      },
      {
        label: "Security Systems Services",
        value: "Security Systems Services",
      },
      {
        label: "Janitorial Services",
        value: "Janitorial Services",
      },
      {
        label: "Landscaping Services",
        value: "Landscaping Services",
      },
      {
        label: "Events Services",
        value: "Events Services",
      },
      {
        label: "Fundraising",
        value: "Fundraising",
      },
    ],
  },
  {
    label: "Construction and Contractors",
    value: "Construction and Contractors",
    children: [
      {
        label: "Construction",
        value: "Construction",
      },
      {
        label: "Residential Building Construction",
        value: "Residential Building Construction",
      },
      {
        label: "Building Construction",
        value: "Building Construction",
      },
      {
        label: "Nonresidential Building Construction",
        value: "Nonresidential Building Construction",
      },
      {
        label: "Utility System Construction",
        value: "Utility System Construction",
      },
      {
        label: "Subdivision of Land",
        value: "Subdivision of Land",
      },
      {
        label: "Highway, Street, and Bridge Construction",
        value: "Highway, Street, and Bridge Construction",
      },
      {
        label: "Civil Engineering",
        value: "Civil Engineering",
      },
      {
        label: "Building Structure and Exterior Contractors",
        value: "Building Structure and Exterior Contractors",
      },
      {
        label: "Building Equipment Contractors",
        value: "Building Equipment Contractors",
      },
      {
        label: "Building Finishing Contractors",
        value: "Building Finishing Contractors",
      },
      {
        label: "Specialty Trade Contractors",
        value: "Specialty Trade Contractors",
      },
    ],
  },
  {
    label: "Consumer Services and Community Organizations",
    value: "Consumer Services and Community Organizations",
    children: [
      {
        label: "Vehicle Repair and Maintenance",
        value: "Vehicle Repair and Maintenance",
      },
      {
        label: "Electronic and Precision Equipment Maintenance",
        value: "Electronic and Precision Equipment Maintenance",
      },
      {
        label: "Commercial and Industrial Machinery Maintenance",
        value: "Commercial and Industrial Machinery Maintenance",
      },
      {
        label: "Footwear and Leather Goods Repair",
        value: "Footwear and Leather Goods Repair",
      },
      {
        label: "Repair and Maintenance",
        value: "Repair and Maintenance",
      },
      {
        label: "Reupholstery and Furniture Repair",
        value: "Reupholstery and Furniture Repair",
      },
      {
        label: "Personal Care Services",
        value: "Personal Care Services",
      },
      {
        label: "Laundry and Drycleaning Services",
        value: "Laundry and Drycleaning Services",
      },
      {
        label: "Consumer Services",
        value: "Consumer Services",
      },
      {
        label: "Personal and Laundry Services",
        value: "Personal and Laundry Services",
      },
      {
        label: "Pet Services",
        value: "Pet Services",
      },
      {
        label: "Religious Institutions",
        value: "Religious Institutions",
      },
      {
        label: "Philanthropic Fundraising Services",
        value: "Philanthropic Fundraising Services",
      },
      {
        label: "Civic and Social Organizations",
        value: "Civic and Social Organizations",
      },
      {
        label: "Industry Associations",
        value: "Industry Associations",
      },
      {
        label: "Non-profit Organizations",
        value: "Non-profit Organizations",
      },
      {
        label: "Political Organizations",
        value: "Political Organizations",
      },
      {
        label: "Professional Organizations",
        value: "Professional Organizations",
      },
      {
        label: "Household Services",
        value: "Household Services",
      },
    ],
  },
  {
    label: "Education and Training",
    value: "Education and Training",
    children: [
      {
        label: "Primary and Secondary Education",
        value: "Primary and Secondary Education",
      },
      {
        label: "Higher Education",
        value: "Higher Education",
      },
      {
        label: "E-Learning Providers",
        value: "E-Learning Providers",
      },
      {
        label: "Professional Training and Coaching",
        value: "Professional Training and Coaching",
      },
      {
        label: "Secretarial Schools",
        value: "Secretarial Schools",
      },
      {
        label: "Cosmetology and Barber Schools",
        value: "Cosmetology and Barber Schools",
      },
      {
        label: "Flight Training",
        value: "Flight Training",
      },
      {
        label: "Technical and Vocational Training",
        value: "Technical and Vocational Training",
      },
      {
        label: "Fine Arts Schools",
        value: "Fine Arts Schools",
      },
      {
        label: "Language Schools",
        value: "Language Schools",
      },
      {
        label: "Sports and Recreation Instruction",
        value: "Sports and Recreation Instruction",
      },
      {
        label: "Education",
        value: "Education",
      },
    ],
  },
  {
    label: "Entertainment",
    value: "Entertainment",
    children: [
      {
        label: "Entertainment Providers",
        value: "Entertainment Providers",
      },
      {
        label: "Circuses and Magic Shows",
        value: "Circuses and Magic Shows",
      },
      {
        label: "Dance Companies",
        value: "Dance Companies",
      },
      {
        label: "Musicians",
        value: "Musicians",
      },
      {
        label: "Performing Arts",
        value: "Performing Arts",
      },
      {
        label: "Theater Companies",
        value: "Theater Companies",
      },
      {
        label: "Racetracks",
        value: "Racetracks",
      },
      {
        label: "Spectator Sports",
        value: "Spectator Sports",
      },
      {
        label: "Sports Teams and Clubs",
        value: "Sports Teams and Clubs",
      },
      {
        label: "Performing Arts and Spectator Sports",
        value: "Performing Arts and Spectator Sports",
      },
      {
        label: "Artists and Writers",
        value: "Artists and Writers",
      },
      {
        label: "Historical Sites",
        value: "Historical Sites",
      },
      {
        label: "Museums",
        value: "Museums",
      },
      {
        label: "Museums, Historical Sites, and Zoos",
        value: "Museums, Historical Sites, and Zoos",
      },
      {
        label: "Zoos and Botanical Gardens",
        value: "Zoos and Botanical Gardens",
      },
      {
        label: "Amusement Parks and Arcades",
        value: "Amusement Parks and Arcades",
      },
      {
        label: "Gambling Facilities and Casinos",
        value: "Gambling Facilities and Casinos",
      },
      {
        label: "Golf Courses and Country Clubs",
        value: "Golf Courses and Country Clubs",
      },
      {
        label: "Recreational Facilities",
        value: "Recreational Facilities",
      },
      {
        label: "Skiing Facilities",
        value: "Skiing Facilities",
      },
      {
        label: "Wellness and Fitness Services",
        value: "Wellness and Fitness Services",
      },
    ],
  },
  {
    label: "Farming and Agriculture",
    value: "Farming and Agriculture",
    children: [
      {
        label: "Farming",
        value: "Farming",
      },
      {
        label: "Ranching",
        value: "Ranching",
      },
      {
        label: "Ranching and Fisheries",
        value: "Ranching and Fisheries",
      },
      {
        label: "Forestry and Logging",
        value: "Forestry and Logging",
      },
      {
        label: "Fisheries",
        value: "Fisheries",
      },
      {
        label: "Farming, Ranching, Forestry",
        value: "Farming, Ranching, Forestry",
      },
      {
        label: "Horticulture",
        value: "Horticulture",
      },
    ],
  },
  {
    label: "Finance",
    value: "Finance",
    children: [
      {
        label: "Banking",
        value: "Banking",
      },
      {
        label: "Savings Institutions",
        value: "Savings Institutions",
      },
      {
        label: "International Trade and Development",
        value: "International Trade and Development",
      },
      {
        label: "Credit Intermediation",
        value: "Credit Intermediation",
      },
      {
        label: "Financial Services",
        value: "Financial Services",
      },
      {
        label: "Loan Brokers",
        value: "Loan Brokers",
      },
      {
        label: "Capital Markets",
        value: "Capital Markets",
      },
      {
        label: "Investment Banking",
        value: "Investment Banking",
      },
      {
        label: "Securities and Commodity Exchanges",
        value: "Securities and Commodity Exchanges",
      },
      {
        label: "Investment Advice",
        value: "Investment Advice",
      },
      {
        label: "Investment Management",
        value: "Investment Management",
      },
      {
        label: "Trusts and Estates",
        value: "Trusts and Estates",
      },
      {
        label: "Venture Capital and Private Equity Principals",
        value: "Venture Capital and Private Equity Principals",
      },
      {
        label: "Insurance Carriers",
        value: "Insurance Carriers",
      },
      {
        label: "Claims Adjusting, Actuarial Services",
        value: "Claims Adjusting, Actuarial Services",
      },
      {
        label: "Insurance",
        value: "Insurance",
      },
      {
        label: "Insurance Agencies and Brokerages",
        value: "Insurance Agencies and Brokerages",
      },
      {
        label: "Insurance and Employee Benefit Funds",
        value: "Insurance and Employee Benefit Funds",
      },
      {
        label: "Pension Funds",
        value: "Pension Funds",
      },
      {
        label: "Funds and Trusts",
        value: "Funds and Trusts",
      },
    ],
  },
  {
    label: "Government",
    value: "Government",
    children: [
      {
        label: "Executive Offices",
        value: "Executive Offices",
      },
      {
        label: "Government Administration",
        value: "Government Administration",
      },
      {
        label: "Legislative Offices",
        value: "Legislative Offices",
      },
      {
        label: "Public Policy Offices",
        value: "Public Policy Offices",
      },
      {
        label: "Administration of Justice",
        value: "Administration of Justice",
      },
      {
        label: "Correctional Institutions",
        value: "Correctional Institutions",
      },
      {
        label: "Courts of Law",
        value: "Courts of Law",
      },
      {
        label: "Fire Protection",
        value: "Fire Protection",
      },
      {
        label: "Law Enforcement",
        value: "Law Enforcement",
      },
      {
        label: "Public Safety",
        value: "Public Safety",
      },
      {
        label: "Education Administration Programs",
        value: "Education Administration Programs",
      },
      {
        label: "Health and Human Services",
        value: "Health and Human Services",
      },
      {
        label: "Public Assistance Programs",
        value: "Public Assistance Programs",
      },
      {
        label: "Public Health",
        value: "Public Health",
      },
      {
        label: "Air, Water, and Waste Program Management",
        value: "Air, Water, and Waste Program Management",
      },
      {
        label: "Conservation Programs",
        value: "Conservation Programs",
      },
      {
        label: "Environmental Quality Programs",
        value: "Environmental Quality Programs",
      },
      {
        label: "Community Development and Urban Planning",
        value: "Community Development and Urban Planning",
      },
      {
        label: "Housing and Community Development",
        value: "Housing and Community Development",
      },
      {
        label: "Housing Programs",
        value: "Housing Programs",
      },
      {
        label: "Economic Programs",
        value: "Economic Programs",
      },
      {
        label: "Transportation Programs",
        value: "Transportation Programs",
      },
      {
        label: "Utilities Administration",
        value: "Utilities Administration",
      },
      {
        label: "Space Research and Technology",
        value: "Space Research and Technology",
      },
      {
        label: "Armed Forces",
        value: "Armed Forces",
      },
      {
        label: "International Affairs",
        value: "International Affairs",
      },
      {
        label: "Military and International Affairs",
        value: "Military and International Affairs",
      },
    ],
  },
  {
    label: "Holding Companies",
    value: "Holding Companies",
    children: [
      {
        label: "Holding Companies",
        value: "Holding Companies",
      },
    ],
  },
  {
    label: "Hospitals and Health Services",
    value: "Hospitals and Health Services",
    children: [
      {
        label: "Medical Practices",
        value: "Medical Practices",
      },
      {
        label: "Physicians",
        value: "Physicians",
      },
      {
        label: "Dentists",
        value: "Dentists",
      },
      {
        label: "Alternative Medicine",
        value: "Alternative Medicine",
      },
      {
        label: "Chiropractors",
        value: "Chiropractors",
      },
      {
        label: "Mental Health Care",
        value: "Mental Health Care",
      },
      {
        label: "Optometrists",
        value: "Optometrists",
      },
      {
        label: "Physical, Occupational and Speech Therapists",
        value: "Physical, Occupational and Speech Therapists",
      },
      {
        label: "Family Planning Centers",
        value: "Family Planning Centers",
      },
      {
        label: "Outpatient Care Centers",
        value: "Outpatient Care Centers",
      },
      {
        label: "Medical and Diagnostic Laboratories",
        value: "Medical and Diagnostic Laboratories",
      },
      {
        label: "Home Health Care Services",
        value: "Home Health Care Services",
      },
      {
        label: "Ambulance Services",
        value: "Ambulance Services",
      },
      {
        label: "Hospitals",
        value: "Hospitals",
      },
      {
        label: "Hospitals and Health Care",
        value: "Hospitals and Health Care",
      },
      {
        label: "Nursing Homes and Residential Care Facilities",
        value: "Nursing Homes and Residential Care Facilities",
      },
      {
        label: "Individual and Family Services",
        value: "Individual and Family Services",
      },
      {
        label: "Services for the Elderly and Disabled",
        value: "Services for the Elderly and Disabled",
      },
      {
        label: "Community Services",
        value: "Community Services",
      },
      {
        label: "Emergency and Relief Services",
        value: "Emergency and Relief Services",
      },
      {
        label: "Vocational Rehabilitation Services",
        value: "Vocational Rehabilitation Services",
      },
      {
        label: "Child Day Care Services",
        value: "Child Day Care Services",
      },
    ],
  },
  {
    label: "Manufacturing Products",
    value: "Manufacturing Products",
    children: [
      {
        label: "Animal Feed Manufacturing",
        value: "Animal Feed Manufacturing",
      },
      {
        label: "Sugar and Confectionery Product Manufacturing",
        value: "Sugar and Confectionery Product Manufacturing",
      },
      {
        label: "Fruit and Vegetable Preserves Manufacturing",
        value: "Fruit and Vegetable Preserves Manufacturing",
      },
      {
        label: "Dairy Product Manufacturing",
        value: "Dairy Product Manufacturing",
      },
      {
        label: "Meat Products Manufacturing",
        value: "Meat Products Manufacturing",
      },
      {
        label: "Seafood Product Manufacturing",
        value: "Seafood Product Manufacturing",
      },
      {
        label: "Baked Goods Manufacturing",
        value: "Baked Goods Manufacturing",
      },
      {
        label: "Food and Beverage Manufacturing",
        value: "Food and Beverage Manufacturing",
      },
      {
        label: "Beverage Manufacturing",
        value: "Beverage Manufacturing",
      },
      {
        label: "Breweries",
        value: "Breweries",
      },
      {
        label: "Distilleries",
        value: "Distilleries",
      },
      {
        label: "Wineries",
        value: "Wineries",
      },
      {
        label: "Tobacco Manufacturing",
        value: "Tobacco Manufacturing",
      },
      {
        label: "Textile Manufacturing",
        value: "Textile Manufacturing",
      },
      {
        label: "Apparel Manufacturing",
        value: "Apparel Manufacturing",
      },
      {
        label: "Fashion Accessories Manufacturing",
        value: "Fashion Accessories Manufacturing",
      },
      {
        label: "Footwear Manufacturing",
        value: "Footwear Manufacturing",
      },
      {
        label: "Leather Product Manufacturing",
        value: "Leather Product Manufacturing",
      },
      {
        label: "Women's Handbag Manufacturing",
        value: "Women's Handbag Manufacturing",
      },
      {
        label: "Wood Product Manufacturing",
        value: "Wood Product Manufacturing",
      },
      {
        label: "Paper and Forest Product Manufacturing",
        value: "Paper and Forest Product Manufacturing",
      },
      {
        label: "Printing Services",
        value: "Printing Services",
      },
      {
        label: "Oil and Coal Product Manufacturing",
        value: "Oil and Coal Product Manufacturing",
      },
      {
        label: "Chemical Raw Materials Manufacturing",
        value: "Chemical Raw Materials Manufacturing",
      },
      {
        label: "Artificial Rubber and Synthetic Fiber Manufacturing",
        value: "Artificial Rubber and Synthetic Fiber Manufacturing",
      },
      {
        label: "Agricultural Chemical Manufacturing",
        value: "Agricultural Chemical Manufacturing",
      },
      {
        label: "Pharmaceutical Manufacturing",
        value: "Pharmaceutical Manufacturing",
      },
      {
        label: "Paint, Coating, and Adhesive Manufacturing",
        value: "Paint, Coating, and Adhesive Manufacturing",
      },
      {
        label: "Soap and Cleaning Product Manufacturing",
        value: "Soap and Cleaning Product Manufacturing",
      },
      {
        label: "Chemical Manufacturing",
        value: "Chemical Manufacturing",
      },
      {
        label: "Packaging and Containers Manufacturing",
        value: "Packaging and Containers Manufacturing",
      },
      {
        label: "Plastics and Rubber Product Manufacturing",
        value: "Plastics and Rubber Product Manufacturing",
      },
      {
        label: "Plastics Manufacturing",
        value: "Plastics Manufacturing",
      },
      {
        label: "Rubber Products Manufacturing",
        value: "Rubber Products Manufacturing",
      },
      {
        label: "Clay and Refractory Products Manufacturing",
        value: "Clay and Refractory Products Manufacturing",
      },
      {
        label: "Glass Product Manufacturing",
        value: "Glass Product Manufacturing",
      },
      {
        label: "Glass, Ceramics and Concrete Manufacturing",
        value: "Glass, Ceramics and Concrete Manufacturing",
      },
      {
        label: "Lime and Gypsum Products Manufacturing",
        value: "Lime and Gypsum Products Manufacturing",
      },
      {
        label: "Abrasives and Nonmetallic Minerals Manufacturing",
        value: "Abrasives and Nonmetallic Minerals Manufacturing",
      },
      {
        label: "Primary Metal Manufacturing",
        value: "Primary Metal Manufacturing",
      },
      {
        label: "Fabricated Metal Products",
        value: "Fabricated Metal Products",
      },
      {
        label: "Cutlery and Handtool Manufacturing",
        value: "Cutlery and Handtool Manufacturing",
      },
      {
        label: "Architectural and Structural Metal Manufacturing",
        value: "Architectural and Structural Metal Manufacturing",
      },
      {
        label: "Automation Machinery Manufacturing",
        value: "Automation Machinery Manufacturing",
      },
      {
        label: "Boilers, Tanks, and Shipping Container Manufacturing",
        value: "Boilers, Tanks, and Shipping Container Manufacturing",
      },
      {
        label: "Construction Hardware Manufacturing",
        value: "Construction Hardware Manufacturing",
      },
      {
        label: "Spring and Wire Product Manufacturing",
        value: "Spring and Wire Product Manufacturing",
      },
      {
        label: "Turned Products and Fastener Manufacturing",
        value: "Turned Products and Fastener Manufacturing",
      },
      {
        label: "Metal Treatments",
        value: "Metal Treatments",
      },
      {
        label: "Metal Valve, Ball, and Roller Manufacturing",
        value: "Metal Valve, Ball, and Roller Manufacturing",
      },
      {
        label: "Agriculture, Construction, Mining Machinery Manufacturing",
        value: "Agriculture, Construction, Mining Machinery Manufacturing",
      },
      {
        label: "Industrial Machinery Manufacturing",
        value: "Industrial Machinery Manufacturing",
      },
      {
        label: "Commercial and Service Industry Machinery Manufacturing",
        value: "Commercial and Service Industry Machinery Manufacturing",
      },
      {
        label: "HVAC and Refrigeration Equipment Manufacturing",
        value: "HVAC and Refrigeration Equipment Manufacturing",
      },
      {
        label: "Metalworking Machinery Manufacturing",
        value: "Metalworking Machinery Manufacturing",
      },
      {
        label: "Engines and Power Transmission Equipment Manufacturing",
        value: "Engines and Power Transmission Equipment Manufacturing",
      },
      {
        label: "Machinery Manufacturing",
        value: "Machinery Manufacturing",
      },
      {
        label: "Robot Manufacturing",
        value: "Robot Manufacturing",
      },
      {
        label: "Computer Hardware Manufacturing",
        value: "Computer Hardware Manufacturing",
      },
      {
        label: "Communications Equipment Manufacturing",
        value: "Communications Equipment Manufacturing",
      },
      {
        label: "Audio and Video Equipment Manufacturing",
        value: "Audio and Video Equipment Manufacturing",
      },
      {
        label: "Computers and Electronics Manufacturing",
        value: "Computers and Electronics Manufacturing",
      },
      {
        label: "Renewable Energy Semiconductor Manufacturing",
        value: "Renewable Energy Semiconductor Manufacturing",
      },
      {
        label: "Semiconductor Manufacturing",
        value: "Semiconductor Manufacturing",
      },
      {
        label: "Climate Technology Product Manufacturing",
        value: "Climate Technology Product Manufacturing",
      },
      {
        label: "Measuring and Control Instrument Manufacturing",
        value: "Measuring and Control Instrument Manufacturing",
      },
      {
        label: "Magnetic and Optical Media Manufacturing",
        value: "Magnetic and Optical Media Manufacturing",
      },
      {
        label: "Electric Lighting Equipment Manufacturing",
        value: "Electric Lighting Equipment Manufacturing",
      },
      {
        label: "Household Appliance Manufacturing",
        value: "Household Appliance Manufacturing",
      },
      {
        label: "Electrical Equipment Manufacturing",
        value: "Electrical Equipment Manufacturing",
      },
      {
        label: "Appliances, Electrical, and Electronics Manufacturing",
        value: "Appliances, Electrical, and Electronics Manufacturing",
      },
      {
        label: "Renewable Energy Equipment Manufacturing",
        value: "Renewable Energy Equipment Manufacturing",
      },
      {
        label: "Motor Vehicle Manufacturing",
        value: "Motor Vehicle Manufacturing",
      },
      {
        label: "Motor Vehicle Parts Manufacturing",
        value: "Motor Vehicle Parts Manufacturing",
      },
      {
        label: "Aviation and Aerospace Component Manufacturing",
        value: "Aviation and Aerospace Component Manufacturing",
      },
      {
        label: "Defense and Space Manufacturing",
        value: "Defense and Space Manufacturing",
      },
      {
        label: "Railroad Equipment Manufacturing",
        value: "Railroad Equipment Manufacturing",
      },
      {
        label: "Shipbuilding",
        value: "Shipbuilding",
      },
      {
        label: "Transportation Equipment Manufacturing",
        value: "Transportation Equipment Manufacturing",
      },
      {
        label: "Household and Institutional Furniture Manufacturing",
        value: "Household and Institutional Furniture Manufacturing",
      },
      {
        label: "Office Furniture and Fixtures Manufacturing",
        value: "Office Furniture and Fixtures Manufacturing",
      },
      {
        label: "Mattress and Blinds Manufacturing",
        value: "Mattress and Blinds Manufacturing",
      },
      {
        label: "Medical Equipment Manufacturing",
        value: "Medical Equipment Manufacturing",
      },
      {
        label: "Manufacturing",
        value: "Manufacturing",
      },
      {
        label: "Sporting Goods Manufacturing",
        value: "Sporting Goods Manufacturing",
      },
      {
        label: "Furniture and Home Furnishings Manufacturing",
        value: "Furniture and Home Furnishings Manufacturing",
      },
      {
        label: "Personal Care Product Manufacturing",
        value: "Personal Care Product Manufacturing",
      },
      {
        label: "Accessible Hardware Manufacturing",
        value: "Accessible Hardware Manufacturing",
      },
      {
        label: "Fuel Cell Manufacturing",
        value: "Fuel Cell Manufacturing",
      },
      {
        label: "Smart Meter Manufacturing",
        value: "Smart Meter Manufacturing",
      },
      {
        label: "Alternative Fuel Vehicle Manufacturing",
        value: "Alternative Fuel Vehicle Manufacturing",
      },
    ],
  },
  {
    label: "Oil and Mining",
    value: "Oil and Mining",
    children: [
      {
        label: "Natural Gas Extraction",
        value: "Natural Gas Extraction",
      },
      {
        label: "Oil Extraction",
        value: "Oil Extraction",
      },
      {
        label: "Coal Mining",
        value: "Coal Mining",
      },
      {
        label: "Metal Ore Mining",
        value: "Metal Ore Mining",
      },
      {
        label: "Mining",
        value: "Mining",
      },
      {
        label: "Nonmetallic Mineral Mining",
        value: "Nonmetallic Mineral Mining",
      },
      {
        label: "Oil and Gas",
        value: "Oil and Gas",
      },
      {
        label: "Oil, Gas, and Mining",
        value: "Oil, Gas, and Mining",
      },
    ],
  },
  {
    label: "Professional Services and Practices",
    value: "Professional Services and Practices",
    children: [
      {
        label: "Computer and Network Security",
        value: "Computer and Network Security",
      },
      {
        label: "Law Practice",
        value: "Law Practice",
      },
      {
        label: "Legal Services",
        value: "Legal Services",
      },
      {
        label: "Accounting",
        value: "Accounting",
      },
      {
        label: "Accessible Architecture and Design",
        value: "Accessible Architecture and Design",
      },
      {
        label: "Architecture and Planning",
        value: "Architecture and Planning",
      },
      {
        label: "Engineering Services",
        value: "Engineering Services",
      },
      {
        label: "Robotics Engineering",
        value: "Robotics Engineering",
      },
      {
        label: "Surveying and Mapping Services",
        value: "Surveying and Mapping Services",
      },
      {
        label: "Design Services",
        value: "Design Services",
      },
      {
        label: "Graphic Design",
        value: "Graphic Design",
      },
      {
        label: "Interior Design",
        value: "Interior Design",
      },
      {
        label: "Digital Accessibility Services",
        value: "Digital Accessibility Services",
      },
      {
        label: "IT Services and IT Consulting",
        value: "IT Services and IT Consulting",
      },
      {
        label: "IT System Custom Software Development",
        value: "IT System Custom Software Development",
      },
      {
        label: "IT System Data Services",
        value: "IT System Data Services",
      },
      {
        label: "IT System Design Services",
        value: "IT System Design Services",
      },
      {
        label: "IT System Installation and Disposal",
        value: "IT System Installation and Disposal",
      },
      {
        label: "IT System Operations and Maintenance",
        value: "IT System Operations and Maintenance",
      },
      {
        label: "IT System Testing and Evaluation",
        value: "IT System Testing and Evaluation",
      },
      {
        label: "IT System Training and Support",
        value: "IT System Training and Support",
      },
      {
        label: "Advertising Services",
        value: "Advertising Services",
      },
      {
        label: "Business Consulting and Services",
        value: "Business Consulting and Services",
      },
      {
        label: "Environmental Services",
        value: "Environmental Services",
      },
      {
        label: "Human Resources Services",
        value: "Human Resources Services",
      },
      {
        label: "Operations Consulting",
        value: "Operations Consulting",
      },
      {
        label: "Outsourcing and Offshoring Consulting",
        value: "Outsourcing and Offshoring Consulting",
      },
      {
        label: "Strategic Management Services",
        value: "Strategic Management Services",
      },
      {
        label: "Biotechnology Research",
        value: "Biotechnology Research",
      },
      {
        label: "Nanotechnology Research",
        value: "Nanotechnology Research",
      },
      {
        label: "Research Services",
        value: "Research Services",
      },
      {
        label: "Services for Renewable Energy",
        value: "Services for Renewable Energy",
      },
      {
        label: "Think Tanks",
        value: "Think Tanks",
      },
      {
        label: "Government Relations Services",
        value: "Government Relations Services",
      },
      {
        label: "Marketing Services",
        value: "Marketing Services",
      },
      {
        label: "Public Relations and Communications Services",
        value: "Public Relations and Communications Services",
      },
      {
        label: "Market Research",
        value: "Market Research",
      },
      {
        label: "Photography",
        value: "Photography",
      },
      {
        label: "Professional Services",
        value: "Professional Services",
      },
      {
        label: "Veterinary Services",
        value: "Veterinary Services",
      },
      {
        label: "Alternative Dispute Resolution",
        value: "Alternative Dispute Resolution",
      },
      {
        label: "Regenerative Design",
        value: "Regenerative Design",
      },
    ],
  },
  {
    label: "Real Estate and Services",
    value: "Real Estate and Services",
    children: [
      {
        label: "Leasing Non-residential Real Estate",
        value: "Leasing Non-residential Real Estate",
      },
      {
        label: "Leasing Residential Real Estate",
        value: "Leasing Residential Real Estate",
      },
      {
        label: "Real Estate and Equipment Rental Services",
        value: "Real Estate and Equipment Rental Services",
      },
      {
        label: "Consumer Goods Rental",
        value: "Consumer Goods Rental",
      },
      {
        label: "Equipment Rental Services",
        value: "Equipment Rental Services",
      },
      {
        label: "Commercial and Industrial Equipment Rental",
        value: "Commercial and Industrial Equipment Rental",
      },
      {
        label: "Real Estate",
        value: "Real Estate",
      },
      {
        label: "Real Estate Agents and Brokers",
        value: "Real Estate Agents and Brokers",
      },
    ],
  },
  {
    label: "Retail and Consumer Goods",
    value: "Retail and Consumer Goods",
    children: [
      {
        label: "Retail Art Supplies",
        value: "Retail Art Supplies",
      },
      {
        label: "Retail Motor Vehicles",
        value: "Retail Motor Vehicles",
      },
      {
        label: "Retail Building Materials and Garden Equipment",
        value: "Retail Building Materials and Garden Equipment",
      },
      {
        label: "Retail Groceries",
        value: "Retail Groceries",
      },
      {
        label: "Food and Beverage Retail",
        value: "Food and Beverage Retail",
      },
      {
        label: "Retail Apparel and Fashion",
        value: "Retail Apparel and Fashion",
      },
      {
        label: "Retail Furniture and Home Furnishings",
        value: "Retail Furniture and Home Furnishings",
      },
      {
        label: "Retail Appliances, Electrical, and Electronic Equipment",
        value: "Retail Appliances, Electrical, and Electronic Equipment",
      },
      {
        label: "Online and Mail Order Retail",
        value: "Online and Mail Order Retail",
      },
      {
        label: "Retail",
        value: "Retail",
      },
      {
        label: "Retail Health and Personal Care Products",
        value: "Retail Health and Personal Care Products",
      },
      {
        label: "Retail Pharmacies",
        value: "Retail Pharmacies",
      },
      {
        label: "Retail Gasoline",
        value: "Retail Gasoline",
      },
      {
        label: "Retail Luxury Goods and Jewelry",
        value: "Retail Luxury Goods and Jewelry",
      },
      {
        label: "Retail Musical Instruments",
        value: "Retail Musical Instruments",
      },
      {
        label: "Retail Books and Printed News",
        value: "Retail Books and Printed News",
      },
      {
        label: "Retail Florists",
        value: "Retail Florists",
      },
      {
        label: "Retail Office Equipment",
        value: "Retail Office Equipment",
      },
      {
        label: "Retail Office Supplies and Gifts",
        value: "Retail Office Supplies and Gifts",
      },
      {
        label: "Retail Recyclable Materials & Used Merchandise",
        value: "Retail Recyclable Materials & Used Merchandise",
      },
      {
        label: "Retail Art Dealers",
        value: "Retail Art Dealers",
      },
    ],
  },
  {
    label: "Technology and Media",
    value: "Technology and Media",
    children: [
      {
        label: "Internet Marketplace Platforms",
        value: "Internet Marketplace Platforms",
      },
      {
        label: "Animation and Post-production",
        value: "Animation and Post-production",
      },
      {
        label: "Media Production",
        value: "Media Production",
      },
      {
        label: "Movies and Sound Recording",
        value: "Movies and Sound Recording",
      },
      {
        label: "Movies, Videos and Sound",
        value: "Movies, Videos and Sound",
      },
      {
        label: "Sheet Music Publishing",
        value: "Sheet Music Publishing",
      },
      {
        label: "Sound Recording",
        value: "Sound Recording",
      },
      {
        label: "Book and Periodical Publishing",
        value: "Book and Periodical Publishing",
      },
      {
        label: "Book Publishing",
        value: "Book Publishing",
      },
      {
        label: "Newspaper Publishing",
        value: "Newspaper Publishing",
      },
      {
        label: "Periodical Publishing",
        value: "Periodical Publishing",
      },
      {
        label: "Desktop Computing Software Products",
        value: "Desktop Computing Software Products",
      },
      {
        label: "Embedded Software Products",
        value: "Embedded Software Products",
      },
      {
        label: "Mobile Computing Software Products",
        value: "Mobile Computing Software Products",
      },
      {
        label: "Software Development",
        value: "Software Development",
      },
      {
        label: "Broadcast Media Production and Distribution",
        value: "Broadcast Media Production and Distribution",
      },
      {
        label: "Cable and Satellite Programming",
        value: "Cable and Satellite Programming",
      },
      {
        label: "Radio and Television Broadcasting",
        value: "Radio and Television Broadcasting",
      },
      {
        label: "Social Networking Platforms",
        value: "Social Networking Platforms",
      },
      {
        label: "Telecommunications Carriers",
        value: "Telecommunications Carriers",
      },
      {
        label: "Wireless Services",
        value: "Wireless Services",
      },
      {
        label: "Satellite Telecommunications",
        value: "Satellite Telecommunications",
      },
      {
        label: "Media and Telecommunications",
        value: "Media and Telecommunications",
      },
      {
        label: "Telecommunications",
        value: "Telecommunications",
      },
      {
        label: "Blockchain Services",
        value: "Blockchain Services",
      },
      {
        label: "Blogs",
        value: "Blogs",
      },
      {
        label: "Data Infrastructure and Analytics",
        value: "Data Infrastructure and Analytics",
      },
      {
        label: "Information Services",
        value: "Information Services",
      },
      {
        label: "Internet News",
        value: "Internet News",
      },
      {
        label: "Internet Publishing",
        value: "Internet Publishing",
      },
      {
        label: "Libraries",
        value: "Libraries",
      },
      {
        label: "Online Audio and Video Media",
        value: "Online Audio and Video Media",
      },
      {
        label: "Technology, Information and Internet",
        value: "Technology, Information and Internet",
      },
      {
        label: "Technology, Information and Media",
        value: "Technology, Information and Media",
      },
      {
        label: "Computer Games",
        value: "Computer Games",
      },
      {
        label: "Computer Networking Products",
        value: "Computer Networking Products",
      },
      {
        label: "Mobile Gaming Apps",
        value: "Mobile Gaming Apps",
      },
      {
        label: "Climate Data and Analytics",
        value: "Climate Data and Analytics",
      },
      {
        label: "Business Intelligence Platforms",
        value: "Business Intelligence Platforms",
      },
      {
        label: "Business Content",
        value: "Business Content",
      },
      {
        label: "Data Security Software Products",
        value: "Data Security Software Products",
      },
    ],
  },
  {
    label: "Transportation",
    value: "Transportation",
    children: [
      {
        label: "Airlines and Aviation",
        value: "Airlines and Aviation",
      },
      {
        label: "Rail Transportation",
        value: "Rail Transportation",
      },
      {
        label: "Maritime Transportation",
        value: "Maritime Transportation",
      },
      {
        label: "Truck Transportation",
        value: "Truck Transportation",
      },
      {
        label: "Urban Transit Services",
        value: "Urban Transit Services",
      },
      {
        label: "Interurban and Rural Bus Services",
        value: "Interurban and Rural Bus Services",
      },
      {
        label: "Taxi and Limousine Services",
        value: "Taxi and Limousine Services",
      },
      {
        label: "School and Employee Bus Services",
        value: "School and Employee Bus Services",
      },
      {
        label: "Ground Passenger Transportation",
        value: "Ground Passenger Transportation",
      },
      {
        label: "Shuttles and Special Needs Transportation Services",
        value: "Shuttles and Special Needs Transportation Services",
      },
      {
        label: "Pipeline Transportation",
        value: "Pipeline Transportation",
      },
      {
        label: "Sightseeing Transportation",
        value: "Sightseeing Transportation",
      },
      {
        label: "Transportation, Logistics, Supply Chain and Storage",
        value: "Transportation, Logistics, Supply Chain and Storage",
      },
      {
        label: "Postal Services",
        value: "Postal Services",
      },
      {
        label: "Freight and Package Transportation",
        value: "Freight and Package Transportation",
      },
      {
        label: "Warehousing and Storage",
        value: "Warehousing and Storage",
      },
    ],
  },
  {
    label: "Utilities and Electric Generation",
    value: "Utilities and Electric Generation",
    children: [
      {
        label: "Biomass Electric Power Generation",
        value: "Biomass Electric Power Generation",
      },
      {
        label: "Electric Power Generation",
        value: "Electric Power Generation",
      },
      {
        label: "Electric Power Transmission, Control, and Distribution",
        value: "Electric Power Transmission, Control, and Distribution",
      },
      {
        label: "Fossil Fuel Electric Power Generation",
        value: "Fossil Fuel Electric Power Generation",
      },
      {
        label: "Geothermal Electric Power Generation",
        value: "Geothermal Electric Power Generation",
      },
      {
        label: "Hydroelectric Power Generation",
        value: "Hydroelectric Power Generation",
      },
      {
        label: "Nuclear Electric Power Generation",
        value: "Nuclear Electric Power Generation",
      },
      {
        label: "Renewable Energy Power Generation",
        value: "Renewable Energy Power Generation",
      },
      {
        label: "Solar Electric Power Generation",
        value: "Solar Electric Power Generation",
      },
      {
        label: "Utilities",
        value: "Utilities",
      },
      {
        label: "Wind Electric Power Generation",
        value: "Wind Electric Power Generation",
      },
      {
        label: "Natural Gas Distribution",
        value: "Natural Gas Distribution",
      },
      {
        label: "Steam and Air-Conditioning Supply",
        value: "Steam and Air-Conditioning Supply",
      },
      {
        label: "Water Supply and Irrigation Systems",
        value: "Water Supply and Irrigation Systems",
      },
      {
        label: "Water, Waste, Steam, and Air Conditioning Services",
        value: "Water, Waste, Steam, and Air Conditioning Services",
      },
      {
        label: "Waste Collection",
        value: "Waste Collection",
      },
      {
        label: "Waste Treatment and Disposal",
        value: "Waste Treatment and Disposal",
      },
    ],
  },
  {
    label: "Wholesale Products",
    value: "Wholesale Products",
    children: [
      {
        label: "Wholesale Motor Vehicles and Parts",
        value: "Wholesale Motor Vehicles and Parts",
      },
      {
        label: "Wholesale Furniture and Home Furnishings",
        value: "Wholesale Furniture and Home Furnishings",
      },
      {
        label: "Wholesale Building Materials",
        value: "Wholesale Building Materials",
      },
      {
        label: "Wholesale Computer Equipment",
        value: "Wholesale Computer Equipment",
      },
      {
        label: "Wholesale Photography Equipment and Supplies",
        value: "Wholesale Photography Equipment and Supplies",
      },
      {
        label: "Wholesale Metals and Minerals",
        value: "Wholesale Metals and Minerals",
      },
      {
        label: "Wholesale Appliances, Electrical, and Electronics",
        value: "Wholesale Appliances, Electrical, and Electronics",
      },
      {
        label: "Wholesale Hardware, Plumbing, Heating Equipment",
        value: "Wholesale Hardware, Plumbing, Heating Equipment",
      },
      {
        label: "Wholesale Import and Export",
        value: "Wholesale Import and Export",
      },
      {
        label: "Wholesale Machinery",
        value: "Wholesale Machinery",
      },
      {
        label: "Wholesale Luxury Goods and Jewelry",
        value: "Wholesale Luxury Goods and Jewelry",
      },
      {
        label: "Wholesale Recyclable Materials",
        value: "Wholesale Recyclable Materials",
      },
      {
        label: "Wholesale Paper Products",
        value: "Wholesale Paper Products",
      },
      {
        label: "Wholesale Drugs and Sundries",
        value: "Wholesale Drugs and Sundries",
      },
      {
        label: "Wholesale Apparel and Sewing Supplies",
        value: "Wholesale Apparel and Sewing Supplies",
      },
      {
        label: "Wholesale Footwear",
        value: "Wholesale Footwear",
      },
      {
        label: "Wholesale Food and Beverage",
        value: "Wholesale Food and Beverage",
      },
      {
        label: "Wholesale Raw Farm Products",
        value: "Wholesale Raw Farm Products",
      },
      {
        label: "Wholesale Chemical and Allied Products",
        value: "Wholesale Chemical and Allied Products",
      },
      {
        label: "Wholesale Petroleum and Petroleum Products",
        value: "Wholesale Petroleum and Petroleum Products",
      },
      {
        label: "Wholesale Alcoholic Beverages",
        value: "Wholesale Alcoholic Beverages",
      },
      {
        label: "Wholesale",
        value: "Wholesale",
      },
    ],
  },
];

export const seniorityLevelList = [
  "Partners",
  "Owners",
  "C-Levels",
  "VPs",
  "Directors",
  "Managers",
  "Individual Contributors",
  "Juniors",
  "Interns",
  "Volunteers",
];
/*
export const seniorityLevelList = [
  {value:'Partner',label:'Partners'},
  {value:'Owner',label:'Owners'},
  {value:'Cxo',label:'C-Levels'},
  {value:'Vp',label:'VPs'},
  {value:'Director',label:'Directors'},
  {value:'Manager',label:'Managers'},
  {value:'Senior',label:'Individual Contributors'},
  {value:'Junior',label:'Juniors'},
  {value:'Training',label:'Interns'},
  {value:'Unpaid',label:'Volunteers'}]
*/
/*
export const sicCodeList = [
  {value:'0132',label:'0132: Tobacco'},
{value:'0191',label:'0191: General farms, primarily crop'},
{value:'0212',label:'0212: Beef cattle, except feedlots'},
{value:'0241',label:'0241: Dairy farms'},
{value:'0273',label:'0273: Animal aquaculture'},
{value:'0741',label:'0741: Veterinary services for livestock'},
{value:'0781',label:'0781: Landscape counseling and planning'},
{value:'0782',label:'0782: Lawn and garden services'},
{value:'1099',label:'1099: Metal ores'},
{value:'1241',label:'1241: Coal mining services'},
{value:'1311',label:'1311: Crude petroleum and natural gas'},
{value:'1382',label:'1382: Oil and gas exploration services'},
{value:'1429',label:'1429: Crushed and broken stone'},
{value:'1522',label:'1522: Residential construction'},
{value:'1541',label:'1541: Industrial buildings and warehouses'},
{value:'1542',label:'1542: Nonresidential construction'},
{value:'1611',label:'1611: Highway and street construction'},
{value:'1629',label:'1629: Heavy construction'},
{value:'1711',label:'1711: Plumbing, heating, air-conditioning'},
{value:'1761',label:'1761: Roofing, siding, and sheetmetal work'},
{value:'1791',label:'1791: Structural steel erection'},
{value:'1799',label:'1799: Special trade contractors, nec'},
{value:'2032',label:'2032: Canned specialties'},
{value:'2033',label:'2033: Canned fruits and specialties'},
{value:'2048',label:'2048: Prepared feeds'},
{value:'2051',label:'2051: Bread, cake, and related products'},
{value:'2084',label:'2084: Wines, brandy, and brandy spirits'},
{value:'2085',label:'2085: Distilled and blended liquors'},
{value:'2091',label:'2091: Canned and cured fish and seafoods'},
{value:'2389',label:'2389: Apparel and accessories'},
{value:'2426',label:'2426: Hardwood dimension and flooring mills'},
{value:'2512',label:'2512: Upholstered household furniture'},
{value:'2621',label:'2621: Paper mills'},
{value:'2711',label:'2711: Newspapers'},
{value:'2721',label:'2721: Periodicals'},
{value:'2731',label:'2731: Book publishing'},
{value:'2741',label:'2741: Miscellaneous publishing'},
{value:'2789',label:'2789: Bookbinding and related work'},
{value:'2791',label:'2791: Typesetting'},
{value:'2819',label:'2819: Industrial inorganic chemicals'},
{value:'2822',label:'2822: Synthetic rubber'},
{value:'2834',label:'2834: Pharmaceutical preparations'},
{value:'2841',label:'2841: Soap and other detergents'},
{value:'2851',label:'2851: Paints and allied products'},
{value:'2879',label:'2879: Agricultural chemicals'},
{value:'2911',label:'2911: Petroleum refining'},
{value:'3061',label:'3061: Mechanical rubber goods'},
{value:'3069',label:'3069: Fabricated rubber products, nec'},
{value:'3089',label:'3089: Plastics products, nec'},
{value:'3149',label:'3149: Footwear, except rubber'},
{value:'3171',label:'3171: Women\'s handbags and purses'},
{value:'3199',label:'3199: Leather good'},
{value:'3231',label:'3231: Products of purchased glass'},
{value:'3253',label:'3253: Ceramic wall and floor tile'},
{value:'3274',label:'3274: Lime'},
{value:'3291',label:'3291: Abrasive products'},
{value:'3312',label:'3312: Blast furnaces and steel mills'},
{value:'3331',label:'3331: Primary copper'},
{value:'3421',label:'3421: Cutlery'},
{value:'3442',label:'3442: Metal doors, sash, and trim'},
{value:'3443',label:'3443: Fabricated plate work (boiler shop)'},
{value:'3493',label:'3493: Steel springs, except wire'},
{value:'3497',label:'3497: Metal foil and leaf'},
{value:'3499',label:'3499: Fabricated metal products'},
{value:'3531',label:'3531: Construction machinery'},
{value:'3532',label:'3532: Mining machinery'},
{value:'3545',label:'3545: Machine tool accessories'},
{value:'3549',label:'3549: Metalworking machinery'},
{value:'3552',label:'3552: Textile machinery'},
{value:'3559',label:'3559: Special industry machinery'},
{value:'3568',label:'3568: Power transmission equipment'},
{value:'3571',label:'3571: Electronic computers'},
{value:'3572',label:'3572: Computer storage devices'},
{value:'3575',label:'3575: Computer terminals'},
{value:'3579',label:'3579: Office machines'},
{value:'3599',label:'3599: Industrial machinery'},
{value:'3631',label:'3631: Household cooking equipment'},
{value:'3634',label:'3634: Electric housewares and fans'},
{value:'3651',label:'3651: Household audio and video equipment'},
{value:'3669',label:'3669: Communications equipment'},
{value:'3674',label:'3674: Semiconductors and related devices'},
{value:'3679',label:'3679: Electronic components'},
{value:'3694',label:'3694: Engine electrical equipment'},
{value:'3695',label:'3695: Magnetic and optical recording media'},
{value:'3731',label:'3731: Shipbuilding and repairing'},
{value:'3743',label:'3743: Railroad equipment'},
{value:'3761',label:'3761: Guided missiles and space vehicles'},
{value:'3799',label:'3799: Transportation equipment'},
{value:'3822',label:'3822: Environmental controls'},
{value:'3845',label:'3845: Electromedical equipment'},
{value:'3949',label:'3949: Sporting and athletic goods'},
{value:'3999',label:'3999: Manufacturing industries'},
{value:'4011',label:'4011: Railroads, line-haul operating'},
{value:'4111',label:'4111: Local and suburban transit'},
{value:'4119',label:'4119: Local passenger transportation'},
{value:'4121',label:'4121: Taxicabs'},
{value:'4131',label:'4131: Intercity and rural bus transportation'},
{value:'4151',label:'4151: School buses'},
{value:'4212',label:'4212: Local trucking, without storage'},
{value:'4213',label:'4213: Trucking, except local'},
{value:'4226',label:'4226: Special warehousing and storage'},
{value:'4311',label:'4311: U.S. Postal Service'},
{value:'4412',label:'4412: Deep sea foreign transportation of freight'},
{value:'4512',label:'4512: Air transportation, scheduled'},
{value:'4513',label:'4513: Air courier services'},
{value:'4619',label:'4619: Pipelines'},
{value:'4729',label:'4729: Passenger transportation arrangement'},
{value:'4731',label:'4731: Freight transportation arrangement'},
{value:'4812',label:'4812: Radiotelephone communication'},
{value:'4813',label:'4813: Telephone communication, except radio'},
{value:'4833',label:'4833: Television broadcasting stations'},
{value:'4899',label:'4899: Communication services'},
{value:'4911',label:'4911: Electric services'},
{value:'4931',label:'4931: Electric and other services combined'},
{value:'4932',label:'4932: Gas and other services combined'},
{value:'4939',label:'4939: Combination utilities'},
{value:'4959',label:'4959: Sanitary services'},
{value:'4961',label:'4961: Steam and air-conditioning supply'},
{value:'4971',label:'4971: Irrigation systems'},
{value:'5012',label:'5012: Automobiles and other motor vehicles'},
{value:'5013',label:'5013: Motor vehicle supplies and new parts'},
{value:'5021',label:'5021: Furniture'},
{value:'5023',label:'5023: Homefurnishings'},
{value:'5043',label:'5043: Photographic equipment and supplies'},
{value:'5045',label:'5045: Computers, peripherals, and software'},
{value:'5047',label:'5047: Medical and hospital equipment'},
{value:'5051',label:'5051: Metals service centers and offices'},
{value:'5064',label:'5064: Electrical appliances, television and radio'},
{value:'5074',label:'5074: Plumbing and hydronic heating supplies'},
{value:'5075',label:'5075: Warm air heating and air conditioning'},
{value:'5082',label:'5082: Construction and mining machinery'},
{value:'5084',label:'5084: Industrial machinery and equipment'},
{value:'5088',label:'5088: Transportation equipment and supplies'},
{value:'5093',label:'5093: Scrap and waste materials'},
{value:'5094',label:'5094: Jewelry and precious stones'},
{value:'5112',label:'5112: Stationery and office supplies'},
{value:'5113',label:'5113: Industrial and personal service paper'},
{value:'5122',label:'5122: Drugs, proprietaries, and sundries'},
{value:'5136',label:'5136: Men\'s and boy\'s clothing'},
{value:'5139',label:'5139: Footwear'},
{value:'5143',label:'5143: Dairy products, except dried or canned'},
{value:'5145',label:'5145: Confectionery'},
{value:'5147',label:'5147: Meats and meat products'},
{value:'5149',label:'5149: Groceries and related products'},
{value:'5159',label:'5159: Farm-product raw materials'},
{value:'5169',label:'5169: Chemicals and allied products'},
{value:'5172',label:'5172: Petroleum products'},
{value:'5181',label:'5181: Beer and ale'},
{value:'5181',label:'5181: Beer and ale'},
{value:'5199',label:'5199: Nondurable goods'},
{value:'5261',label:'5261: Retail nurseries and garden stores'},
{value:'5411',label:'5411: Grocery stores'},
{value:'5499',label:'5499: Miscellaneous food stores'},
{value:'5511',label:'5511: New and used car dealers'},
{value:'5541',label:'5541: Gasoline service stations'},
{value:'5651',label:'5651: Family clothing stores'},
{value:'5712',label:'5712: Furniture stores'},
{value:'5734',label:'5734: Computer and software stores'},
{value:'5812',label:'5812: Eating places'},
{value:'5813',label:'5813: Drinking places'},
{value:'5932',label:'5932: Used merchandise stores'},
{value:'5942',label:'5942: Book stores'},
{value:'5963',label:'5963: Direct selling establishments'},
{value:'5992',label:'5992: Florists'},
{value:'5999',label:'5999: Miscellaneous retail stores'},
{value:'6029',label:'6029: Commercial banks'},
{value:'6036',label:'6036: Savings institutions, except federal'},
{value:'6082',label:'6082: Foreign trade and international banks'},
{value:'6099',label:'6099: Functions related to depository banking'},
{value:'6141',label:'6141: Personal credit institutions'},
{value:'6211',label:'6211: Security brokers and dealers'},
{value:'6221',label:'6221: Commodity contracts brokers, dealers'},
{value:'6231',label:'6231: Security and commodity exchanges'},
{value:'6282',label:'6282: Investment advice'},
{value:'6311',label:'6311: Life insurance'},
{value:'6371',label:'6371: Pension, health, and welfare funds'},
{value:'6399',label:'6399: Insurance carriers'},
{value:'6411',label:'6411: Insurance agents, brokers, and service'},
{value:'6519',label:'6519: Real property lessors'},
{value:'6519',label:'6519: Real property lessors, nec'},
{value:'6531',label:'6531: Real estate agents and managers'},
{value:'6552',label:'6552: Subdividers and developers'},
{value:'6719',label:'6719: Holding companies'},
{value:'6732',label:'6732: Trusts: educational, religious, etc.'},
{value:'6733',label:'6733: Trusts'},
{value:'7011',label:'7011: Hotels and motels'},
{value:'7216',label:'7216: Drycleaning plants, except rugs'},
{value:'7218',label:'7218: Industrial launderers'},
{value:'7221',label:'7221: Photographic studios, portrait'},
{value:'7251',label:'7251: Shoe repair and shoeshine parlors'},
{value:'7299',label:'7299: Miscellaneous personal services'},
{value:'7311',label:'7311: Advertising agencies'},
{value:'7313',label:'7313: Radio, television, publisher representatives'},
{value:'7322',label:'7322: Adjustment and collection services'},
{value:'7336',label:'7336: Commercial art and graphic design'},
{value:'7349',label:'7349: Building maintenance services'},
{value:'7361',label:'7361: Employment agencies'},
{value:'7363',label:'7363: Help supply services'},
{value:'7371',label:'7371: Custom computer programming services'},
{value:'7372',label:'7372: Prepackaged software'},
{value:'7373',label:'7373: Computer integrated systems design'},
{value:'7374',label:'7374: Data processing and preparation'},
{value:'7375',label:'7375: Information retrieval services'},
{value:'7376',label:'7376: Computer facilities management'},
{value:'7378',label:'7378: Computer maintenance and repair'},
{value:'7379',label:'7379: Computer related services'},
{value:'7389',label:'7389: Business services'},
{value:'7519',label:'7519: Utility trailer rental'},
{value:'7538',label:'7538: General automotive repair shops'},
{value:'7539',label:'7539: Automotive repair shops'},
{value:'7622',label:'7622: Radio and television repair'},
{value:'7641',label:'7641: Reupholstery and furniture repair'},
{value:'7692',label:'7692: Welding repair'},
{value:'7812',label:'7812: Motion picture and video production'},
{value:'7819',label:'7819: Services allied to motion pictures'},
{value:'7911',label:'7911: Dance studios, schools, and halls'},
{value:'7922',label:'7922: Theatrical producers and services'},
{value:'7929',label:'7929: Entertainers and entertainment groups'},
{value:'7941',label:'7941: Sports clubs, managers, and promoters'},
{value:'7991',label:'7991: Physical fitness facilities'},
{value:'7992',label:'7992: Public golf courses'},
{value:'7993',label:'7993: Coin-operated amusement devices'},
{value:'7996',label:'7996: Amusement parks'},
{value:'7999',label:'7999: Amusement and recreation'},
{value:'8011',label:'8011: Offices and clinics of medical doctors'},
{value:'8021',label:'8021: Offices and clinics of dentists'},
{value:'8041',label:'8041: Offices and clinics of chiropractors'},
{value:'8042',label:'8042: Offices and clinics of optometrists'},
{value:'8049',label:'8049: Offices of health practitioner'},
{value:'8059',label:'8059: Nursing and personal care'},
{value:'8062',label:'8062: General medical and surgical hospitals'},
{value:'8063',label:'8063: Psychiatric hospitals'},
{value:'8082',label:'8082: Home health care services'},
{value:'8093',label:'8093: Specialty outpatient clinics'},
{value:'8111',label:'8111: Legal services'},
{value:'8211',label:'8211: Elementary and secondary schools'},
{value:'8221',label:'8221: Colleges and universities'},
{value:'8231',label:'8231: Libraries'},
{value:'8243',label:'8243: Data processing schools'},
{value:'8249',label:'8249: Vocational schools'},
{value:'8299',label:'8299: Schools and educational services'},
{value:'8322',label:'8322: Individual and family services'},
{value:'8331',label:'8331: Job training and related services'},
{value:'8351',label:'8351: Child day care services'},
{value:'8361',label:'8361: Residential care'},
{value:'8399',label:'8399: Social services'},
{value:'8412',label:'8412: Museums and art galleries'},
{value:'8611',label:'8611: Business associations'},
{value:'8621',label:'8621: Professional organizations'},
{value:'8631',label:'8631: Labor organizations'},
{value:'8641',label:'8641: Civic and social associations'},
{value:'8661',label:'8661: Religious organizations'},
{value:'8712',label:'8712: Architectural services'},
{value:'8713',label:'8713: Surveying services'},
{value:'8721',label:'8721: Accounting, auditing, and bookkeeping'},
{value:'8731',label:'8731: Commercial physical research'},
{value:'8732',label:'8732: Commercial nonphysical research'},
{value:'8733',label:'8733: Noncommercial research organizations'},
{value:'8734',label:'8734: Testing laboratories'},
{value:'8741',label:'8741: Management services'},
{value:'8743',label:'8743: Public relations services'},
{value:'8744',label:'8744: Facilities support services'},
{value:'8748',label:'8748: Business consulting'},
{value:'9111',label:'9111: Executive offices'},
{value:'9121',label:'9121: Legislative bodies'},
{value:'9199',label:'9199: General government'},
{value:'9211',label:'9211: Courts'},
{value:'9221',label:'9221: Police protection'},
{value:'9222',label:'9222: Legal counsel and prosecution'},
{value:'9223',label:'9223: Correctional institutions'},
{value:'9224',label:'9224: Fire protection'},
{value:'9229',label:'9229: Public order and safety'},
{value:'9311',label:'9311: Finance, taxation, and monetary policy'},
{value:'9411',label:'9411: Administration of educational programs'},
{value:'9431',label:'9431: Administration of public health programs'},
{value:'9511',label:'9511: Air, water, and solid waste management'},
{value:'9531',label:'9531: Housing programs'},
{value:'9611',label:'9611: Administration of general economic programs'},
{value:'9661',label:'9661: Space research and technology'},
{value:'9711',label:'9711: National security'},
{value:'9721',label:'9721: International affairs'},
]
*/
export const sicCodeList = [
  { key: "0132", label: "0132: Tobacco", value: "0132: Tobacco" },
  { key: "0191", label: "0191: General farms, primarily crop", value: "0191: General farms, primarily crop" },
  { key: "0212", label: "0212: Beef cattle, except feedlots", value: "0212: Beef cattle, except feedlots" },
  { key: "0241", label: "0241: Dairy farms", value: "0241: Dairy farms" },
  { key: "0273", label: "0273: Animal aquaculture", value: "0273: Animal aquaculture" },
  { key: "0741", label: "0741: Veterinary services for livestock", value: "0741: Veterinary services for livestock" },
  { key: "0781", label: "0781: Landscape counseling and planning", value: "0781: Landscape counseling and planning" },
  { key: "0782", label: "0782: Lawn and garden services", value: "0782: Lawn and garden services" },
  { key: "1099", label: "1099: Metal ores", value: "1099: Metal ores" },
  { key: "1241", label: "1241: Coal mining services", value: "1241: Coal mining services" },
  { key: "1311", label: "1311: Crude petroleum and natural gas", value: "1311: Crude petroleum and natural gas" },
  { key: "1382", label: "1382: Oil and gas exploration services", value: "1382: Oil and gas exploration services" },
  { key: "1429", label: "1429: Crushed and broken stone", value: "1429: Crushed and broken stone" },
  { key: "1522", label: "1522: Residential construction", value: "1522: Residential construction" },
  { key: "1541", label: "1541: Industrial buildings and warehouses", value: "1541: Industrial buildings and warehouses" },
  { key: "1542", label: "1542: Nonresidential construction", value: "1542: Nonresidential construction" },
  { key: "1611", label: "1611: Highway and street construction", value: "1611: Highway and street construction" },
  { key: "1629", label: "1629: Heavy construction", value: "1629: Heavy construction" },
  { key: "1711", label: "1711: Plumbing, heating, air-conditioning", value: "1711: Plumbing, heating, air-conditioning" },
  { key: "1761", label: "1761: Roofing, siding, and sheetmetal work", value: "1761: Roofing, siding, and sheetmetal work" },
  { key: "1791", label: "1791: Structural steel erection", value: "1791: Structural steel erection" },
  { key: "1799", label: "1799: Special trade contractors, nec", value: "1799: Special trade contractors, nec" },
  { key: "2032", label: "2032: Canned specialties", value: "2032: Canned specialties" },
  { key: "2033", label: "2033: Canned fruits and specialties", value: "2033: Canned fruits and specialties" },
  { key: "2048", label: "2048: Prepared feeds", value: "2048: Prepared feeds" },
  { key: "2051", label: "2051: Bread, cake, and related products", value: "2051: Bread, cake, and related products" },
  { key: "2084", label: "2084: Wines, brandy, and brandy spirits", value: "2084: Wines, brandy, and brandy spirits" },
  { key: "2085", label: "2085: Distilled and blended liquors", value: "2085: Distilled and blended liquors" },
  { key: "2091", label: "2091: Canned and cured fish and seafoods", value: "2091: Canned and cured fish and seafoods" },
  { key: "2389", label: "2389: Apparel and accessories", value: "2389: Apparel and accessories" },
  { key: "2426", label: "2426: Hardwood dimension and flooring mills", value: "2426: Hardwood dimension and flooring mills" },
  { key: "2512", label: "2512: Upholstered household furniture", value: "2512: Upholstered household furniture" },
  { key: "2621", label: "2621: Paper mills", value: "2621: Paper mills" },
  { key: "2711", label: "2711: Newspapers", value: "2711: Newspapers" },
  { key: "2721", label: "2721: Periodicals", value: "2721: Periodicals" },
  { key: "2731", label: "2731: Book publishing", value: "2731: Book publishing" },
  { key: "2741", label: "2741: Miscellaneous publishing", value: "2741: Miscellaneous publishing" },
  { key: "2789", label: "2789: Bookbinding and related work", value: "2789: Bookbinding and related work" },
  { key: "2791", label: "2791: Typesetting", value: "2791: Typesetting" },
  { key: "2819", label: "2819: Industrial inorganic chemicals", value: "2819: Industrial inorganic chemicals" },
  { key: "2822", label: "2822: Synthetic rubber", value: "2822: Synthetic rubber" },
  { key: "2834", label: "2834: Pharmaceutical preparations", value: "2834: Pharmaceutical preparations" },
  { key: "2841", label: "2841: Soap and other detergents", value: "2841: Soap and other detergents" },
  { key: "2851", label: "2851: Paints and allied products", value: "2851: Paints and allied products" },
  { key: "2879", label: "2879: Agricultural chemicals", value: "2879: Agricultural chemicals" },
  { key: "2911", label: "2911: Petroleum refining", value: "2911: Petroleum refining" },
  { key: "3061", label: "3061: Mechanical rubber goods", value: "3061: Mechanical rubber goods" },
  { key: "3069", label: "3069: Fabricated rubber products, nec", value: "3069: Fabricated rubber products, nec" },
  { key: "3089", label: "3089: Plastics products, nec", value: "3089: Plastics products, nec" },
  { key: "3149", label: "3149: Footwear, except rubber", value: "3149: Footwear, except rubber" },
  { key: "3171", label: "3171: Women's handbags and purses", value: "3171: Women's handbags and purses" },
  { key: "3199", label: "3199: Leather good", value: "3199: Leather good" },
  { key: "3231", label: "3231: Products of purchased glass", value: "3231: Products of purchased glass" },
  { key: "3253", label: "3253: Ceramic wall and floor tile", value: "3253: Ceramic wall and floor tile" },
  { key: "3274", label: "3274: Lime", value: "3274: Lime" },
  { key: "3291", label: "3291: Abrasive products", value: "3291: Abrasive products" },
  { key: "3312", label: "3312: Blast furnaces and steel mills", value: "3312: Blast furnaces and steel mills" },
  { key: "3331", label: "3331: Primary copper", value: "3331: Primary copper" },
  { key: "3421", label: "3421: Cutlery", value: "3421: Cutlery" },
  { key: "3442", label: "3442: Metal doors, sash, and trim", value: "3442: Metal doors, sash, and trim" },
  { key: "3443", label: "3443: Fabricated plate work (boiler shop)", value: "3443: Fabricated plate work (boiler shop)" },
  { key: "3493", label: "3493: Steel springs, except wire", value: "3493: Steel springs, except wire" },
  { key: "3497", label: "3497: Metal foil and leaf", value: "3497: Metal foil and leaf" },
  { key: "3499", label: "3499: Fabricated metal products", value: "3499: Fabricated metal products" },
  { key: "3531", label: "3531: Construction machinery", value: "3531: Construction machinery" },
  { key: "3532", label: "3532: Mining machinery", value: "3532: Mining machinery" },
  { key: "3545", label: "3545: Machine tool accessories", value: "3545: Machine tool accessories" },
  { key: "3549", label: "3549: Metalworking machinery", value: "3549: Metalworking machinery" },
  { key: "3552", label: "3552: Textile machinery", value: "3552: Textile machinery" },
  { key: "3559", label: "3559: Special industry machinery", value: "3559: Special industry machinery" },
  { key: "3568", label: "3568: Power transmission equipment", value: "3568: Power transmission equipment" },
  { key: "3571", label: "3571: Electronic computers", value: "3571: Electronic computers" },
  { key: "3572", label: "3572: Computer storage devices", value: "3572: Computer storage devices" },
  { key: "3575", label: "3575: Computer terminals", value: "3575: Computer terminals" },
  { key: "3579", label: "3579: Office machines", value: "3579: Office machines" },
  { key: "3599", label: "3599: Industrial machinery", value: "3599: Industrial machinery" },
  { key: "3631", label: "3631: Household cooking equipment", value: "3631: Household cooking equipment" },
  { key: "3634", label: "3634: Electric housewares and fans", value: "3634: Electric housewares and fans" },
  { key: "3651", label: "3651: Household audio and video equipment", value: "3651: Household audio and video equipment" },
  { key: "3669", label: "3669: Communications equipment", value: "3669: Communications equipment" },
  { key: "3674", label: "3674: Semiconductors and related devices", value: "3674: Semiconductors and related devices" },
  { key: "3679", label: "3679: Electronic components", value: "3679: Electronic components" },
  { key: "3694", label: "3694: Engine electrical equipment", value: "3694: Engine electrical equipment" },
  { key: "3695", label: "3695: Magnetic and optical recording media", value: "3695: Magnetic and optical recording media" },
  { key: "3731", label: "3731: Shipbuilding and repairing", value: "3731: Shipbuilding and repairing" },
  { key: "3743", label: "3743: Railroad equipment", value: "3743: Railroad equipment" },
  { key: "3761", label: "3761: Guided missiles and space vehicles", value: "3761: Guided missiles and space vehicles" },
  { key: "3799", label: "3799: Transportation equipment", value: "3799: Transportation equipment" },
  { key: "3822", label: "3822: Environmental controls", value: "3822: Environmental controls" },
  { key: "3845", label: "3845: Electromedical equipment", value: "3845: Electromedical equipment" },
  { key: "3949", label: "3949: Sporting and athletic goods", value: "3949: Sporting and athletic goods" },
  { key: "3999", label: "3999: Manufacturing industries", value: "3999: Manufacturing industries" },
  { key: "4011", label: "4011: Railroads, line-haul operating", value: "4011: Railroads, line-haul operating" },
  { key: "4111", label: "4111: Local and suburban transit", value: "4111: Local and suburban transit" },
  { key: "4119", label: "4119: Local passenger transportation", value: "4119: Local passenger transportation" },
  { key: "4121", label: "4121: Taxicabs", value: "4121: Taxicabs" },
  { key: "4131", label: "4131: Intercity and rural bus transportation", value: "4131: Intercity and rural bus transportation" },
  { key: "4151", label: "4151: School buses", value: "4151: School buses" },
  { key: "4212", label: "4212: Local trucking, without storage", value: "4212: Local trucking, without storage" },
  { key: "4213", label: "4213: Trucking, except local", value: "4213: Trucking, except local" },
  { key: "4226", label: "4226: Special warehousing and storage", value: "4226: Special warehousing and storage" },
  { key: "4311", label: "4311: U.S. Postal Service", value: "4311: U.S. Postal Service" },
  { key: "4412", label: "4412: Deep sea foreign transportation of freight", value: "4412: Deep sea foreign transportation of freight" },
  { key: "4512", label: "4512: Air transportation, scheduled", value: "4512: Air transportation, scheduled" },
  { key: "4513", label: "4513: Air courier services", value: "4513: Air courier services" },
  { key: "4619", label: "4619: Pipelines", value: "4619: Pipelines" },
  { key: "4729", label: "4729: Passenger transportation arrangement", value: "4729: Passenger transportation arrangement" },
  { key: "4731", label: "4731: Freight transportation arrangement", value: "4731: Freight transportation arrangement" },
  { key: "4812", label: "4812: Radiotelephone communication", value: "4812: Radiotelephone communication" },
  { key: "4813", label: "4813: Telephone communication, except radio", value: "4813: Telephone communication, except radio" },
  { key: "4833", label: "4833: Television broadcasting stations", value: "4833: Television broadcasting stations" },
  { key: "4899", label: "4899: Communication services", value: "4899: Communication services" },
  { key: "4911", label: "4911: Electric services", value: "4911: Electric services" },
  { key: "4931", label: "4931: Electric and other services combined", value: "4931: Electric and other services combined" },
  { key: "4932", label: "4932: Gas and other services combined", value: "4932: Gas and other services combined" },
  { key: "4939", label: "4939: Combination utilities", value: "4939: Combination utilities" },
  { key: "4959", label: "4959: Sanitary services", value: "4959: Sanitary services" },
  { key: "4961", label: "4961: Steam and air-conditioning supply", value: "4961: Steam and air-conditioning supply" },
  { key: "4971", label: "4971: Irrigation systems", value: "4971: Irrigation systems" },
  { key: "5012", label: "5012: Automobiles and other motor vehicles", value: "5012: Automobiles and other motor vehicles" },
  { key: "5013", label: "5013: Motor vehicle supplies and new parts", value: "5013: Motor vehicle supplies and new parts" },
  { key: "5021", label: "5021: Furniture", value: "5021: Furniture" },
  { key: "5023", label: "5023: Homefurnishings", value: "5023: Homefurnishings" },
  { key: "5043", label: "5043: Photographic equipment and supplies", value: "5043: Photographic equipment and supplies" },
  { key: "5045", label: "5045: Computers, peripherals, and software", value: "5045: Computers, peripherals, and software" },
  { key: "5047", label: "5047: Medical and hospital equipment", value: "5047: Medical and hospital equipment" },
  { key: "5051", label: "5051: Metals service centers and offices", value: "5051: Metals service centers and offices" },
  { key: "5064", label: "5064: Electrical appliances, television and radio", value: "5064: Electrical appliances, television and radio" },
  { key: "5074", label: "5074: Plumbing and hydronic heating supplies", value: "5074: Plumbing and hydronic heating supplies" },
  { key: "5075", label: "5075: Warm air heating and air conditioning", value: "5075: Warm air heating and air conditioning" },
  { key: "5082", label: "5082: Construction and mining machinery", value: "5082: Construction and mining machinery" },
  { key: "5084", label: "5084: Industrial machinery and equipment", value: "5084: Industrial machinery and equipment" },
  { key: "5088", label: "5088: Transportation equipment and supplies", value: "5088: Transportation equipment and supplies" },
  { key: "5093", label: "5093: Scrap and waste materials", value: "5093: Scrap and waste materials" },
  { key: "5094", label: "5094: Jewelry and precious stones", value: "5094: Jewelry and precious stones" },
  { key: "5112", label: "5112: Stationery and office supplies", value: "5112: Stationery and office supplies" },
  { key: "5113", label: "5113: Industrial and personal service paper", value: "5113: Industrial and personal service paper" },
  { key: "5122", label: "5122: Drugs, proprietaries, and sundries", value: "5122: Drugs, proprietaries, and sundries" },
  { key: "5136", label: "5136: Men's and boy's clothing", value: "5136: Men's and boy's clothing" },
  { key: "5139", label: "5139: Footwear", value: "5139: Footwear" },
  { key: "5143", label: "5143: Dairy products, except dried or canned", value: "5143: Dairy products, except dried or canned" },
  { key: "5145", label: "5145: Confectionery", value: "5145: Confectionery" },
  { key: "5147", label: "5147: Meats and meat products", value: "5147: Meats and meat products" },
  { key: "5149", label: "5149: Groceries and related products", value: "5149: Groceries and related products" },
  { key: "5159", label: "5159: Farm-product raw materials", value: "5159: Farm-product raw materials" },
  { key: "5169", label: "5169: Chemicals and allied products", value: "5169: Chemicals and allied products" },
  { key: "5172", label: "5172: Petroleum products", value: "5172: Petroleum products" },
  { key: "5181", label: "5181: Beer and ale", value: "5181: Beer and ale" },
  { key: "5181", label: "5181: Beer and ale", value: "5181: Beer and ale" },
  { key: "5199", label: "5199: Nondurable goods", value: "5199: Nondurable goods" },
  { key: "5261", label: "5261: Retail nurseries and garden stores", value: "5261: Retail nurseries and garden stores" },
  { key: "5411", label: "5411: Grocery stores", value: "5411: Grocery stores" },
  { key: "5499", label: "5499: Miscellaneous food stores", value: "5499: Miscellaneous food stores" },
  { key: "5511", label: "5511: New and used car dealers", value: "5511: New and used car dealers" },
  { key: "5541", label: "5541: Gasoline service stations", value: "5541: Gasoline service stations" },
  { key: "5651", label: "5651: Family clothing stores", value: "5651: Family clothing stores" },
  { key: "5712", label: "5712: Furniture stores", value: "5712: Furniture stores" },
  { key: "5734", label: "5734: Computer and software stores", value: "5734: Computer and software stores" },
  { key: "5812", label: "5812: Eating places", value: "5812: Eating places" },
  { key: "5813", label: "5813: Drinking places", value: "5813: Drinking places" },
  { key: "5932", label: "5932: Used merchandise stores", value: "5932: Used merchandise stores" },
  { key: "5942", label: "5942: Book stores", value: "5942: Book stores" },
  { key: "5963", label: "5963: Direct selling establishments", value: "5963: Direct selling establishments" },
  { key: "5992", label: "5992: Florists", value: "5992: Florists" },
  { key: "5999", label: "5999: Miscellaneous retail stores", value: "5999: Miscellaneous retail stores" },
  { key: "6029", label: "6029: Commercial banks", value: "6029: Commercial banks" },
  { key: "6036", label: "6036: Savings institutions, except federal", value: "6036: Savings institutions, except federal" },
  { key: "6082", label: "6082: Foreign trade and international banks", value: "6082: Foreign trade and international banks" },
  { key: "6099", label: "6099: Functions related to depository banking", value: "6099: Functions related to depository banking" },
  { key: "6141", label: "6141: Personal credit institutions", value: "6141: Personal credit institutions" },
  { key: "6211", label: "6211: Security brokers and dealers", value: "6211: Security brokers and dealers" },
  { key: "6221", label: "6221: Commodity contracts brokers, dealers", value: "6221: Commodity contracts brokers, dealers" },
  { key: "6231", label: "6231: Security and commodity exchanges", value: "6231: Security and commodity exchanges" },
  { key: "6282", label: "6282: Investment advice", value: "6282: Investment advice" },
  { key: "6311", label: "6311: Life insurance", value: "6311: Life insurance" },
  { key: "6371", label: "6371: Pension, health, and welfare funds", value: "6371: Pension, health, and welfare funds" },
  { key: "6399", label: "6399: Insurance carriers", value: "6399: Insurance carriers" },
  { key: "6411", label: "6411: Insurance agents, brokers, and service", value: "6411: Insurance agents, brokers, and service" },
  { key: "6519", label: "6519: Real property lessors", value: "6519: Real property lessors" },
  { key: "6519", label: "6519: Real property lessors, nec", value: "6519: Real property lessors, nec" },
  { key: "6531", label: "6531: Real estate agents and managers", value: "6531: Real estate agents and managers" },
  { key: "6552", label: "6552: Subdividers and developers", value: "6552: Subdividers and developers" },
  { key: "6719", label: "6719: Holding companies", value: "6719: Holding companies" },
  { key: "6732", label: "6732: Trusts: educational, religious, etc.", value: "6732: Trusts: educational, religious, etc." },
  { key: "6733", label: "6733: Trusts", value: "6733: Trusts" },
  { key: "7011", label: "7011: Hotels and motels", value: "7011: Hotels and motels" },
  { key: "7216", label: "7216: Drycleaning plants, except rugs", value: "7216: Drycleaning plants, except rugs" },
  { key: "7218", label: "7218: Industrial launderers", value: "7218: Industrial launderers" },
  { key: "7221", label: "7221: Photographic studios, portrait", value: "7221: Photographic studios, portrait" },
  { key: "7251", label: "7251: Shoe repair and shoeshine parlors", value: "7251: Shoe repair and shoeshine parlors" },
  { key: "7299", label: "7299: Miscellaneous personal services", value: "7299: Miscellaneous personal services" },
  { key: "7311", label: "7311: Advertising agencies", value: "7311: Advertising agencies" },
  { key: "7313", label: "7313: Radio, television, publisher representatives", value: "7313: Radio, television, publisher representatives" },
  { key: "7322", label: "7322: Adjustment and collection services", value: "7322: Adjustment and collection services" },
  { key: "7336", label: "7336: Commercial art and graphic design", value: "7336: Commercial art and graphic design" },
  { key: "7349", label: "7349: Building maintenance services", value: "7349: Building maintenance services" },
  { key: "7361", label: "7361: Employment agencies", value: "7361: Employment agencies" },
  { key: "7363", label: "7363: Help supply services", value: "7363: Help supply services" },
  { key: "7371", label: "7371: Custom computer programming services", value: "7371: Custom computer programming services" },
  { key: "7372", label: "7372: Prepackaged software", value: "7372: Prepackaged software" },
  { key: "7373", label: "7373: Computer integrated systems design", value: "7373: Computer integrated systems design" },
  { key: "7374", label: "7374: Data processing and preparation", value: "7374: Data processing and preparation" },
  { key: "7375", label: "7375: Information retrieval services", value: "7375: Information retrieval services" },
  { key: "7376", label: "7376: Computer facilities management", value: "7376: Computer facilities management" },
  { key: "7378", label: "7378: Computer maintenance and repair", value: "7378: Computer maintenance and repair" },
  { key: "7379", label: "7379: Computer related services", value: "7379: Computer related services" },
  { key: "7389", label: "7389: Business services", value: "7389: Business services" },
  { key: "7519", label: "7519: Utility trailer rental", value: "7519: Utility trailer rental" },
  { key: "7538", label: "7538: General automotive repair shops", value: "7538: General automotive repair shops" },
  { key: "7539", label: "7539: Automotive repair shops", value: "7539: Automotive repair shops" },
  { key: "7622", label: "7622: Radio and television repair", value: "7622: Radio and television repair" },
  { key: "7641", label: "7641: Reupholstery and furniture repair", value: "7641: Reupholstery and furniture repair" },
  { key: "7692", label: "7692: Welding repair", value: "7692: Welding repair" },
  { key: "7812", label: "7812: Motion picture and video production", value: "7812: Motion picture and video production" },
  { key: "7819", label: "7819: Services allied to motion pictures", value: "7819: Services allied to motion pictures" },
  { key: "7911", label: "7911: Dance studios, schools, and halls", value: "7911: Dance studios, schools, and halls" },
  { key: "7922", label: "7922: Theatrical producers and services", value: "7922: Theatrical producers and services" },
  { key: "7929", label: "7929: Entertainers and entertainment groups", value: "7929: Entertainers and entertainment groups" },
  { key: "7941", label: "7941: Sports clubs, managers, and promoters", value: "7941: Sports clubs, managers, and promoters" },
  { key: "7991", label: "7991: Physical fitness facilities", value: "7991: Physical fitness facilities" },
  { key: "7992", label: "7992: Public golf courses", value: "7992: Public golf courses" },
  { key: "7993", label: "7993: Coin-operated amusement devices", value: "7993: Coin-operated amusement devices" },
  { key: "7996", label: "7996: Amusement parks", value: "7996: Amusement parks" },
  { key: "7999", label: "7999: Amusement and recreation", value: "7999: Amusement and recreation" },
  { key: "8011", label: "8011: Offices and clinics of medical doctors", value: "8011: Offices and clinics of medical doctors" },
  { key: "8021", label: "8021: Offices and clinics of dentists", value: "8021: Offices and clinics of dentists" },
  { key: "8041", label: "8041: Offices and clinics of chiropractors", value: "8041: Offices and clinics of chiropractors" },
  { key: "8042", label: "8042: Offices and clinics of optometrists", value: "8042: Offices and clinics of optometrists" },
  { key: "8049", label: "8049: Offices of health practitioner", value: "8049: Offices of health practitioner" },
  { key: "8059", label: "8059: Nursing and personal care", value: "8059: Nursing and personal care" },
  { key: "8062", label: "8062: General medical and surgical hospitals", value: "8062: General medical and surgical hospitals" },
  { key: "8063", label: "8063: Psychiatric hospitals", value: "8063: Psychiatric hospitals" },
  { key: "8082", label: "8082: Home health care services", value: "8082: Home health care services" },
  { key: "8093", label: "8093: Specialty outpatient clinics", value: "8093: Specialty outpatient clinics" },
  { key: "8111", label: "8111: Legal services", value: "8111: Legal services" },
  { key: "8211", label: "8211: Elementary and secondary schools", value: "8211: Elementary and secondary schools" },
  { key: "8221", label: "8221: Colleges and universities", value: "8221: Colleges and universities" },
  { key: "8231", label: "8231: Libraries", value: "8231: Libraries" },
  { key: "8243", label: "8243: Data processing schools", value: "8243: Data processing schools" },
  { key: "8249", label: "8249: Vocational schools", value: "8249: Vocational schools" },
  { key: "8299", label: "8299: Schools and educational services", value: "8299: Schools and educational services" },
  { key: "8322", label: "8322: Individual and family services", value: "8322: Individual and family services" },
  { key: "8331", label: "8331: Job training and related services", value: "8331: Job training and related services" },
  { key: "8351", label: "8351: Child day care services", value: "8351: Child day care services" },
  { key: "8361", label: "8361: Residential care", value: "8361: Residential care" },
  { key: "8399", label: "8399: Social services", value: "8399: Social services" },
  { key: "8412", label: "8412: Museums and art galleries", value: "8412: Museums and art galleries" },
  { key: "8611", label: "8611: Business associations", value: "8611: Business associations" },
  { key: "8621", label: "8621: Professional organizations", value: "8621: Professional organizations" },
  { key: "8631", label: "8631: Labor organizations", value: "8631: Labor organizations" },
  { key: "8641", label: "8641: Civic and social associations", value: "8641: Civic and social associations" },
  { key: "8661", label: "8661: Religious organizations", value: "8661: Religious organizations" },
  { key: "8712", label: "8712: Architectural services", value: "8712: Architectural services" },
  { key: "8713", label: "8713: Surveying services", value: "8713: Surveying services" },
  { key: "8721", label: "8721: Accounting, auditing, and bookkeeping", value: "8721: Accounting, auditing, and bookkeeping" },
  { key: "8731", label: "8731: Commercial physical research", value: "8731: Commercial physical research" },
  { key: "8732", label: "8732: Commercial nonphysical research", value: "8732: Commercial nonphysical research" },
  { key: "8733", label: "8733: Noncommercial research organizations", value: "8733: Noncommercial research organizations" },
  { key: "8734", label: "8734: Testing laboratories", value: "8734: Testing laboratories" },
  { key: "8741", label: "8741: Management services", value: "8741: Management services" },
  { key: "8743", label: "8743: Public relations services", value: "8743: Public relations services" },
  { key: "8744", label: "8744: Facilities support services", value: "8744: Facilities support services" },
  { key: "8748", label: "8748: Business consulting", value: "8748: Business consulting" },
  { key: "9111", label: "9111: Executive offices", value: "9111: Executive offices" },
  { key: "9121", label: "9121: Legislative bodies", value: "9121: Legislative bodies" },
  { key: "9199", label: "9199: General government", value: "9199: General government" },
  { key: "9211", label: "9211: Courts", value: "9211: Courts" },
  { key: "9221", label: "9221: Police protection", value: "9221: Police protection" },
  { key: "9222", label: "9222: Legal counsel and prosecution", value: "9222: Legal counsel and prosecution" },
  { key: "9223", label: "9223: Correctional institutions", value: "9223: Correctional institutions" },
  { key: "9224", label: "9224: Fire protection", value: "9224: Fire protection" },
  { key: "9229", label: "9229: Public order and safety", value: "9229: Public order and safety" },
  { key: "9311", label: "9311: Finance, taxation, and monetary policy", value: "9311: Finance, taxation, and monetary policy" },
  { key: "9411", label: "9411: Administration of educational programs", value: "9411: Administration of educational programs" },
  { key: "9431", label: "9431: Administration of public health programs", value: "9431: Administration of public health programs" },
  { key: "9511", label: "9511: Air, water, and solid waste management", value: "9511: Air, water, and solid waste management" },
  { key: "9531", label: "9531: Housing programs", value: "9531: Housing programs" },
  { key: "9611", label: "9611: Administration of general economic programs", value: "9611: Administration of general economic programs" },
  { key: "9661", label: "9661: Space research and technology", value: "9661: Space research and technology" },
  { key: "9711", label: "9711: National security", value: "9711: National security" },
  { key: "9721", label: "9721: International affairs", value: "9721: International affairs" },
];

export const headcountList = ["1-10", "11-50", "51-200", "201-500", "501-1k", "1k-5k", "5k-10k", "10k+"];

/*
export const headcountList = [
  {
      label: "1-10",
    value: "1-10 employees",
  },
  {
      label: "11-50",
    value: "11-50 employees",
  },
  {
      label: "51-200",
    value: "51-200 employees",
  },
  {
      label: "201-500",
    value: "201-500 employees",
  },
  {
      label: "501-1k",
    value: "501-1000 employees",
  },
  {
      label: "1k-5k",
    value: "1,001-5,000 employees",
  },
  {
      label: "5k-10k",
    value: "5,001-10,000 employees",
  },
  {
      label: "10k+",
    value: "10,001+ employees",
  },
]

*/
/*
export const headcountList = [
  {
      label: "1-10",
    value: "1-10 employees",
  },
  {
      label: "11-50",
    value: "11-50 employees",
  },
  {
      label: "51-200",
    value: "51-200 employees",
  },
  {
      label: "201-500",
    value: "201-500 employees",
  },
  {
      label: "501-1k",
    value: "501-1000 employees",
  },
  {
      label: "1k-5k",
    value: "1,001-5,000 employees",
  },
  {
      label: "5k-10k",
    value: "5,001-10,000 employees",
  },
  {
      label: "10k+",
    value: "10,001+ employees",
  },
]*/

export const revenueList = ["$0-1M", "$1-10M", "$10-50M", "$50-100M", "$100-250M", "$250-500M", "$500-1B", ">$1B"];

export const informationOptionsArr = [
  "Business Email Address",
  "Any Phone",
  "Validated Email",
  "Direct Phone",
  "Personal Email Address",
  "Recently Updated",
  "Exclude My Extracted Contacts",
  "Changed Jobs Recently",
];

export const contactTypeList = ["All", "Business Email Address", "Personal Email Address", "Direct Phone", "Mobile Phone"];

export const accuracyTypeList = ["Validated Emails", "Updated In The Last 3 Months"];
