/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */

import { createSelector,createSlice } from '@reduxjs/toolkit';
// import colors from 'config/assets';
import { cancelGuestMeeting,countEmailClick,getavail,getavailAndGuest,gettimezones,reschGuestMeeting } from './asyncRequests';

import moment from 'moment';
import tz from 'moment-timezone';

// function loadingFailed(state, action) {
//   state.error = action.payload;
// }

// const INITIAL_STATE = {
//   user: {}, // do not reload this, it will put the dashboard in an unclickable loading mode. Only during upgrade/downgrade do that.
//   settings: {},
//   error: null as ErrorType,
//   loading: false,
//   settingsLoading: false,
//   account_status: null,
//   show_softdeleted_popup: false,
//   is_notifications_insist: false,
//   tutorialRun: false,
//   showTutorial: false, // not used
// }

const calSlice = createSlice({
  name: 'cal',
  initialState: {
    clientFreeSlotsUnix: [] as any[],
    guestAvail: [] as any[],
    timezone: '',
    timezones: [],
    afterBookQuestions: [],
    status: '', // error showCal booked bookedAlready
    timeBookedAlr: '', // Only if seen that this flow is completed
    daysAvail: [],
    startDT: Date.now(),
    dateNow: Date.now(),
    guestJwtErr: '',
    fullName: '',
    clientIdInGroup: null,
    title: '',
    addAnotherMetingURL: '',
    rescheduleMetingURL: '',
    metLength: 30,
    length: 30,
    selDtTog: true,
    difMonthTog: true, // just a toggle for fe efficiency will change each time a month is changed
    dayFocused: '',
    timeSubmitted: '',
    emailThread: '',
    guestCalIntegrated: false,
    error: null as string|null,
    loading: false,
    loadedFirstTimeCal: false,
    emailLoading: false,
    loadingCountEmailClick: false,
    guestEmail: '',
    meetingRescStart: '',
    linkToReschTo: '',
    animateCancOrResch: '' as string|null,
    questions: [] as string[],
    guestsList: [] as string[] ,
    answers: [] as string[],
    fadeBooked: 'fadeQuestionInPermThree',
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },

    guestAvailRemMeeting: (state, action) => {
      const guestArr = [...state.guestAvail];
      const id = action?.payload;
      const index = guestArr.findIndex((meeting) => meeting.id === id);

      if (index !== -1) {
        guestArr.splice(index, 1);
      }
      state.guestAvail = guestArr;
    },
    setGuestsList: (state, action) => {
      // const addEmail = ;
      state.guestsList = action?.payload;
    },
    addBookingMeetingEmails: (state, action) => {
      const addEmail = action?.payload;
      state.guestsList = [...state.guestsList, addEmail];
    },
    remBookingMeetingEmails: (state, action) => {
      const index = action?.payload;
      const copyArr = [...state.guestsList];

      copyArr.splice(index, 1);
      state.guestsList = copyArr;
    },
    addAnswer: (state, action) => {
      const index = action?.payload?.ind - 1;
      const copyArr = [...state.answers];

      if (index >= copyArr.length) {
        copyArr.push(action?.payload?.ans);
      } else {
        copyArr.splice(index, 1, action?.payload?.ans);
      }

      return {
        ...state,
        answers: copyArr,
      };
    },
    animateCancOrReschReset: (state) => {
      state.animateCancOrResch = null;
    },
    clearDaysAvail: (state) => {
      state.daysAvail = [];
    },
    slowInternet: (state) => {
      state.status = 'error';
    },
    setStatus: (state, action) => {
      state.status = action?.payload;
    },
    setFadeBooked: (state, action) => {
      state.fadeBooked = action?.payload;
    },
    // clearTimeSel: (state) => {
    //   state.timeSubmitted = '';
    // },
    // timeSel: (state, action) => {
    //   state.timeSubmitted = action?.payload;
    // },
    // actButtonTog: (state, action) => {
    //   state.actButton = action?.payload;
    // },
    // changeTheMlResOfCurrThread: (state, action) => {
    //   state.mlres = action?.payload;
    // },
    changeDayFocused: (state, action) => {
      state.dayFocused = action?.payload;
    },
    selDateToggle: (state) => {
      state.selDtTog = !state.selDtTog; // if click on today edge case
    },
    difMonthToggle: (state) => {
      state.difMonthTog = !state.difMonthTog;
    },
    changeDateNow: (state, action) => {
      state.dateNow = action?.payload;
    },

    setEmailThread: (state, action) => {
      state.emailThread = action?.payload;
    },
    changeTheTimezone: (state, action) => {
      state.timezone = action?.payload;
    },

    // setFreeSlotsInDay: (state, action) => {
    //   state.freeSlotsInDay = action?.payload;
    // },
    guestCalIsErrored: (state) => {
      state.guestCalIntegrated = false;
    },
  },
  extraReducers: (builder) => {
    builder
   .addCase(getavail.pending, (state) => {
      if (!state.loading) {
        state.loading = true;
      }
    })
  .addCase(getavail.fulfilled, (state, action) => {
    
      if (state.loading) {
        state.loading = false;
      }
      state.loadedFirstTimeCal =true
      if (action?.payload?.res === 'bookedAlready') {
        state.status = 'bookedAlready';
        state.timeBookedAlr = action?.payload?.times ? action?.payload?.times : '';
        state.addAnotherMetingURL = action?.payload?.addAnotherMetingURL;
        state.rescheduleMetingURL = action?.payload?.rescheduleMetingURL;
        return;
      }
      if (!action?.payload?.metLength && !action?.payload?.linkToReschTo) {console.log('err!2');
        state.status = 'error';
        return;
      }

      if (action.payload?.guestAvail === 'issue') {
        localStorage.removeItem('guestAvailJwt');
        state.guestJwtErr = '';
        state.guestCalIntegrated = false;
      } else {
        state.guestCalIntegrated = true;
      }
      const { date_start } = action.payload;
      state.clientFreeSlotsUnix = action.payload.freeSlots?.filter((ts) => ts.start >= date_start / 1000);

      let date;
      if (state.clientFreeSlotsUnix?.length) {
        date = moment.unix(state.clientFreeSlotsUnix[0].start).format('D');
      }
      const daysAvail = action.payload?.daysAvail?.length ? action.payload.daysAvail : [];
      if (date && daysAvail?.length) {
        const filteredDays = daysAvail?.filter((d) => d >= date);
        state.daysAvail = filteredDays;
      } else {
        state.daysAvail = daysAvail;
      }
      console.log(action.payload?.daysAvail, 'action.payload?.daysAvail122');
      if (action.payload.dayFocused) {
        state.dayFocused = action.payload.dayFocused;
      }
      if (action.payload.dateNow) {
        state.dateNow = action.payload.dateNow;
      }
      if (action.payload.questions) {
        state.questions = action.payload.questions;
      }
      if (action.payload.fullName) {
        state.fullName = action.payload.fullName;
      }
      if (action.payload.clientIdInGroup) {
        state.clientIdInGroup = action.payload.clientIdInGroup;
      }

      if (action.payload.metLength) {
        state.metLength = action.payload.metLength / 60;
      }
      if (action.payload.title) {
        state.title = action.payload.title;
      }
      if (action.payload.meetingStart) {
        state.meetingRescStart = action.payload.meetingStart;
      }
      if (action.payload.linkToReschTo) {
        state.linkToReschTo = action.payload.linkToReschTo;
      }
      state.guestAvail = action.payload?.guestAvail?.length && action.payload?.guestAvail !== 'issue' ? action.payload?.guestAvail : [];
      // state.timezone = action.payload.timezone;
      if (state.status !== 'booked') {
        state.status = 'showCal';
      }
    }).addCase(
    getavail.rejected, (state, action) => {
      if (state.loading) {
        state.loading = false;

        // state.loginError = action.error;
      }
      state.status = 'error';
    },)
 .addCase(getavailAndGuest.pending, (state) => {
      if (!state.loading) {
        state.loading = true;
      }
    },).addCase(
    getavailAndGuest.fulfilled, (state, action) => {
      if (state.loading) {
        state.loading = false
      }
      state.loadedFirstTimeCal = true
      if (action?.payload?.res === 'bookedAlready') {
        state.status = 'bookedAlready';
        state.timeBookedAlr = action?.payload?.times?.length ? action?.payload?.times[0] : '';
        state.addAnotherMetingURL = action?.payload?.addAnotherMetingURL;
        state.rescheduleMetingURL = action?.payload?.rescheduleMetingURL;
        return;
      }
      if (action.payload?.guestAvail === 'issue') {
        localStorage.removeItem('guestAvailJwt');
        state.guestJwtErr = '';
        state.guestCalIntegrated = false;
      } else {
        state.guestCalIntegrated = true;
      }

      const { date_start } = action.payload;
      // filter is to ensure that only future avail slots are given . later this can be incorporated in backend but its complex
      state.clientFreeSlotsUnix = action.payload.freeSlots?.filter((ts) => ts.start >= date_start / 1000);

      if (action.payload?.guestEmail) {
        state.guestsList = [action.payload.guestEmail];
        state.guestEmail = action.payload.guestEmail;
      }
      let date;
      if (state.clientFreeSlotsUnix?.length) {
        date = moment.unix(state.clientFreeSlotsUnix[0].start).format('D');
      }
      const daysAvail = action.payload?.daysAvail?.length ? action.payload.daysAvail : [];
      if (date && daysAvail?.length) {
        const filteredDays = daysAvail?.filter((d) => d >= date);
        state.daysAvail = filteredDays;
      } else {
        state.daysAvail = daysAvail;
      }
      console.log(action.payload?.daysAvail, 'action.payload?.daysAvail1');
      if (action.payload.dayFocused) {
        state.dayFocused = action.payload.dayFocused;
      }
      if (action.payload.dateNow) {
        state.dateNow = action.payload.dateNow;
      }
      if (action.payload.questions) {
        state.questions = action.payload.questions;
      }
      if (action.payload.fullName) {
        state.fullName = action.payload.fullName;
      }
      if (action.payload.clientIdInGroup) {
        state.clientIdInGroup = action.payload.clientIdInGroup;
      }
      if (!action?.payload?.metLength) {console.log('err!3');
        state.status = 'error';
        return;
      }
      if (action.payload.metLength) {
        state.metLength = action.payload.metLength / 60;
      }
      if (action.payload.title) {
        state.title = action.payload.title;
      }
      if (action.payload.meetingStart) {
        state.meetingRescStart = action.payload.meetingStart;
      }
      if (action.payload.linkToReschTo) {
        state.linkToReschTo = action.payload.linkToReschTo;
      }
      state.guestAvail = action.payload?.guestAvail?.length && action.payload?.guestAvail !== 'issue' ? action.payload?.guestAvail : [];
      //  state.timezone = action.pa yload.timezone;
      if (state.status !== 'booked') {
        state.status = 'showCal';
      }
    },).addCase(
    getavailAndGuest.rejected, (state, action) => {
      if (state.loading) {
        state.loading = false
        // state.loginError = action.error;
      }
      console.log("err!5", action)
      state.status = 'error';
    },).addCase(
    reschGuestMeeting.pending, (state) => {
      if (!state.emailLoading) {
        state.emailLoading = true;
      }
    },).addCase(
  reschGuestMeeting.fulfilled, (state, action) => {
      if (state.emailLoading) {
        state.animateCancOrResch = action.payload.res;
      }
    },).addCase(
    reschGuestMeeting.rejected, (state, action) => {
      if (state.emailLoading) {
        state.animateCancOrResch = `${action.error}`;
      }
    },).addCase(
    cancelGuestMeeting.pending, (state) => {
      if (!state.emailLoading) {
        state.emailLoading = true;
      }
    },).addCase(
    cancelGuestMeeting.fulfilled, (state, action) => {
      if (state.emailLoading) {
        state.animateCancOrResch = action.payload.res;
      }
    },).addCase(
    cancelGuestMeeting.rejected, (state, action) => {
      if (state.emailLoading) {
        state.animateCancOrResch = `${action.error}`
      }
    },).addCase(
  gettimezones.pending, (state) => {
      if (!state.emailLoading) {
        state.emailLoading = true;
      }
    },).addCase(
    gettimezones.fulfilled, (state, action) => {
      if (state.emailLoading) {
        state.emailLoading = false;
      }
      state.timezones = action.payload;
    },).addCase(
    gettimezones.rejected, (state, action) => {
      if (state.emailLoading) {
        state.emailLoading = false
        state.error = `${action.error}`
      }
    },).addCase(
    countEmailClick.pending, (state) => {
      state.loadingCountEmailClick = true;
    },
    ).addCase(countEmailClick.fulfilled, (state, action) => {
      state.loadingCountEmailClick = false;
      state.guestsList = action.payload.email ? [action.payload.email] : [];
    },).addCase(
    countEmailClick.rejected, (state, action) => {
      state.loadingCountEmailClick = false;
    },)
  },
});
const { reducer, actions } = calSlice;

export const {
  // logout,
  // timeSel,
  // setError,
  guestCalIsErrored,
  clearDaysAvail,
  addBookingMeetingEmails,
  slowInternet,
  // changeTheMlResOfCurrThread,
  // guestAvailJWT,
  addAnswer,
  // actButtonTog,
  // clearTimeSel,
  changeTheTimezone,
  changeDayFocused,
  changeDateNow,
  guestAvailRemMeeting,
  selDateToggle,
  animateCancOrReschReset,
  difMonthToggle,
  remBookingMeetingEmails,
  setFadeBooked,
  setStatus,
  setEmailThread,setGuestsList
  // setFreeSlotsInDay,
} = actions;

export const { clearError } = actions;

export default reducer;

export const calSelector = createSelector(
  (state) => state.cal,
  (cal) => cal
);
export const calMlSelector = createSelector(
  (state) => state.cal,
  (cal) => cal.mlres
);

export const errorSelector = createSelector(
  (state) => state.cal,
  (cal) => cal.error
);
