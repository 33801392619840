/* eslint-disable no-useless-return */
/* eslint-disable no-unused-vars */
import { createSelector, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {
  // addFreeNum,
  // cancelStripeSubscription,
  createPayIntent,
  createSubIntentNewClientsOnly,
  createSubscriptionV3,
  newActivatePaidClient,
  newActivateTrialClient,
} from "./asyncRequests";

const stripeSlice = createSlice({
  name: "stripe",
  initialState: {
    // phoneNumberReadable: null,
    // phoneNum: null,
    // phoneCountry: null,
    // billing_address: null,
    // coupon_value: null,
    // coupon_type: null,
    // coupon_name: null,
    // customer: null,
    // data: [],
    // error: null,
    // is_checkout: false,
    // loading: false,
    // loading_billing: false,
    // loading_coupon: false,
    // loading_history: false,
    // loading_invoice: false,
    // loading_payment: false,
    // phoneNumPurchaseErr: '',
    // phoneNumPurchaseNow: '',
    // invoiceHostedLink: '',
    // pros_data_cred_amount: 0,
    // isFirstPhoneNum: false,
    // loading_preview: false,
    // can_exit_checkout: true,
    // loading_phone_subscription: false,
    // loading_subscription: false,
    // next_invoice: null,
    // sort_param: null,
    // selected_price_id: null,
    // subscription_toggle: SUBSCRIPTION_INTERVAL.month,
    // subscription: null,
    // sales_cred_plan: 1000,
    // payment_method: null,
    // payment_history: [],
    // plans: null,
    // preview: null,
    loading_intent: false,
    payIntentId: "",
    payIntentIdentification: "",
    loading_upd_client_package: false,
    // price_id_mobile: "",
    // include_sched_mobile: true,
    // include_sched_desk: false,
    // pageData: {
    //   numberOfPages: null,
    //   page: 1,
    //   take: 10,
    // },
    // directions: {
    //   amount: true,
    //   card_number: true,
    //   date_created: false,
    // },
  },
  reducers: {
    // resetPhonePurchase: (state) => {
    //   state.phoneNumPurchaseNow = '';
    //   state.isFirstPhoneNum = false;
    // },
    resetPayIntent: (state) => {
      console.log("payIntentIdresetPayIntent");
      state.payIntentId = "";
      state.payIntentIdentification = "";
    },
    // clearCouponValue: (state) => {
    //   state.coupon_value = null;
    //   state.coupon_type = null;
    //   state.coupon_name = null;
    // },
    // clearError: (state) => {
    //   state.error = null;
    // },
    // clearPreview: (state) => {
    //   state.preview = null;
    //   state.loading_preview = false;
    // },
    // canExitCheckout: (state, action) => {
    //   state.can_exit_checkout = action.payload;
    // },
    // changePriceId: (state, action) => {
    //   state.price_id_mobile = action.payload;
    // },
    // changeInclSchedDesk: (state, action) => {
    //   state.include_sched_desk = action.payload;
    // },
    // toggleInclSched: (state, action) => {
    //   if (!action.payload) {
    //     state.include_sched_mobile = !state.include_sched_mobile;
    //   } else {
    //     state.include_sched_mobile = action.payload;
    //   }
    // },
    // toggleSubscriptionSelect: (state, action) => {
    //   state.subscription_toggle = action.payload;
    // },
    // openCheckoutSalesCred: (state, action) => {
    //   state.phoneNum = '';
    //   state.country = '';
    //   state.pros_data_cred_amount = action.payload;
    //   state.selected_price_id = '';
    //   state.is_checkout = true;
    // },
    // openCheckout: (state, action) => {
    //   state.phoneNum = '';
    //   state.country = '';
    //   state.pros_data_cred_amount = 0;
    //   state.selected_price_id = action.payload;
    //   state.is_checkout = true;
    // },
    // changeSalesCredExtra: (state, action) => {
    //   state.sales_cred_plan = action.payload;
    // },
    // openCheckoutPhone: (state, action) => {
    //   state.selected_price_id = '';
    //   state.pros_data_cred_amount = 0;
    //   state.phoneNumberReadable = action.payload.num?.friendlyName;
    //   state.phoneNum = action.payload.num?.phoneNumber;
    //   state.phoneCountry = action.payload.countryName;
    //   state.is_checkout = true;
    // },
    // closeCheckout: (state) => {
    //   state.is_checkout = false;
    //   state.sales_cred_plan = 1000;
    //   // state.selected_price_id = null;
    // },
    // error3dPayment: (state) => {
    //   state.loading_subscription = false;
    //   if (state.subscription?.status === SUBSCRIPTION_STATUS.incomplete) {
    //     state.error = {
    //       message: `Payment was not approved by the customer. Subscription was not created.`,
    //       color: colors.error,
    //       show: true,
    //     };
    //   }
    //   state.subscription = null;
    // },
    // succeed3dPayment: (state) => {
    //   state.loading_subscription = false;
    //   if (state.subscription?.status === SUBSCRIPTION_STATUS.incomplete) {
    //     state.loading_subscription = false;
    //     state.error = {
    //       message: `Successfully created your subscription. Next billing period starts on ${moment(
    //         state?.subscription?.current_period_end * 1000
    //       ).format('DD/MM/YY')}`,
    //       color: colors.success,
    //       show: true,
    //     };
    //     state.subscription.status = SUBSCRIPTION_STATUS.active;
    //   }
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPayIntent.pending, (state) => {
        console.log("payload??pending");
        state.loading_intent = true;
      })
      .addCase(createPayIntent.rejected, (state, action) => {
        console.log("payload??rejected", action);
        if (state.loading_intent) {
          state.loading_intent = false;
          // state.error = action.error
        }
      })
      .addCase(createPayIntent.fulfilled, (state, action) => {
        if (state.loading_intent) {
          state.loading_intent = false;
        }
        // console.log(action.payload, "payload??fulfilled")
        state.payIntentId = action.payload.payIntentId;
        state.payIntentIdentification = action.payload.payIntentIdentification;
      })
      .addCase(createSubIntentNewClientsOnly.pending, (state) => {
        console.log("payload??pending");
        state.loading_intent = true;
      })
      .addCase(createSubIntentNewClientsOnly.rejected, (state, action) => {
        console.log("payload??rejected", action);
        if (state.loading_intent) {
          state.loading_intent = false;
          // state.error = action.error
        }
      })
      .addCase(createSubIntentNewClientsOnly.fulfilled, (state, action) => {
        if (state.loading_intent) {
          state.loading_intent = false;
        }
        // console.log(action.payload, "payload??fulfilled")
        state.payIntentId = action.payload.payIntentId;
        state.payIntentIdentification = action.payload.payIntentIdentification;
      })
      .addCase(newActivateTrialClient.pending, (state) => {
        state.loading_upd_client_package = true;
      })
      .addCase(newActivateTrialClient.rejected, (state, action) => {
        // console.log("payload??rejected", action);
        if (state.loading_upd_client_package) {
          state.loading_upd_client_package = false;
          // state.error = action.error
        }
      })
      .addCase(newActivateTrialClient.fulfilled, (state, action) => {
        if (state.loading_upd_client_package) {
          state.loading_upd_client_package = false;
        }
      })

      .addCase(createSubscriptionV3.pending, (state) => {
        state.loading_upd_client_package = true;
      })
      .addCase(createSubscriptionV3.rejected, (state, action) => {
        // console.log("payload??rejected", action);
        if (state.loading_upd_client_package) {
          state.loading_upd_client_package = false;
          // state.error = action.error
        }
      })
      .addCase(createSubscriptionV3.fulfilled, (state, action) => {
        if (state.loading_upd_client_package) {
          state.loading_upd_client_package = false;
        }
      });
    // [addFreeNum.pending]: (state) => {
    //   state.loading_phone_subscription = true;
    // },
    // [addFreeNum.rejected]: (state, action) => {
    //   if (state.loading_phone_subscription) {
    //     state.loading_phone_subscription = false;
    //   }
    //   console.log(action, 'addFreeNum error'); // this error is not working properly for some reason
    //   if (action?.code === 21422) {
    //     action.message = 'The selected phone number is not available anymore, please choose a different one 🥲';
    //   } else if (action?.code === 21628 || action?.code === 21629) {
    //     action?.message === "Error: Address couldn't be verified. Make sure your address is complete and accurate.";
    //   } else if (action?.code === 21615) {
    //     action?.message === 'Error: Address must be a local address to the phone number location.';
    //   } else if (action?.message === 'Error: Your card number is incorrect.') {
    //     action.message = `${action.message} Supported card types are ${Object.keys(CARD_TYPES).join(', ')}.`;
    //   } else {
    //     action.error.message = 'An error occured. Please try again later';
    //   }

    //   state.error = {
    //     message: action.error?.message,
    //     color: colors.error,
    //     show: true,
    //   };
    //   state.is_checkout = false;
    //   state.selected_price_id = null;
    // },
    // [addFreeNum.fulfilled]: (state, action) => {
    //   if (state.loading_phone_subscription) {
    //     state.loading_phone_subscription = false;
    //   }
    //   console.log(action, 'addFreeNum');
    //   const { phoneNumPurchaseNow, isFirstPhoneNum } = action.payload;
    //   state.phoneNumPurchaseNow = phoneNumPurchaseNow;
    //   state.isFirstPhoneNum = isFirstPhoneNum;
    // },
    // [createPhoneSubscription.pending]: (state) => {
    //   state.loading_phone_subscription = true;
    // },
    // [createPhoneSubscription.rejected]: (state, action) => {
    //   if (state.loading_phone_subscription) {
    //     state.loading_phone_subscription = false;
    //   }
    //   console.log(action, 'action error');
    //   if (action?.code === 21422) {
    //     action.message = 'The selected phone number is not available anymore, please choose a different one 🥲';
    //   } else if (action?.code === 21628 || action?.code === 21629) {
    //     action?.message === "Error: Address couldn't be verified. Make sure your address is complete and accurate.";
    //   } else if (action?.code === 21615) {
    //     action?.message === 'Error: Address must be a local address to the phone number location.';
    //   } else if (action?.message === 'Error: Your card number is incorrect.') {
    //     action.message = `${action.message} Supported card types are ${Object.keys(CARD_TYPES).join(', ')}.`;
    //   } else {
    //     action.error.message = 'An error occured. Please try again later';
    //   }

    //   state.error = {
    //     message: action.error?.message,
    //     color: colors.error,
    //     show: true,
    //   };
    //   state.is_checkout = false;
    //   state.selected_price_id = null;
    // },
    // [createPhoneSubscription.fulfilled]: (state, action) => {
    //   if (state.loading_phone_subscription) {
    //     state.loading_phone_subscription = false;
    //   }
    //   const { phoneNumPurchaseNow, isFirstPhoneNum } = action.payload;
    //   state.phoneNumPurchaseNow = phoneNumPurchaseNow;
    //   state.isFirstPhoneNum = isFirstPhoneNum;
    // },
    // [createSubscription.pending]: (state) => {
    //   state.loading_subscription = true;
    // },
    // [createSubscription.rejected]: (state, action) => {
    //   state.loading_subscription = false;

    //   if (action.error?.message === 'Error: Your card number is incorrect.') {
    //     action.error.message = `${action.error.message} Supported card types are ${Object.keys(CARD_TYPES).join(', ')}.`;
    //   } else {
    //     action.error.message = 'An error occured. Please try again later';
    //   }

    //   state.error = {
    //     message: action.error?.message,
    //     color: colors.error,
    //     show: true,
    //   };
    //   state.is_checkout = false;
    //   state.selected_price_id = null;
    // },
    // [createSubscription.fulfilled]: (state, action) => {
    //   state.loading_subscription = false;

    //   const res = action?.payload;
    //   if (action.payload?.subscription?.status === SUBSCRIPTION_STATUS.incomplete) {
    //     state.is_checkout = false;
    //     state.selected_price_id = null;
    //     // state.loading_subscription = true;
    //   } else {
    //     state.error = {
    //       message: `Successfully created your subscription. Next billing period starts on ${moment(
    //         action.payload?.subscription?.current_period_end * 1000
    //       ).format('DD/MM/YY')}`,
    //       color: colors.success,
    //       show: true,
    //     };
    //   }

    //   if (state.loading_subscription && action.payload?.subscription?.status !== SUBSCRIPTION_STATUS.incomplete) {
    //     state.loading_subscription = false;
    //   }
    //   state.loading_subscription = false;

    //   state.subscription = action.payload.subscription;
    // },

    // [getLatestSubIssue.pending]: (state) => {
    //   // if (!state.loading_subscription) {
    //   //   state.loading_subscription = true;
    //   // }
    // },
    // [getLatestSubIssue.rejected]: (state, action) => {
    //   // if (state.loading_subscription) {
    //   //   state.error = action.error;
    //   // }
    //   // state.loading_subscription = false;
    // },
    // [getLatestSubIssue.fulfilled]: (state, action) => {
    //   // state.loading_subscription = false;
    //   if (action.payload.clientSecret) {
    //     state.invoiceHostedLink = action.payload.clientSecret;
    //     return;
    //   }
    // },
    // // @cancel subscription
    // [cancelStripeSubscription.pending]: (state) => {
    //   if (!state.loading_subscription) {
    //     state.loading_subscription = true;
    //   }
    // },
    // [cancelStripeSubscription.rejected]: (state, action) => {
    //   if (state.loading_subscription) {
    //     state.error = action.error;
    //   }
    //   state.loading_subscription = false;
    // },
    // [cancelStripeSubscription.fulfilled]: (state) => {
    //   if (state.loading_subscription) {
    //     state.subscription = null;
    //     state.coupon_type = null;
    //     state.coupon_value = null;
    //   }
    //   state.loading_subscription = false;
    // },

    // [getPreviewOnSubChange.pending]: (state) => {
    //   state.loading_preview = true;
    // },
    // [getPreviewOnSubChange.rejected]: (state, action) => {
    //   if (state.loading_preview) {
    //     state.loading_preview = false;
    //     state.error = action.error;
    //   }
    // },
    // [getPreviewOnSubChange.fulfilled]: (state, action) => {
    //   if (state.loading_preview) {
    //     state.loading_preview = false;
    //   }
    //   state.preview = action.payload;
    // },

    // [fetchPlans.pending]: (state) => {
    //   if (!state.loading) {
    //     state.loading = true;
    //   }
    // },
    // [fetchPlans.rejected]: (state, action) => {
    //   if (state.loading) {
    //     state.loading = false;
    //   }
    //   state.error = action.error;
    // },
    // [fetchPlans.fulfilled]: (state, action) => {
    //   if (state.loading) {
    //     state.loading = false;
    //   }
    //   state.plans = action.payload;
    // },

    // // @ billing history
    // [fetchBillingHistory.pending]: (state, action) => {
    //   if (!state.loading_history) {
    //     state.loading_history = true;
    //   }

    //   if (action?.meta?.arg?.sort_param) {
    //     state.sort_param = action.meta.arg.sort_param;
    //   }
    // },
    // [fetchBillingHistory.fulfilled]: (state, action) => {
    //   state.loading_history = false;
    //   state.payment_history = action.payload.data;
    //   state.pageData = action.payload.pageData;
    //   if (action.payload.directions) {
    //     state.directions = action.payload.directions;
    //   }
    // },
    // [fetchBillingHistory.rejected]: (state, action) => {
    //   state.loading_history = false;
    //   state.error = action.error;
    // },

    // // @ billing address
    // [fetchBillingAddress.pending]: (state) => {
    //   if (!state.loading_billing) {
    //     state.loading_billing = true;
    //   }
    // },
    // [fetchBillingAddress.fulfilled]: (state, action) => {
    //   if (state.loading_billing) {
    //     state.loading_billing = false;
    //     if (Object.keys(action.payload).length > 0) {
    //       state.billing_address = action.payload;
    //     }
    //   }
    // },
    // [fetchBillingAddress.rejected]: (state, action) => {
    //   if (state.loading_billing) {
    //     state.loading_billing = false;
    //     state.error = action.error;
    //   }
    // },

    // // @ payment method AKA card
    // [fetchPaymentMethod.pending]: (state) => {
    //   if (!state.loading_payment) {
    //     state.loading_payment = true;
    //   }
    // },
    // [fetchPaymentMethod.fulfilled]: (state, action) => {
    //   if (state.loading_payment) {
    //     state.loading_payment = false;
    //     if (Object.keys(action.payload).length > 0) {
    //       state.payment_method = action.payload;
    //     }
    //   }
    // },
    // [fetchPaymentMethod.rejected]: (state, action) => {
    //   if (state.loading_payment) {
    //     state.loading_payment = false;
    //     state.error = action.error;
    //   }
    // },

    // // @ next invoice
    // [fetchNextInvoice.pending]: (state) => {
    //   if (!state.loading_invoice) {
    //     state.loading_invoice = true;
    //   }
    // },
    // [fetchNextInvoice.fulfilled]: (state, action) => {
    //   if (state.loading_invoice) {
    //     state.loading_invoice = false;
    //     state.next_invoice = action.payload;
    //   }
    // },
    // [fetchNextInvoice.rejected]: (state, action) => {
    //   if (state.loading_invoice) {
    //     state.loading_invoice = false;
    //     state.error = action.error;
    //   }
    // },

    // [fetchStripeCustomer.rejected]: (state, action) => {
    //   state.error = action.error;
    // },
    // [fetchStripeCustomer.fulfilled]: (state, action) => {
    //   state.customer = action.payload;
    // },

    // // @get current subscription status
    // [getCurrentSubscription.rejected]: (state) => {
    //   if (state.loading) {
    //     state.loading = false;
    //   }
    // },
    // [getCurrentSubscription.fulfilled]: (state, action) => {
    //   if (state.loading) {
    //     state.loading = false;
    //   }

    //   state.subscription = action.payload;
    // },

    // // @coupon validation
    // [handleCouponValidation.pending]: (state) => {
    //   state.loading_coupon = true;
    // },
    // [handleCouponValidation.fulfilled]: (state, action) => {
    //   state.coupon_value = action.payload?.value;
    //   state.coupon_type = action.payload?.type;
    //   state.coupon_name = action.payload?.coupon_name;
    //   state.loading_coupon = false;
    // },
    // [handleCouponValidation.rejected]: (state, action) => {
    //   state.error = {
    //     message: action.error?.message || `Coupon code is not valid`,
    //     color: colors.error,
    //     show: true,
    //   };
    //   state.coupon_value = 'error';
    //   state.coupon_type = null;
    //   state.coupon_name = null;
    //   state.loading_coupon = false;
    // },

    // // add billing address
    // [postBillingAddress.pending]: (state) => {
    //   if (!state.loading_billing) {
    //     state.loading_billing = true;
    //   }
    // },
    // [postBillingAddress.fulfilled]: (state, action) => {
    //   if (state.loading_billing) {
    //     state.loading_billing = false;
    //     state.billing_address = action.payload;
    //   }
    // },
    // [postBillingAddress.rejected]: (state, action) => {
    //   if (state.loading_billing) {
    //     state.loading_billing = false;
    //     state.error = action.error;
    //   }
    // },

    // // @ payment method AKA card
    // [postPaymentMethod.pending]: (state) => {
    //   if (!state.loading_payment) {
    //     state.loading_payment = true;
    //   }
    // },
    // [postPaymentMethod.fulfilled]: (state, action) => {
    //   if (state.loading_payment) {
    //     state.loading_payment = false;
    //     state.payment_method = action.payload;
    //     state.billing_address = { ...state.billing_address, name: action.payload?.name_on_card };
    //   }
    // },
    // [postPaymentMethod.rejected]: (state, action) => {
    //   if (state.loading_payment) {
    //     state.loading_payment = false;
    //     state.error = action.error;
    //   }
    // },

    // [updateSubscription.pending]: (state) => {
    //   state.loading_subscription = true;
    // },
    // [updateSubscription.rejected]: (state, action) => {
    //   state.loading_subscription = false;

    //   if (action.error?.message === 'Error: Your card number is incorrect.') {
    //     action.error.message = `${action.error.message} Supported card types are ${Object.keys(CARD_TYPES).join(', ')}.`;
    //   } else {
    //     action.error.message = 'An error occured. Please try again later';
    //   }

    //   state.error = {
    //     message: action.error?.message,
    //     color: colors.error,
    //     show: true,
    //   };
    //   state.is_checkout = false;
    //   // state.selected_price_id = null;
    // },
    // [updateSubscription.fulfilled]: (state, action) => {
    //   state.loading_subscription = false;
    //   const subscription = action.payload.subscription || action.payload.subscription_type;
    //   state.subscription = subscription;
    //   const time = subscription?.current_period_end;
    //   state.error = {
    //     message: `Successfully updated your subscription. This period ends on ${moment(time * 1000).format('DD/MM/YY')}`,
    //     color: colors.success,
    //     show: true,
    //   };
    //   state.is_checkout = false;
    //   state.selected_price_id = null;
    // },
  },
});

const { actions, reducer } = stripeSlice;

// eslint-disable-next-line no-empty-pattern
export const {
  // clearCouponValue,
  // clearError,
  // clearPreview,
  // closeCheckout,
  // openCheckout,
  // toggleSubscriptionSelect,
  // error3dPayment,
  // succeed3dPayment,
  // changePriceId,
  // toggleInclSched,
  // canExitCheckout,
  // changeInclSchedDesk,
  // openCheckoutPhone,
  // changeSalesCredExtra,
  // resetPhonePurchase,
  // openCheckoutSalesCred,
  resetPayIntent,
} = actions;

export default reducer;

export const stripeSelector = createSelector(
  (state) => state.stripe,
  (stripe) => stripe
);
