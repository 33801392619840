/* eslint-disable react/jsx-fragments */
import { Button, Checkbox, Flex, Form, Input, Radio, Row, Space, Tag, Tooltip, Typography, message } from "antd";
import React, { FC, useEffect, useState } from "react";
import { ConfirmButtons, Modal } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import "./index.less";
import { useDispatch } from "react-redux";
import { updateOptimisticEmails } from "src/store/outreach-emails";
import { updateEmailLim } from "src/store/outreach-emails/asyncRequests";

type EmailSendingLimitsProps = {
  onCancel: VoidFunction;
  emailData: any;
};

const emailsPerDay = [
  {
    id: "1",
    day: "1",
    value: "10",
  },
  {
    id: "2",
    day: "3",
    value: "16",
  },
  {
    id: "3",
    day: "4",
    value: "4/40",
  },
  {
    id: "4",
    day: "5",
    value: "20",
  },
  {
    id: "5",
    day: "6",
    value: "32",
  },
];

export const warmUpTooltipText =
  "Slowly increment sends day by day to warm up your email address.\n" +
  "The best part? Since every email is completely unique the warm up will be even more effective since it looks handwritten and not templated!";

export const updateLimitTooltipText = (
  <Flex vertical gap={16}>
    <Typography.Paragraph>Not recommended to go over 300 emails per day or over 100 for a new email address.</Typography.Paragraph>
  </Flex>
);
export const updateWarmUpLimitTooltipText = (
  <Flex vertical gap={16}>
    <Typography.Paragraph>Any Update Will Reset the Warm Up Day to 1 </Typography.Paragraph>
    <Typography.Paragraph>Not recommended to go over 300 emails per day or over 100 for a new email address.</Typography.Paragraph>
  </Flex>
);

export const EmailSendingLimitsModal: FC<EmailSendingLimitsProps> = ({ onCancel, emailData }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [form] = Form.useForm();

  // keep first indexed number the same so that the below use effect works
  const defaultWarmUp = [10, 16, 24, 20, 32, 25, 43, 50, 70, 61, 75, 80, 95, 86, 98, 100, 85, 120, 112, 150];
  const fastWarmUp = [16, 24, 30, 24, 43, 52, 80, 91, 102, 115, 134, 150];
  const slowWarmUp = [
    8, 16, 24, 18, 20, 32, 25, 43, 50, 57, 54, 62, 70, 61, 75, 80, 95, 86, 98, 102, 106, 97, 110, 113, 120, 134, 126, 128, 134, 139, 145, 142, 140, 138, 125,
    129, 143, 134, 147, 150,
  ];

  useEffect(() => {
    if (open) {
      // console.log(emailData?.sends_per_day?.length===1 ||emailData?.sends_per_day[0]===10 ? 'Recommended':emailData?.sends_per_day[0]===16  ?'Quick'  : 'Long','sss');
      form.setFieldsValue({
        warmUp: emailData?.sends_per_day?.length !== 1,
        warmType:
          emailData?.sends_per_day?.length === 1 || emailData?.sends_per_day[0] === 10 ? "Recommended" : emailData?.sends_per_day[0] === 16 ? "Quick" : "Long",
      });
    }
  }, [emailData, !open]);

  const closeModal = () => {
    onCancel();
    // form.resetFields()
  };

  const handleButtonClick = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const handleFormSubmit = () => {
    console.log(" test");
    onCancel();
    form.resetFields();
  };
  const dispatch = useDispatch();

  const [formData, setFormData] = useState<any>();
  useEffect(() => {
    const formValues = form.getFieldsValue();
    setFormData(formValues);
    // console.log(formValues,'formValues');
  }, [form]);

  const handleSubmit = () => {
    const formValues = form.getFieldsValue();
    console.log(formValues, "sendingArr1", emailData);
    // warmUp true/false warmType long/recommended/quick and limit is a string
    // limit is string sometimes
    if (formValues?.warmUp || formValues?.limit) {
      const sendingArr = formValues?.limit
        ? [+formValues?.limit || 100]
        : formValues?.warmType === "Recommended"
        ? defaultWarmUp
        : formValues?.warmType === "Quick"
        ? fastWarmUp
        : slowWarmUp;
      console.log(sendingArr, "sendingArr2");
      dispatch(updateOptimisticEmails([{ value: { sends_per_day: sendingArr }, id: emailData?.id }]));
      dispatch(updateEmailLim({ sendingArr, id: emailData?.id }));
      message.info(formValues?.limit ? "Updating the daily email sends" : "Updating email warm up");
    } else {
      message.error("Needs to have at least 1 email send per day.");
    }
  };

  const handleToggleWarmUp = (event) => {
    form.setFieldsValue({ warmUp: event?.target?.checked });
  };

  const onValueChange = (changedValues: any, allValues: any) => {
    // console.log('test',allValues);
    setFormData(allValues);
  };
  const isWarmUp = form.getFieldValue("warmUp");
  const warmType = form.getFieldValue("warmType");
  // console.log(emailData?.sends_per_day,'warmType');

  const handleInputChange = (e) => {
    let value = e.target.value;
    // Remove leading zeros
    value = value.replace(/^0+/, "");
    if (isNaN(value) || value <= 0) {
      form.setFieldsValue({ limit: null });
    } else if (value > 1500) {
      form.setFieldsValue({ limit: 1500 });
    } else {
      form.setFieldsValue({ limit: Math.floor(Number(value)) });
    }
  };

  return (
    <Modal width={530} customClassName={"sending-limits-modal"} open title={"Email Sending Limits"} onCancel={closeModal}>
      <Tag>{emailData?.email_alias_emailofcal}</Tag>
      <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={handleFormSubmit} onValuesChange={onValueChange}>
        {!isWarmUp ? (
          <>
            <Row justify={"space-between"} align={"middle"} wrap={false} style={{ marginBottom: 8 }}>
              <Typography.Title level={4}>
                Daily Email Limit{" "}
                <Tooltip placement={"bottomRight"} title={updateLimitTooltipText}>
                  <SvgIcon type={"tooltip"} style={{ marginLeft: 3, cursor: "pointer" }} />
                </Tooltip>
              </Typography.Title>
            </Row>
            <Form.Item name={"limit"} initialValue={emailData?.sends_per_day?.length === 1 ? emailData?.sends_per_day[0] : 100}>
              <Input onChange={handleInputChange} />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item name={"warmType"} label={"Warm Up Schedule"}>
              <Radio.Group defaultValue={warmType}>
                <Space direction={"vertical"}>
                  <Radio value={"Recommended"}>Recommended - 20 days</Radio>
                  <Radio value={"Quick"}>Quick - 12 days</Radio>
                  <Radio value={"Long"}>Long - 40 days</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Row justify={"space-between"} align={"middle"} wrap={false} style={{ marginBottom: 18 }}>
              <Typography.Title level={4}>
                Email Sends Per Day{" "}
                <Tooltip placement={"bottomRight"} title={updateWarmUpLimitTooltipText}>
                  <SvgIcon type={"tooltip"} style={{ marginLeft: 3, cursor: "pointer" }} />
                </Tooltip>
              </Typography.Title>
            </Row>
            <Flex gap={20} style={{ overflowX: "scroll" }}>
              {(warmType === "Recommended" ? defaultWarmUp : warmType === "Quick" ? fastWarmUp : slowWarmUp).map((amount, index) => {
                return (
                  <Space
                    key={`${index}`}
                    // className={`emails-qty-btn`}
                    className={`emails-qty-btn ${index === activeIndex ? "active" : ""}`}
                    direction={"vertical"}
                    style={{ margin: "8px 0 28px", justifyContent: "center", alignItems: "center" }}
                    // onClick={() => handleButtonClick(index)}
                  >
                    <Typography.Paragraph className={"emails-qty-day"}>{`Day ${index + 1}`}</Typography.Paragraph>
                    <Typography.Paragraph className={`emails-qty ${index === activeIndex ? "active" : ""}`}>{amount}</Typography.Paragraph>
                  </Space>
                );
              })}
            </Flex>
          </>
        )}
        <Form.Item name={"warmUp"} style={{ marginTop: "20px" }}>
          <Checkbox className={"warm-up-checkbox"} checked={isWarmUp} onChange={handleToggleWarmUp}>
            Warm Up Email Schedule{" "}
            <Tooltip placement={"bottomRight"} title={warmUpTooltipText}>
              <SvgIcon type={"tooltip"} style={{ marginLeft: 8 }} />
            </Tooltip>
          </Checkbox>
        </Form.Item>

        <ConfirmButtons leftButtonTitle={"Back"} rightButtonTitle={"Save"} handleSubmit={handleSubmit} onCancel={closeModal} />
      </Form>
    </Modal>
  );
};
