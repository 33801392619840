import { Row } from "antd"
import React, { FC } from "react"
import { StatusTag } from "src/components/ui"
import { useSelector } from "react-redux"

interface HeaderTagsBarProps {
  totalAmount: number
  selectedAmount: number
removeSelectedPros?: ()=>void
}

export const HeaderTagsBar: FC<HeaderTagsBarProps> = ({ totalAmount, selectedAmount,removeSelectedPros }) => {


  return (
    <Row style={{ gap: 16 }} wrap={false}>
      <StatusTag value={"Total filtered prospects"} color={"default"} count={totalAmount ?? "-"} />
      <StatusTag value={"Amount of prospects selected"} color={"processing"} count={selectedAmount ?? "-"} removeAction={removeSelectedPros} showRemActionIcon />
    </Row>
  )
}
